import unknownCharacter from '../../local-assets/aia-specific/unknown-character.png'
import tokenIcon from '../../local-assets/aia-specific/aia-token.png'
import exitButton from '../../local-assets/generic/exit-white.svg'
import exitButtonDark from '../../local-assets/generic/exit.svg'
import winPopup from '../../local-assets/game-play/win-popup.webp'
import losePopup from '../../local-assets/game-play/lose-popup.webp'
import tiePopup from '../../local-assets/game-play/tie-popup.webp'
import clock from '../../local-assets/game-play/battle-clock.webp'
import homeIcon from '../../local-assets/game-play/home-icon.svg'
import gearIcon from '../../local-assets/game-play/gear-icon.svg'
import redoIcon from '../../local-assets/game-play/redo-icon.svg'
import breedingIcon from '../../local-assets/game-play/dna.png'
import hourglass from '../../local-assets/game-play/hourglass.svg'
import boxingGloves from '../../local-assets/game-play/boxing.svg'
import contractIcon from '../../local-assets/generic/clipboard.webp'
import saveIcon from '../../local-assets/generic/save.svg'
import eyeIcon from '../../local-assets/generic/eye.png'

import keyboardBasic from '../../local-assets/game-play/keyboard-visual.svg'
import keyboardJump from '../../local-assets/game-play/keyboard-visual-new.svg'
import keyboardLowKick from '../../local-assets/game-play/keyboard-visual-lowkick.svg'

const battleAssets = {
  token: tokenIcon,
  eye: eyeIcon,
  exit: exitButton,
  exitDark: exitButtonDark,
  win: winPopup,
  lose:losePopup,
  tie: tiePopup,
  clock: clock,
  home: homeIcon,
  gear: gearIcon,
  redo: redoIcon,
  breed: breedingIcon,
  hourglass: hourglass,
  gloves: boxingGloves,
  contract: contractIcon,
  unknownCharacter: unknownCharacter,
  save: saveIcon
}

const nActionsToKeyboard = {
  5: keyboardBasic,
  9: keyboardJump,
  10: keyboardLowKick,
}

export {
  battleAssets,
  nActionsToKeyboard
}
