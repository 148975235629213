import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader'
import * as THREE from 'three'
// import { GUI } from 'dat.gui'

const setUpBloom = (renderer, scene, camera, bloomParams) => {
  if (bloomParams === undefined) {
    bloomParams = {
      exposure: 0.5,
      bloomStrength: 1.4,
      bloomThreshold: 0,
      bloomRadius: 0
    };
  }

  const renderScene = new RenderPass( scene, camera );
  
  const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
  bloomPass.exposure = bloomParams.exposure;
  bloomPass.threshold = bloomParams.bloomThreshold;
  bloomPass.strength = bloomParams.bloomStrength;
  bloomPass.radius = bloomParams.bloomRadius;

  // bloomPass.strength = 0
  // bloomPass.exposure = 0

  // const gui = new GUI()
  // const bloomFolder = gui.addFolder('Bloom')
  // bloomFolder.add(bloomPass, 'exposure', 0, 2, 0.01)
  // bloomFolder.add(bloomPass, 'radius', 0, 2, 0.01)
  // bloomFolder.add(bloomPass, 'strength', 0, 2, 0.01)
  // bloomFolder.open()
  
  const bloomComposer = new EffectComposer( renderer );
  bloomComposer.renderToScreen = false;
  bloomComposer.addPass( renderScene );
  bloomComposer.addPass( bloomPass );
  // bloomComposer.addPass(new ShaderPass(GammaCorrectionShader))
  // bloomComposer.outputEncoding = THREE.sRGBEncoding
  
  const finalPass = new ShaderPass(
    new THREE.ShaderMaterial( {
      uniforms: {
        baseTexture: { value: null },
        bloomTexture: { value: bloomComposer.renderTarget2.texture }
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {    
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
      `,
      fragmentShader: `
        uniform sampler2D baseTexture;
        uniform sampler2D bloomTexture;      
        varying vec2 vUv;      
        void main() {      
          gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );      
        }
      `,
      defines: {}
    } ), 'baseTexture'
  );
  finalPass.needsSwap = true;
  
  const finalComposer = new EffectComposer( renderer );
  finalComposer.addPass( renderScene );
  finalComposer.addPass( finalPass );
  // bloomComposer.addPass(new ShaderPass(GammaCorrectionShader))
  // bloomComposer.outputEncoding = LinearEncoding;
  // finalComposer.outputEncoding = THREE.sRGBEncoding

  return [bloomComposer, finalComposer]
}

const addMeshes = (scene, allMeshes) =>  {
  scene.traverse(function(object) {
    if (object.isMesh) {
      allMeshes.push(object)
    }
  })
}

const darkenNonBloomed = (msh, materials, darkMaterial) => {
  if (!msh.name.toLowerCase().includes("glow")) {
    materials[ msh.uuid ] = msh.material;
    msh.material = darkMaterial;
  }
}

const restoreMaterial = (msh, materials) => {
  if (materials[ msh.uuid ]) {
    msh.material = materials[ msh.uuid ];
    delete materials[ msh.uuid ];
  }
}

const renderBloom = (bloomComposer, finalComposer, allMeshes, materials, darkMaterial) =>  {
  allMeshes.forEach((msh) => {
    darkenNonBloomed(msh, materials, darkMaterial)
  }) 
  bloomComposer.render();
  allMeshes.forEach((msh) => {
    restoreMaterial(msh, materials)
  })
  finalComposer.render();
}

export {
  addMeshes,
  setUpBloom,
  renderBloom
}