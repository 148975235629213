// import fighterBody from '../local-assets/landing-page/models/kevuru-body.fbx'
// import hologramBody from '../local-assets/landing-page/models/fighter-wireframe.fbx'
import robotBody from '../local-assets/landing-page/models/robot-body.fbx'
import agnes from '../local-assets/landing-page/models/agnes.fbx'
// import robotHead from '../local-assets/landing-page/models/robot-head.fbx'
// import hexagonSphere from '../local-assets/landing-page/models/hexagon-sphere.fbx'
// import hologramBase from '../local-assets/landing-page/models/hologram-base.fbx'
// import laptop from '../local-assets/landing-page/models/laptop.fbx'
// import stage from '../local-assets/landing-page/models/stage.fbx'
// import punchingBag from '../local-assets/landing-page/models/punching-bag.fbx'
// import mapModel from '../local-assets/landing-page/models/map.fbx'

const landingTags = ["landingPage"]

const models = [
    // {
    //     name: 'fighterBody',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: fighterBody
    // },
    // {
    //     name: 'hologramBody',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: hologramBody
    // },    
    {
        name: 'robotBody',
        type: 'fbxModel',
        tags: landingTags,
        path: robotBody
    },
    {
        name: 'agnes',
        type: 'fbxModel',
        tags: landingTags,
        path: agnes
    },
    // {
    //     name: 'robotHead',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: robotHead
    // },
    // {
    //     name: 'hexagonSphere',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: hexagonSphere
    // },
    // {
    //     name: 'hologramBase',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: hologramBase
    // },
    // {
    //     name: 'laptop',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: laptop
    // },
    // {
    //     name: 'stage',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: stage
    // },
    // {
    //     name: 'punchingBag',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: punchingBag
    // },
    // {
    //     name: 'mapModel',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: mapModel
    // }
]

export default models