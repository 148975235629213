import { reverseMapping } from '../misc/mapping-handling'

const actionsList = [
  "Run Left",
  "Run Right",
  "Single Punch",
  "Double Punch",
  "Defend",
  "Jump",
  "Jump Left",
  "Jump Right",
  "Jump Punch",
  "Low Kick"
]
const animationFrames = {
  "Get Hit": 3,
  "Run Left": 9,
  "Run Right": 9,
  "Single Punch": 4,
  "Double Punch": 9,
  "Defend": 7,
  "Start Defend": 4,
  "Continue Defend": 4,
  "Hit Shield": 2,
  "End Defend": 2,
  "Stun": 23,
  "Jump": 10 + 5,
  "Jump Left": 10 + 5,
  "Jump Right": 10 + 5,
  "Jump Punch": 10 + 5,
  "Low Kick": 8
}

const actionKeyToIdx = {
  left: 0,
  right: 1,
  singlePunch: 2,
  doublePunch: 3,
  defend: 4,
  jump: 5,
  lowKick: 9,
}
const attackStateToIdx = {
  singlePunch: 1,
  doublePunch: 2,
  lowKick: 3
}
const idxToAttackState = reverseMapping(attackStateToIdx)

const defenceActions = [
  "Defend",
  "Drone"
]
const attackActions = [
  "Single Punch",
  "Double Punch",
  "Jump Punch",
  "Low Kick",
  "High Kick",
  "Jump Kick",
  "Elemental Attack"
]
const moveActions = [
  "Jump Left",
  "Run Left",
  "Jump",
  "Run Right",
  "Jump Right"
]


const getActionEmbedding = (action) => {
  // 3 element array with the following logic: [defence, attack, move]
  var actionEmbedding = [0, 0, 0]
  if (defenceActions.includes(action)) {
    actionEmbedding = [1, 0, 0]
  }
  else if (attackActions.includes(action)) {
    actionEmbedding = [0, 1, 0]
  }
  else if (moveActions.includes(action)) {
    actionEmbedding = [0, 0, 1]
  }
  return actionEmbedding
}

export {
  actionsList,
  moveActions,
  actionKeyToIdx,
  attackStateToIdx,
  idxToAttackState,
  animationFrames
}
