import React from "react"
import { displayModal } from '../../../generic-components/custom-modal/CustomModal'
import RadarChart from "react-svg-radar-chart"
import "react-svg-radar-chart/build/css/index.css"
import './ranked-summary.css'

const MatchSummary = ({ rankedStats, rankedSummaryStats, resultColors }) => {
  var yourResult
  if (rankedStats !== undefined && rankedStats.results !== undefined) {
    const tiedResult = rankedStats.results[0] === rankedStats.results[2]
    yourResult = (
      !tiedResult
      ? (
        rankedStats.results[0] > rankedStats.results[2] ? 
        "won" : 
        "lost"
      ) 
      :  "tied"
    )
  }

  const captions = {
    Defending: "Defending",
    Jumping: "Jumping",
    Kicking: "Kicking",
    Punching: "Punching",
    Running: "Running",
  }  

  return (
    <div 
      className="ranked-summary__aggregate-container"
      onClick={() => displayModal(`ranked-matches-modal`)}
    >
      <p className="ranked-summary__container-title">
        Ranked Battle
      </p>

      {
        rankedStats !== undefined &&
        <>
          <div className="ranked-summary__aggregate-middle">
            <div className="ranked-summary__title-content">
              <p className="ranked-summary__title">Results</p>
              <p className="ranked-summary__content">
                {
                  rankedStats !== undefined ? 
                  rankedStats.record : 
                  ""
                }
              </p>
            </div>
            <div className="ranked-summary__title-content">
              <p className="ranked-summary__title">Duration</p>
              <p className="ranked-summary__content">
                {
                  rankedStats !== undefined ? 
                  Math.round(rankedStats.duration) + " seconds" : 
                  ""
                }
              </p>
            </div>
          </div>      

          <div className="ranked-summary__fighter-container">
            <p className="ranked-summary__title">
              Rank: {rankedStats !== undefined ? rankedStats.rank : ""}
            </p>
            <p className="ranked-summary__content">
              ELO: {rankedStats !== undefined ? Math.round(rankedStats.currentRating) : ""}
            </p>
          </div>
          
          <img 
              id="ranked-summary__logo"
              src={rankedStats !== undefined ? rankedStats.logo : ""} 
              alt="" />

          <div className="ranked-summary__radar-chart">
            {
              rankedSummaryStats !== undefined && yourResult !== undefined &&
              <RadarChart
                options={{ captions: false, scales: 3 }}
                captions={captions}
                data={[
                  {
                    data: rankedSummaryStats[0].actionDominance,
                    meta: { color: resultColors[yourResult].border },
                  },
                ]}
                size={120}
              />          
            }
          </div>
        </>
      }
      {
        rankedStats === undefined &&
        <p id="ranked-summary__no-data">NO RANKED DATA</p>
      }
    </div>
  )
}

export default MatchSummary
