import React, { useState, useEffect } from 'react'
import token from '../../../../local-assets/aia-specific/aia-token.png'
import tokenAnimated from '../../../../local-assets/aia-specific/aia-token-spinning.png'
import * as d3 from 'd3'

const BestOfThree = ({ fighterIdx, score, currentRound}) => {  
  const [resultImage, setResultImage] = useState({ 0: undefined, 1: undefined, 2: undefined})

  const addResult = (result) => {
    if (result === "win") {
      setResultImage((prevState) => {
        prevState[currentRound-1] = tokenAnimated
        return {...prevState}
      })
      setTimeout(() => {
        setResultImage((prevState) => {
          prevState[currentRound-1] = token
          return {...prevState}
        })
      }, 2500)
    }
    else if (result === "tie" || result === "loss") {
      const ringColor = result === "tie" ? "#006cff" : "red"
      d3.select(`#best_of_three__container-${fighterIdx}`)
      .select(`#best-of-three--${currentRound}`)
        .style("border-color", ringColor)
        .style("box-shadow", `
          inset -0.4vw 0 0 ${ringColor}, 
          inset 0 -0.4vw 0 ${ringColor}, 
          inset 0.4vw 0 0 ${ringColor}, 
          inset 0 0.4vw 0 ${ringColor}
        `)
    }
  }

  useEffect(() => {
    if (currentRound > 0) {
      addResult(score[currentRound-1])
    }
  }, [score])

  return (
    <div className="best-of-three__container" id={`best_of_three__container-${fighterIdx}`}>
      <div className="best-of-three__result" id="best-of-three--1">
        { resultImage[0] !== undefined && <img src={resultImage[0]} alt="" /> }
      </div>
      <div className="best-of-three__result" id="best-of-three--2">
        { resultImage[1] !== undefined && <img src={resultImage[1]} alt="" /> }
      </div>
      <div className="best-of-three__result" id="best-of-three--3">
        { resultImage[2] !== undefined && <img src={resultImage[2]} alt="" /> }
      </div>
    </div>
  )
}

export default BestOfThree
