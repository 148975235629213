import React, {useState, useEffect} from "react"
import ConnectPopup from "../connect-popup/ConnectPopup"
import './wallet-connection.css'

const WalletConnection = ({ connectionData, accountData, connect, disconnect, showBalance }) => {
  const [open, setOpen] = useState(false)
  const [currentAccount, setCurrentAccount] = useState(undefined)

  useEffect(() => {
    accountData !== undefined && setCurrentAccount(accountData.address)
  }, [accountData])

  return (
    <div id="wallet-connect__container">
      {
        !accountData && !open &&
        <button 
          onClick={() => setOpen(!open)} 
          id="wallet-connect__button--connect">
          Connect
        </button>
      }
      {
        accountData &&
        <button 
          onClick={() => {
            setOpen(false)
            disconnect()
          }} 
          id="wallet-connect__button--disconnect">
          Disconnect
        </button>
      }
      {
        !accountData && open &&
        <ConnectPopup
          setOpen={setOpen} 
          connect={connect} 
          data={connectionData}>
        </ConnectPopup>
      }
    </div>
  );
};

export default WalletConnection
