import {
  sizeMultiples,
  defaultLimbPositions,
  getLimbData,
  getPositionAndSize,
  renderAttribute
} from '../asset-handling/render-helper'
import {
  getRotation,
  attributeTransition,
  attributeRotation,
  limbTransition,
  delayBase,
  extraAssets
} from './animation-helper'
import * as d3 from 'd3'

const charSize = sizeMultiples.battle

const leftArmData = getLimbData(charSize, ...defaultLimbPositions.leftArm)
const rightArmData = getLimbData(charSize, ...defaultLimbPositions.rightArm)
const leftLegData = getLimbData(charSize, ...defaultLimbPositions.leftLeg)
const rightLegData = getLimbData(charSize, ...defaultLimbPositions.rightLeg)


function winAnimation(characterIdNumber, loopIdx, allTimeouts) {
  if (d3.select("#battle-arena")._groups[0][0] !== null) {
    const currentId = `#character${characterIdNumber}`
    const characterCard = d3.select(currentId)

    const head = characterCard.select(`#head-${characterIdNumber}`)
      , eyes = characterCard.select(`#eyes-${characterIdNumber}`)
      , body = characterCard.select(`#body-${characterIdNumber}`)
      , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
      , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
      , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
      , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

    const leftFootBBox = leftFoot.node().getBBox()
      , leftFootX = leftFootBBox.x + leftFootBBox.width / 2
      , leftFootY = leftFootBBox.y + leftFootBBox.height / 2
      , leftFootRotation = getRotation(0, 63, leftFootX, leftFootY)
      , leftFootRotation2 = getRotation(63, 120, leftFootX, leftFootY)
      , leftFootRotation3 = getRotation(120, 115, leftFootX, leftFootY)
      , leftFootRotation4 = getRotation(115, 40, leftFootX, leftFootY)
      , leftFootRotation5 = getRotation(40, 8, leftFootX, leftFootY)
      , leftFootRotation6 = getRotation(8, 0, leftFootX, leftFootY)

    const rightFootBBox = rightFoot.node().getBBox()
      , rightFootX = rightFootBBox.x + rightFootBBox.width / 2
      , rightFootY = rightFootBBox.y + rightFootBBox.height / 2
      , rightFootRotation = getRotation(0, 63, rightFootX, rightFootY)
      , rightFootRotation2 = getRotation(63, 122, rightFootX, rightFootY)
      , rightFootRotation3 = getRotation(120, 110, rightFootX, rightFootY)
      , rightFootRotation4 = getRotation(110, 40, rightFootX, rightFootY)
      , rightFootRotation5 = getRotation(40, 8, rightFootX, rightFootY)
      , rightFootRotation6 = getRotation(8, 0, rightFootX, rightFootY)

    const leftHandBBox = leftHand.node().getBBox()
      , leftHandX = leftHandBBox.x + leftHandBBox.width / 2
      , leftHandY = leftHandBBox.y + leftHandBBox.height / 2
      , leftHandRotation = getRotation(0, -100, leftHandX, leftHandY)
      , leftHandRotation2 = getRotation(-100, -25, leftHandX, leftHandY)
      , leftHandRotation3 = getRotation(-25, 0, leftHandX, leftHandY)

    const rightHandBBox = rightHand.node().getBBox()
      , rightHandX = rightHandBBox.x + rightHandBBox.width / 2
      , rightHandY = rightHandBBox.y + rightHandBBox.height / 2
      , rightHandRotation = getRotation(0, -40, rightHandX, rightHandY)
      , rightHandRotation2 = getRotation(-40, 0, rightHandX, rightHandY)

    // Attribute Transitions
    const initialHeadPosition = getPositionAndSize("head")
    attributeTransition(head, 0, initialHeadPosition[0], initialHeadPosition[1] + 0.06)
    attributeTransition(head, delayBase, initialHeadPosition[0] - 0.05, initialHeadPosition[1] - 0.22)
    attributeTransition(head, delayBase * 2, initialHeadPosition[0] - 0.05, initialHeadPosition[1] - 0.27)
    attributeTransition(head, delayBase * 3, initialHeadPosition[0] - 0.05, initialHeadPosition[1] - 0.22)
    attributeTransition(head, delayBase * 5, initialHeadPosition[0] - 0.05, initialHeadPosition[1] - 0.04)
    attributeTransition(head, delayBase * 6, initialHeadPosition[0], initialHeadPosition[1])

    characterCard.select(".shield-icon").remove()

    if (characterIdNumber !== "cpu4") {
      const initialEyesPosition = getPositionAndSize("eyes")
      const defaultInputs = [characterCard.select("svg"), characterIdNumber]
      renderAttribute(...defaultInputs, extraAssets["happy-eyes"], "happy-eyes", charSize, ...initialEyesPosition, false)
      const happyEyes = characterCard.select(`#happy-eyes-${characterIdNumber}`)
      eyes.attr("display", "none")

      if (loopIdx === 3) {
        allTimeouts.push(setTimeout(() => {
          characterCard.selectAll(`#happy-eyes-${characterIdNumber}`).remove()
          eyes.attr("display", "block")
        }, delayBase * 6))
      }
    }

    const initialBodyPosition = getPositionAndSize("body")
    attributeTransition(body, 0, initialBodyPosition[0], initialBodyPosition[1] + 0.06)
    attributeTransition(body, delayBase, initialBodyPosition[0] - 0.01, initialBodyPosition[1] - 0.22)
    attributeTransition(body, delayBase * 2, initialBodyPosition[0] - 0.01, initialBodyPosition[1] - 0.27)
    attributeTransition(body, delayBase * 3, initialBodyPosition[0] - 0.01, initialBodyPosition[1] - 0.22)
    attributeTransition(body, delayBase * 5, initialBodyPosition[0] - 0.035, initialBodyPosition[1] - 0.04)
    attributeTransition(body, delayBase * 6, initialBodyPosition[0], initialBodyPosition[1])

    const initialLeftHandPosition = getPositionAndSize("leftHand")
    attributeTransition(leftHand, 0, initialLeftHandPosition[0] + 0.03, initialLeftHandPosition[1] - 0.05)
    attributeTransition(leftHand, delayBase, initialLeftHandPosition[0] - 0.35, initialLeftHandPosition[1] - 0.8)
    attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0] - 0.35, initialLeftHandPosition[1] - 0.85)
    attributeTransition(leftHand, delayBase * 3, initialLeftHandPosition[0] - 0.35, initialLeftHandPosition[1] - 0.81)
    attributeTransition(leftHand, delayBase * 4, initialLeftHandPosition[0] - 0.35, initialLeftHandPosition[1] - 0.79)
    attributeTransition(leftHand, delayBase * 5, initialLeftHandPosition[0] - 0.09, initialLeftHandPosition[1] - 0.23)
    attributeTransition(leftHand, delayBase * 6, initialLeftHandPosition[0], initialLeftHandPosition[1])

    const initialRightHandPosition = getPositionAndSize("rightHand")
    attributeTransition(rightHand, 0, initialRightHandPosition[0] - 0.02, initialRightHandPosition[1] - 0.01)
    attributeTransition(rightHand, delayBase, initialRightHandPosition[0] + 0.085, initialRightHandPosition[1] - 0.82)
    attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0] + 0.085, initialRightHandPosition[1] - 0.87)
    attributeTransition(rightHand, delayBase * 3, initialRightHandPosition[0] + 0.085, initialRightHandPosition[1] - 0.83)
    attributeTransition(rightHand, delayBase * 4, initialRightHandPosition[0] + 0.085, initialRightHandPosition[1] - 0.81)
    attributeTransition(rightHand, delayBase * 5, initialRightHandPosition[0] - 0.03, initialRightHandPosition[1] - 0.14)
    attributeTransition(rightHand, delayBase * 6, initialRightHandPosition[0], initialRightHandPosition[1])

    const initialLeftFootPosition = getPositionAndSize("leftFoot")
    attributeTransition(leftFoot, delayBase, initialLeftFootPosition[0], initialLeftFootPosition[1] - 0.25)
    attributeTransition(leftFoot, delayBase * 2, initialLeftFootPosition[0] - 0.05, initialLeftFootPosition[1] - 0.42)
    attributeTransition(leftFoot, delayBase * 3, initialLeftFootPosition[0] - 0.02, initialLeftFootPosition[1] - 0.36)
    attributeTransition(leftFoot, delayBase * 4, initialLeftFootPosition[0] + 0.05, initialLeftFootPosition[1] - 0.18)
    attributeTransition(leftFoot, delayBase * 5, initialLeftFootPosition[0], initialLeftFootPosition[1] - 0.03)
    attributeTransition(leftFoot, delayBase * 6, initialLeftFootPosition[0], initialLeftFootPosition[1])

    const initialRightFootPosition = getPositionAndSize("rightFoot")
    attributeTransition(rightFoot, delayBase, initialRightFootPosition[0] - 0.22, initialRightFootPosition[1] - 0.22)
    attributeTransition(rightFoot, delayBase * 2, initialRightFootPosition[0] - 0.26, initialRightFootPosition[1] - 0.34)
    attributeTransition(rightFoot, delayBase * 3, initialRightFootPosition[0] - 0.24, initialRightFootPosition[1] - 0.27)
    attributeTransition(rightFoot, delayBase * 4, initialRightFootPosition[0] - 0.14, initialRightFootPosition[1] - 0.15)
    attributeTransition(rightFoot, delayBase * 5, initialRightFootPosition[0] - 0.01, initialRightFootPosition[1] - 0.02)
    attributeTransition(rightFoot, delayBase * 6, initialRightFootPosition[0], initialRightFootPosition[1])

    // Attribute Rotations
    attributeRotation(leftFoot, delayBase, leftFootRotation)
    attributeRotation(leftFoot, delayBase * 2, leftFootRotation2)
    attributeRotation(leftFoot, delayBase * 3, leftFootRotation3)
    attributeRotation(leftFoot, delayBase * 4, leftFootRotation4)
    attributeRotation(leftFoot, delayBase * 5, leftFootRotation5)
    attributeRotation(leftFoot, delayBase * 6, leftFootRotation6)

    attributeRotation(rightFoot, delayBase, rightFootRotation)
    attributeRotation(rightFoot, delayBase * 2, rightFootRotation2)
    attributeRotation(rightFoot, delayBase * 3, rightFootRotation3)
    attributeRotation(rightFoot, delayBase * 4, rightFootRotation4)
    attributeRotation(rightFoot, delayBase * 5, rightFootRotation5)
    attributeRotation(rightFoot, delayBase * 6, rightFootRotation6)

    attributeRotation(leftHand, delayBase, leftHandRotation)
    attributeRotation(leftHand, delayBase * 5, leftHandRotation2)
    attributeRotation(leftHand, delayBase * 6, leftHandRotation3)

    attributeRotation(rightHand, delayBase, rightHandRotation)
    attributeRotation(rightHand, delayBase * 5, rightHandRotation2)

    const newLeftArmData = getLimbData(charSize, 0.34, 0.53, -0.03, 0.62, 0.3, 0.57)
    const newRightArmData = getLimbData(charSize, 0.52, 0.5, 0.58, 0.665, 0.785, 0.6)
    const newLeftLegData = getLimbData(charSize, 0.32, 0.7, 0.42, 0.89, 0.118, 0.91)
    const newRightLegData = getLimbData(charSize, 0.55, 0.75, 0.73, 0.8, 0.605, 0.91)

    const newLeftArmData2 = getLimbData(charSize, 0.34, 0.35, 0.18, 0.25, 0.04, 0.01)
    const newRightArmData2 = getLimbData(charSize, 0.4, 0.45, 0.65, 0.35, 0.95, 0.01)
    const newLeftLegData2 = getLimbData(charSize, 0.35, 0.55, 0.4, 0.7, 0.15, 0.69)
    const newRightLegData2 = getLimbData(charSize, 0.55, 0.55, 0.66, 0.7, 0.38, 0.7)

    const newLeftArmData3 = getLimbData(charSize, 0.34, 0.31, 0.22, 0.25, 0.04, 0.01)
    const newRightArmData3 = getLimbData(charSize, 0.4, 0.35, 0.65, 0.3, 0.925, 0.01)
    const newLeftLegData3 = getLimbData(charSize, 0.37, 0.53, 0.4, 0.72, 0.18, 0.55)
    const newRightLegData3 = getLimbData(charSize, 0.55, 0.5, 0.66, 0.75, 0.48, 0.6)

    const newLeftArmData4 = getLimbData(charSize, 0.34, 0.35, 0.2, 0.25, 0.04, 0.01)
    const newRightArmData4 = getLimbData(charSize, 0.4, 0.35, 0.7, 0.3, 0.96, 0.01)
    const newLeftLegData4 = getLimbData(charSize, 0.37, 0.53, 0.45, 0.75, 0.2, 0.6)
    const newRightLegData4 = getLimbData(charSize, 0.55, 0.5, 0.68, 0.78, 0.48, 0.65)

    const newLeftArmData5 = getLimbData(charSize, 0.33, 0.36, 0.17, 0.25, 0.04, 0.01)
    const newRightArmData5 = getLimbData(charSize, 0.4, 0.45, 0.7, 0.3, 0.98, 0.01)
    const newLeftLegData5 = getLimbData(charSize, 0.33, 0.53, 0.43, 0.7, 0.2, 0.75)
    const newRightLegData5 = getLimbData(charSize, 0.55, 0.55, 0.64, 0.7, 0.45, 0.78)

    const newLeftArmData6 = getLimbData(charSize, 0.3, 0.48, -0.02, 0.585, 0.3, 0.38)
    const newRightArmData6 = getLimbData(charSize, 0.52, 0.51, 0.62, 0.56, 0.785, 0.5)
    const newLeftLegData6 = getLimbData(charSize, 0.32, 0.7, 0.3, 0.84, 0.09, 0.9)
    const newRightLegData6 = getLimbData(charSize, 0.53, 0.69, 0.65, 0.78, 0.58, 0.91)

    const leftArm = characterCard.select("#leftArm")
    limbTransition(leftArm, 0, newLeftArmData)
    limbTransition(leftArm, delayBase, newLeftArmData2)
    limbTransition(leftArm, delayBase * 2, newLeftArmData3)
    limbTransition(leftArm, delayBase * 3, newLeftArmData4)
    limbTransition(leftArm, delayBase * 4, newLeftArmData5)
    limbTransition(leftArm, delayBase * 5, newLeftArmData6)
    limbTransition(leftArm, delayBase * 6, leftArmData)

    const rightArm = characterCard.select("#rightArm")
    limbTransition(rightArm, 0, newRightArmData)
    limbTransition(rightArm, delayBase, newRightArmData2)
    limbTransition(rightArm, delayBase * 2, newRightArmData3)
    limbTransition(rightArm, delayBase * 3, newRightArmData4)
    limbTransition(rightArm, delayBase * 4, newRightArmData5)
    limbTransition(rightArm, delayBase * 5, newRightArmData6)
    limbTransition(rightArm, delayBase * 6, rightArmData)

    const leftLeg = characterCard.select("#leftLeg")
    limbTransition(leftLeg, 0, newLeftLegData)
    limbTransition(leftLeg, delayBase, newLeftLegData2)
    limbTransition(leftLeg, delayBase * 2, newLeftLegData3)
    limbTransition(leftLeg, delayBase * 3, newLeftLegData4)
    limbTransition(leftLeg, delayBase * 4, newLeftLegData5)
    limbTransition(leftLeg, delayBase * 5, newLeftLegData6)
    limbTransition(leftLeg, delayBase * 6, leftLegData)

    const rightLeg = characterCard.select("#rightLeg")
    limbTransition(rightLeg, 0, newRightLegData)
    limbTransition(rightLeg, delayBase, newRightLegData2)
    limbTransition(rightLeg, delayBase * 2, newRightLegData3)
    limbTransition(rightLeg, delayBase * 3, newRightLegData4)
    limbTransition(rightLeg, delayBase * 4, newRightLegData5)
    limbTransition(rightLeg, delayBase * 5, newRightLegData6)
    limbTransition(rightLeg, delayBase * 6, rightLegData)

    if (loopIdx === undefined) {
      loopIdx = 1
    }
    if (loopIdx < 3) {
      allTimeouts.push(setTimeout(() => {
        allTimeouts = winAnimation(characterIdNumber, loopIdx + 1, allTimeouts)
      }, delayBase * 7))
    }
  }
  return allTimeouts
}


function loseAnimation(characterIdNumber, allTimeouts) {
  if (d3.select("#battle-arena")._groups[0][0] !== null) {
    const currentId = `#character${characterIdNumber}`
    const characterCard = d3.select(currentId)

    const head = characterCard.select(`#head-${characterIdNumber}`)
      , eyes = characterCard.select(`#eyes-${characterIdNumber}`)
      , mouth = characterCard.select(`#mouth-${characterIdNumber}`)
      , body = characterCard.select(`#body-${characterIdNumber}`)
      , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
      , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
      , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
      , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

    const headBBox = head.node().getBBox()
      , headX = headBBox.x + headBBox.width / 2
      , headY = headBBox.y + headBBox.height / 2
      , headRotation = getRotation(0, 4, headX, headY)
      , headRotation2 = getRotation(4, -8, headX, headY)
      , headRotation3 = getRotation(-8, 0, headX, headY)
      , headRotation4 = getRotation(0, 10, headX, headY)
      , headRotation5 = getRotation(10, 18, headX, headY)
      , headRotation6 = getRotation(18, 0, headX, headY)
      , headRotation7 = getRotation(0, -5, headX, headY)
      , headRotation8 = getRotation(-5, 0, headX, headY)

    const bodyBBox = body.node().getBBox()
      , bodyX = bodyBBox.x + bodyBBox.width / 2
      , bodyY = bodyBBox.y + bodyBBox.height / 2
      , bodyRotation = getRotation(0, 4, bodyX, bodyY)
      , bodyRotation2 = getRotation(4, -8, bodyX, bodyY)
      , bodyRotation3 = getRotation(-8, -12, bodyX, bodyY)
      , bodyRotation4 = getRotation(-12, -26, bodyX, bodyY)
      , bodyRotation5 = getRotation(-26, -70, bodyX, bodyY)
      , bodyRotation6 = getRotation(-70, -90, bodyX, bodyY)

    const leftFootBBox = leftFoot.node().getBBox()
      , leftFootX = leftFootBBox.x + leftFootBBox.width / 2
      , leftFootY = leftFootBBox.y + leftFootBBox.height / 2
      , leftFootRotation = getRotation(0, -20, leftFootX, leftFootY)
      , leftFootRotation2 = getRotation(-20, -10, leftFootX, leftFootY)
      , leftFootRotation3 = getRotation(-10, 0, leftFootX, leftFootY)

    const rightFootBBox = rightFoot.node().getBBox()
      , rightFootX = rightFootBBox.x + rightFootBBox.width / 2
      , rightFootY = rightFootBBox.y + rightFootBBox.height / 2
      , rightFootRotation = getRotation(0, -20, rightFootX, rightFootY)
      , rightFootRotation2 = getRotation(-20, -40, rightFootX, rightFootY)
      , rightFootRotation3 = getRotation(-40, -65, rightFootX, rightFootY)
      , rightFootRotation4 = getRotation(-65, -90, rightFootX, rightFootY)

    const leftHandBBox = leftHand.node().getBBox()
      , leftHandX = leftHandBBox.x + leftHandBBox.width / 2
      , leftHandY = leftHandBBox.y + leftHandBBox.height / 2
      , leftHandRotation = getRotation(0, 20, leftHandX, leftHandY)
      , leftHandRotation2 = getRotation(20, -50, leftHandX, leftHandY)
      , leftHandRotation3 = getRotation(-50, -80, leftHandX, leftHandY)
      , leftHandRotation4 = getRotation(-80, -115, leftHandX, leftHandY)
      , leftHandRotation5 = getRotation(-115, -150, leftHandX, leftHandY)
      , leftHandRotation6 = getRotation(-150, -160, leftHandX, leftHandY)

    const rightHandBBox = rightHand.node().getBBox()
      , rightHandX = rightHandBBox.x + rightHandBBox.width / 2
      , rightHandY = rightHandBBox.y + rightHandBBox.height / 2
      , rightHandRotation = getRotation(0, -10, rightHandX, rightHandY)
      , rightHandRotation2 = getRotation(-10, -40, rightHandX, rightHandY)
      , rightHandRotation3 = getRotation(-40, -50, rightHandX, rightHandY)
      , rightHandRotation4 = getRotation(-50, -40, rightHandX, rightHandY)
      , rightHandRotation5 = getRotation(-40, 25, rightHandX, rightHandY)

    // Attribute Transitions
    const initialHeadPosition = getPositionAndSize("head")
    attributeTransition(head, 0, initialHeadPosition[0], initialHeadPosition[1] + 0.08)
    attributeTransition(head, delayBase, initialHeadPosition[0] + 0.045, initialHeadPosition[1] + 0.15)
    attributeTransition(head, delayBase * 2, initialHeadPosition[0] - 0.19, initialHeadPosition[1] - 0.13)
    attributeTransition(head, delayBase * 3, initialHeadPosition[0] - 0.15, initialHeadPosition[1] - 0.1)
    attributeTransition(head, delayBase * 4, initialHeadPosition[0] - 0.13, initialHeadPosition[1] - 0.08)
    attributeTransition(head, delayBase * 5, initialHeadPosition[0] - 0.08, initialHeadPosition[1] + 0.05)
    attributeTransition(head, delayBase * 6, initialHeadPosition[0] + 0.02, initialHeadPosition[1] + 0.47)
    attributeTransition(head, delayBase * 7, initialHeadPosition[0] + 0.06, initialHeadPosition[1] + 0.68)
    attributeTransition(head, delayBase * 8, initialHeadPosition[0] + 0.28, initialHeadPosition[1] + 0.55)
    attributeTransition(head, delayBase * 9, initialHeadPosition[0] + 0.32, initialHeadPosition[1] + 0.53)
    attributeTransition(head, delayBase * 10, initialHeadPosition[0] + 0.4, initialHeadPosition[1] + 0.53)
    attributeTransition(head, delayBase * 11, initialHeadPosition[0] + 0.5, initialHeadPosition[1] + 0.68)
    attributeTransition(head, delayBase * 12, initialHeadPosition[0] + 0.62, initialHeadPosition[1] + 0.63)
    attributeTransition(head, delayBase * 13, initialHeadPosition[0] + 0.64, initialHeadPosition[1] + 0.64)
    attributeTransition(head, delayBase * 14, initialHeadPosition[0] + 0.72, initialHeadPosition[1] + 0.68)

    const initialEyesPosition = getPositionAndSize("eyes")
    const defaultInputs = [characterCard.select("svg"), characterIdNumber]
    renderAttribute(...defaultInputs, extraAssets["dead-eyes"], "dead-eyes", charSize, ...initialEyesPosition, true)
    renderAttribute(...defaultInputs, extraAssets["dead-mouth"], "dead-mouth", charSize, 0.3, 0.47, 0.7, true)
    const deadEyes = characterCard.select(`#dead-eyes-${characterIdNumber}`)
    const deadMouth = characterCard.select(`#dead-mouth-${characterIdNumber}`)
    deadEyes.attr("display", "none")
    deadMouth.attr("display", "none")

    characterCard.select(".shield-icon").remove()

    if (characterIdNumber !== "cpu4") {
      allTimeouts.push(setTimeout(() => {
        mouth.attr("display", "none")
        deadMouth.attr("display", "block")
      }, delayBase * 2))
      allTimeouts.push(setTimeout(() => {
        eyes.attr("display", "none")
        deadEyes.attr("display", "block")
      }, delayBase * 3))
    }

    const initialBodyPosition = getPositionAndSize("body")
    attributeTransition(body, 0, initialBodyPosition[0], initialBodyPosition[1] + 0.08)
    attributeTransition(body, delayBase, initialBodyPosition[0], initialBodyPosition[1] + 0.15)
    attributeTransition(body, delayBase * 2, initialBodyPosition[0] - 0.14, initialBodyPosition[1] - 0.12)
    attributeTransition(body, delayBase * 5, initialBodyPosition[0] - 0.15, initialBodyPosition[1] - 0.12)
    attributeTransition(body, delayBase * 6, initialBodyPosition[0] - 0.17, initialBodyPosition[1] - 0.12)
    attributeTransition(body, delayBase * 7, initialBodyPosition[0] - 0.19, initialBodyPosition[1] - 0.12)
    attributeTransition(body, delayBase * 8, initialBodyPosition[0] - 0.23, initialBodyPosition[1] - 0.04)
    attributeTransition(body, delayBase * 9, initialBodyPosition[0] - 0.17, initialBodyPosition[1] + 0.28)
    attributeTransition(body, delayBase * 10, initialBodyPosition[0] - 0.17, initialBodyPosition[1] + 0.35)
    attributeTransition(body, delayBase * 11, initialBodyPosition[0] - 0.12, initialBodyPosition[1] + 0.32)
    attributeTransition(body, delayBase * 12, initialBodyPosition[0] - 0.11, initialBodyPosition[1] + 0.33)
    attributeTransition(body, delayBase * 13, initialBodyPosition[0] - 0.11, initialBodyPosition[1] + 0.34)

    const initialLeftHandPosition = getPositionAndSize("leftHand")
    attributeTransition(leftHand, 0, initialLeftHandPosition[0] + 0.005, initialLeftHandPosition[1] + 0.12)
    attributeTransition(leftHand, delayBase, initialLeftHandPosition[0] + 0.005, initialLeftHandPosition[1] + 0.18)
    attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0] - 0.38, initialLeftHandPosition[1] - 0.63)
    attributeTransition(leftHand, delayBase * 3, initialLeftHandPosition[0] - 0.38, initialLeftHandPosition[1] - 0.6)
    attributeTransition(leftHand, delayBase * 5, initialLeftHandPosition[0] - 0.41, initialLeftHandPosition[1] - 0.58)
    attributeTransition(leftHand, delayBase * 6, initialLeftHandPosition[0] - 0.54, initialLeftHandPosition[1] - 0.46)
    attributeTransition(leftHand, delayBase * 7, initialLeftHandPosition[0] - 0.6, initialLeftHandPosition[1] - 0.25)
    attributeTransition(leftHand, delayBase * 8, initialLeftHandPosition[0] - 0.7, initialLeftHandPosition[1] + 0.3)
    attributeTransition(leftHand, delayBase * 9, initialLeftHandPosition[0] - 0.65, initialLeftHandPosition[1] + 0.5)

    const initialRightHandPosition = getPositionAndSize("rightHand")
    attributeTransition(rightHand, 0, initialRightHandPosition[0] - 0.04, initialRightHandPosition[1] + 0.085)
    attributeTransition(rightHand, delayBase, initialRightHandPosition[0] - 0.02, initialRightHandPosition[1] + 0.135)
    attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0] - 0.14, initialRightHandPosition[1] - 0.63)
    attributeTransition(rightHand, delayBase * 3, initialRightHandPosition[0] - 0.11, initialRightHandPosition[1] - 0.59)
    attributeTransition(rightHand, delayBase * 5, initialRightHandPosition[0] - 0.14, initialRightHandPosition[1] - 0.62)
    attributeTransition(rightHand, delayBase * 6, initialRightHandPosition[0] - 0.18, initialRightHandPosition[1] - 0.6)
    attributeTransition(rightHand, delayBase * 7, initialRightHandPosition[0] - 0.22, initialRightHandPosition[1] - 0.62)
    attributeTransition(rightHand, delayBase * 8, initialRightHandPosition[0] - 0.42, initialRightHandPosition[1] - 0.63)
    attributeTransition(rightHand, delayBase * 9, initialRightHandPosition[0] - 0.46, initialRightHandPosition[1] - 0.63)
    attributeTransition(rightHand, delayBase * 10, initialRightHandPosition[0] - 0.4, initialRightHandPosition[1] - 0.3)
    attributeTransition(rightHand, delayBase * 11, initialRightHandPosition[0] - 0.225, initialRightHandPosition[1] - 0.225)
    attributeTransition(rightHand, delayBase * 12, initialRightHandPosition[0] + 0.06, initialRightHandPosition[1] + 0.18)
    attributeTransition(rightHand, delayBase * 13, initialRightHandPosition[0] + 0.14, initialRightHandPosition[1] + 0.48)

    const initialLeftFootPosition = getPositionAndSize("leftFoot")
    attributeTransition(leftFoot, delayBase * 2, initialLeftFootPosition[0] - 0.04, initialLeftFootPosition[1] - 0.05)
    attributeTransition(leftFoot, delayBase * 8, initialLeftFootPosition[0] - 0.02, initialLeftFootPosition[1] - 0.03)
    attributeTransition(leftFoot, delayBase * 9, initialLeftFootPosition[0] + 0.06, initialLeftFootPosition[1] - 0.02)
    attributeTransition(leftFoot, delayBase * 10, initialLeftFootPosition[0] + 0.1, initialLeftFootPosition[1])

    const initialRightFootPosition = getPositionAndSize("rightFoot")
    attributeTransition(rightFoot, delayBase * 2, initialRightFootPosition[0] - 0.04, initialRightFootPosition[1] - 0.05)
    attributeTransition(rightFoot, delayBase * 8, initialRightFootPosition[0] + 0.1, initialRightFootPosition[1] - 0.15)
    attributeTransition(rightFoot, delayBase * 9, initialRightFootPosition[0] + 0.16, initialRightFootPosition[1] - 0.12)
    attributeTransition(rightFoot, delayBase * 10, initialRightFootPosition[0] + 0.26, initialRightFootPosition[1] - 0.12)
    attributeTransition(rightFoot, delayBase * 11, initialRightFootPosition[0] + 0.26, initialRightFootPosition[1] - 0.06)
    attributeTransition(rightFoot, delayBase * 12, initialRightFootPosition[0] + 0.32, initialRightFootPosition[1] - 0.06)

    // Attribute Rotations
    attributeRotation(head, delayBase, headRotation)
    attributeRotation(head, delayBase * 2, headRotation2)
    attributeRotation(head, delayBase * 3, headRotation3)
    attributeRotation(head, delayBase * 8, headRotation4)
    attributeRotation(head, delayBase * 10, headRotation5)
    attributeRotation(head, delayBase * 11, headRotation6)
    attributeRotation(head, delayBase * 12, headRotation7)
    attributeRotation(head, delayBase * 13, headRotation8)

    attributeRotation(body, delayBase, bodyRotation)
    attributeRotation(body, delayBase * 2, bodyRotation2)
    attributeRotation(body, delayBase * 7, bodyRotation3)
    attributeRotation(body, delayBase * 8, bodyRotation4)
    attributeRotation(body, delayBase * 9, bodyRotation5)
    attributeRotation(body, delayBase * 10, bodyRotation6)

    attributeRotation(leftFoot, delayBase * 2, leftFootRotation)
    attributeRotation(leftFoot, delayBase * 8, leftFootRotation2)
    attributeRotation(leftFoot, delayBase * 10, leftFootRotation3)

    attributeRotation(rightFoot, delayBase * 2, rightFootRotation)
    attributeRotation(rightFoot, delayBase * 8, rightFootRotation2)
    attributeRotation(rightFoot, delayBase * 9, rightFootRotation3)
    attributeRotation(rightFoot, delayBase * 11, rightFootRotation4)

    attributeRotation(leftHand, 0, leftHandRotation)
    attributeRotation(leftHand, delayBase * 2, leftHandRotation2)
    attributeRotation(leftHand, delayBase * 6, leftHandRotation3)
    attributeRotation(leftHand, delayBase * 7, leftHandRotation4)
    attributeRotation(leftHand, delayBase * 8, leftHandRotation5)
    attributeRotation(leftHand, delayBase * 9, leftHandRotation6)

    attributeRotation(rightHand, 0, rightHandRotation)
    attributeRotation(rightHand, delayBase * 2, rightHandRotation2)
    attributeRotation(rightHand, delayBase * 8, rightHandRotation3)
    attributeRotation(rightHand, delayBase * 11, rightHandRotation4)
    attributeRotation(rightHand, delayBase * 12, rightHandRotation5)

    const newLeftArmData = getLimbData(charSize, 0.34, 0.53, 0.01, 0.68, 0.3, 0.64)
    const newRightArmData = getLimbData(charSize, 0.52, 0.5, 0.58, 0.72, 0.785, 0.65)
    const newLeftLegData = getLimbData(charSize, 0.32, 0.7, 0.42, 0.89, 0.118, 0.91)
    const newRightLegData = getLimbData(charSize, 0.55, 0.75, 0.73, 0.8, 0.605, 0.91)

    const newLeftArmData2 = getLimbData(charSize, 0.36, 0.58, 0.08, 0.69, 0.3, 0.68)
    const newRightArmData2 = getLimbData(charSize, 0.52, 0.5, 0.6, 0.76, 0.785, 0.7)
    const newLeftLegData2 = getLimbData(charSize, 0.32, 0.6, 0.48, 0.93, 0.14, 0.905)
    const newRightLegData2 = getLimbData(charSize, 0.55, 0.78, 0.84, 0.83, 0.605, 0.91)

    const newLeftArmData3 = getLimbData(charSize, 0.2, 0.4, -0.1, 0.35, 0.01, 0.18)
    const newRightArmData3 = getLimbData(charSize, 0.4, 0.4, 0.6, 0.3, 0.72, 0.2)
    const newLeftLegData3 = getLimbData(charSize, 0.2, 0.6, 0.13, 0.75, 0.06, 0.905)
    const newRightLegData3 = getLimbData(charSize, 0.4, 0.6, 0.49, 0.75, 0.58, 0.91)

    const newLeftArmData4 = getLimbData(charSize, 0.2, 0.4, -0.13, 0.38, 0.01, 0.18)
    const newRightArmData4 = getLimbData(charSize, 0.4, 0.41, 0.79, 0.4, 0.75, 0.15)

    const newLeftArmData5 = getLimbData(charSize, 0.2, 0.4, -0.1, 0.4, -0.04, 0.18)
    const newLeftLegData5 = getLimbData(charSize, 0.19, 0.6, 0.13, 0.75, 0.06, 0.905)
    const newRightLegData5 = getLimbData(charSize, 0.36, 0.6, 0.48, 0.75, 0.58, 0.91)

    const newLeftArmData6 = getLimbData(charSize, 0.16, 0.4, -0.16, 0.48, -0.18, 0.18)
    const newRightArmData6 = getLimbData(charSize, 0.25, 0.41, 0.65, 0.4, 0.66, 0.15)

    const newLeftArmData7 = getLimbData(charSize, 0.14, 0.4, -0.05, 0.61, -0.18, 0.45)
    const newRightArmData7 = getLimbData(charSize, 0.3, 0.41, 0.63, 0.37, 0.62, 0.15)
    const newLeftLegData7 = getLimbData(charSize, 0.17, 0.6, 0.26, 0.75, 0.06, 0.905)

    const newLeftArmData8 = getLimbData(charSize, 0.02, 0.6, 0.02, 0.81, -0.22, 0.8)
    const newRightArmData8 = getLimbData(charSize, 0.22, 0.45, 0.45, 0.37, 0.44, 0.15)
    const newLeftLegData8 = getLimbData(charSize, 0.18, 0.7, 0.32, 0.8, 0.08, 0.91)
    const newRightLegData8 = getLimbData(charSize, 0.3, 0.65, 0.6, 0.8, 0.7, 0.85)

    const newLeftArmData9 = getLimbData(charSize, 0.16, 0.8, 0.01, 0.95, -0.22, 0.92)
    const newRightArmData9 = getLimbData(charSize, 0.31, 0.53, 0.4, 0.37, 0.4, 0.15)
    const newLeftLegData9 = getLimbData(charSize, 0.2, 0.85, 0.32, 0.88, 0.18, 0.91)
    const newRightLegData9 = getLimbData(charSize, 0.45, 0.7, 0.6, 0.78, 0.75, 0.85)

    const newLeftArmData10 = getLimbData(charSize, 0.16, 0.91, 0.01, 0.94, -0.22, 0.92)
    const newRightArmData10 = getLimbData(charSize, 0.3, 0.8, 0.45, 0.67, 0.46, 0.4)
    const newLeftLegData10 = getLimbData(charSize, 0.54, 0.73, 0.45, 0.88, 0.18, 0.91)
    const newRightLegData10 = getLimbData(charSize, 0.47, 0.78, 0.7, 0.87, 0.9, 0.89)

    const newLeftArmData11 = getLimbData(charSize, 0.18, 0.91, 0.01, 0.92, -0.22, 0.92)
    const newRightArmData11 = getLimbData(charSize, 0.3, 0.8, 0.55, 0.67, 0.62, 0.45)
    const newLeftLegData11 = getLimbData(charSize, 0.7, 0.85, 0.6, 0.92, 0.18, 0.91)
    const newRightLegData11 = getLimbData(charSize, 0.58, 0.93, 0.75, 0.93, 0.9, 0.93)

    const newLeftArmData12 = getLimbData(charSize, 0.18, 0.92, 0.01, 0.92, -0.22, 0.92)
    const newRightArmData12 = getLimbData(charSize, 0.3, 0.8, 0.65, 0.82, 0.85, 0.7)
    const newLeftLegData12 = getLimbData(charSize, 0.7, 0.96, 0.6, 0.94, 0.18, 0.91)
    const newRightLegData12 = getLimbData(charSize, 0.58, 0.95, 0.75, 0.94, 1, 0.93)

    const newRightArmData13 = getLimbData(charSize, 0.3, 0.92, 0.65, 0.93, 0.95, 0.89)

    const leftArm = characterCard.select("#leftArm")
    limbTransition(leftArm, 0, newLeftArmData)
    limbTransition(leftArm, delayBase, newLeftArmData2)
    limbTransition(leftArm, delayBase * 2, newLeftArmData3)
    limbTransition(leftArm, delayBase * 3, newLeftArmData4)
    limbTransition(leftArm, delayBase * 5, newLeftArmData5)
    limbTransition(leftArm, delayBase * 6, newLeftArmData6)
    limbTransition(leftArm, delayBase * 7, newLeftArmData7)
    limbTransition(leftArm, delayBase * 8, newLeftArmData8)
    limbTransition(leftArm, delayBase * 9, newLeftArmData9)
    limbTransition(leftArm, delayBase * 10, newLeftArmData10)
    limbTransition(leftArm, delayBase * 11, newLeftArmData11)
    limbTransition(leftArm, delayBase * 12, newLeftArmData12)

    const rightArm = characterCard.select("#rightArm")
    limbTransition(rightArm, 0, newRightArmData)
    limbTransition(rightArm, delayBase, newRightArmData2)
    limbTransition(rightArm, delayBase * 2, newRightArmData3)
    limbTransition(rightArm, delayBase * 3, newRightArmData4)
    limbTransition(rightArm, delayBase * 6, newRightArmData6)
    limbTransition(rightArm, delayBase * 7, newRightArmData7)
    limbTransition(rightArm, delayBase * 8, newRightArmData8)
    limbTransition(rightArm, delayBase * 9, newRightArmData9)
    limbTransition(rightArm, delayBase * 10, newRightArmData10)
    limbTransition(rightArm, delayBase * 11, newRightArmData11)
    limbTransition(rightArm, delayBase * 12, newRightArmData12)
    limbTransition(rightArm, delayBase * 13, newRightArmData13)

    const leftLeg = characterCard.select("#leftLeg")
    limbTransition(leftLeg, 0, newLeftLegData)
    limbTransition(leftLeg, delayBase, newLeftLegData2)
    limbTransition(leftLeg, delayBase * 2, newLeftLegData3)
    limbTransition(leftLeg, delayBase * 5, newLeftLegData5)
    limbTransition(leftLeg, delayBase * 7, newLeftLegData7)
    limbTransition(leftLeg, delayBase * 8, newLeftLegData8)
    limbTransition(leftLeg, delayBase * 9, newLeftLegData9)
    limbTransition(leftLeg, delayBase * 10, newLeftLegData10)
    limbTransition(leftLeg, delayBase * 11, newLeftLegData11)
    limbTransition(leftLeg, delayBase * 12, newLeftLegData12)

    const rightLeg = characterCard.select("#rightLeg")
    limbTransition(rightLeg, 0, newRightLegData)
    limbTransition(rightLeg, delayBase, newRightLegData2)
    limbTransition(rightLeg, delayBase * 2, newRightLegData3)
    limbTransition(rightLeg, delayBase * 5, newRightLegData5)
    limbTransition(rightLeg, delayBase * 8, newRightLegData8)
    limbTransition(rightLeg, delayBase * 9, newRightLegData9)
    limbTransition(rightLeg, delayBase * 10, newRightLegData10)
    limbTransition(rightLeg, delayBase * 11, newRightLegData11)
    limbTransition(rightLeg, delayBase * 12, newRightLegData12)
  }
  return allTimeouts
}

export {
  winAnimation,
  loseAnimation
}
