const noiseFunctions = `
  vec3 mod289(vec3 x)
  {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec4 mod289(vec4 x)
  {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec4 permute(vec4 x)
  {
    return mod289(((x*34.0)+1.0)*x);
  }

  vec4 taylorInvSqrt(vec4 r)
  {
    return 1.79284291400159 - 0.85373472095314 * r;
  }

  vec3 fade(vec3 t) {
    return t*t*t*(t*(t*6.0-15.0)+10.0);
  }

  // Classic Perlin noise
  float cnoise(vec3 P)
  {
    vec3 Pi0 = floor(P); // Integer part for indexing
    vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
    Pi0 = mod289(Pi0);
    Pi1 = mod289(Pi1);
    vec3 Pf0 = fract(P); // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;

    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);

    vec4 gx0 = ixy0 * (1.0 / 7.0);  
    vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);    

    vec4 gx1 = ixy1 * (1.0 / 7.0);
    vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);

    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;

    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);

    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
    float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
    return 2.2 * n_xyz;
  }

  // Classic Perlin noise, periodic variant
  float pnoise(vec3 P, vec3 rep)
  {
    vec3 Pi0 = mod(floor(P), rep); // Integer part, modulo period
    vec3 Pi1 = mod(Pi0 + vec3(1.0), rep); // Integer part + 1, mod period
    Pi0 = mod289(Pi0);
    Pi1 = mod289(Pi1);
    vec3 Pf0 = fract(P); // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;

    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);

    vec4 gx0 = ixy0 * (1.0 / 7.0);
    vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);

    vec4 gx1 = ixy1 * (1.0 / 7.0);
    vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);

    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;

    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);

    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
    float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
    return 2.2 * n_xyz;
  }
`

const classicPerlinNoiseJS = (x, y, z) => {
  const mod289 = (array) => {
    return array.map((p) => { return p - Math.floor(p * (1.0 / 289.0)) * 289.0 });
  }

  const permute = (array) => {
    return mod289(array.map((p) => { return ((p*34.0)+1.0)*p }))
  }

  const taylorInvSqrt = (array) => {
    return array.map((r) => { return 1.79284291400159 - 0.85373472095314 * r });
  }

  const fade = (array) => {
    return array.map((t) => { return t*t*t*(t*(t*6.0-15.0)+10.0) })
  }

  const fract = (array) => {
    return array.map((p) => { return p - Math.floor(p) })
  }

  const roundNearest = (x, n) => {
    return Math.round(x * 10**n) / 10**n
  }

  const step = (edge, array) => {
    return array.map((x, i) => { return roundNearest(x, 6) < roundNearest(edge[i], 6) ? 0 : 1 })
  }

  const dot = (a, b) => {
    return a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n)
  }

  const mix = (array1, array2, k) => {
    return array1.map((a, i) => { return a * (1 - k) + array2[i] * k })
  }

  // Classic Perlin noise
  const cnoise = (x, y, z) => {
    var Pi0 = [Math.floor(x), Math.floor(y), Math.floor(z)];
    var Pi1 = Pi0.map((p) => { return p + 1 })
    Pi0 = mod289(Pi0)
    Pi1 = mod289(Pi1)
    const Pf0 = fract([x, y, z]);
    const Pf1 = Pf0.map((p) => { return p - 1 })
    const ix = [Pi0[0], Pi1[0], Pi0[0], Pi1[0]];
    const iy = [Pi0[1], Pi0[1], Pi1[1], Pi1[1]];
    const iz0 = [Pi0[2], Pi0[2], Pi0[2], Pi0[2]];
    const iz1 = [Pi1[2], Pi1[2], Pi1[2], Pi1[2]];

    const ixy = permute(permute(ix).map((p, i) => { return p + iy[i] }));
    const ixy0 = permute(ixy.map((p, i) => { return p + iz0[i] }));
    const ixy1 = permute(ixy.map((p, i) => { return p + iz1[i] }));

    var gx0 = ixy0.map((p) => { return p * (1.0 / 7.0)});    
    var gy0 = fract(gx0.map((p) => { return Math.floor(p) * (1.0 / 7.0)})).map((p) => { return p - 0.5 });
    gx0 = fract(gx0);
    const gz0 = gx0.map((p, i) => { return 0.5 - Math.abs(p) - Math.abs(gy0[i])});
    const sz0 = step(gz0, [0,0,0,0]);
    const gx0Step = step([0,0,0,0], gx0)
    const gy0Step = step([0,0,0,0], gy0)
    const gx0Increment = sz0.map((p, i) => { return -p * (gx0Step[i] - 0.5) })
    const gy0Increment = sz0.map((p, i) => { return -p * (gy0Step[i] - 0.5) });
    gx0 = gx0.map((p, i) => { return p + gx0Increment[i] })
    gy0 = gy0.map((p, i) => { return p + gy0Increment[i] })

    var gx1 = ixy1.map((p) => { return p * (1.0 / 7.0) });
    const gx1Floor = gx1.map((p) => { return Math.floor(p) * (1.0 / 7.0) })
    const gy1Fract = fract(gx1Floor)
    var gy1 = gy1Fract.map((p) => { return p - 0.5 });
    gx1 = fract(gx1);
    const gz1 = gx1.map((p, i) => { return 0.5 - Math.abs(p) - Math.abs(gy1[i]) });
    const sz1 = step(gz1, [0,0,0,0]);
    const gx1Step = step([0,0,0,0], gx1)
    const gy1Step = step([0,0,0,0], gy1)
    const gx1Increment = sz1.map((p, i) => { return -p * (gx1Step[i] - 0.5) })
    const gy1Increment = sz1.map((p, i) => { return -p * (gy1Step[i] - 0.5) });
    gx1 = gx1.map((p, i) => { return p + gx1Increment[i] })
    gy1 = gy1.map((p, i) => { return p + gy1Increment[i] })

    var g000 = [gx0[0], gy0[0], gz0[0]];
    var g100 = [gx0[1], gy0[1], gz0[1]];
    var g010 = [gx0[2], gy0[2], gz0[2]];
    var g110 = [gx0[3], gy0[3], gz0[3]];
    var g001 = [gx1[0], gy1[0], gz1[0]];
    var g101 = [gx1[1], gy1[1], gz1[1]];
    var g011 = [gx1[2], gy1[2], gz1[2]];
    var g111 = [gx1[3], gy1[3], gz1[3]];

    const norm0 = taylorInvSqrt([dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)]);
    g000 = g000.map((p) => { return p * norm0[0] });
    g010 = g010.map((p) => { return p * norm0[1] });
    g100 = g100.map((p) => { return p * norm0[2] });
    g110 = g110.map((p) => { return p * norm0[3] });

    const norm1 = taylorInvSqrt([dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)]);
    g001 = g000.map((p) => { return p * norm1[0] });
    g011 = g010.map((p) => { return p * norm1[1] });
    g101 = g100.map((p) => { return p * norm1[2] });
    g111 = g110.map((p) => { return p * norm1[3] });

    const n000 = dot(g000, Pf0);
    const n100 = dot(g100, [Pf1[0], Pf0[1], Pf0[2]]);
    const n010 = dot(g010, [Pf0[0], Pf1[1], Pf0[2]]);
    const n110 = dot(g110, [Pf1[0], Pf1[1], Pf0[2]]);
    const n001 = dot(g001, [Pf0[0], Pf0[1], Pf1[2]]);
    const n101 = dot(g101, [Pf1[0], Pf0[1], Pf1[2]]);
    const n011 = dot(g011, [Pf0[0], Pf1[1], Pf1[2]]);
    const n111 = dot(g111, Pf1);

    const fade_xyz = fade(Pf0);
    const n_z = mix([n000, n100, n010, n110], [n001, n101, n011, n111], fade_xyz[2]);
    const n_yz = mix([n_z[0], n_z[1]], [n_z[2], n_z[3]], fade_xyz[1]);
    const n_xyz = mix([n_yz[0]], [n_yz[1]], fade_xyz[0]);
    return 2.2 * n_xyz;
  }

  return cnoise(x, y, z)
}

export {
  noiseFunctions,
  classicPerlinNoiseJS
}