import {
  sizeMultiples,
  defaultLimbPositions,
  getLimbData,
  getPositionAndSize,
  renderAttribute
} from '../asset-handling/render-helper'
import {
  animationSpeed,
  getRotation,
  attributeTransition,
  attributeRotation,
  limbTransition,
  delayBase,
  extraAssets
} from './animation-helper'
import { renderFX } from './fx-helper'
import { gameParameters } from '../game-setup/game-parameters'
import { playSoundEffect } from '../../components/game-play/sound-fx/SoundFX'
import * as d3 from 'd3'

const charSize = sizeMultiples.battle

const leftArmData = getLimbData(charSize, ...defaultLimbPositions.leftArm)
const rightArmData = getLimbData(charSize, ...defaultLimbPositions.rightArm)
const leftLegData = getLimbData(charSize, ...defaultLimbPositions.leftLeg)
const rightLegData = getLimbData(charSize, ...defaultLimbPositions.rightLeg)

const adjustHealthBar = (id, newHealth, animationDuration) => {
  if (newHealth !== 100) {
    d3.select(`#attacker-health${id}`)
      .transition()
        .duration(animationDuration)
        .style("width", `${newHealth}%`)
  }
}

function idleAnimation(currentId) {
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  // const idleLoopIndicator = d3.select("#idle-loop-indicator")._groups[0][0]
  // , loopBool = idleLoopIndicator !== null

  const idleSpeed = delayBase * 1.5

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(0, 2, headX, headY)
  , headRotation2 = getRotation(2, 1, headX, headY)
  , headRotation3 = getRotation(1, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(0, 3, bodyX, bodyY)
  , bodyRotation2 = getRotation(3, 4, bodyX, bodyY)
  , bodyRotation3 = getRotation(4, 0, bodyX, bodyY)
  , bodyRotation4 = getRotation(0, -2, bodyX, bodyY)
  , bodyRotation5 = getRotation(-2, 0, bodyX, bodyY)

  // Attribute Transitions
  const initialHeadPosition = getPositionAndSize("head")

  attributeTransition(head, 0, initialHeadPosition[0]+0.02, initialHeadPosition[1]-0.02)
  attributeTransition(head, idleSpeed, initialHeadPosition[0]+0.03, initialHeadPosition[1]+0.01)
  attributeTransition(head, idleSpeed * 2, initialHeadPosition[0]+0.035, initialHeadPosition[1]+0.04)
  attributeTransition(head, idleSpeed * 3, initialHeadPosition[0]+0.02, initialHeadPosition[1]+0.035)
  attributeTransition(head, idleSpeed * 4, initialHeadPosition[0], initialHeadPosition[1]+0.025)
  attributeTransition(head, idleSpeed * 5, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0], initialBodyPosition[1]-0.02)
  attributeTransition(body, idleSpeed, initialBodyPosition[0], initialBodyPosition[1])
  attributeTransition(body, idleSpeed * 2, initialBodyPosition[0], initialBodyPosition[1]+0.03)
  attributeTransition(body, idleSpeed * 5, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0]+0.07, initialLeftHandPosition[1]-0.02)
  attributeTransition(leftHand, idleSpeed, initialLeftHandPosition[0]+0.11, initialLeftHandPosition[1]+0.02)
  attributeTransition(leftHand, idleSpeed * 2, initialLeftHandPosition[0]+0.09, initialLeftHandPosition[1]+0.08)
  attributeTransition(leftHand, idleSpeed * 3, initialLeftHandPosition[0]+0.06, initialLeftHandPosition[1]+0.11)
  attributeTransition(leftHand, idleSpeed * 4, initialLeftHandPosition[0]+0.03, initialLeftHandPosition[1]+0.05)
  attributeTransition(leftHand, idleSpeed * 5, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0]+0.01, initialRightHandPosition[1]+0.01)
  attributeTransition(rightHand, idleSpeed, initialRightHandPosition[0]+0.01, initialRightHandPosition[1]+0.06)
  attributeTransition(rightHand, idleSpeed * 2, initialRightHandPosition[0]-0.025, initialRightHandPosition[1]+0.075)
  attributeTransition(rightHand, idleSpeed * 3, initialRightHandPosition[0]-0.0375, initialRightHandPosition[1]+0.045)
  attributeTransition(rightHand, idleSpeed * 4, initialRightHandPosition[0]-0.03, initialRightHandPosition[1]+0.02)
  attributeTransition(rightHand, idleSpeed * 5, initialRightHandPosition[0], initialRightHandPosition[1])

  // Attribute Rotations
  attributeRotation(head, idleSpeed, headRotation)
  attributeRotation(head, idleSpeed * 3, headRotation2)
  attributeRotation(head, idleSpeed * 4, headRotation3)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, idleSpeed, bodyRotation2)
  attributeRotation(body, idleSpeed * 3, bodyRotation3)
  attributeRotation(body, idleSpeed * 4, bodyRotation4)
  attributeRotation(body, idleSpeed * 5, bodyRotation5)

  const newLeftArmData = getLimbData(charSize, 0.39, 0.46, 0.06, 0.655, 0.36, 0.57)
  const newRightArmData = getLimbData(charSize, 0.55, 0.52, 0.61, 0.685, 0.81, 0.6)
  const newLeftLegData = getLimbData(charSize, 0.35, 0.7, 0.32, 0.82, 0.11, 0.918)

  const newLeftArmData2 = getLimbData(charSize, 0.39, 0.48, 0.1, 0.65, 0.4, 0.6)
  const newRightArmData2 = getLimbData(charSize, 0.56, 0.555, 0.62, 0.695, 0.81, 0.64)
  const newLeftLegData2 = getLimbData(charSize, 0.35, 0.7, 0.32, 0.85, 0.11, 0.918)
  const newRightLegData2 = getLimbData(charSize, 0.55, 0.72, 0.68, 0.8, 0.605, 0.91)

  const newLeftArmData3 = getLimbData(charSize, 0.375, 0.5, 0.12, 0.675, 0.4, 0.63)
  const newRightArmData3 = getLimbData(charSize, 0.56, 0.55, 0.6, 0.72, 0.81, 0.63)
  const newLeftLegData3 = getLimbData(charSize, 0.36, 0.7, 0.33, 0.87, 0.11, 0.918)
  const newRightLegData3 = getLimbData(charSize, 0.55, 0.73, 0.705, 0.8, 0.605, 0.91)

  const newLeftArmData4 = getLimbData(charSize, 0.375, 0.5, 0.05, 0.67, 0.4, 0.66)
  const newRightArmData4 = getLimbData(charSize, 0.545, 0.54, 0.6, 0.71, 0.81, 0.595)
  const newLeftLegData4 = getLimbData(charSize, 0.36, 0.7, 0.36, 0.88, 0.11, 0.918)
  const newRightLegData4 = getLimbData(charSize, 0.55, 0.735, 0.725, 0.8, 0.605, 0.91)

  const newLeftArmData5 = getLimbData(charSize, 0.38, 0.5, 0.015, 0.665, 0.4, 0.61)
  const newRightArmData5 = getLimbData(charSize, 0.55, 0.56, 0.615, 0.68, 0.81, 0.59)
  const newLeftLegData5 = getLimbData(charSize, 0.36, 0.7, 0.34, 0.86, 0.11, 0.918)
  const newRightLegData5 = getLimbData(charSize, 0.55, 0.73, 0.71, 0.8, 0.605, 0.91)

  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, idleSpeed, newLeftArmData2)
  limbTransition(leftArm, idleSpeed * 2, newLeftArmData3)
  limbTransition(leftArm, idleSpeed * 3, newLeftArmData4)
  limbTransition(leftArm, idleSpeed * 4, newLeftArmData5)
  limbTransition(leftArm, idleSpeed * 5, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, idleSpeed, newRightArmData2)
  limbTransition(rightArm, idleSpeed * 2, newRightArmData3)
  limbTransition(rightArm, idleSpeed * 3, newRightArmData4)
  limbTransition(rightArm, idleSpeed * 4, newRightArmData5)
  limbTransition(rightArm, idleSpeed * 5, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, idleSpeed, newLeftLegData2)
  limbTransition(leftLeg, idleSpeed * 2, newLeftLegData3)
  limbTransition(leftLeg, idleSpeed * 3, newLeftLegData4)
  limbTransition(leftLeg, idleSpeed * 4, newLeftLegData5)
  limbTransition(leftLeg, idleSpeed * 5, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, idleSpeed, newRightLegData2)
  limbTransition(rightLeg, idleSpeed * 2, newRightLegData3)
  limbTransition(rightLeg, idleSpeed * 3, newRightLegData4)
  limbTransition(rightLeg, idleSpeed * 4, newRightLegData5)
  limbTransition(rightLeg, idleSpeed * 5, rightLegData)

  // setTimeout(() => {
  //   if (loopBool) {
  //     idleAnimation(currentId)
  //   }
  // }, idleSpeed * 5.5)
}

function runAnimation(currentId) {
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  playSoundEffect("sound-fx--run")
  renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Run")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

  const leftFootBBox = leftFoot.node().getBBox()
  , leftFootX = leftFootBBox.x + leftFootBBox.width/2
  , leftFootY = leftFootBBox.y + leftFootBBox.height/2
  , leftFootRotation = getRotation(0, 68, leftFootX, leftFootY)
  , leftFootRotation2 = getRotation(68, 42, leftFootX, leftFootY)
  , leftFootRotation3 = getRotation(42, -22, leftFootX, leftFootY)
  , leftFootRotation4 = getRotation(-22, -43, leftFootX, leftFootY)
  , leftFootRotation5 = getRotation(-43, 0, leftFootX, leftFootY)
  , leftFootRotation6 = getRotation(0, 36, leftFootX, leftFootY)
  , leftFootRotation7 = getRotation(36, 62, leftFootX, leftFootY)
  , leftFootRotation8 = getRotation(62, 128, leftFootX, leftFootY)
  , leftFootRotation9 = getRotation(128, 0, leftFootX, leftFootY)

  const rightFootBBox = rightFoot.node().getBBox()
  , rightFootX = rightFootBBox.x + rightFootBBox.width/2
  , rightFootY = rightFootBBox.y + rightFootBBox.height/2
  , rightFootRotation = getRotation(0, 30, rightFootX, rightFootY)
  , rightFootRotation2 = getRotation(30, 66, rightFootX, rightFootY)
  , rightFootRotation3 = getRotation(66, 128, rightFootX, rightFootY)
  , rightFootRotation4 = getRotation(128, 90, rightFootX, rightFootY)
  , rightFootRotation5 = getRotation(90, 36, rightFootX, rightFootY)
  , rightFootRotation6 = getRotation(36, -25, rightFootX, rightFootY)
  , rightFootRotation7 = getRotation(-25, -42, rightFootX, rightFootY)
  , rightFootRotation8 = getRotation(-42, 0, rightFootX, rightFootY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(0, 7, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(7, 0, rightHandX, rightHandY)
  , rightHandRotation3 = getRotation(0, 18, rightHandX, rightHandY)
  , rightHandRotation4 = getRotation(18, 0, rightHandX, rightHandY)

  // Attribute Transitions
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, 0.245, 0.02)
  attributeTransition(head, delayBase, 0.245, -0.02)
  attributeTransition(head, delayBase * 2, 0.245, -0.07)
  attributeTransition(head, delayBase * 3, 0.265, -0.02)
  attributeTransition(head, delayBase * 4, 0.245, 0.02)
  attributeTransition(head, delayBase * 5, 0.245, -0.02)
  attributeTransition(head, delayBase * 6, 0.245, -0.07)
  attributeTransition(head, delayBase * 7, 0.245, -0.02)
  attributeTransition(head, delayBase * 8, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, 0.2583, 0.6467)
  attributeTransition(body, delayBase, 0.2583, 0.6067)
  attributeTransition(body, delayBase * 2, 0.2583, 0.5567)
  attributeTransition(body, delayBase * 3, 0.2583, 0.6067)
  attributeTransition(body, delayBase * 4, 0.2583, 0.6467)
  attributeTransition(body, delayBase * 5, 0.2583, 0.6067)
  attributeTransition(body, delayBase * 6, 0.2583, 0.5567)
  attributeTransition(body, delayBase * 7, 0.2583, 0.6067)
  attributeTransition(body, delayBase * 8, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, 0.2833, 0.5667)
  attributeTransition(leftHand, delayBase, 0.2833, 0.5867)
  attributeTransition(leftHand, delayBase * 2, 0.2833, 0.5667)
  attributeTransition(leftHand, delayBase * 3, 0.2833, 0.54)
  attributeTransition(leftHand, delayBase * 4, 0.2833, 0.51)
  attributeTransition(leftHand, delayBase * 5, 0.2833, 0.58)
  attributeTransition(leftHand, delayBase * 6, 0.2833, 0.59)
  attributeTransition(leftHand, delayBase * 7, 0.2833, 0.5467)
  attributeTransition(leftHand, delayBase * 8, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, 0.72, 0.6133)
  attributeTransition(rightHand, delayBase, 0.73, 0.6333)
  attributeTransition(rightHand, delayBase * 2, 0.73, 0.6133)
  attributeTransition(rightHand, delayBase * 3, 0.73, 0.5933)
  attributeTransition(rightHand, delayBase * 4, 0.73, 0.5433)
  attributeTransition(rightHand, delayBase * 5, 0.74, 0.6133)
  attributeTransition(rightHand, delayBase * 6, 0.74, 0.64)
  attributeTransition(rightHand, delayBase * 7, 0.735, 0.5833)
  attributeTransition(rightHand, delayBase * 8, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, 0, 0.115, 1.04)
  attributeTransition(leftFoot, delayBase, 0.335, 1.025)
  attributeTransition(leftFoot, delayBase * 2, 0.605, 0.99)
  attributeTransition(leftFoot, delayBase * 3, 0.585, 1.08)
  attributeTransition(leftFoot, delayBase * 4, 0.475, 1.1667)
  attributeTransition(leftFoot, delayBase * 5, 0.045, 1.11)
  attributeTransition(leftFoot, delayBase * 6, -0.01, 1.02)
  attributeTransition(leftFoot, delayBase * 7, -0.075, 0.965)
  attributeTransition(leftFoot, delayBase * 8, initialLeftFootPosition[0], initialLeftFootPosition[1])

  const initialRightFootPosition = getPositionAndSize("rightFoot")
  attributeTransition(rightFoot, 0, 0.3333, 1.15)
  attributeTransition(rightFoot, delayBase, 0.1233, 1.085)
  attributeTransition(rightFoot, delayBase * 2, 0.045, 1)
  attributeTransition(rightFoot, delayBase * 3, -0.01, 0.955)
  attributeTransition(rightFoot, delayBase * 4, 0.14, 1.025)
  attributeTransition(rightFoot, delayBase * 5, 0.375, 1.03)
  attributeTransition(rightFoot, delayBase * 6, 0.61, 1.0)
  attributeTransition(rightFoot, delayBase * 7, 0.62, 1.035)
  attributeTransition(rightFoot, delayBase * 8, initialRightFootPosition[0], initialRightFootPosition[1])

  // Attribute Rotations
  attributeRotation(leftFoot, 0, leftFootRotation)
  attributeRotation(leftFoot, delayBase, leftFootRotation2)
  attributeRotation(leftFoot, delayBase * 2, leftFootRotation3)
  attributeRotation(leftFoot, delayBase * 3, leftFootRotation4)
  attributeRotation(leftFoot, delayBase * 4, leftFootRotation5)
  attributeRotation(leftFoot, delayBase * 5, leftFootRotation6)
  attributeRotation(leftFoot, delayBase * 6, leftFootRotation7)
  attributeRotation(leftFoot, delayBase * 7, leftFootRotation8)
  attributeRotation(leftFoot, delayBase * 8, leftFootRotation9)

  attributeRotation(rightFoot, delayBase, rightFootRotation)
  attributeRotation(rightFoot, delayBase * 2, rightFootRotation2)
  attributeRotation(rightFoot, delayBase * 3, rightFootRotation3)
  attributeRotation(rightFoot, delayBase * 4, rightFootRotation4)
  attributeRotation(rightFoot, delayBase * 5, rightFootRotation5)
  attributeRotation(rightFoot, delayBase * 6, rightFootRotation6)
  attributeRotation(rightFoot, delayBase * 7, rightFootRotation7)
  attributeRotation(rightFoot, delayBase * 8, rightFootRotation8)

  attributeRotation(rightHand, delayBase, rightHandRotation)
  attributeRotation(rightHand, delayBase * 3, rightHandRotation2)
  attributeRotation(rightHand, delayBase * 5, rightHandRotation)
  attributeRotation(rightHand, delayBase * 6, rightHandRotation3)
  attributeRotation(rightHand, delayBase * 7, rightHandRotation4)

  const newLeftArmData = getLimbData(charSize, 0.35, 0.47, 0.02, 0.6, 0.355, 0.54)
  const newRightArmData = getLimbData(charSize, 0.58, 0.55, 0.63, 0.6, 0.77, 0.57)
  const newLeftLegData = getLimbData(charSize, 0.43, 0.72, 0.54, 0.84, 0.3, 0.78)
  const newRightLegData = getLimbData(charSize, 0.43, 0.72, 0.54, 0.8, 0.4, 0.9)

  const newLeftArmData2 = getLimbData(charSize, 0.35, 0.44, 0.05, 0.55, 0.355, 0.55)
  const newRightArmData2 = getLimbData(charSize, 0.58, 0.53, 0.63, 0.59, 0.77, 0.58)
  const newLeftLegData2 = getLimbData(charSize, 0.5, 0.7, 0.78, 0.77, 0.4, 0.775)
  const newRightLegData2 = getLimbData(charSize, 0.45, 0.7, 0.42, 0.75, 0.18, 0.85)

  const newLeftArmData3 = getLimbData(charSize, 0.39, 0.4, 0.02, 0.525, 0.355, 0.54)
  const newRightArmData3 = getLimbData(charSize, 0.58, 0.49, 0.63, 0.55, 0.77, 0.56)
  const newLeftLegData3 = getLimbData(charSize, 0.5, 0.66, 0.73, 0.68, 0.68, 0.8)
  const newRightLegData3 = getLimbData(charSize, 0.45, 0.65, 0.38, 0.75, 0.18, 0.74)

  const newLeftArmData4 = getLimbData(charSize, 0.39, 0.41, 0.02, 0.585, 0.355, 0.52)
  const newRightArmData4 = getLimbData(charSize, 0.58, 0.5, 0.63, 0.58, 0.77, 0.55)
  const newLeftLegData4 = getLimbData(charSize, 0.4, 0.66, 0.52, 0.78, 0.66, 0.92)
  const newRightLegData4 = getLimbData(charSize, 0.47, 0.665, 0.38, 0.875, 0.2, 0.69)

  const newLeftArmData5 = getLimbData(charSize, 0.39, 0.41, 0.0, 0.565, 0.355, 0.51)
  const newRightArmData5 = getLimbData(charSize, 0.58, 0.5, 0.63, 0.57, 0.77, 0.52)
  const newLeftLegData5 = getLimbData(charSize, 0.4, 0.66, 0.53, 0.78, 0.52, 0.92)
  const newRightLegData5 = getLimbData(charSize, 0.52, 0.665, 0.505, 0.875, 0.3, 0.75)

  const newLeftArmData6 = getLimbData(charSize, 0.39, 0.42, 0.01, 0.55, 0.355, 0.56)
  const newRightArmData6 = getLimbData(charSize, 0.58, 0.525, 0.63, 0.585, 0.77, 0.56)
  const newLeftLegData6 = getLimbData(charSize, 0.45, 0.66, 0.37, 0.78, 0.12, 0.85)
  const newRightLegData6 = getLimbData(charSize, 0.52, 0.69, 0.81, 0.75, 0.44, 0.78)

  const newLeftArmData7 = getLimbData(charSize, 0.4, 0.4, 0.03, 0.53, 0.355, 0.56)
  const newRightArmData7 = getLimbData(charSize, 0.58, 0.515, 0.65, 0.555, 0.77, 0.575)
  const newLeftLegData7 = getLimbData(charSize, 0.41, 0.66, 0.3, 0.78, 0.05, 0.75)
  const newRightLegData7 = getLimbData(charSize, 0.52, 0.66, 0.73, 0.7, 0.68, 0.82)

  const newLeftArmData8 = getLimbData(charSize, 0.4, 0.41, 0.01, 0.58, 0.355, 0.53)
  const newRightArmData8 = getLimbData(charSize, 0.58, 0.515, 0.63, 0.585, 0.77, 0.54)
  const newLeftLegData8 = getLimbData(charSize, 0.41, 0.66, 0.35, 0.89, 0.13, 0.69)
  const newRightLegData8 = getLimbData(charSize, 0.46, 0.66, 0.57, 0.78, 0.7, 0.92)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData2)
  limbTransition(leftArm, delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayBase * 3, newLeftArmData4)
  limbTransition(leftArm, delayBase * 4, newLeftArmData5)
  limbTransition(leftArm, delayBase * 5, newLeftArmData6)
  limbTransition(leftArm, delayBase * 6, newLeftArmData7)
  limbTransition(leftArm, delayBase * 7, newLeftArmData8)
  limbTransition(leftArm, delayBase * 8, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData2)
  limbTransition(rightArm, delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayBase * 3, newRightArmData4)
  limbTransition(rightArm, delayBase * 4, newRightArmData5)
  limbTransition(rightArm, delayBase * 5, newRightArmData6)
  limbTransition(rightArm, delayBase * 6, newRightArmData7)
  limbTransition(rightArm, delayBase * 7, newRightArmData8)
  limbTransition(rightArm, delayBase * 8, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayBase * 3, newLeftLegData4)
  limbTransition(leftLeg, delayBase * 4, newLeftLegData5)
  limbTransition(leftLeg, delayBase * 5, newLeftLegData6)
  limbTransition(leftLeg, delayBase * 6, newLeftLegData7)
  limbTransition(leftLeg, delayBase * 7, newLeftLegData8)
  limbTransition(leftLeg, delayBase * 8, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData2)
  limbTransition(rightLeg, delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayBase * 3, newRightLegData4)
  limbTransition(rightLeg, delayBase * 4, newRightLegData5)
  limbTransition(rightLeg, delayBase * 5, newRightLegData6)
  limbTransition(rightLeg, delayBase * 6, newRightLegData7)
  limbTransition(rightLeg, delayBase * 7, newRightLegData8)
  limbTransition(rightLeg, delayBase * 8, rightLegData)
}

function moveFighter(currentId, xPositions, jumpingBool) {
  if (!jumpingBool) {
    runAnimation(currentId)
  }
  for (var runIdx = 0; runIdx < xPositions.length - 1; runIdx++) {
    d3.select(currentId)
      .transition()
        .duration(delayBase)
        .delay(delayBase * runIdx)
        .ease(d3.easeLinear)
        .style("left", (xPositions[runIdx + 1] * 100)+"%")
  }
}

const shiftVertical = (currentId, yPositions, allTimeouts) => {
  const nFrames = yPositions.length
  const endingShadowFrame = nFrames === 16 ? 2 : 3
  for (var jumpIdx = 0; jumpIdx < nFrames - 1; jumpIdx++) {
    d3.select(currentId).select(".svg-responsive__content")
      .transition()
        .delay(delayBase * jumpIdx)
        .duration(delayBase)
        .ease(d3.easeLinear)
        .style("bottom", `${10 + yPositions[jumpIdx + 1] * 100}%`)
        .style("top", "auto")
    const hideShadow = (
      yPositions[jumpIdx + 1] !== 0 ||
      (yPositions[jumpIdx + 1] === 0 && jumpIdx > nFrames/2 && jumpIdx < nFrames - endingShadowFrame)
    )
    d3.select(currentId).select("ellipse")
      .transition()
        .delay(delayBase * jumpIdx)
        .duration(delayBase)
          .style("display", hideShadow ? "none" : "block")
  }
  return allTimeouts
}

function runRightAnimation(yourIdNumber, xPositions, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  d3.select(currentId).select("svg").attr("transform", "scale(1,1)")
  d3.select(currentId).select("svg").style("-webkit-transform", "scale(1,1)")
  moveFighter(currentId, xPositions, false)
  return [delayBase * 9, allTimeouts]
}

function runLeftAnimation(yourIdNumber, xPositions, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  d3.select(currentId).select("svg").attr("transform", "scale(-1,1)")
  d3.select(currentId).select("svg").style("-webkit-transform", "scale(-1,1)")
  moveFighter(currentId, xPositions, false)
  return [delayBase * 9, allTimeouts]
}

function jumpStart(yourIdNumber, jumpMultiple, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`);

  const defaultMultiple = 2
  const timeMultiple = jumpMultiple/defaultMultiple
  const customSpeed = animationSpeed * timeMultiple

  playSoundEffect("sound-fx--jump")

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(0, 8, headX, headY)
  , headRotation2 = getRotation(8, 13, headX, headY)
  , headRotation3 = getRotation(13, -2, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(0, 8, bodyX, bodyY)
  , bodyRotation2 = getRotation(8, 13, bodyX, bodyY)
  , bodyRotation3 = getRotation(13, -2, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(0, 17, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(17, 47, leftHandX, leftHandY)
  , leftHandRotation3 = getRotation(47, 140, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(0, 10, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(10, 43, rightHandX, rightHandY)
  , rightHandRotation3 = getRotation(43, 86, rightHandX, rightHandY)

  const leftFootBBox = leftFoot.node().getBBox()
  , leftFootX = leftFootBBox.x + leftFootBBox.width/2
  , leftFootY = leftFootBBox.y + leftFootBBox.height/2
  , leftFootRotation = getRotation(0, 95, leftFootX, leftFootY)

  const rightFootBBox = rightFoot.node().getBBox()
  , rightFootX = rightFootBBox.x + rightFootBBox.width/2
  , rightFootY = rightFootBBox.y + rightFootBBox.height/2
  , rightFootRotation = getRotation(0, 47, rightFootX, rightFootY)

  // Attribute Transitions
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0]+0.05, initialHeadPosition[1]+0.14)
  attributeTransition(head, delayBase, initialHeadPosition[0]+0.08, initialHeadPosition[1]+0.17)
  attributeTransition(head, delayBase * 2, initialHeadPosition[0]-0.02, initialHeadPosition[1]-0.15, undefined, customSpeed)

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0]-0.03, initialBodyPosition[1]+0.1)
  attributeTransition(body, delayBase, initialBodyPosition[0]-0.01, initialBodyPosition[1]+0.15)
  attributeTransition(body, delayBase * 2, initialBodyPosition[0]-0.01, initialBodyPosition[1]-0.17, undefined, customSpeed)

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0]+0.02, initialLeftHandPosition[1]+0.06)
  attributeTransition(leftHand, delayBase, initialLeftHandPosition[0]-0.01, initialLeftHandPosition[1]+0.17)
  attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0]-0.21, initialLeftHandPosition[1]+0.11, undefined, customSpeed)

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0]-0.06, initialRightHandPosition[1]+0.1)
  attributeTransition(rightHand, delayBase, initialRightHandPosition[0]-0.14, initialRightHandPosition[1]+0.225)
  attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0]-0.135, initialRightHandPosition[1]+0.09, undefined, customSpeed)

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, delayBase * 2, initialLeftFootPosition[0]+0.03, initialLeftFootPosition[1]-0.11, undefined, customSpeed)

  const initialRightFootPosition = getPositionAndSize("rightFoot")
  attributeTransition(rightFoot, delayBase * 2, initialRightFootPosition[0]-0.07, initialRightFootPosition[1]-0.25, undefined, customSpeed)

  // Attribute Rotations
  attributeRotation(head, 0, headRotation)
  attributeRotation(head, delayBase, headRotation2)
  attributeRotation(head, delayBase * 2, headRotation3, customSpeed)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, delayBase, bodyRotation2)
  attributeRotation(body, delayBase * 2, bodyRotation3, customSpeed)

  attributeRotation(leftHand, 0, leftHandRotation)
  attributeRotation(leftHand, delayBase, leftHandRotation2)
  attributeRotation(leftHand, delayBase * 2, leftHandRotation3, customSpeed)

  attributeRotation(rightHand, 0, rightHandRotation)
  attributeRotation(rightHand, delayBase, rightHandRotation2)
  attributeRotation(rightHand, delayBase * 2, rightHandRotation3, customSpeed)

  attributeRotation(leftFoot, delayBase * 2, leftFootRotation, customSpeed)

  attributeRotation(rightFoot, delayBase * 2, rightFootRotation, customSpeed)

  const newLeftArmData = getLimbData(charSize, 0.34, 0.54, 0.0, 0.615, 0.3, 0.6)
  const newRightArmData = getLimbData(charSize, 0.54, 0.5, 0.6, 0.59, 0.785, 0.67)
  const newLeftLegData = getLimbData(charSize, 0.32, 0.79, 0.41, 0.89, 0.115, 0.912)
  const newRightLegData = getLimbData(charSize, 0.5, 0.8, 0.76, 0.8, 0.605, 0.91)

  const newLeftArmData2 = getLimbData(charSize, 0.34, 0.58, 0.01, 0.5, 0.3, 0.65)
  const newRightArmData2 = getLimbData(charSize, 0.74, 0.6, 0.9, 0.66, 0.81, 0.71)
  const newLeftLegData2 = getLimbData(charSize, 0.3, 0.79, 0.47, 0.93, 0.14, 0.905)
  const newRightLegData2 = getLimbData(charSize, 0.5, 0.83, 0.8, 0.82, 0.605, 0.91)

  const newLeftArmData3 = getLimbData(charSize, 0.36, 0.36, 0.3, 0.44, 0.2, 0.59)
  const newRightArmData3 = getLimbData(charSize, 0.52, 0.35, 0.55, 0.43, 0.67, 0.58)
  const newLeftLegData3 = getLimbData(charSize, 0.36, 0.55, 0.31, 0.7, 0.27, 0.82)
  const newRightLegData3 = getLimbData(charSize, 0.5, 0.6, 0.77, 0.57, 0.605, 0.71)

  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData2)
  limbTransition(leftArm, delayBase * 2, newLeftArmData3, customSpeed)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData2)
  limbTransition(rightArm, delayBase * 2, newRightArmData3, customSpeed)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayBase * 2, newLeftLegData3, customSpeed)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData2)
  limbTransition(rightLeg, delayBase * 2, newRightLegData3, customSpeed)

  return [delayBase * 2 + customSpeed, allTimeouts]
}


function jumpAnimation(yourIdNumber, yPositions, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`);

  const jumpMultiple = 4
  const defaultMultiple = 2
  const timeMultiple = jumpMultiple/defaultMultiple
  const customSpeed = animationSpeed * timeMultiple
  const additionalDelay = animationSpeed * (timeMultiple - 1)

  var delayStart
  [delayStart, allTimeouts] = jumpStart(yourIdNumber, jumpMultiple, allTimeouts)

  allTimeouts.push(setTimeout(() => {
    playSoundEffect("sound-fx--land")
    renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Jump")
  }, delayStart + delayBase * 4 + additionalDelay * 4))

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(-2, 0, headX, headY)
  , headRotation2 = getRotation(0, 10, headX, headY)
  , headRotation3 = getRotation(10, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(-2, 0, bodyX, bodyY)
  , bodyRotation2 = getRotation(0, 10, bodyX, bodyY)
  , bodyRotation3 = getRotation(10, 0, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(140, 148, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(148, 170, leftHandX, leftHandY)
  , leftHandRotation3 = getRotation(170, 196, leftHandX, leftHandY)
  , leftHandRotation4 = getRotation(196, 47, leftHandX, leftHandY)
  , leftHandRotation5 = getRotation(47, 8, leftHandX, leftHandY)
  , leftHandRotation6 = getRotation(8, 0, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(86, 78, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(78, 40, rightHandX, rightHandY)
  , rightHandRotation3 = getRotation(40, 20, rightHandX, rightHandY)
  , rightHandRotation4 = getRotation(20, 45, rightHandX, rightHandY)
  , rightHandRotation5 = getRotation(45, 0, rightHandX, rightHandY)

  const leftFootBBox = leftFoot.node().getBBox()
  , leftFootX = leftFootBBox.x + leftFootBBox.width/2
  , leftFootY = leftFootBBox.y + leftFootBBox.height/2
  , leftFootRotation = getRotation(95, 91, leftFootX, leftFootY)
  , leftFootRotation2 = getRotation(91, 40, leftFootX, leftFootY)
  , leftFootRotation3 = getRotation(40, 0, leftFootX, leftFootY)

  const rightFootBBox = rightFoot.node().getBBox()
  , rightFootX = rightFootBBox.x + rightFootBBox.width/2
  , rightFootY = rightFootBBox.y + rightFootBBox.height/2
  , rightFootRotation = getRotation(47, 40, rightFootX, rightFootY)
  , rightFootRotation2 = getRotation(47, 33, rightFootX, rightFootY)
  , rightFootRotation3 = getRotation(33, 0, rightFootX, rightFootY)

  // Attribute Transitions
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, delayStart, initialHeadPosition[0]-0.02, initialHeadPosition[1]-0.17, undefined, customSpeed)
  attributeTransition(head, delayStart + delayBase + additionalDelay, initialHeadPosition[0]-0.02, initialHeadPosition[1]-0.15, undefined, customSpeed)
  attributeTransition(head, delayStart + delayBase * 2 + additionalDelay * 2, initialHeadPosition[0]-0.05, initialHeadPosition[1]-0.15, undefined, customSpeed)
  attributeTransition(head, delayStart + delayBase * 4 + additionalDelay * 4, initialHeadPosition[0]+0.08, initialHeadPosition[1]+0.18)
  attributeTransition(head, delayStart + delayBase * 5 + additionalDelay * 4, initialHeadPosition[0], initialHeadPosition[1]+0.05)
  attributeTransition(head, delayStart + delayBase * 6 + additionalDelay * 4, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, delayStart, initialBodyPosition[0]-0.01, initialBodyPosition[1]-0.18, undefined, customSpeed)
  attributeTransition(body, delayStart + delayBase + additionalDelay, initialBodyPosition[0]-0.01, initialBodyPosition[1]-0.16, undefined, customSpeed)
  attributeTransition(body, delayStart + delayBase * 2 + additionalDelay * 2, initialBodyPosition[0]-0.04, initialBodyPosition[1]-0.17, undefined, customSpeed)
  attributeTransition(body, delayStart + delayBase * 4 + additionalDelay * 4, initialBodyPosition[0]-0.01, initialBodyPosition[1]+0.15)
  attributeTransition(body, delayStart + delayBase * 5 + additionalDelay * 4, initialBodyPosition[0]-0.02, initialBodyPosition[1]+0.03)
  attributeTransition(body, delayStart + delayBase * 6 + additionalDelay * 4, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, delayStart, initialLeftHandPosition[0]-0.34, initialLeftHandPosition[1]-0.01, undefined, customSpeed)
  attributeTransition(leftHand, delayStart + delayBase + additionalDelay, initialLeftHandPosition[0]-0.415, initialLeftHandPosition[1]-0.17, undefined, customSpeed)
  attributeTransition(leftHand, delayStart + delayBase * 2 + additionalDelay * 2, initialLeftHandPosition[0]-0.445, initialLeftHandPosition[1]-0.33, undefined, customSpeed)
  attributeTransition(leftHand, delayStart + delayBase * 4 + additionalDelay * 4, initialLeftHandPosition[0]-0.01, initialLeftHandPosition[1]+0.17)
  attributeTransition(leftHand, delayStart + delayBase * 5 + additionalDelay * 4, initialLeftHandPosition[0]+0.03, initialLeftHandPosition[1]+0.06)
  attributeTransition(leftHand, delayStart + delayBase * 6 + additionalDelay * 4, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, delayStart, initialRightHandPosition[0]-0.035, initialRightHandPosition[1]+0.02, undefined, customSpeed)
  attributeTransition(rightHand, delayStart + delayBase + additionalDelay, initialRightHandPosition[0]+0.09, initialRightHandPosition[1]-0.155, undefined, customSpeed)
  attributeTransition(rightHand, delayStart + delayBase * 2 + additionalDelay * 2, initialRightHandPosition[0]+0.06, initialRightHandPosition[1]-0.29, undefined, customSpeed)
  attributeTransition(rightHand, delayStart + delayBase * 4 + additionalDelay * 4, initialRightHandPosition[0]-0.14, initialRightHandPosition[1]+0.23)
  attributeTransition(rightHand, delayStart + delayBase * 5 + additionalDelay * 4, initialRightHandPosition[0]-0.03, initialRightHandPosition[1]+0.05)
  attributeTransition(rightHand, delayStart + delayBase * 6 + additionalDelay * 4, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, delayStart, initialLeftFootPosition[0]+0.05, initialLeftFootPosition[1]-0.11, undefined, customSpeed)
  attributeTransition(leftFoot, delayStart + delayBase + additionalDelay, initialLeftFootPosition[0]+0.05, initialLeftFootPosition[1]-0.09, undefined, customSpeed)
  attributeTransition(leftFoot, delayStart + delayBase * 2 + additionalDelay * 2, initialLeftFootPosition[0]+0.09, initialLeftFootPosition[1]-0.1, undefined, customSpeed)
  attributeTransition(leftFoot, delayStart + delayBase * 4 + additionalDelay * 4, initialLeftFootPosition[0], initialLeftFootPosition[1])
  attributeTransition(leftFoot, delayStart + delayBase * 6 + additionalDelay * 4, initialLeftFootPosition[0], initialLeftFootPosition[1])

  const initialRightFootPosition = getPositionAndSize("rightFoot")
  attributeTransition(rightFoot, delayStart, initialRightFootPosition[0]-0.05, initialRightFootPosition[1]-0.27, undefined, customSpeed)
  attributeTransition(rightFoot, delayStart + delayBase + additionalDelay, initialRightFootPosition[0]-0.03, initialRightFootPosition[1]-0.22, undefined, customSpeed)
  attributeTransition(rightFoot, delayStart + delayBase * 2 + additionalDelay * 2, initialRightFootPosition[0]-0.04, initialRightFootPosition[1]-0.21, undefined, customSpeed)
  attributeTransition(rightFoot, delayStart + delayBase * 3 + additionalDelay * 3, initialRightFootPosition[0]-0.04, initialRightFootPosition[1]-0.13, undefined, customSpeed)
  attributeTransition(rightFoot, delayStart + delayBase * 4 + additionalDelay * 4, initialRightFootPosition[0]-0.02, initialRightFootPosition[1])
  attributeTransition(rightFoot, delayStart + delayBase * 6 + additionalDelay * 4, initialRightFootPosition[0], initialRightFootPosition[1])

  // Attribute Rotations
  attributeRotation(head, delayStart + delayBase + additionalDelay, headRotation, customSpeed)
  attributeRotation(head, delayStart + delayBase * 4 + additionalDelay * 4, headRotation2)
  attributeRotation(head, delayStart + delayBase * 5 + additionalDelay * 4, headRotation3)

  attributeRotation(body, delayStart + delayBase + additionalDelay, bodyRotation, customSpeed)
  attributeRotation(body, delayStart + delayBase * 4 + additionalDelay * 4, bodyRotation2)
  attributeRotation(body, delayStart + delayBase * 5 + additionalDelay * 4, bodyRotation3)

  attributeRotation(leftHand, delayStart, leftHandRotation, customSpeed)
  attributeRotation(leftHand, delayStart + delayBase + additionalDelay, leftHandRotation2, customSpeed)
  attributeRotation(leftHand, delayStart + delayBase * 2 + additionalDelay * 2, leftHandRotation3, customSpeed)
  attributeRotation(leftHand, delayStart + delayBase * 4 + additionalDelay * 4, leftHandRotation4)
  attributeRotation(leftHand, delayStart + delayBase * 5 + additionalDelay * 4, leftHandRotation5)
  attributeRotation(leftHand, delayStart + delayBase * 6 + additionalDelay * 4, leftHandRotation6)

  attributeRotation(rightHand, delayStart, rightHandRotation, customSpeed)
  attributeRotation(rightHand, delayStart + delayBase + additionalDelay, rightHandRotation2, customSpeed)
  attributeRotation(rightHand, delayStart + delayBase * 2 + additionalDelay * 2, rightHandRotation3, customSpeed)
  attributeRotation(rightHand, delayStart + delayBase * 4 + additionalDelay * 4, rightHandRotation4)
  attributeRotation(rightHand, delayStart + delayBase * 5 + additionalDelay * 4, rightHandRotation5)

  attributeRotation(leftFoot, delayStart, leftFootRotation, customSpeed)
  attributeRotation(leftFoot, delayStart + delayBase * 3 + additionalDelay * 3, leftFootRotation2, customSpeed)
  attributeRotation(leftFoot, delayStart + delayBase * 4 + additionalDelay * 4, leftFootRotation3)

  attributeRotation(rightFoot, delayStart, rightFootRotation, customSpeed)
  attributeRotation(rightFoot, delayStart + delayBase + additionalDelay, rightFootRotation2, customSpeed)
  attributeRotation(rightFoot, delayStart + delayBase * 4 + additionalDelay * 4, rightFootRotation3)

  const newLeftArmData = getLimbData(charSize, 0.36, 0.35, 0.2, 0.41, 0.1, 0.47)
  const newRightArmData = getLimbData(charSize, 0.52, 0.35, 0.63, 0.43, 0.82, 0.58)
  const newLeftLegData = getLimbData(charSize, 0.35, 0.59, 0.31, 0.74, 0.29, 0.86)
  const newRightLegData = getLimbData(charSize, 0.5, 0.61, 0.79, 0.55, 0.58, 0.72)

  const newLeftArmData2 = getLimbData(charSize, 0.36, 0.365, 0.2, 0.35, 0.05, 0.37)
  const newRightArmData2 = getLimbData(charSize, 0.52, 0.41, 0.55, 0.37, 0.87, 0.435)
  const newLeftLegData2 = getLimbData(charSize, 0.37, 0.55, 0.33, 0.7, 0.29, 0.87)
  const newRightLegData2 = getLimbData(charSize, 0.5, 0.59, 0.76, 0.58, 0.62, 0.74)

  const newLeftArmData3 = getLimbData(charSize, 0.35, 0.38, 0.1, 0.305, 0.04, 0.3)
  const newRightArmData3 = getLimbData(charSize, 0.5, 0.42, 0.59, 0.4, 0.87, 0.37)
  const newLeftLegData3 = getLimbData(charSize, 0.32, 0.59, 0.31, 0.76, 0.26, 0.82)
  const newRightLegData3 = getLimbData(charSize, 0.5, 0.61, 0.71, 0.64, 0.63, 0.79)

  const newLeftArmData4 = getLimbData(charSize, 0.34, 0.58, 0.01, 0.5, 0.3, 0.65)
  const newRightArmData4 = getLimbData(charSize, 0.74, 0.6, 0.9, 0.66, 0.81, 0.71)
  const newLeftLegData4 = getLimbData(charSize, 0.32, 0.8, 0.43, 0.93, 0.24, 0.905)
  const newRightLegData4 = getLimbData(charSize, 0.5, 0.835, 0.79, 0.82, 0.63, 0.89)

  const newLeftArmData5 = getLimbData(charSize, 0.34, 0.53, 0.03, 0.68, 0.3, 0.61)
  const newRightArmData5 = getLimbData(charSize, 0.54, 0.55, 0.6, 0.68, 0.785, 0.63)
  const newLeftLegData5 = getLimbData(charSize, 0.35, 0.7, 0.34, 0.86, 0.11, 0.918)
  const newRightLegData5 = getLimbData(charSize, 0.55, 0.74, 0.68, 0.8, 0.605, 0.91)

  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, delayStart, newLeftArmData, customSpeed)
  limbTransition(leftArm, delayStart + delayBase + additionalDelay, newLeftArmData2, customSpeed)
  limbTransition(leftArm, delayStart + delayBase * 2 + additionalDelay * 2, newLeftArmData3, customSpeed)
  limbTransition(leftArm, delayStart + delayBase * 4 + additionalDelay * 4, newLeftArmData4)
  limbTransition(leftArm, delayStart + delayBase * 5 + additionalDelay * 4, newLeftArmData5)
  limbTransition(leftArm, delayStart + delayBase * 6 + additionalDelay * 4, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, delayStart, newRightArmData, customSpeed)
  limbTransition(rightArm, delayStart + delayBase + additionalDelay, newRightArmData2, customSpeed)
  limbTransition(rightArm, delayStart + delayBase * 2 + additionalDelay * 2, newRightArmData3, customSpeed)
  limbTransition(rightArm, delayStart + delayBase * 4 + additionalDelay * 4, newRightArmData4)
  limbTransition(rightArm, delayStart + delayBase * 5 + additionalDelay * 4, newRightArmData5)
  limbTransition(rightArm, delayStart + delayBase * 6 + additionalDelay * 4, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, delayStart, newLeftLegData, customSpeed)
  limbTransition(leftLeg, delayStart + delayBase + additionalDelay, newLeftLegData2, customSpeed)
  limbTransition(leftLeg, delayStart + delayBase * 3 + additionalDelay * 3, newLeftLegData3, customSpeed)
  limbTransition(leftLeg, delayStart + delayBase * 4 + additionalDelay * 4, newLeftLegData4)
  limbTransition(leftLeg, delayStart + delayBase * 5 + additionalDelay * 4, newLeftLegData5)
  limbTransition(leftLeg, delayStart + delayBase * 6 + additionalDelay * 4, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, delayStart, newRightLegData, customSpeed)
  limbTransition(rightLeg, delayStart + delayBase + additionalDelay, newRightLegData2, customSpeed)
  limbTransition(rightLeg, delayStart + delayBase * 3 + additionalDelay * 3, newRightLegData3, customSpeed)
  limbTransition(rightLeg, delayStart + delayBase * 4 + additionalDelay * 4, newRightLegData4)
  limbTransition(rightLeg, delayStart + delayBase * 5 + additionalDelay * 4, newRightLegData5)
  limbTransition(rightLeg, delayStart + delayBase * 6 + additionalDelay * 4, rightLegData)

  // Adjust height of the jump
  allTimeouts = shiftVertical(currentId, yPositions, allTimeouts)

  const totalAnimationDuration = delayStart + delayBase * 7 + additionalDelay * 4
  return [totalAnimationDuration, allTimeouts]
}

function jumpMove(yourIdNumber, xPositions, yPositions, allTimeouts) {
  var animationDuration
  [animationDuration, allTimeouts] = jumpAnimation(yourIdNumber, yPositions, allTimeouts)
  moveFighter(`#character${yourIdNumber}`, xPositions, true)
  return [animationDuration, allTimeouts]
}

function jumpLeftAnimation(yourIdNumber, xPositions, yPositions, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  d3.select(currentId).select("svg").attr("transform", "scale(-1,1)")
  d3.select(currentId).select("svg").style("-webkit-transform", "scale(-1,1)")
  return jumpMove(yourIdNumber, xPositions, yPositions, allTimeouts)
}

function jumpRightAnimation(yourIdNumber, xPositions, yPositions, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  d3.select(currentId).select("svg").attr("transform", "scale(1,1)")
  d3.select(currentId).select("svg").style("-webkit-transform", "scale(1,1)")
  return jumpMove(yourIdNumber, xPositions, yPositions, allTimeouts)
}

function jumpPunchAnimation(yourIdNumber, yPositions, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`);

  const jumpMultiple = 4
  const defaultMultiple = 2
  const timeMultiple = jumpMultiple/defaultMultiple
  const customSpeed = animationSpeed * timeMultiple
  const additionalDelay = animationSpeed * (timeMultiple - 1)

  var delayStart
  [delayStart, allTimeouts] = jumpStart(yourIdNumber, jumpMultiple, allTimeouts)

  allTimeouts.push(setTimeout(() => {
    playSoundEffect("sound-fx--punch")
    renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Jump Punch")    
  }, delayStart + delayBase * 2 + additionalDelay * 2))
  allTimeouts.push(setTimeout(() => {
    playSoundEffect("sound-fx--land")
    renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Jump")
  }, delayStart + delayBase * 4 + additionalDelay * 4))

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(-2, 0, headX, headY)
  , headRotation2 = getRotation(0, 10, headX, headY)
  , headRotation3 = getRotation(10, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(-2, -4, bodyX, bodyY)
  , bodyRotation2 = getRotation(-4, 10, bodyX, bodyY)
  , bodyRotation3 = getRotation(10, 0, bodyX, bodyY)
  , bodyRotation4 = getRotation(0, 10, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(140, 50, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(50, 0, leftHandX, leftHandY)
  , leftHandRotation3 = getRotation(0, 40, leftHandX, leftHandY)
  , leftHandRotation4 = getRotation(40, 47, leftHandX, leftHandY)
  , leftHandRotation5 = getRotation(47, 0, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(86, 45, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(45, -30, rightHandX, rightHandY)
  , rightHandRotation3 = getRotation(-30, 20, rightHandX, rightHandY)
  , rightHandRotation4 = getRotation(20, 45, rightHandX, rightHandY)
  , rightHandRotation5 = getRotation(45, 0, rightHandX, rightHandY)

  const leftFootBBox = leftFoot.node().getBBox()
  , leftFootX = leftFootBBox.x + leftFootBBox.width/2
  , leftFootY = leftFootBBox.y + leftFootBBox.height/2
  , leftFootRotation = getRotation(95, 91, leftFootX, leftFootY)
  , leftFootRotation2 = getRotation(91, 40, leftFootX, leftFootY)
  , leftFootRotation3 = getRotation(40, 0, leftFootX, leftFootY)

  const rightFootBBox = rightFoot.node().getBBox()
  , rightFootX = rightFootBBox.x + rightFootBBox.width/2
  , rightFootY = rightFootBBox.y + rightFootBBox.height/2
  , rightFootRotation = getRotation(47, 40, rightFootX, rightFootY)
  , rightFootRotation2 = getRotation(47, 39, rightFootX, rightFootY)
  , rightFootRotation3 = getRotation(39, 33, rightFootX, rightFootY)
  , rightFootRotation4 = getRotation(33, 0, rightFootX, rightFootY)

  // Attribute Transitions
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, delayStart, initialHeadPosition[0]-0.02, initialHeadPosition[1]-0.17, undefined, customSpeed)
  attributeTransition(head, delayStart + delayBase + additionalDelay, initialHeadPosition[0]-0.06, initialHeadPosition[1]-0.15, undefined, customSpeed)
  attributeTransition(head, delayStart + delayBase * 2 + additionalDelay * 2, initialHeadPosition[0]+0.05, initialHeadPosition[1]-0.15, undefined, customSpeed)
  attributeTransition(head, delayStart + delayBase * 4 + additionalDelay * 4, initialHeadPosition[0]-0.05, initialHeadPosition[1]-0.17)
  attributeTransition(head, delayStart + delayBase * 5 + additionalDelay * 4, initialHeadPosition[0]+0.08, initialHeadPosition[1]+0.18)
  attributeTransition(head, delayStart + delayBase * 6 + additionalDelay * 4, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, delayStart, initialBodyPosition[0]-0.01, initialBodyPosition[1]-0.18, undefined, customSpeed)
  attributeTransition(body, delayStart + delayBase + additionalDelay, initialBodyPosition[0]-0.01, initialBodyPosition[1]-0.16, undefined, customSpeed)
  attributeTransition(body, delayStart + delayBase * 2 + additionalDelay * 2, initialBodyPosition[0], initialBodyPosition[1]-0.19, undefined, customSpeed)
  attributeTransition(body, delayStart + delayBase * 4 + additionalDelay * 4, initialBodyPosition[0]-0.03, initialBodyPosition[1]-0.18)
  attributeTransition(body, delayStart + delayBase * 5 + additionalDelay * 4, initialBodyPosition[0]-0.01, initialBodyPosition[1]+0.15)
  attributeTransition(body, delayStart + delayBase * 6 + additionalDelay * 4, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, delayStart, initialLeftHandPosition[0]-0.03, initialLeftHandPosition[1]+0.02, undefined, customSpeed)
  attributeTransition(leftHand, delayStart + delayBase + additionalDelay, initialLeftHandPosition[0]-0.03, initialLeftHandPosition[1]-0.165, undefined, customSpeed)
  attributeTransition(leftHand, delayStart + delayBase * 2 + additionalDelay * 2, initialLeftHandPosition[0]-0.04, initialLeftHandPosition[1]-0.28, undefined, customSpeed)
  attributeTransition(leftHand, delayStart + delayBase * 3 + additionalDelay * 3, initialLeftHandPosition[0]-0.04, initialLeftHandPosition[1]-0.26, undefined, customSpeed)
  attributeTransition(leftHand, delayStart + delayBase * 4 + additionalDelay * 4, initialLeftHandPosition[0]-0.14, initialLeftHandPosition[1]-0.13)
  attributeTransition(leftHand, delayStart + delayBase * 5 + additionalDelay * 4, initialLeftHandPosition[0]-0.01, initialLeftHandPosition[1]+0.17)
  attributeTransition(leftHand, delayStart + delayBase * 6 + additionalDelay * 4, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, delayStart, initialRightHandPosition[0]-0.04, initialRightHandPosition[1]-0.12, undefined, customSpeed)
  attributeTransition(rightHand, delayStart + delayBase + additionalDelay, initialRightHandPosition[0]-0.12, initialRightHandPosition[1]-0.2, undefined, customSpeed)
  attributeTransition(rightHand, delayStart + delayBase * 2 + additionalDelay * 2, initialRightHandPosition[0]+0.37, initialRightHandPosition[1]-0.23, undefined, customSpeed)
  attributeTransition(rightHand, delayStart + delayBase * 3 + additionalDelay * 3, initialRightHandPosition[0]+0.3, initialRightHandPosition[1]-0.23, undefined, customSpeed)
  attributeTransition(rightHand, delayStart + delayBase * 4 + additionalDelay * 4, initialRightHandPosition[0]+0.07, initialRightHandPosition[1]-0.31)
  attributeTransition(rightHand, delayStart + delayBase * 5 + additionalDelay * 4, initialRightHandPosition[0]-0.14, initialRightHandPosition[1]+0.23)
  attributeTransition(rightHand, delayStart + delayBase * 6 + additionalDelay * 4, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, delayStart, initialLeftFootPosition[0]+0.05, initialLeftFootPosition[1]-0.11, undefined, customSpeed)
  attributeTransition(leftFoot, delayStart + delayBase + additionalDelay, initialLeftFootPosition[0]+0.05, initialLeftFootPosition[1]-0.09, undefined, customSpeed)
  attributeTransition(leftFoot, delayStart + delayBase * 2 + additionalDelay * 2, initialLeftFootPosition[0]+0.05, initialLeftFootPosition[1]-0.13, undefined, customSpeed)
  attributeTransition(leftFoot, delayStart + delayBase * 3 + additionalDelay * 3, initialLeftFootPosition[0]+0.05, initialLeftFootPosition[1]-0.1, undefined, customSpeed)
  attributeTransition(leftFoot, delayStart + delayBase * 4 + additionalDelay * 4, initialLeftFootPosition[0]+0.08, initialLeftFootPosition[1]-0.11)
  attributeTransition(leftFoot, delayStart + delayBase * 5 + additionalDelay * 4, initialLeftFootPosition[0], initialLeftFootPosition[1])

  const initialRightFootPosition = getPositionAndSize("rightFoot")
  attributeTransition(rightFoot, delayStart, initialRightFootPosition[0]-0.05, initialRightFootPosition[1]-0.27, undefined, customSpeed)
  attributeTransition(rightFoot, delayStart + delayBase + additionalDelay, initialRightFootPosition[0]-0.03, initialRightFootPosition[1]-0.22, undefined, customSpeed)
  attributeTransition(rightFoot, delayStart + delayBase * 2 + additionalDelay * 2, initialRightFootPosition[0]-0.02, initialRightFootPosition[1]-0.25, undefined, customSpeed)
  attributeTransition(rightFoot, delayStart + delayBase * 3 + additionalDelay * 3, initialRightFootPosition[0]-0.02, initialRightFootPosition[1]-0.22, undefined, customSpeed)
  attributeTransition(rightFoot, delayStart + delayBase * 4 + additionalDelay * 4, initialRightFootPosition[0]-0.02, initialRightFootPosition[1]-0.14)
  attributeTransition(rightFoot, delayStart + delayBase * 5 + additionalDelay * 4, initialRightFootPosition[0]-0.02, initialRightFootPosition[1])
  attributeTransition(rightFoot, delayStart + delayBase * 6 + additionalDelay * 4, initialRightFootPosition[0], initialRightFootPosition[1])

  // Attribute Rotations
  attributeRotation(head, delayStart + delayBase + additionalDelay, headRotation, customSpeed)
  attributeRotation(head, delayStart + delayBase * 5 + additionalDelay * 4, headRotation2, customSpeed)
  attributeRotation(head, delayStart + delayBase * 6 + additionalDelay * 4, headRotation3, customSpeed)

  attributeRotation(body, delayStart + delayBase + additionalDelay, bodyRotation, customSpeed)
  attributeRotation(body, delayStart + delayBase * 2 + additionalDelay * 2, bodyRotation2, customSpeed)
  attributeRotation(body, delayStart + delayBase * 4 + additionalDelay * 4, bodyRotation3)
  attributeRotation(body, delayStart + delayBase * 5 + additionalDelay * 4, bodyRotation4)
  attributeRotation(body, delayStart + delayBase * 6 + additionalDelay * 4, bodyRotation3)

  attributeRotation(leftHand, delayStart, leftHandRotation, customSpeed)
  attributeRotation(leftHand, delayStart + delayBase + additionalDelay, leftHandRotation2, customSpeed)
  attributeRotation(leftHand, delayStart + delayBase * 4 + additionalDelay * 4, leftHandRotation3)
  attributeRotation(leftHand, delayStart + delayBase * 5 + additionalDelay * 4, leftHandRotation4)
  attributeRotation(leftHand, delayStart + delayBase * 6 + additionalDelay * 4, leftHandRotation5)

  attributeRotation(rightHand, delayStart, rightHandRotation, customSpeed)
  attributeRotation(rightHand, delayStart + delayBase + additionalDelay, rightHandRotation2, customSpeed)
  attributeRotation(rightHand, delayStart + delayBase * 2 + additionalDelay * 2, rightHandRotation3, customSpeed)
  attributeRotation(rightHand, delayStart + delayBase * 5 + additionalDelay * 4, rightHandRotation4)
  attributeRotation(rightHand, delayStart + delayBase * 6 + additionalDelay * 4, rightHandRotation5)

  attributeRotation(leftFoot, delayStart, leftFootRotation, customSpeed)
  attributeRotation(leftFoot, delayStart + delayBase * 4 + additionalDelay * 4, leftFootRotation2)
  attributeRotation(leftFoot, delayStart + delayBase * 5 + additionalDelay * 4, leftFootRotation3)

  attributeRotation(rightFoot, delayStart, rightFootRotation, customSpeed)
  attributeRotation(rightFoot, delayStart + delayBase + additionalDelay, rightFootRotation2, customSpeed)
  attributeRotation(rightFoot, delayStart + delayBase * 2 + additionalDelay * 2, rightFootRotation3, customSpeed)
  attributeRotation(rightFoot, delayStart + delayBase * 5 + additionalDelay * 4, rightFootRotation4)

  const newLeftArmData = getLimbData(charSize, 0.36, 0.35, 0.12, 0.41, 0.3, 0.55)
  const newRightArmData = getLimbData(charSize, 0.52, 0.35, 0.55, 0.5, 0.8, 0.47)
  const newLeftLegData = getLimbData(charSize, 0.35, 0.59, 0.31, 0.74, 0.29, 0.86)
  const newRightLegData = getLimbData(charSize, 0.5, 0.61, 0.79, 0.55, 0.58, 0.72)

  const newLeftArmData2 = getLimbData(charSize, 0.35, 0.35, -0.09, 0.49, 0.32, 0.45)
  const newRightArmData2 = getLimbData(charSize, 0.55, 0.43, 0.83, 0.625, 0.77, 0.45)
  const newLeftLegData2 = getLimbData(charSize, 0.37, 0.55, 0.33, 0.7, 0.29, 0.87)
  const newRightLegData2 = getLimbData(charSize, 0.5, 0.61, 0.79, 0.58, 0.6, 0.74)

  const newLeftArmData3 = getLimbData(charSize, 0.25, 0.35, -0.075, 0.44, 0.25, 0.4)
  const newRightArmData3 = getLimbData(charSize, 0.56, 0.4, 0.67, 0.405, 1.17, 0.41)
  const newLeftLegData3 = getLimbData(charSize, 0.35, 0.59, 0.315, 0.76, 0.29, 0.82)
  const newRightLegData3 = getLimbData(charSize, 0.5, 0.58, 0.77, 0.56, 0.6, 0.75)

  const newLeftArmData4 = getLimbData(charSize, 0.27, 0.38, -0.05, 0.51, 0.27, 0.43)
  const newRightArmData4 = getLimbData(charSize, 0.56, 0.4, 0.67, 0.405, 1.17, 0.41)
  const newLeftLegData4 = getLimbData(charSize, 0.35, 0.59, 0.315, 0.76, 0.29, 0.82)
  const newRightLegData4 = getLimbData(charSize, 0.5, 0.6, 0.77, 0.58, 0.6, 0.75)

  const newLeftArmData5 = getLimbData(charSize, 0.34, 0.35, -0.1, 0.31, 0.2, 0.45)
  const newRightArmData5 = getLimbData(charSize, 0.5, 0.36, 0.67, 0.355, 0.9, 0.35)
  const newLeftLegData5 = getLimbData(charSize, 0.32, 0.6, 0.32, 0.72, 0.28, 0.818)
  const newRightLegData5 = getLimbData(charSize, 0.5, 0.6, 0.72, 0.64, 0.64, 0.77)

  const newLeftArmData6 = getLimbData(charSize, 0.34, 0.58, 0.01, 0.5, 0.3, 0.65)
  const newRightArmData6 = getLimbData(charSize, 0.74, 0.6, 0.9, 0.66, 0.81, 0.71)
  const newLeftLegData6 = getLimbData(charSize, 0.32, 0.8, 0.43, 0.93, 0.24, 0.905)
  const newRightLegData6 = getLimbData(charSize, 0.5, 0.835, 0.79, 0.82, 0.63, 0.89)

  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, delayStart, newLeftArmData, customSpeed)
  limbTransition(leftArm, delayStart + delayBase + additionalDelay, newLeftArmData2, customSpeed)
  limbTransition(leftArm, delayStart + delayBase * 2 + additionalDelay * 2, newLeftArmData3, customSpeed)
  limbTransition(leftArm, delayStart + delayBase * 3 + additionalDelay * 3, newLeftArmData4, customSpeed)
  limbTransition(leftArm, delayStart + delayBase * 4 + additionalDelay * 4, newLeftArmData5)
  limbTransition(leftArm, delayStart + delayBase * 5 + additionalDelay * 4, newLeftArmData6)
  limbTransition(leftArm, delayStart + delayBase * 6 + additionalDelay * 4, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, delayStart, newRightArmData, customSpeed)
  limbTransition(rightArm, delayStart + delayBase + additionalDelay, newRightArmData2, customSpeed)
  limbTransition(rightArm, delayStart + delayBase * 2 + additionalDelay * 2, newRightArmData3, customSpeed)
  limbTransition(rightArm, delayStart + delayBase * 3 + additionalDelay * 3, newRightArmData4, customSpeed)
  limbTransition(rightArm, delayStart + delayBase * 4 + additionalDelay * 4, newRightArmData5)
  limbTransition(rightArm, delayStart + delayBase * 5 + additionalDelay * 4, newRightArmData6)
  limbTransition(rightArm, delayStart + delayBase * 6 + additionalDelay * 4, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, delayStart, newLeftLegData, customSpeed)
  limbTransition(leftLeg, delayStart + delayBase + additionalDelay, newLeftLegData2, customSpeed)
  limbTransition(leftLeg, delayStart + delayBase * 2 + additionalDelay * 2, newLeftLegData3, customSpeed)
  limbTransition(leftLeg, delayStart + delayBase * 3 + additionalDelay * 3, newLeftLegData4, customSpeed)
  limbTransition(leftLeg, delayStart + delayBase * 4 + additionalDelay * 4, newLeftLegData5)
  limbTransition(leftLeg, delayStart + delayBase * 5 + additionalDelay * 4, newLeftLegData6)
  limbTransition(leftLeg, delayStart + delayBase * 6 + additionalDelay * 4, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, delayStart, newRightLegData, customSpeed)
  limbTransition(rightLeg, delayStart + delayBase + additionalDelay, newRightLegData2, customSpeed)
  limbTransition(rightLeg, delayStart + delayBase * 2 + additionalDelay * 2, newRightLegData3, customSpeed)
  limbTransition(rightLeg, delayStart + delayBase * 3 + additionalDelay * 3, newRightLegData4, customSpeed)
  limbTransition(rightLeg, delayStart + delayBase * 4 + additionalDelay * 4, newRightLegData5)
  limbTransition(rightLeg, delayStart + delayBase * 5 + additionalDelay * 4, newRightLegData6)
  limbTransition(rightLeg, delayStart + delayBase * 6 + additionalDelay * 4, rightLegData)

  // Adjust height of the jump
  allTimeouts = shiftVertical(currentId, yPositions, allTimeouts)

  const totalAnimationDuration = delayStart + delayBase * 7 + additionalDelay * 4
  return [totalAnimationDuration, allTimeouts]
}

function singlePunchAnimation(yourIdNumber, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  characterCard.raise() // Makes sure that the punching character is on top

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(0, 7, headX, headY)
  , headRotation2 = getRotation(7, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(0, -7, bodyX, bodyY)
  , bodyRotation2 = getRotation(-7, 7, bodyX, bodyY)
  , bodyRotation3 = getRotation(7, 4, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , guardHandX = leftHandBBox.x + leftHandBBox.width/2
  , guardHandY = leftHandBBox.y + leftHandBBox.height/2
  , guardRotation = getRotation(0, -7, guardHandX, guardHandY)
  , guardRotation2 = getRotation(-7, 0, guardHandX, guardHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , punchHandX = rightHandBBox.x + rightHandBBox.width/2
  , punchHandY = rightHandBBox.y + rightHandBBox.height/2
  , punchRotation = getRotation(0, 25, punchHandX, punchHandY)
  , punchRotation2 = getRotation(25, 19, punchHandX, punchHandY)
  , punchRotation3 = getRotation(19, -35, punchHandX, punchHandY)
  , punchRotation4 = getRotation(-35, 0, punchHandX, punchHandY)

  playSoundEffect("sound-fx--punch")
  renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Single Punch")

  // Attribute Transition
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0]-0.175, initialHeadPosition[1]+0.1)
  attributeTransition(head, delayBase, initialHeadPosition[0]+0.165, initialHeadPosition[1]+0.045)
  attributeTransition(head, delayBase * 2, initialHeadPosition[0]+0.075, initialHeadPosition[1]+0.03)
  attributeTransition(head, delayBase * 3, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0]-0.1233, initialBodyPosition[1]+0.1)
  attributeTransition(body, delayBase, initialBodyPosition[0]+0.1, initialBodyPosition[1]+0.04)
  attributeTransition(body, delayBase * 2, initialBodyPosition[0]+0.02, initialBodyPosition[1])
  attributeTransition(body, delayBase * 3, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0]-0.3333, initialLeftHandPosition[1]+0.1133)
  attributeTransition(leftHand, delayBase, initialLeftHandPosition[0]+0.055, initialLeftHandPosition[1]-0.06)
  attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0]-0.01, initialLeftHandPosition[1]-0.08)
  attributeTransition(leftHand, delayBase * 3, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0]-0.23, 0.71, 0.24)
  attributeTransition(rightHand, delayBase, initialRightHandPosition[0]+0.5, initialRightHandPosition[1]-0.02, 0.29)
  attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0]+0.32, initialRightHandPosition[1]-0.04)
  attributeTransition(rightHand, delayBase * 3, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, 0, initialLeftFootPosition[0]-0.05, 1.1667, 0.33)
  attributeTransition(leftFoot, delayBase, initialLeftFootPosition[0], initialLeftFootPosition[1])

  // Attribute Rotation
  attributeRotation(head, delayBase, headRotation)
  attributeRotation(head, delayBase * 2, headRotation2)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, delayBase, bodyRotation2)
  attributeRotation(body, delayBase * 2, bodyRotation3)

  attributeRotation(leftHand, delayBase * 2, guardRotation)
  attributeRotation(leftHand, delayBase * 3, guardRotation2)

  attributeRotation(rightHand, 0, punchRotation3)
  attributeRotation(rightHand, delayBase, punchRotation2)
  attributeRotation(rightHand, delayBase * 2, punchRotation)
  attributeRotation(rightHand, delayBase * 3, punchRotation4)

  // Attribute Flip
  leftFoot.select("g")
    .attr("transform", "scale(-1,1)")
    .attr("transform-origin", "50% 100%")
  rightFoot.select("g")
    .attr("transform", "scale(-1,1)")
    .attr("transform-origin", "50% 100%")

  allTimeouts.push(setTimeout(() => {
    leftFoot.select("g")
      .attr("transform", "scale(1,1)")
      .attr("transform-origin", "0% 0%")
    rightFoot.select("g")
      .attr("transform", "scale(1,1)")
      .attr("transform-origin", "0% 0%")
  }, delayBase))

  const newLeftArmData = getLimbData(charSize, 0.25, 0.5, 0.15, 0.85, -0.01, 0.6)
  const newRightArmData = getLimbData(charSize, 0.42, 0.58, 0.71, 0.8, 0.64, 0.6)
  const newLeftLegData = getLimbData(charSize, 0.25, 0.795, -0.01, 0.78, 0.24, 0.92)
  const newRightLegData = getLimbData(charSize, 0.45, 0.77, 0.55, 0.87, 0.76, 0.9)

  const newLeftArmData2 = getLimbData(charSize, 0.35, 0.51, 0.05, 0.6, 0.355, 0.55)
  const newRightArmData2 = getLimbData(charSize, 0.66, 0.55, 0.77, 0.555, 1.27, 0.56)
  const newLeftLegData2 = getLimbData(charSize, 0.49, 0.72, 0.33, 0.84, 0.105, 0.918)
  const newRightLegData2 = getLimbData(charSize, 0.55, 0.71, 0.85, 0.82, 0.6, 0.91)

  const newLeftArmData3 = getLimbData(charSize, 0.32, 0.49, -0.015, 0.64, 0.275, 0.555)
  const newRightArmData3 = getLimbData(charSize, 0.54, 0.52, 0.77, 0.525, 1.1, 0.53)
  const newLeftLegData3 = getLimbData(charSize, 0.39, 0.72, 0.33, 0.84, 0.105, 0.918)
  const newRightLegData3 = getLimbData(charSize, 0.55, 0.71, 0.72, 0.82, 0.6, 0.91)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData2)
  limbTransition(leftArm, delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayBase * 3, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData2)
  limbTransition(rightArm, delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayBase * 3, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayBase * 3, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData2)
  limbTransition(rightLeg, delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayBase * 3, rightLegData)

  return [delayBase * 4, allTimeouts]
}

function doublePunchAnimation(yourIdNumber, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  allTimeouts = singlePunchAnimation(yourIdNumber, allTimeouts)[1]
  const delayStart = delayBase * 4

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(0, -5, headX, headY)
  , headRotation2 = getRotation(-5, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(0, -7, bodyX, bodyY)
  , bodyRotation2 = getRotation(-7, 16, bodyX, bodyY)
  , bodyRotation3 = getRotation(16, 7, bodyX, bodyY)
  , bodyRotation4 = getRotation(7, 0, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(0, 10, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(10, 0, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(19, -35, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(-35, 0, rightHandX, rightHandY)

  allTimeouts.push(setTimeout(() => {
    playSoundEffect("sound-fx--punch")
    renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Double Punch")
  }, delayStart))

  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, delayStart, initialHeadPosition[0]-0.21, initialHeadPosition[1]+0.1)
  attributeTransition(head, delayStart + delayBase, initialHeadPosition[0]+0.07, initialHeadPosition[1]+0.06)
  attributeTransition(head, delayStart + delayBase * 2, initialHeadPosition[0]+0.01, initialHeadPosition[1]+0.06)
  attributeTransition(head, delayStart + delayBase * 3, initialHeadPosition[0], initialHeadPosition[1]+0.05)
  attributeTransition(head, delayStart + delayBase * 4, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  // [0.2333, 0.6867, 0.41]
  attributeTransition(body, delayStart, initialBodyPosition[0]-0.13, initialBodyPosition[1]+0.1)
  attributeTransition(body, delayStart + delayBase, initialBodyPosition[0]+0.06, initialBodyPosition[1]+0.01)
  attributeTransition(body, delayStart + delayBase * 2, initialBodyPosition[0]+0.06, initialBodyPosition[1]+0.03)
  attributeTransition(body, delayStart + delayBase * 3, initialBodyPosition[0]+0.03, 0.72)
  attributeTransition(body, delayStart + delayBase * 4, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  // [0.2533, 0.6267, 0.31]
  attributeTransition(leftHand, delayStart, initialLeftHandPosition[0]-0.39, initialLeftHandPosition[1]+0.13)
  attributeTransition(leftHand, delayStart + delayBase, initialLeftHandPosition[0]+0.96, initialLeftHandPosition[1]-0.03)
  attributeTransition(leftHand, delayStart + delayBase * 2, initialLeftHandPosition[0]+0.79, initialLeftHandPosition[1]-0.015)
  attributeTransition(leftHand, delayStart + delayBase * 3, initialLeftHandPosition[0]+0.4, initialLeftHandPosition[1])
  attributeTransition(leftHand, delayStart + delayBase * 4, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  // [0.7433, 0.66, 0.28]
  attributeTransition(rightHand, delayStart, initialRightHandPosition[0]-0.24, initialRightHandPosition[1]+0.03)
  attributeTransition(rightHand, delayStart + delayBase, initialRightHandPosition[0]-0.39, initialRightHandPosition[1]+0.03)
  attributeTransition(rightHand, delayStart + delayBase * 2, initialRightHandPosition[0]-0.39, initialRightHandPosition[1]+0.075)
  attributeTransition(rightHand, delayStart + delayBase * 3, initialRightHandPosition[0]-0.3, initialRightHandPosition[1])
  attributeTransition(rightHand, delayStart + delayBase * 4, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  // [0.0333, 1.1667, 0.33]
  attributeTransition(leftFoot, delayStart, initialLeftFootPosition[0]-0.06, initialLeftFootPosition[1])
  attributeTransition(leftFoot, delayStart + delayBase, initialLeftFootPosition[0], initialLeftFootPosition[1])
  attributeTransition(leftFoot, delayStart + delayBase * 2, 0.025, 1.1667, 0.33)
  attributeTransition(leftFoot, delayStart + delayBase * 4, initialLeftFootPosition[0], initialLeftFootPosition[1])

  // Attribute Flip
  setTimeout(() => {
    leftFoot.select("g")
      .attr("transform", "scale(-1,1)")
      .attr("transform-origin", "50% 100%")
    rightFoot.select("g")
      .attr("transform", "scale(-1,1)")
      .attr("transform-origin", "50% 100%")
  }, delayStart)

  setTimeout(() => {
    leftFoot.select("g")
      .attr("transform", "scale(1,1)")
      .attr("transform-origin", "0% 0%")
    rightFoot.select("g")
      .attr("transform", "scale(1,1)")
      .attr("transform-origin", "0% 0%")
  }, delayStart + delayBase)

  // Attribute Rotation
  attributeRotation(head, delayStart, headRotation)
  attributeRotation(head, delayStart + delayBase, headRotation2)

  attributeRotation(body, delayStart, bodyRotation)
  attributeRotation(body, delayStart + delayBase, bodyRotation2)
  attributeRotation(body, delayStart + delayBase * 2, bodyRotation3)
  attributeRotation(body, delayStart + delayBase * 3, bodyRotation4)

  attributeRotation(rightHand, delayStart, rightHandRotation)
  attributeRotation(rightHand, delayStart + delayBase * 3, rightHandRotation2)

  attributeRotation(leftHand, delayStart + delayBase, leftHandRotation)
  attributeRotation(leftHand, delayStart + delayBase * 4, leftHandRotation2)

  const newLeftArmData = getLimbData(charSize, 0.24, 0.5, 0.09, 0.86, -0.06, 0.6)
  const newRightArmData = getLimbData(charSize, 0.42, 0.58, 0.74, 0.79, 0.64, 0.6)
  const newLeftLegData = getLimbData(charSize, 0.25, 0.795, -0.01, 0.78, 0.22, 0.92)
  const newRightLegData = getLimbData(charSize, 0.4, 0.76, 0.6, 0.89, 0.77, 0.895)

  const newLeftArmData2 = getLimbData(charSize, 0.45, 0.51, 0.6, 0.56, 1.24, 0.54)
  const newRightArmData2 = getLimbData(charSize, 0.42, 0.5, 0.23, 0.5, 0.44, 0.55)
  const newLeftLegData2 = getLimbData(charSize, 0.42, 0.69, 0.33, 0.78, 0.11, 0.92)
  const newRightLegData2 = getLimbData(charSize, 0.55, 0.72, 0.75, 0.8, 0.59, 0.92)

  const newLeftArmData3 = getLimbData(charSize, 0.45, 0.51, 0.6, 0.56, 1.24, 0.54)
  const newRightArmData3 = getLimbData(charSize, 0.42, 0.525, 0.21, 0.54, 0.44, 0.58)
  const newLeftLegData3 = getLimbData(charSize, 0.42, 0.69, 0.36, 0.78, 0.095, 0.92)
  const newRightLegData3 = getLimbData(charSize, 0.55, 0.73, 0.78, 0.8, 0.59, 0.92)

  const newLeftArmData4 = getLimbData(charSize, 0.4, 0.52, 0.14, 0.58, 0.7, 0.56)
  const newRightArmData4 = getLimbData(charSize, 0.55, 0.54, 0.555, 0.555, 0.56, 0.54)
  const newLeftLegData4 = getLimbData(charSize, 0.4, 0.66, 0.36, 0.78, 0.095, 0.92)
  const newRightLegData4 = getLimbData(charSize, 0.55, 0.72, 0.72, 0.81, 0.595, 0.92)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, delayStart, newLeftArmData)
  limbTransition(leftArm, delayStart + delayBase, newLeftArmData2)
  limbTransition(leftArm, delayStart + delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayStart + delayBase * 3, newLeftArmData4)
  limbTransition(leftArm, delayStart + delayBase * 4, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, delayStart, newRightArmData)
  limbTransition(rightArm, delayStart + delayBase, newRightArmData2)
  limbTransition(rightArm, delayStart + delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayStart + delayBase * 3, newRightArmData4)
  limbTransition(rightArm, delayStart + delayBase * 4, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, delayStart, newLeftLegData)
  limbTransition(leftLeg, delayStart + delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayStart + delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayStart + delayBase * 3, newLeftLegData4)
  limbTransition(leftLeg, delayStart + delayBase * 4, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, delayStart, newRightLegData)
  limbTransition(rightLeg, delayStart + delayBase, newRightLegData2)
  limbTransition(rightLeg, delayStart + delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayStart + delayBase * 3, newRightLegData4)
  limbTransition(rightLeg, delayStart + delayBase * 4, rightLegData)

  return [delayStart + delayBase * 5, allTimeouts]
}

function lowKickAnimation(yourIdNumber, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  characterCard.raise() // Makes sure that the kicking character is on top

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`);

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(0, -5, headX, headY)
  , headRotation2 = getRotation(-5, -12, headX, headY)
  , headRotation3 = getRotation(-12, -5, headX, headY)
  , headRotation4 = getRotation(-5, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(0, -5, bodyX, bodyY)
  , bodyRotation2 = getRotation(-5, -20, bodyX, bodyY)
  , bodyRotation3 = getRotation(-20, -5, bodyX, bodyY)
  , bodyRotation4 = getRotation(-5, -2, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(0, 11, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(11, 140, leftHandX, leftHandY)
  , leftHandRotation3 = getRotation(140, 65, leftHandX, leftHandY)
  , leftHandRotation4 = getRotation(65, -10, leftHandX, leftHandY)
  , leftHandRotation5 = getRotation(-10, 0, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(0, -25, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(0, -30, rightHandX, rightHandY)
  , rightHandRotation3 = getRotation(-30, 20, rightHandX, rightHandY)
  , rightHandRotation4 = getRotation(20, 10, rightHandX, rightHandY)
  , rightHandRotation5 = getRotation(10, 0, rightHandX, rightHandY)

  const rightFootBBox = rightFoot.node().getBBox()
  , rightFootX = rightFootBBox.x + rightFootBBox.width/2
  , rightFootY = rightFootBBox.y + rightFootBBox.height/2
  , rightFootRotation = getRotation(0, 26, rightFootX, rightFootY)
  , rightFootRotation2 = getRotation(26, 52, rightFootX, rightFootY)
  , rightFootRotation3 = getRotation(52, -5, rightFootX, rightFootY)
  , rightFootRotation4 = getRotation(-5, -36, rightFootX, rightFootY)
  , rightFootRotation5 = getRotation(-36, -15, rightFootX, rightFootY)
  , rightFootRotation6 = getRotation(-15, -5, rightFootX, rightFootY)
  , rightFootRotation7 = getRotation(-5, 0, rightFootX, rightFootY)

  playSoundEffect("sound-fx--kick")
  renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Low Kick")

  // Attribute Transitions
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0]-0.07, initialHeadPosition[1])
  attributeTransition(head, delayBase, initialHeadPosition[0]-0.41, initialHeadPosition[1]+0.03)
  attributeTransition(head, delayBase * 2, initialHeadPosition[0]-0.3, initialHeadPosition[1]+0.13)
  attributeTransition(head, delayBase * 3, initialHeadPosition[0]-0.21, initialHeadPosition[1]+0.2)
  attributeTransition(head, delayBase * 4, initialHeadPosition[0]-0.25, initialHeadPosition[1]+0.21)
  attributeTransition(head, delayBase * 5, initialHeadPosition[0]-0.11, initialHeadPosition[1]+0.11)
  attributeTransition(head, delayBase * 6, initialHeadPosition[0]-0.03, initialHeadPosition[1]+0.04)
  attributeTransition(head, delayBase * 7, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0]-0.03, initialBodyPosition[1]-0.02)
  attributeTransition(body, delayBase, initialBodyPosition[0]-0.23, initialBodyPosition[1])
  attributeTransition(body, delayBase * 2, initialBodyPosition[0]-0.17, initialBodyPosition[1]+0.1)
  attributeTransition(body, delayBase * 3, initialBodyPosition[0]+0.02, initialBodyPosition[1]+0.17)
  attributeTransition(body, delayBase * 4, initialBodyPosition[0]-0.03, initialBodyPosition[1]+0.17)
  attributeTransition(body, delayBase * 5, initialBodyPosition[0]-0.04, initialBodyPosition[1]+0.1)
  attributeTransition(body, delayBase * 6, initialBodyPosition[0], initialBodyPosition[1]+0.02)
  attributeTransition(body, delayBase * 7, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0]-0.02, initialLeftHandPosition[1]+0.06)
  attributeTransition(leftHand, delayBase, initialLeftHandPosition[0]-0.58, initialLeftHandPosition[1]+0.27)
  attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0]-0.38, initialLeftHandPosition[1]+0.27)
  attributeTransition(leftHand, delayBase * 3, initialLeftHandPosition[0]-0.165, initialLeftHandPosition[1]+0.25)
  attributeTransition(leftHand, delayBase * 4, initialLeftHandPosition[0]-0.22, initialLeftHandPosition[1]+0.25)
  attributeTransition(leftHand, delayBase * 5, initialLeftHandPosition[0]-0.12, initialLeftHandPosition[1]+0.12)
  attributeTransition(leftHand, delayBase * 6, initialLeftHandPosition[0]+0.005, initialLeftHandPosition[1]+0.04)
  attributeTransition(leftHand, delayBase * 7, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0]-0.14, initialRightHandPosition[1]-0.095)
  attributeTransition(rightHand, delayBase, initialRightHandPosition[0]-0.28, initialRightHandPosition[1]-0.26)
  attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0]-0.2, initialRightHandPosition[1]+0.12)
  attributeTransition(rightHand, delayBase * 3, initialRightHandPosition[0]-0.14, initialRightHandPosition[1]+0.2)
  attributeTransition(rightHand, delayBase * 4, initialRightHandPosition[0]-0.2, initialRightHandPosition[1]+0.21)
  attributeTransition(rightHand, delayBase * 5, initialRightHandPosition[0]-0.14, initialRightHandPosition[1]+0.13)
  attributeTransition(rightHand, delayBase * 6, initialRightHandPosition[0]-0.01, initialRightHandPosition[1]+0.03)
  attributeTransition(rightHand, delayBase * 7, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, delayBase * 2, initialLeftFootPosition[0]+0.02, initialLeftFootPosition[1])
  attributeTransition(leftFoot, delayBase * 6, initialLeftFootPosition[0], initialLeftFootPosition[1])

  const initialRightFootPosition = getPositionAndSize("rightFoot")
  attributeTransition(rightFoot, 0, initialRightFootPosition[0]-0.18, initialRightFootPosition[1]-0.01)
  attributeTransition(rightFoot, delayBase, initialRightFootPosition[0]-0.6, initialRightFootPosition[1]-0.07)
  attributeTransition(rightFoot, delayBase * 2, initialRightFootPosition[0]-0.33, initialRightFootPosition[1]-0.1)
  attributeTransition(rightFoot, delayBase * 3, initialRightFootPosition[0]+0.35, initialRightFootPosition[1]-0.02)
  attributeTransition(rightFoot, delayBase * 4, initialRightFootPosition[0]+0.24, initialRightFootPosition[1]-0.07)
  attributeTransition(rightFoot, delayBase * 5, initialRightFootPosition[0]+0.08, initialRightFootPosition[1]-0.07)
  attributeTransition(rightFoot, delayBase * 6, initialRightFootPosition[0], initialRightFootPosition[1]-0.03)
  attributeTransition(rightFoot, delayBase * 7, initialRightFootPosition[0], initialRightFootPosition[1])

  // Attribute Rotations
  attributeRotation(head, 0, headRotation)
  attributeRotation(head, delayBase, headRotation2)
  attributeRotation(head, delayBase * 2, headRotation3)
  attributeRotation(head, delayBase * 5, headRotation4)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, delayBase, bodyRotation2)
  attributeRotation(body, delayBase * 2, bodyRotation3)
  attributeRotation(body, delayBase * 5, bodyRotation4)

  attributeRotation(leftHand, 0, leftHandRotation)
  attributeRotation(leftHand, delayBase, leftHandRotation2)
  attributeRotation(leftHand, delayBase * 2, leftHandRotation3)
  attributeRotation(leftHand, delayBase * 3, leftHandRotation4)
  attributeRotation(leftHand, delayBase * 5, leftHandRotation5)

  attributeRotation(rightHand, 0, rightHandRotation)
  attributeRotation(rightHand, delayBase, rightHandRotation2)
  attributeRotation(rightHand, delayBase * 2, rightHandRotation3)
  attributeRotation(rightHand, delayBase * 5, rightHandRotation4)
  attributeRotation(rightHand, delayBase * 7, rightHandRotation5)

  attributeRotation(rightFoot, delayBase, rightFootRotation)
  attributeRotation(rightFoot, delayBase * 2, rightFootRotation2)
  attributeRotation(rightFoot, delayBase * 3, rightFootRotation3)
  attributeRotation(rightFoot, delayBase * 4, rightFootRotation4)
  attributeRotation(rightFoot, delayBase * 5, rightFootRotation5)
  attributeRotation(rightFoot, delayBase * 6, rightFootRotation6)
  attributeRotation(rightFoot, delayBase * 7, rightFootRotation7)

  // Attribute Flip
  allTimeouts.push(setTimeout(() => {
    leftFoot.select("g")
      .attr("transform", "scale(-1,1)")
      .attr("transform-origin", "50% 100%")
      // .attr("style", "-webkit-transform: scale(-1,1)")
  }, delayBase * 2))

  allTimeouts.push(setTimeout(() => {
    body.select("g")
      .attr("transform", "scale(-1,1)")
      .attr("transform-origin", "50% 100%")
      // .attr("style", "-webkit-transform: scale(-1,1)")
  }, delayBase * 3.2))

  allTimeouts.push(setTimeout(() => {
    body.select("g")
      .attr("transform", "scale(1,1)")
      .attr("transform-origin", "0% 0%")
      // .attr("style", "-webkit-transform: scale(1,1)")
      // .attr("style", "-webkit-transform-origin: 0% 0%")
  }, delayBase * 5))

  allTimeouts.push(setTimeout(() => {
    leftFoot.select("g")
      .attr("transform", "scale(1,1)")
      .attr("transform-origin", "0% 0%")
      // .attr("style", "-webkit-transform: scale(1,1)")
      // .attr("style", "-webkit-transform-origin: 0% 0%")
  }, delayBase * 6))

  const newLeftArmData = getLimbData(charSize, 0.34, 0.48, -0.04, 0.6, 0.3, 0.6)
  const newRightArmData = getLimbData(charSize, 0.5, 0.5, 0.57, 0.68, 0.7, 0.55)
  const newLeftLegData = getLimbData(charSize, 0.33, 0.7, 0.3, 0.85, 0.11, 0.918)
  const newRightLegData = getLimbData(charSize, 0.5, 0.71, 0.61, 0.8, 0.43, 0.91)

  const newLeftArmData2 = getLimbData(charSize, 0.145, 0.48, -0.08, 0.6, -0.19, 0.7)
  const newRightArmData2 = getLimbData(charSize, 0.3, 0.51, 0.45, 0.5, 0.57, 0.4)
  const newLeftLegData2 = getLimbData(charSize, 0.17, 0.73, 0.275, 0.83, 0.12, 0.918)
  const newRightLegData2 = getLimbData(charSize, 0.35, 0.71, 0.31, 0.76, 0.05, 0.84)

  const newLeftArmData3 = getLimbData(charSize, 0.18, 0.58, -0.15, 0.58, -0.07, 0.7)
  const newRightArmData3 = getLimbData(charSize, 0.35, 0.6, 0.45, 0.685, 0.59, 0.675)
  const newLeftLegData3 = getLimbData(charSize, 0.24, 0.81, -0.02, 0.83, 0.26, 0.905)
  const newRightLegData3 = getLimbData(charSize, 0.4, 0.76, 0.71, 0.8, 0.38, 0.82)

  const newLeftArmData4 = getLimbData(charSize, 0.35, 0.63, -0.15, 0.77, 0.14, 0.75)
  const newRightArmData4 = getLimbData(charSize, 0.5, 0.63, 0.62, 0.63, 0.67, 0.65)
  const newLeftLegData4 = getLimbData(charSize, 0.35, 0.84, 0.06, 0.81, 0.28, 0.905)
  const newRightLegData4 = getLimbData(charSize, 0.5, 0.83, 0.75, 0.85, 0.98, 0.9)

  const newLeftArmData5 = getLimbData(charSize, 0.35, 0.63, -0.24, 0.77, 0.14, 0.75)
  const newRightArmData5 = getLimbData(charSize, 0.5, 0.64, 0.62, 0.64, 0.67, 0.66)
  const newLeftLegData5 = getLimbData(charSize, 0.35, 0.85, 0.02, 0.8, 0.28, 0.905)
  const newRightLegData5 = getLimbData(charSize, 0.5, 0.83, 0.75, 0.86, 0.93, 0.92)

  const newLeftArmData6 = getLimbData(charSize, 0.3, 0.56, -0.145, 0.625, 0.16, 0.65)
  const newRightArmData6 = getLimbData(charSize, 0.48, 0.66, 0.58, 0.7, 0.67, 0.68)
  const newLeftLegData6 = getLimbData(charSize, 0.35, 0.8, 0.06, 0.8, 0.3, 0.915)
  const newRightLegData6 = getLimbData(charSize, 0.5, 0.8, 0.7, 0.765, 0.68, 0.92)

  const newLeftArmData7 = getLimbData(charSize, 0.32, 0.52, -0.025, 0.64, 0.3, 0.6)
  const newRightArmData7 = getLimbData(charSize, 0.52, 0.56, 0.61, 0.66, 0.77, 0.62)
  const newLeftLegData7 = getLimbData(charSize, 0.365, 0.73, 0.32, 0.87, 0.13, 0.91)
  const newRightLegData7 = getLimbData(charSize, 0.5, 0.735, 0.7, 0.77, 0.59, 0.92)

  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData2)
  limbTransition(leftArm, delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayBase * 3, newLeftArmData4)
  limbTransition(leftArm, delayBase * 4, newLeftArmData5)
  limbTransition(leftArm, delayBase * 5, newLeftArmData6)
  limbTransition(leftArm, delayBase * 6, newLeftArmData7)
  limbTransition(leftArm, delayBase * 7, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData2)
  limbTransition(rightArm, delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayBase * 3, newRightArmData4)
  limbTransition(rightArm, delayBase * 4, newRightArmData5)
  limbTransition(rightArm, delayBase * 5, newRightArmData6)
  limbTransition(rightArm, delayBase * 6, newRightArmData7)
  limbTransition(rightArm, delayBase * 7, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayBase * 3, newLeftLegData4)
  limbTransition(leftLeg, delayBase * 4, newLeftLegData5)
  limbTransition(leftLeg, delayBase * 5, newLeftLegData6)
  limbTransition(leftLeg, delayBase * 6, newLeftLegData7)
  limbTransition(leftLeg, delayBase * 7, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData2)
  limbTransition(rightLeg, delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayBase * 3, newRightLegData4)
  limbTransition(rightLeg, delayBase * 4, newRightLegData5)
  limbTransition(rightLeg, delayBase * 5, newRightLegData6)
  limbTransition(rightLeg, delayBase * 6, newRightLegData7)
  limbTransition(rightLeg, delayBase * 7, rightLegData)

  return [delayBase * 8, allTimeouts]
}

function startDefendAnimation(yourIdNumber, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`);

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(0, 4, headX, headY)
  , headRotation2 = getRotation(4, -6, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(0, 8, bodyX, bodyY)
  , bodyRotation2 = getRotation(8, -9, bodyX, bodyY)
  , bodyRotation3 = getRotation(-9, -14, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(0, -12, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(-12, -38, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(0, 18, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(18, -28, rightHandX, rightHandY)

  playSoundEffect("sound-fx--shield-deploy")

  // loadAllShieldAssets(characterCard, characterIdNumber, charSize)
  const startShield1 = characterCard.select(`#start-shield-1-${characterIdNumber}`)
  const startShield2 = characterCard.select(`#start-shield-2-${characterIdNumber}`)
  const startShield3 = characterCard.select(`#start-shield-3-${characterIdNumber}`)
  const startShield4 = characterCard.select(`#start-shield-4-${characterIdNumber}`)

  startShield1.attr("display", "block")
  allTimeouts.push(allTimeouts.push(setTimeout(() => {
    startShield1.attr("display", "none")
    startShield2.attr("display", "block")
  }, delayBase)))
  allTimeouts.push(allTimeouts.push(setTimeout(() => {
    startShield2.attr("display", "none")
    startShield3.attr("display", "block")
  }, delayBase * 2)))
  allTimeouts.push(allTimeouts.push(setTimeout(() => {
    startShield3.attr("display", "none")
    startShield4.attr("display", "block")
  }, delayBase * 3)))

  // Attribute Translation
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0]+0.06, initialHeadPosition[1]+0.05)
  attributeTransition(head, delayBase, initialHeadPosition[0]-0.18, initialHeadPosition[1]+0.07)
  attributeTransition(head, delayBase * 2, initialHeadPosition[0]-0.23, initialHeadPosition[1]+0.1)
  attributeTransition(head, delayBase * 3, initialHeadPosition[0]-0.21, initialHeadPosition[1]+0.09)

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0]+0.01, initialBodyPosition[1]+0.01)
  attributeTransition(body, delayBase, initialBodyPosition[0]-0.05, initialBodyPosition[1]+0.06)
  attributeTransition(body, delayBase * 2, initialBodyPosition[0]-0.1, initialBodyPosition[1]+0.08)
  attributeTransition(body, delayBase * 3, initialBodyPosition[0]-0.08, initialBodyPosition[1]+0.07)

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0]+0.07, initialLeftHandPosition[1]-0.08)
  attributeTransition(leftHand, delayBase, initialLeftHandPosition[0]+0.055, initialLeftHandPosition[1]-0.1)
  attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0], initialLeftHandPosition[1]-0.06)
  attributeTransition(leftHand, delayBase * 3, initialLeftHandPosition[0]+0.02, initialLeftHandPosition[1]-0.07)

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0]-0.17, initialRightHandPosition[1]+0.1)
  attributeTransition(rightHand, delayBase, initialRightHandPosition[0]-0.26, initialRightHandPosition[1]+0.095)
  attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0]-0.32, initialRightHandPosition[1]+0.11)
  attributeTransition(rightHand, delayBase * 3, initialRightHandPosition[0]-0.3, initialRightHandPosition[1]+0.1)

  // Attribute Rotation
  attributeRotation(head, 0, headRotation)
  attributeRotation(head, delayBase, headRotation2)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, delayBase, bodyRotation2)
  attributeRotation(body, delayBase * 2, bodyRotation3)

  attributeRotation(leftHand, 0, leftHandRotation)
  attributeRotation(leftHand, delayBase, leftHandRotation2)

  attributeRotation(rightHand, 0, rightHandRotation)
  attributeRotation(rightHand, delayBase, rightHandRotation2)

  const newLeftArmData = getLimbData(charSize, 0.39, 0.45, 0.12, 0.67, 0.39, 0.51)
  const newRightArmData = getLimbData(charSize, 0.54, 0.6, 0.57, 0.66, 0.67, 0.63)
  const newLeftLegData = getLimbData(charSize, 0.36, 0.7, 0.37, 0.87, 0.115, 0.91)
  const newRightLegData = getLimbData(charSize, 0.5, 0.71, 0.69, 0.8, 0.59, 0.92)

  const newLeftArmData2 = getLimbData(charSize, 0.25, 0.49, 0.26, 0.72, 0.41, 0.535)
  const newRightArmData2 = getLimbData(charSize, 0.46, 0.55, 0.84, 0.53, 0.64, 0.62)
  const newLeftLegData2 = getLimbData(charSize, 0.28, 0.73, 0.44, 0.9, 0.115, 0.91)
  const newRightLegData2 = getLimbData(charSize, 0.5, 0.75, 0.69, 0.82, 0.59, 0.92)

  const newLeftArmData3 = getLimbData(charSize, 0.22, 0.49, 0.21, 0.77, 0.38, 0.535)
  const newRightArmData3 = getLimbData(charSize, 0.44, 0.55, 0.77, 0.54, 0.6, 0.62)
  const newLeftLegData3 = getLimbData(charSize, 0.28, 0.78, 0.48, 0.9, 0.115, 0.91)
  const newRightLegData3 = getLimbData(charSize, 0.48, 0.75, 0.71, 0.82, 0.59, 0.92)

  const newLeftArmData4 = getLimbData(charSize, 0.22, 0.49, 0.21, 0.75, 0.38, 0.535)
  const newRightArmData4 = getLimbData(charSize, 0.44, 0.55, 0.78, 0.54, 0.6, 0.62)
  const newLeftLegData4 = getLimbData(charSize, 0.31, 0.78, 0.48, 0.9, 0.115, 0.91)
  const newRightLegData4 = getLimbData(charSize, 0.48, 0.75, 0.73, 0.82, 0.6, 0.92)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData2)
  limbTransition(leftArm, delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayBase * 3, newLeftArmData4)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData2)
  limbTransition(rightArm, delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayBase * 3, newRightArmData4)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayBase * 3, newLeftLegData4)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData2)
  limbTransition(rightLeg, delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayBase * 3, newRightLegData4)

  return [delayBase * 4, allTimeouts]
}

function stunRockForwardAnimation(yourIdNumber, delayStart, startingRotations, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(startingRotations[0], 14, headX, headY)
  , headRotation2 = getRotation(14, 6, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(startingRotations[1], 14, bodyX, bodyY)
  , bodyRotation2 = getRotation(14, 6, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(startingRotations[2], 130, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(130, 155, leftHandX, leftHandY)
  , leftHandRotation3 = getRotation(155, 161, leftHandX, leftHandY)
  , leftHandRotation4 = getRotation(161, 143, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(startingRotations[3], 132, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(132, 157, rightHandX, rightHandY)
  , rightHandRotation3 = getRotation(157, 168, rightHandX, rightHandY)
  , rightHandRotation4 = getRotation(168, 153, rightHandX, rightHandY)

  const leftFootBBox = leftFoot.node().getBBox()
  , leftFootX = leftFootBBox.x + leftFootBBox.width/2
  , leftFootY = leftFootBBox.y + leftFootBBox.height/2
  , leftFootRotation = getRotation(startingRotations[4], 10, leftFootX, leftFootY)
  , leftFootRotation2 = getRotation(10, 18, leftFootX, leftFootY)
  , leftFootRotation3 = getRotation(18, 22, leftFootX, leftFootY)
  , leftFootRotation4 = getRotation(22, 25, leftFootX, leftFootY)
  , leftFootRotation5 = getRotation(25, 10, leftFootX, leftFootY)

  // Attribute Translation
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, delayStart, initialHeadPosition[0]+0.16, initialHeadPosition[1]+0.055)
  attributeTransition(head, delayStart + delayBase, initialHeadPosition[0]+0.21, initialHeadPosition[1]+0.06)
  attributeTransition(head, delayStart + delayBase * 2, initialHeadPosition[0]+0.22, initialHeadPosition[1]+0.065)
  attributeTransition(head, delayStart + delayBase * 3, initialHeadPosition[0]+0.23, initialHeadPosition[1]+0.065)
  attributeTransition(head, delayStart + delayBase * 4, initialHeadPosition[0]+0.235, initialHeadPosition[1]+0.065)
  attributeTransition(head, delayStart + delayBase * 5, initialHeadPosition[0]+0.14, initialHeadPosition[1]+0.07)

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, delayStart, initialBodyPosition[0]+0.07, initialBodyPosition[1])
  attributeTransition(body, delayStart + delayBase, initialBodyPosition[0]+0.12, initialBodyPosition[1]+0.005)
  attributeTransition(body, delayStart + delayBase * 2, initialBodyPosition[0]+0.13, initialBodyPosition[1]+0.01)
  attributeTransition(body, delayStart + delayBase * 3, initialBodyPosition[0]+0.14, initialBodyPosition[1]+0.01)
  attributeTransition(body, delayStart + delayBase * 4, initialBodyPosition[0]+0.145, initialBodyPosition[1]+0.01)
  attributeTransition(body, delayStart + delayBase * 5, initialBodyPosition[0]+0.09, initialBodyPosition[1]+0.04)

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, delayStart, initialLeftHandPosition[0]-0.035, initialLeftHandPosition[1]+0.385)
  attributeTransition(leftHand, delayStart + delayBase, initialLeftHandPosition[0]-0.16, initialLeftHandPosition[1]+0.29)
  attributeTransition(leftHand, delayStart + delayBase * 2, initialLeftHandPosition[0]-0.2, initialLeftHandPosition[1]+0.26)
  attributeTransition(leftHand, delayStart + delayBase * 3, initialLeftHandPosition[0]-0.2, initialLeftHandPosition[1]+0.23)
  attributeTransition(leftHand, delayStart + delayBase * 4, initialLeftHandPosition[0]-0.205, initialLeftHandPosition[1]+0.22)
  attributeTransition(leftHand, delayStart + delayBase * 5, initialLeftHandPosition[0]-0.09, initialLeftHandPosition[1]+0.38)

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, delayStart, initialRightHandPosition[0]-0.24, initialRightHandPosition[1]+0.38)
  attributeTransition(rightHand, delayStart + delayBase, initialRightHandPosition[0]-0.4, initialRightHandPosition[1]+0.33)
  attributeTransition(rightHand, delayStart + delayBase * 2, initialRightHandPosition[0]-0.45, initialRightHandPosition[1]+0.3)
  attributeTransition(rightHand, delayStart + delayBase * 3, initialRightHandPosition[0]-0.46, initialRightHandPosition[1]+0.29)
  attributeTransition(rightHand, delayStart + delayBase * 4, initialRightHandPosition[0]-0.47, initialRightHandPosition[1]+0.28)
  attributeTransition(rightHand, delayStart + delayBase * 5, initialRightHandPosition[0]-0.33, initialRightHandPosition[1]+0.34)

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, delayStart + delayBase, initialLeftFootPosition[0]+0.02, initialLeftFootPosition[1]-0.03)
  attributeTransition(leftFoot, delayStart + delayBase * 2, initialLeftFootPosition[0], initialLeftFootPosition[1]-0.05)
  attributeTransition(leftFoot, delayStart + delayBase * 3, initialLeftFootPosition[0]-0.01, initialLeftFootPosition[1]-0.06)
  attributeTransition(leftFoot, delayStart + delayBase * 4, initialLeftFootPosition[0]-0.03, initialLeftFootPosition[1]-0.08)
  attributeTransition(leftFoot, delayStart + delayBase * 5, initialLeftFootPosition[0]+0.02, initialLeftFootPosition[1]-0.03)

  // Attribute Rotation
  attributeRotation(head, delayStart, headRotation)
  attributeRotation(head, delayStart + delayBase * 5, headRotation2)

  attributeRotation(body, delayStart, bodyRotation)
  attributeRotation(body, delayStart + delayBase * 5, bodyRotation2)

  attributeRotation(leftHand, delayStart, leftHandRotation)
  attributeRotation(leftHand, delayStart + delayBase, leftHandRotation2)
  attributeRotation(leftHand, delayStart + delayBase * 2, leftHandRotation3)
  attributeRotation(leftHand, delayStart + delayBase * 5, leftHandRotation4)

  attributeRotation(rightHand, delayStart, rightHandRotation)
  attributeRotation(rightHand, delayStart + delayBase, rightHandRotation2)
  attributeRotation(rightHand, delayStart + delayBase * 2, rightHandRotation3)
  attributeRotation(rightHand, delayStart + delayBase * 5, rightHandRotation4)

  attributeRotation(leftFoot, delayStart + delayBase, leftFootRotation)
  attributeRotation(leftFoot, delayStart + delayBase * 2, leftFootRotation2)
  attributeRotation(leftFoot, delayStart + delayBase * 3, leftFootRotation3)
  attributeRotation(leftFoot, delayStart + delayBase * 4, leftFootRotation4)
  attributeRotation(leftFoot, delayStart + delayBase * 5, leftFootRotation5)

  const newLeftArmData = getLimbData(charSize, 0.52, 0.49, 0.43, 0.6, 0.36, 0.8)
  const newRightArmData = getLimbData(charSize, 0.67, 0.5, 0.62, 0.68, 0.64, 0.77)
  const newLeftLegData = getLimbData(charSize, 0.4, 0.7, 0.33, 0.86, 0.115, 0.91)
  const newRightLegData = getLimbData(charSize, 0.54, 0.72, 0.71, 0.8, 0.6, 0.92)

  const newLeftArmData2 = getLimbData(charSize, 0.56, 0.49, 0.4, 0.6, 0.17, 0.8)
  const newRightArmData2 = getLimbData(charSize, 0.67, 0.5, 0.58, 0.68, 0.48, 0.77)
  const newLeftLegData2 = getLimbData(charSize, 0.48, 0.7, 0.39, 0.84, 0.08, 0.91)
  const newRightLegData2 = getLimbData(charSize, 0.54, 0.72, 0.78, 0.82, 0.6, 0.92)

  const newLeftArmData3 = getLimbData(charSize, 0.56, 0.49, 0.4, 0.6, 0.1, 0.75)
  const newRightArmData3 = getLimbData(charSize, 0.67, 0.5, 0.53, 0.68, 0.41, 0.77)
  const newLeftLegData3 = getLimbData(charSize, 0.48, 0.7, 0.39, 0.84, 0.08, 0.88)
  const newRightLegData3 = getLimbData(charSize, 0.54, 0.72, 0.78, 0.82, 0.6, 0.92)

  const newLeftArmData4 = getLimbData(charSize, 0.56, 0.49, 0.4, 0.6, 0.12, 0.7)
  const newRightArmData4 = getLimbData(charSize, 0.67, 0.5, 0.53, 0.68, 0.41, 0.77)
  const newLeftLegData4 = getLimbData(charSize, 0.48, 0.7, 0.38, 0.82, 0.09, 0.86)
  const newRightLegData4 = getLimbData(charSize, 0.54, 0.72, 0.79, 0.82, 0.6, 0.92)

  const newLeftArmData5 = getLimbData(charSize, 0.56, 0.49, 0.38, 0.6, 0.12, 0.7)
  const newRightArmData5 = getLimbData(charSize, 0.67, 0.5, 0.53, 0.68, 0.41, 0.77)
  const newLeftLegData5 = getLimbData(charSize, 0.48, 0.7, 0.39, 0.82, 0.08, 0.84)
  const newRightLegData5 = getLimbData(charSize, 0.54, 0.72, 0.79, 0.82, 0.6, 0.92)

  const newLeftArmData6 = getLimbData(charSize, 0.52, 0.49, 0.44, 0.6, 0.35, 0.75)
  const newRightArmData6 = getLimbData(charSize, 0.67, 0.5, 0.6, 0.68, 0.52, 0.77)
  const newLeftLegData6 = getLimbData(charSize, 0.48, 0.7, 0.39, 0.82, 0.16, 0.9)
  const newRightLegData6 = getLimbData(charSize, 0.54, 0.72, 0.79, 0.82, 0.6, 0.92)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, delayStart, newLeftArmData)
  limbTransition(leftArm, delayStart + delayBase, newLeftArmData2)
  limbTransition(leftArm, delayStart + delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayStart + delayBase * 3, newLeftArmData4)
  limbTransition(leftArm, delayStart + delayBase * 4, newLeftArmData5)
  limbTransition(leftArm, delayStart + delayBase * 5, newLeftArmData6)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, delayStart, newRightArmData)
  limbTransition(rightArm, delayStart + delayBase, newRightArmData2)
  limbTransition(rightArm, delayStart + delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayStart + delayBase * 3, newRightArmData4)
  limbTransition(rightArm, delayStart + delayBase * 4, newRightArmData5)
  limbTransition(rightArm, delayStart + delayBase * 5, newRightArmData6)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, delayStart, newLeftLegData)
  limbTransition(leftLeg, delayStart + delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayStart + delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayStart + delayBase * 3, newLeftLegData4)
  limbTransition(leftLeg, delayStart + delayBase * 4, newLeftLegData5)
  limbTransition(leftLeg, delayStart + delayBase * 5, newLeftLegData6)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, delayStart, newRightLegData)
  limbTransition(rightLeg, delayStart + delayBase, newRightLegData2)
  limbTransition(rightLeg, delayStart + delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayStart + delayBase * 3, newRightLegData4)
  limbTransition(rightLeg, delayStart + delayBase * 4, newRightLegData5)
  limbTransition(rightLeg, delayStart + delayBase * 5, newRightLegData6)

  return [delayStart + delayBase * 6, allTimeouts]
}

function stunRockBackwardAnimation(yourIdNumber, delayStart, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(6, -1, headX, headY)
  , headRotation2 = getRotation(-1, -8, headX, headY)
  , headRotation3 = getRotation(-8, -10, headX, headY)
  , headRotation4 = getRotation(-10, -17, headX, headY)
  , headRotation5 = getRotation(-17, -20, headX, headY)
  , headRotation6 = getRotation(-20, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(6, 0, bodyX, bodyY)
  , bodyRotation2 = getRotation(0, -8, bodyX, bodyY)
  , bodyRotation3 = getRotation(-8, -10, bodyX, bodyY)
  , bodyRotation4 = getRotation(-10, -17, bodyX, bodyY)
  , bodyRotation5 = getRotation(-17, -20, bodyX, bodyY)
  , bodyRotation6 = getRotation(-20, 0, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(143, 74, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(74, 50, leftHandX, leftHandY)
  , leftHandRotation3 = getRotation(50, 35, leftHandX, leftHandY)
  , leftHandRotation4 = getRotation(35, 27, leftHandX, leftHandY)
  , leftHandRotation5 = getRotation(27, 57, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(153, 70, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(70, 45, rightHandX, rightHandY)
  , rightHandRotation3 = getRotation(45, 28, rightHandX, rightHandY)
  , rightHandRotation4 = getRotation(28, 57, rightHandX, rightHandY)

  const leftFootBBox = leftFoot.node().getBBox()
  , leftFootX = leftFootBBox.x + leftFootBBox.width/2
  , leftFootY = leftFootBBox.y + leftFootBBox.height/2
  , leftFootRotation = getRotation(10, 0, leftFootX, leftFootY)

  const rightFootBBox = rightFoot.node().getBBox()
  , rightFootX = rightFootBBox.x + rightFootBBox.width/2
  , rightFootY = rightFootBBox.y + rightFootBBox.height/2
  , rightFootRotation = getRotation(0, -10, rightFootX, rightFootY)
  , rightFootRotation2 = getRotation(-10, -20, rightFootX, rightFootY)
  , rightFootRotation3 = getRotation(-20, 0, rightFootX, rightFootY)

  // Attribute Translation
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, delayStart, initialHeadPosition[0]+0.01, initialHeadPosition[1]+0.05)
  attributeTransition(head, delayStart + delayBase, initialHeadPosition[0]-0.14, initialHeadPosition[1]+0.05)
  attributeTransition(head, delayStart + delayBase * 2, initialHeadPosition[0]-0.2, initialHeadPosition[1]+0.05)
  attributeTransition(head, delayStart + delayBase * 3, initialHeadPosition[0]-0.235, initialHeadPosition[1]+0.065)
  attributeTransition(head, delayStart + delayBase * 4, initialHeadPosition[0]-0.26, initialHeadPosition[1]+0.075)
  attributeTransition(head, delayStart + delayBase * 5, initialHeadPosition[0]-0.04, initialHeadPosition[1]+0.02)

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, delayStart, initialBodyPosition[0]+0.02, initialBodyPosition[1]+0.03)
  attributeTransition(body, delayStart + delayBase, initialBodyPosition[0]-0.06, initialBodyPosition[1]+0.05)
  attributeTransition(body, delayStart + delayBase * 2, initialBodyPosition[0]-0.09, initialBodyPosition[1]+0.05)
  attributeTransition(body, delayStart + delayBase * 3, initialBodyPosition[0]-0.1, initialBodyPosition[1]+0.065)
  attributeTransition(body, delayStart + delayBase * 4, initialBodyPosition[0]-0.105, initialBodyPosition[1]+0.075)
  attributeTransition(body, delayStart + delayBase * 5, initialBodyPosition[0]-0.03, initialBodyPosition[1]+0.01)

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, delayStart, initialLeftHandPosition[0]+0.15, initialLeftHandPosition[1]+0.4)
  attributeTransition(leftHand, delayStart + delayBase, initialLeftHandPosition[0]+0.28, initialLeftHandPosition[1]+0.32)
  attributeTransition(leftHand, delayStart + delayBase * 2, initialLeftHandPosition[0]+0.3, initialLeftHandPosition[1]+0.25)
  attributeTransition(leftHand, delayStart + delayBase * 3, initialLeftHandPosition[0]+0.3, initialLeftHandPosition[1]+0.22)
  attributeTransition(leftHand, delayStart + delayBase * 4, initialLeftHandPosition[0]+0.3, initialLeftHandPosition[1]+0.2)
  attributeTransition(leftHand, delayStart + delayBase * 5, initialLeftHandPosition[0]+0.3, initialLeftHandPosition[1]+0.31)

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, delayStart, initialRightHandPosition[0]-0.12, initialRightHandPosition[1]+0.28)
  attributeTransition(rightHand, delayStart + delayBase, initialRightHandPosition[0]-0.08, initialRightHandPosition[1]+0.16)
  attributeTransition(rightHand, delayStart + delayBase * 2, initialRightHandPosition[0]-0.1, initialRightHandPosition[1]+0.04)
  attributeTransition(rightHand, delayStart + delayBase * 3, initialRightHandPosition[0]-0.09, initialRightHandPosition[1]+0.02)
  attributeTransition(rightHand, delayStart + delayBase * 4, initialRightHandPosition[0]-0.09, initialRightHandPosition[1])
  attributeTransition(rightHand, delayStart + delayBase * 5, initialRightHandPosition[0]-0.05, initialRightHandPosition[1]+0.13)

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, delayStart, initialLeftFootPosition[0], initialLeftFootPosition[1])

  const initialRightFootPosition = getPositionAndSize("rightFoot")
  attributeTransition(rightFoot, delayStart + delayBase, initialRightFootPosition[0]-0.02, initialRightFootPosition[1]-0.03)
  attributeTransition(rightFoot, delayStart + delayBase * 2, initialRightFootPosition[0]-0.01, initialRightFootPosition[1]-0.04)
  attributeTransition(rightFoot, delayStart + delayBase * 3, initialRightFootPosition[0]-0.01, initialRightFootPosition[1]-0.06)
  attributeTransition(rightFoot, delayStart + delayBase * 5, initialRightFootPosition[0], initialRightFootPosition[1])

  // Attribute Rotation
  attributeRotation(head, delayStart, headRotation)
  attributeRotation(head, delayStart + delayBase, headRotation2)
  attributeRotation(head, delayStart + delayBase * 2, headRotation3)
  attributeRotation(head, delayStart + delayBase * 3, headRotation4)
  attributeRotation(head, delayStart + delayBase * 4, headRotation5)
  attributeRotation(head, delayStart + delayBase * 5, headRotation6)

  attributeRotation(body, delayStart, bodyRotation)
  attributeRotation(body, delayStart + delayBase, bodyRotation2)
  attributeRotation(body, delayStart + delayBase * 2, bodyRotation3)
  attributeRotation(body, delayStart + delayBase * 3, bodyRotation4)
  attributeRotation(body, delayStart + delayBase * 4, bodyRotation5)
  attributeRotation(body, delayStart + delayBase * 5, bodyRotation6)

  attributeRotation(leftHand, delayStart, leftHandRotation)
  attributeRotation(leftHand, delayStart + delayBase, leftHandRotation2)
  attributeRotation(leftHand, delayStart + delayBase * 2, leftHandRotation3)
  attributeRotation(leftHand, delayStart + delayBase * 3, leftHandRotation4)
  attributeRotation(leftHand, delayStart + delayBase * 5, leftHandRotation5)

  attributeRotation(rightHand, delayStart, rightHandRotation)
  attributeRotation(rightHand, delayStart + delayBase, rightHandRotation2)
  attributeRotation(rightHand, delayStart + delayBase * 2, rightHandRotation3)
  attributeRotation(rightHand, delayStart + delayBase * 5, rightHandRotation4)

  attributeRotation(leftFoot, delayStart, leftFootRotation)

  attributeRotation(rightFoot, delayStart + delayBase, rightFootRotation)
  attributeRotation(rightFoot, delayStart + delayBase * 2, rightFootRotation2)
  attributeRotation(rightFoot, delayStart + delayBase * 5, rightFootRotation3)

  const newLeftArmData = getLimbData(charSize, 0.4, 0.49, 0.37, 0.63, 0.5, 0.8)
  const newRightArmData = getLimbData(charSize, 0.54, 0.5, 0.55, 0.68, 0.74, 0.77)
  const newLeftLegData = getLimbData(charSize, 0.4, 0.7, 0.33, 0.86, 0.115, 0.91)
  const newRightLegData = getLimbData(charSize, 0.52, 0.72, 0.69, 0.8, 0.6, 0.92)

  const newLeftArmData2 = getLimbData(charSize, 0.32, 0.49, 0.37, 0.63, 0.64, 0.8)
  const newRightArmData2 = getLimbData(charSize, 0.48, 0.55, 0.55, 0.65, 0.74, 0.67)
  const newLeftLegData2 = getLimbData(charSize, 0.3, 0.7, 0.42, 0.88, 0.115, 0.91)
  const newRightLegData2 = getLimbData(charSize, 0.52, 0.72, 0.69, 0.8, 0.6, 0.92)

  const newLeftArmData3 = getLimbData(charSize, 0.22, 0.49, 0.37, 0.63, 0.63, 0.71)
  const newRightArmData3 = getLimbData(charSize, 0.45, 0.56, 0.55, 0.6, 0.74, 0.6)
  const newLeftLegData3 = getLimbData(charSize, 0.25, 0.7, 0.45, 0.9, 0.115, 0.91)
  const newRightLegData3 = getLimbData(charSize, 0.5, 0.73, 0.67, 0.8, 0.6, 0.92)

  const newLeftArmData4 = getLimbData(charSize, 0.15, 0.49, 0.37, 0.63, 0.63, 0.7)
  const newRightArmData4 = getLimbData(charSize, 0.43, 0.56, 0.55, 0.58, 0.74, 0.58)
  const newLeftLegData4 = getLimbData(charSize, 0.23, 0.7, 0.45, 0.9, 0.115, 0.91)
  const newRightLegData4 = getLimbData(charSize, 0.5, 0.73, 0.67, 0.8, 0.58, 0.92)

  const newLeftArmData5 = getLimbData(charSize, 0.05, 0.48, 0.37, 0.62, 0.63, 0.69)
  const newRightArmData5 = getLimbData(charSize, 0.42, 0.56, 0.55, 0.56, 0.74, 0.58)
  const newRightLegData5 = getLimbData(charSize, 0.5, 0.74, 0.67, 0.75, 0.58, 0.92)

  const newLeftArmData6 = getLimbData(charSize, 0.34, 0.48, 0.45, 0.62, 0.59, 0.73)
  const newRightArmData6 = getLimbData(charSize, 0.51, 0.52, 0.6, 0.58, 0.75, 0.64)
  const newLeftLegData6 = getLimbData(charSize, 0.32, 0.7, 0.4, 0.85, 0.115, 0.91)
  const newRightLegData6 = getLimbData(charSize, 0.5, 0.74, 0.67, 0.75, 0.6, 0.92)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, delayStart, newLeftArmData)
  limbTransition(leftArm, delayStart + delayBase, newLeftArmData2)
  limbTransition(leftArm, delayStart + delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, delayStart + delayBase * 3, newLeftArmData4)
  limbTransition(leftArm, delayStart + delayBase * 4, newLeftArmData5)
  limbTransition(leftArm, delayStart + delayBase * 5, newLeftArmData6)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, delayStart, newRightArmData)
  limbTransition(rightArm, delayStart + delayBase, newRightArmData2)
  limbTransition(rightArm, delayStart + delayBase * 2, newRightArmData3)
  limbTransition(rightArm, delayStart + delayBase * 3, newRightArmData4)
  limbTransition(rightArm, delayStart + delayBase * 4, newRightArmData5)
  limbTransition(rightArm, delayStart + delayBase * 5, newRightArmData6)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, delayStart, newLeftLegData)
  limbTransition(leftLeg, delayStart + delayBase, newLeftLegData2)
  limbTransition(leftLeg, delayStart + delayBase * 2, newLeftLegData3)
  limbTransition(leftLeg, delayStart + delayBase * 3, newLeftLegData4)
  limbTransition(leftLeg, delayStart + delayBase * 5, newLeftLegData6)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, delayStart, newRightLegData)
  limbTransition(rightLeg, delayStart + delayBase, newRightLegData2)
  limbTransition(rightLeg, delayStart + delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, delayStart + delayBase * 3, newRightLegData4)
  limbTransition(rightLeg, delayStart + delayBase * 4, newRightLegData5)
  limbTransition(rightLeg, delayStart + delayBase * 5, newRightLegData6)

  return [delayStart + delayBase * 6, allTimeouts]
}

function stunAnimation(yourIdNumber, delayStart, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , eyes = characterCard.select(`#eyes-${characterIdNumber}`)
  , mouth = characterCard.select(`#mouth-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(0, 8, headX, headY)
  , headRotation1a = getRotation(6, 8, headX, headY)
  , headRotation2 = getRotation(8, 4, headX, headY)
  , headRotation3 = getRotation(4, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(0, 8, bodyX, bodyY)
  , bodyRotation1a = getRotation(6, 8, bodyX, bodyY)
  , bodyRotation2 = getRotation(8, 4, bodyX, bodyY)
  , bodyRotation3 = getRotation(4, 0, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(52, 90, leftHandX, leftHandY)
  , leftHandRotation1a = getRotation(143, 90, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(90, 30, leftHandX, leftHandY)
  , leftHandRotation3 = getRotation(30, 0, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(54, 110, rightHandX, rightHandY)
  , rightHandRotation1a = getRotation(153, 110, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(90, 44, rightHandX, rightHandY)
  , rightHandRotation3 = getRotation(44, 0, rightHandX, rightHandY)

  const leftFootBBox = leftFoot.node().getBBox()
  , leftFootX = leftFootBBox.x + leftFootBBox.width/2
  , leftFootY = leftFootBBox.y + leftFootBBox.height/2
  , leftFootRotation = getRotation(10, 0, leftFootX, leftFootY)

  // Attribute Translation
  const initialHeadPosition = getPositionAndSize("head")
  const initialBodyPosition = getPositionAndSize("body")
  const initialLeftHandPosition = getPositionAndSize("leftHand")
  const initialRightHandPosition = getPositionAndSize("rightHand")
  const initialLeftFootPosition = getPositionAndSize("leftFoot")

  attributeTransition(head, delayStart, initialHeadPosition[0]+0.13, initialHeadPosition[1]+0.045)
  attributeTransition(body, delayStart, initialBodyPosition[0]+0.07, initialBodyPosition[1])
  attributeTransition(leftHand, delayStart, initialLeftHandPosition[0]+0.14, initialLeftHandPosition[1]+0.41)
  attributeTransition(rightHand, delayStart, initialRightHandPosition[0]-0.08, initialRightHandPosition[1]+0.35)

  // Attribute Rotation
  attributeRotation(head, delayStart, headRotation)
  attributeRotation(body, delayStart, bodyRotation)
  attributeRotation(leftHand, delayStart, leftHandRotation)
  attributeRotation(rightHand, delayStart, rightHandRotation)

  const newLeftArmData = getLimbData(charSize, 0.48, 0.49, 0.42, 0.6, 0.47, 0.8)
  const newRightArmData = getLimbData(charSize, 0.62, 0.5, 0.68, 0.68, 0.78, 0.77)
  const newLeftLegData = getLimbData(charSize, 0.44, 0.7, 0.33, 0.86, 0.115, 0.91)
  const newRightLegData = getLimbData(charSize, 0.54, 0.72, 0.74, 0.8, 0.6, 0.92)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  const rightArm = characterCard.select("#rightArm")
  const leftLeg = characterCard.select("#leftLeg")
  const rightLeg = characterCard.select("#rightLeg")

  limbTransition(leftArm, delayStart, newLeftArmData)
  limbTransition(rightArm, delayStart, newRightArmData)
  limbTransition(leftLeg, delayStart, newLeftLegData)
  limbTransition(rightLeg, delayStart, newRightLegData)

  const startingRotations1 = [8, 8, 90, 110, 0]
  const startingRotations2 = [0, 0, 57, 57, 0]
  var [forwardDelayStart, allTimeouts] = stunRockForwardAnimation(yourIdNumber, delayStart + delayBase, startingRotations1, allTimeouts)
  var [backwardDelayStart, allTimeouts] = stunRockBackwardAnimation(yourIdNumber, forwardDelayStart, allTimeouts)
  var [finalDelayStart, allTimeouts] = stunRockForwardAnimation(yourIdNumber, backwardDelayStart, startingRotations2, allTimeouts)

  const initialEyesPosition = getPositionAndSize("eyes")
  const initialMouthPosition = getPositionAndSize("mouth")
  const defaultInputs = [characterCard.select("svg"), characterIdNumber]
  var stunEyes = characterCard.select(`#stun-eyes-${characterIdNumber}`)
  var stunMouth = characterCard.select(`#dead-mouth-${characterIdNumber}`)
  if (stunEyes._groups[0][0] === null || stunEyes._groups[0][0] === undefined) {
    renderAttribute(...defaultInputs, extraAssets["stun-eyes"], "stun-eyes", charSize, ...initialEyesPosition, false)
  }
  if (stunMouth._groups[0][0] === null || stunMouth._groups[0][0] === undefined) {
    renderAttribute(...defaultInputs, extraAssets["dead-mouth"], "dead-mouth", charSize, 0.3, 0.47, 0.7, false)
  }
  stunEyes = characterCard.select(`#stun-eyes-${characterIdNumber}`)
  stunMouth = characterCard.select(`#dead-mouth-${characterIdNumber}`)
  eyes.attr("display", "none")
  mouth.attr("display", "none")

  allTimeouts.push(setTimeout(() => {
    stunEyes.remove()
    stunMouth.remove()
    eyes.attr("display", "block")
    mouth.attr("display", "block")
  }, finalDelayStart + delayBase * 2))

  attributeTransition(head, finalDelayStart, initialHeadPosition[0]+0.13, initialHeadPosition[1]+0.035)
  attributeTransition(head, finalDelayStart + delayBase, initialHeadPosition[0]+0.055, initialHeadPosition[1]+0.02)
  attributeTransition(head, finalDelayStart + delayBase * 2, initialHeadPosition[0]+0.02, initialHeadPosition[1]+0.01)
  attributeTransition(head, finalDelayStart + delayBase * 3, initialHeadPosition[0], initialHeadPosition[1])

  attributeTransition(body, finalDelayStart, initialBodyPosition[0]+0.07, initialBodyPosition[1]+0.01)
  attributeTransition(body, finalDelayStart + delayBase, initialBodyPosition[0]+0.02, initialBodyPosition[1]+0.005)
  attributeTransition(body, finalDelayStart + delayBase * 2, initialBodyPosition[0]+0.01, initialBodyPosition[1])
  attributeTransition(body, finalDelayStart + delayBase * 3, initialBodyPosition[0], initialBodyPosition[1])

  attributeTransition(leftHand, finalDelayStart, initialLeftHandPosition[0]+0.14, initialLeftHandPosition[1]+0.41)
  attributeTransition(leftHand, finalDelayStart + delayBase, initialLeftHandPosition[0]+0.13, initialLeftHandPosition[1]+0.2)
  attributeTransition(leftHand, finalDelayStart + delayBase * 2, initialLeftHandPosition[0]+0.08, initialLeftHandPosition[1]+0.01)
  attributeTransition(leftHand, finalDelayStart + delayBase * 3, initialLeftHandPosition[0], initialLeftHandPosition[1])

  attributeTransition(rightHand, finalDelayStart, initialRightHandPosition[0]-0.08, initialRightHandPosition[1]+0.35)
  attributeTransition(rightHand, finalDelayStart + delayBase, initialRightHandPosition[0], initialRightHandPosition[1]+0.19)
  attributeTransition(rightHand, finalDelayStart + delayBase * 2, initialRightHandPosition[0]+0.02, initialRightHandPosition[1]+0.03)
  attributeTransition(rightHand, finalDelayStart + delayBase * 3, initialRightHandPosition[0], initialRightHandPosition[1])

  attributeTransition(leftFoot, finalDelayStart, initialLeftFootPosition[0], initialLeftFootPosition[1])
  attributeTransition(leftFoot, finalDelayStart + delayBase, initialLeftFootPosition[0], initialLeftFootPosition[1])


  attributeRotation(head, finalDelayStart, headRotation1a)
  attributeRotation(head, finalDelayStart + delayBase, headRotation2)
  attributeRotation(head, finalDelayStart + delayBase * 2, headRotation3)

  attributeRotation(body, finalDelayStart, bodyRotation1a)
  attributeRotation(body, finalDelayStart + delayBase, bodyRotation2)
  attributeRotation(body, finalDelayStart + delayBase * 2, bodyRotation3)

  attributeRotation(leftHand, finalDelayStart, leftHandRotation1a)
  attributeRotation(leftHand, finalDelayStart + delayBase, leftHandRotation2)
  attributeRotation(leftHand, finalDelayStart + delayBase * 2, leftHandRotation3)

  attributeRotation(rightHand, finalDelayStart, rightHandRotation1a)
  attributeRotation(rightHand, finalDelayStart + delayBase, rightHandRotation2)
  attributeRotation(rightHand, finalDelayStart + delayBase * 2, rightHandRotation3)

  attributeRotation(leftFoot, finalDelayStart, leftFootRotation)

  const newLeftArmData2 = getLimbData(charSize, 0.41, 0.49, 0.12, 0.59, 0.47, 0.68)
  const newRightArmData2 = getLimbData(charSize, 0.54, 0.5, 0.57, 0.67, 0.78, 0.685)
  const newLeftLegData2 = getLimbData(charSize, 0.37, 0.7, 0.29, 0.86, 0.115, 0.91)
  const newRightLegData2 = getLimbData(charSize, 0.54, 0.72, 0.65, 0.8, 0.6, 0.92)

  const newLeftArmData3 = getLimbData(charSize, 0.41, 0.49, 0.05, 0.67, 0.47, 0.57)
  const newRightArmData3 = getLimbData(charSize, 0.54, 0.5, 0.6, 0.71, 0.83, 0.6)
  const newRightLegData3 = getLimbData(charSize, 0.54, 0.7, 0.65, 0.8, 0.6, 0.92)

  limbTransition(leftArm, finalDelayStart, newLeftArmData)
  limbTransition(leftArm, finalDelayStart + delayBase, newLeftArmData2)
  limbTransition(leftArm, finalDelayStart + delayBase * 2, newLeftArmData3)
  limbTransition(leftArm, finalDelayStart + delayBase * 3, leftArmData)

  limbTransition(rightArm, finalDelayStart, newRightArmData)
  limbTransition(rightArm, finalDelayStart + delayBase, newRightArmData2)
  limbTransition(rightArm, finalDelayStart + delayBase * 2, newRightArmData3)
  limbTransition(rightArm, finalDelayStart + delayBase * 3, rightArmData)

  limbTransition(leftLeg, finalDelayStart, newLeftLegData)
  limbTransition(leftLeg, finalDelayStart + delayBase, newLeftLegData2)
  limbTransition(leftLeg, finalDelayStart + delayBase * 3, leftLegData)

  limbTransition(rightLeg, finalDelayStart, newRightLegData)
  limbTransition(rightLeg, finalDelayStart + delayBase, newRightLegData2)
  limbTransition(rightLeg, finalDelayStart + delayBase * 2, newRightLegData3)
  limbTransition(rightLeg, finalDelayStart + delayBase * 3, rightLegData)

  return [finalDelayStart + delayBase * 4, allTimeouts]
}

function endDefendAnimation(yourIdNumber, delayStart, shieldBreak, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`);

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(-6, 0, headX, headY)
  , headRotationStun = getRotation(-6, 2, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(-14, 0, bodyX, bodyY)
  , bodyRotationStun = getRotation(-14, 2, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(-38, 0, leftHandX, leftHandY)
  , leftHandRotationStun = getRotation(-38, 52, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(-28, 0, rightHandX, rightHandY)
  , rightHandRotationStun = getRotation(-28, 54, rightHandX, rightHandY)

  const initialHeadPosition = getPositionAndSize("head")
  const initialBodyPosition = getPositionAndSize("body")
  const initialLeftHandPosition = getPositionAndSize("leftHand")
  const initialRightHandPosition = getPositionAndSize("rightHand")

  attributeTransition(head, delayStart, initialHeadPosition[0]-0.17, initialHeadPosition[1]+0.07)
  attributeTransition(body, delayStart, initialBodyPosition[0]-0.06, initialBodyPosition[1]+0.07)
  attributeTransition(leftHand, delayStart, initialLeftHandPosition[0]+0.065, initialLeftHandPosition[1]-0.09)
  attributeTransition(rightHand, delayStart, initialRightHandPosition[0]-0.27, initialRightHandPosition[1]+0.1)

  const newLeftArmData = getLimbData(charSize, 0.27, 0.49, 0.24, 0.71, 0.43, 0.535)
  const newRightArmData = getLimbData(charSize, 0.44, 0.55, 0.85, 0.54, 0.6, 0.62)
  const newLeftLegData = getLimbData(charSize, 0.32, 0.78, 0.46, 0.9, 0.115, 0.91)
  const newRightLegData = getLimbData(charSize, 0.48, 0.75, 0.73, 0.82, 0.6, 0.92)

  const newLeftArmDataStun = getLimbData(charSize, 0.44, 0.49, 0.15, 0.6, 0.43, 0.7)
  const newRightArmDataStun = getLimbData(charSize, 0.5, 0.57, 0.64, 0.68, 0.8, 0.67)
  const newLeftLegDataStun = getLimbData(charSize, 0.35, 0.75, 0.33, 0.86, 0.115, 0.91)
  const newRightLegDataStun = getLimbData(charSize, 0.48, 0.68, 0.7, 0.82, 0.6, 0.92)

  const leftArm = characterCard.select("#leftArm")
  const rightArm = characterCard.select("#rightArm")
  const leftLeg = characterCard.select("#leftLeg")
  const rightLeg = characterCard.select("#rightLeg")

  limbTransition(leftArm, delayStart, newLeftArmData)
  limbTransition(rightArm, delayStart, newRightArmData)
  limbTransition(leftLeg, delayStart, newLeftLegData)
  limbTransition(rightLeg, delayStart, newRightLegData)

  if (!shieldBreak) {
    allTimeouts.push(setTimeout(() => {
      characterCard.select(`#start-shield-4-${characterIdNumber}`).attr("display", "none")
      characterCard.select(`#end-shield-${characterIdNumber}`).attr("display", "block")
    }, delayStart))
    allTimeouts.push(setTimeout(() => {
      characterCard.selectAll(".shield-icon").attr("display", "none")
    }, delayStart + delayBase))

    attributeRotation(head, delayStart + delayBase, headRotation)
    attributeRotation(body, delayStart + delayBase, bodyRotation)
    attributeRotation(leftHand, delayStart + delayBase, leftHandRotation)
    attributeRotation(rightHand, delayStart + delayBase, rightHandRotation)

    attributeTransition(head, delayStart + delayBase, initialHeadPosition[0], initialHeadPosition[1])
    attributeTransition(body, delayStart + delayBase, initialBodyPosition[0], initialBodyPosition[1])
    attributeTransition(leftHand, delayStart + delayBase, initialLeftHandPosition[0], initialLeftHandPosition[1])
    attributeTransition(rightHand, delayStart + delayBase, initialRightHandPosition[0], initialRightHandPosition[1])

    limbTransition(leftArm, delayStart + delayBase, leftArmData)
    limbTransition(rightArm, delayStart + delayBase, rightArmData)
    limbTransition(leftLeg, delayStart + delayBase, leftLegData)
    limbTransition(rightLeg, delayStart + delayBase, rightLegData)
  }
  else {
    characterCard.selectAll(".shield-icon").attr("display", "none")

    attributeRotation(head, delayStart + delayBase, headRotationStun)
    attributeRotation(body, delayStart + delayBase, bodyRotationStun)
    attributeRotation(leftHand, delayStart + delayBase, leftHandRotationStun)
    attributeRotation(rightHand, delayStart + delayBase, rightHandRotationStun)

    attributeTransition(head, delayStart + delayBase, initialHeadPosition[0]+0.02, initialHeadPosition[1]+0.03)
    attributeTransition(body, delayStart + delayBase, initialBodyPosition[0]+0.02, initialBodyPosition[1]+0.03)
    attributeTransition(leftHand, delayStart + delayBase, initialLeftHandPosition[0]+0.08, initialLeftHandPosition[1]+0.23)
    attributeTransition(rightHand, delayStart + delayBase, initialRightHandPosition[0]-0.03, initialRightHandPosition[1]+0.19)

    limbTransition(leftArm, delayStart + delayBase, newLeftArmDataStun)
    limbTransition(rightArm, delayStart + delayBase, newRightArmDataStun)
    limbTransition(leftLeg, delayStart + delayBase, newLeftLegDataStun)
    limbTransition(rightLeg, delayStart + delayBase, newRightLegDataStun)
  }
  return [delayStart + delayBase * 2, allTimeouts]
}

function hitShieldAnimation(yourIdNumber, prevShieldHealth, shieldHealth, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`);

  const shieldMultiple = 100 / gameParameters.shieldHealth
  const prevHitNumber = Math.ceil((1 - prevShieldHealth * shieldMultiple) * 5)
  const hitNumber = Math.ceil((1 - shieldHealth * shieldMultiple) * 5)
  const shieldBreak = shieldHealth === 0
  if (!shieldBreak) {
    playSoundEffect("sound-fx--shield-hit")

    const hitShieldSmall = characterCard.select(`#hit-shield-small-${hitNumber}-${characterIdNumber}`)
    const hitShieldLarge = characterCard.select(`#hit-shield-large-${hitNumber}-${characterIdNumber}`)

    var previousShieldId = (
      hitNumber === 1 ?
      `#start-shield-4-${characterIdNumber}` :
      `#hit-shield-large-${prevHitNumber}-${characterIdNumber}`
    )

    if (prevHitNumber === hitNumber) {
      hitShieldLarge.attr("display", "none")
      hitShieldSmall.attr("display", "block")
      allTimeouts.push(setTimeout(() => {
        hitShieldLarge.attr("display", "block")
        hitShieldSmall.attr("display", "none")
      }, delayBase))
    }
    else {
      characterCard.select(previousShieldId).attr("display", "none")
      if (hitNumber > 1) {
        characterCard.select(`#hit-shield-small-${prevHitNumber}-${characterIdNumber}`).attr("display", "none")
      }
      hitShieldSmall.attr("display", "block")
      allTimeouts.push(setTimeout(() => {
        hitShieldSmall.attr("display", "none")
        hitShieldLarge.attr("display", "block")
      }, delayBase))
    }
  }
  else {
    playSoundEffect("sound-fx--shield-break")

    const hitShieldBreak1 = characterCard.select(`#hit-shield-break-1-${characterIdNumber}`)
    const hitShieldBreak2 = characterCard.select(`#hit-shield-break-2-${characterIdNumber}`)
    const hitShieldBreak3 = characterCard.select(`#hit-shield-break-3-${characterIdNumber}`)
    const hitShieldBreak4 = characterCard.select(`#hit-shield-break-4-${characterIdNumber}`)

    var previousShieldId = `#hit-shield-large-${prevHitNumber}-${characterIdNumber}`
    characterCard.select(previousShieldId).attr("display", "none")
    hitShieldBreak1.attr("display", "block")

    allTimeouts.push(setTimeout(() => {
      hitShieldBreak1.attr("display", "none")
      hitShieldBreak2.attr("display", "block")
    }, delayBase * 0.5))
    allTimeouts.push(setTimeout(() => {
      hitShieldBreak2.attr("display", "none")
      hitShieldBreak3.attr("display", "block")
    }, delayBase))
    allTimeouts.push(setTimeout(() => {
      hitShieldBreak3.attr("display", "none")
      hitShieldBreak4.attr("display", "block")
    }, delayBase * 2))
    allTimeouts.push(setTimeout(() => {
      characterCard.selectAll(".shield-icon").attr("display", "none")
    }, delayBase * 3))
  }

  // Attribute Translation
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0]-0.23, initialHeadPosition[1]+0.1)
  attributeTransition(head, delayBase, initialHeadPosition[0]-0.21, initialHeadPosition[1]+0.09)

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0]-0.1, initialBodyPosition[1]+0.08)
  attributeTransition(body, delayBase, initialBodyPosition[0]-0.08, initialBodyPosition[1]+0.07)

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0], initialLeftHandPosition[1]-0.06)
  attributeTransition(leftHand, delayBase, initialLeftHandPosition[0]+0.02, initialLeftHandPosition[1]-0.07)

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0]-0.32, initialRightHandPosition[1]+0.11)
  attributeTransition(rightHand, delayBase, initialRightHandPosition[0]-0.3, initialRightHandPosition[1]+0.1)

  const newLeftArmData = getLimbData(charSize, 0.22, 0.49, 0.18, 0.77, 0.38, 0.535)
  const newRightArmData = getLimbData(charSize, 0.42, 0.55, 0.75, 0.54, 0.6, 0.62)
  const newLeftLegData = getLimbData(charSize, 0.28, 0.78, 0.48, 0.9, 0.115, 0.91)
  const newRightLegData = getLimbData(charSize, 0.48, 0.75, 0.71, 0.82, 0.59, 0.92)

  const newLeftArmData2 = getLimbData(charSize, 0.22, 0.49, 0.21, 0.75, 0.38, 0.535)
  const newRightArmData2 = getLimbData(charSize, 0.44, 0.55, 0.78, 0.54, 0.6, 0.62)
  const newLeftLegData2 = getLimbData(charSize, 0.31, 0.78, 0.48, 0.9, 0.115, 0.91)
  const newRightLegData2 = getLimbData(charSize, 0.48, 0.75, 0.73, 0.82, 0.6, 0.92)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData2)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData2)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData2)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData2)

  return [delayBase * 2, allTimeouts]
}

function getHitAnimation(yourIdNumber, allTimeouts) {
  const currentId = `#character${yourIdNumber}`
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  playSoundEffect("sound-fx--hit")
  renderFX(characterCard.select("svg"), characterIdNumber, charSize, "Medium Get Hit")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , eyes = characterCard.select(`#eyes-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(0, 15, headX, headY)
  , headRotation2 = getRotation(15, 2, headX, headY)
  , headRotation3 = getRotation(2, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(0, -8, bodyX, bodyY)
  , bodyRotation2 = getRotation(-8, -12, bodyX, bodyY)
  , bodyRotation3 = getRotation(-12, 0, bodyX, bodyY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(0, 32, leftHandX, leftHandY)
  , leftHandRotation2 = getRotation(32, -19, leftHandX, leftHandY)
  , leftHandRotation3 = getRotation(-19, 0, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(0, 22, rightHandX, rightHandY)
  , rightHandRotation2 = getRotation(22, -9, rightHandX, rightHandY)
  , rightHandRotation3 = getRotation(-9, 0, rightHandX, rightHandY)

  const leftFootBBox = leftFoot.node().getBBox()
  , leftFootX = leftFootBBox.x + leftFootBBox.width/2
  , leftFootY = leftFootBBox.y + leftFootBBox.height/2
  , leftFootRotation = getRotation(0, 24, leftFootX, leftFootY)
  , leftFootRotation2 = getRotation(24, -8, leftFootX, leftFootY)
  , leftFootRotation3 = getRotation(-8, 0, leftFootX, leftFootY)

  const rightFootBBox = rightFoot.node().getBBox()
  , rightFootX = rightFootBBox.x + rightFootBBox.width/2
  , rightFootY = rightFootBBox.y + rightFootBBox.height/2
  , rightFootRotation = getRotation(0, 18, rightFootX, rightFootY)
  , rightFootRotation2 = getRotation(18, -6, rightFootX, rightFootY)
  , rightFootRotation3 = getRotation(-6, 0, rightFootX, rightFootY)

  // Attribute Translation
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0]-0.16, initialHeadPosition[1]-0.03)
  attributeTransition(head, delayBase, initialHeadPosition[0]-0.32, initialHeadPosition[1]+0.02)
  attributeTransition(head, delayBase * 2, initialHeadPosition[0], initialHeadPosition[1])

  if (yourIdNumber !== "cpu4") {
    eyes.attr("display", "none")
    const initialEyesPosition = getPositionAndSize("eyes")
    const defaultInputs = [characterCard.select("svg"), characterIdNumber]
    renderAttribute(...defaultInputs, extraAssets["hurt-eyes"], "hurt-eyes", charSize,
                    initialEyesPosition[0], initialEyesPosition[1], initialEyesPosition[2], false)

    allTimeouts.push(setTimeout(() => {
      characterCard.select(`#hurt-eyes-${characterIdNumber}`).remove()
      if (characterCard.select(`#hurt-eyes-${characterIdNumber}`).node() === null) {
        eyes.attr("display", "block")
      }
    }, delayBase * 2))
  }

  characterCard.selectAll(".shield-icon").attr("display", "none")

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, 0.0833, 0.6067)
  attributeTransition(body, delayBase, 0.01, 0.7)
  attributeTransition(body, delayBase * 2, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, 0.41, 0.7)
  attributeTransition(leftHand, delayBase, 0.455, 0.475)
  attributeTransition(leftHand, delayBase * 2, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, 0.745, 0.6)
  attributeTransition(rightHand, delayBase, 0.67, 0.34)
  attributeTransition(rightHand, delayBase * 2, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, 0, 0.1, 1.08)
  attributeTransition(leftFoot, delayBase, 0.045, 1.145)
  attributeTransition(leftFoot, delayBase * 2, initialLeftFootPosition[0], initialLeftFootPosition[1])

  const initialRightFootPosition = getPositionAndSize("rightFoot")
  attributeTransition(rightFoot, delayBase, 0.52, 1.12)
  attributeTransition(rightFoot, delayBase * 2, initialRightFootPosition[0], initialRightFootPosition[1])

  // Attribute Rotation
  attributeRotation(head, 0, headRotation)
  attributeRotation(head, delayBase, headRotation2)
  attributeRotation(head, delayBase * 2, headRotation3)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, delayBase, bodyRotation2)
  attributeRotation(body, delayBase * 2, bodyRotation3)

  attributeRotation(leftHand, 0, leftHandRotation)
  attributeRotation(leftHand, delayBase, leftHandRotation2)
  attributeRotation(leftHand, delayBase * 2, leftHandRotation3)

  attributeRotation(rightHand, 0, rightHandRotation)
  attributeRotation(rightHand, delayBase, rightHandRotation2)
  attributeRotation(rightHand, delayBase * 2, rightHandRotation3)

  attributeRotation(leftFoot, 0, leftFootRotation)
  attributeRotation(leftFoot, delayBase, leftFootRotation2)
  attributeRotation(leftFoot, delayBase * 2, leftFootRotation3)

  attributeRotation(rightFoot, 0, rightFootRotation)
  attributeRotation(rightFoot, delayBase, rightFootRotation2)
  attributeRotation(rightFoot, delayBase * 2, rightFootRotation3)

  const newLeftArmData = getLimbData(charSize, 0.19, 0.43, 0.315, 0.55, 0.45, 0.595)
  const newRightArmData = getLimbData(charSize, 0.4, 0.48, 0.57, 0.5, 0.785, 0.515)
  const newLeftLegData = getLimbData(charSize, 0.31, 0.7, 0.46, 0.8, 0.165, 0.84)
  const newRightLegData = getLimbData(charSize, 0.3, 0.61, 0.58, 0.75, 0.68, 0.9)

  const newLeftArmData2 = getLimbData(charSize, 0.15, 0.43, 0.315, 0.49, 0.55, 0.48)
  const newRightArmData2 = getLimbData(charSize, 0.35, 0.4, 0.57, 0.35, 0.785, 0.31)
  const newLeftLegData2 = getLimbData(charSize, 0.25, 0.66, 0.49, 0.75, 0.159, 0.82)
  const newRightLegData2 = getLimbData(charSize, 0.36, 0.61, 0.61, 0.75, 0.7, 0.83)

  const newLeftArmData3 = getLimbData(charSize, 0.12, 0.5, 0.35, 0.57, 0.55, 0.5)
  const newRightArmData3 = getLimbData(charSize, 0.35, 0.5, 0.57, 0.42, 0.785, 0.33)
  const newLeftLegData3 = getLimbData(charSize, 0.13, 0.7, 0.3, 0.82, 0.105, 0.92)
  const newRightLegData3 = getLimbData(charSize, 0.355, 0.7, 0.5, 0.79, 0.59, 0.89)

  const newLeftArmData4 = getLimbData(charSize, 0.23, 0.55, 0.18, 0.755, 0.57, 0.5)
  const newRightArmData4 = getLimbData(charSize, 0.35, 0.6, 0.6, 0.63, 0.785, 0.53)
  const newLeftLegData4 = getLimbData(charSize, 0.24, 0.8, 0.48, 0.89, 0.115, 0.91)
  const newRightLegData4 = getLimbData(charSize, 0.4, 0.75, 0.73, 0.8, 0.59, 0.905)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, delayBase, newLeftArmData3)
  limbTransition(leftArm, delayBase * 2, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, delayBase, newRightArmData3)
  limbTransition(rightArm, delayBase * 2, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, delayBase, newLeftLegData3)
  limbTransition(leftLeg, delayBase * 2, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, newRightLegData)
  limbTransition(rightLeg, delayBase, newRightLegData3)
  limbTransition(rightLeg, delayBase * 2, rightLegData)

  return [delayBase * 3, allTimeouts]
}

export {
  adjustHealthBar,
  idleAnimation,
  runLeftAnimation,
  runRightAnimation,
  jumpAnimation,
  jumpLeftAnimation,
  jumpRightAnimation,
  jumpPunchAnimation,
  singlePunchAnimation,
  doublePunchAnimation,
  lowKickAnimation,
  startDefendAnimation,
  endDefendAnimation,
  hitShieldAnimation,
  stunAnimation,
  getHitAnimation,
}
