import React, { useContext, useEffect } from 'react'
import { Context } from '../../Store'
import { useHistory, useParams } from 'react-router-dom'
import ModelInspector from './ModelInspector'
import Loader from '../generic-components/loader/Loader'
import Error from '../generic-components/error/Error'
import * as d3 from 'd3'

const ModelInspectorContainer = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const { id } = useParams()
  const history = useHistory()

  const showLoader = false
  const isOwner = parseInt(websiteContext.yourFighter.id) === parseInt(id)
  const locallyStoredModel = localStorage.getItem('model')

  const nonGuestCondition = !isOwner && !websiteContext.guestMode
  const guestCondition = websiteContext.guestMode && locallyStoredModel === null

  useEffect(() => {
    if (nonGuestCondition || guestCondition) {
      history.push('/model-page')
    }
    d3.select("#app-content--right-side").classed("app-content--full-screen", true)
    return () => {
      d3.select("#app-content--right-side").classed("app-content--full-screen", false)
    }
  }, [])

  return (
    <>
      {
        showLoader && 
        <Loader 
          repeatBool={true} 
          smallLoaderBool={true}>
        </Loader>
      }
      {
        (!showLoader && (isOwner || (websiteContext.guestMode && locallyStoredModel !== null))) && 
        <ModelInspector 
          locallyStoredModel={locallyStoredModel}>
        </ModelInspector>
      }
      {
        (!showLoader && !isOwner && !(websiteContext.guestMode && locallyStoredModel !== null)) && 
        <Error></Error>
      }
    </>
  )
}

export default ModelInspectorContainer
