import React, { useEffect } from 'react'
import Modal, { displayModal } from '../../../generic-components/custom-modal/CustomModal'
import ModalCloseButton from '../../../generic-components/modal-close-button/ModalCloseButton'

import gloveIcon from '../../../../local-assets/iconography/boxingglove.svg'
import magnifyingGlass from '../../../../local-assets/iconography/magnifyingglass.svg'

import './model-explore-popup.css'

const ModelExplorePopup = ({ showExplorePopup }) => {  
  const closeFunction = () => {
    document.getElementById("model-explore-modal").style.display = "none"
  }

  const modalSubContent = (
    <div className="model-explore-modal__container">
      <ModalCloseButton closeFunction={closeFunction}></ModalCloseButton>
      <h3>YOU'VE UPLOADED YOUR FIRST MODEL!</h3>
      <p>
        Next you should check out these buttons in the top right corner 
        of your screen.
        Each of these buttons will give you a more in-depth look at how 
        your model performs.
        <br/><br/>
        When you hover over the icons, you will get a description of 
        what each allows you to do.
      </p>

      <div id="model-explore__buttons-container">
        <div id="model-explore-button--watch">
          <img src={gloveIcon} alt="" />
        </div>
        <div id="model-explore-button--inspect">
          <img src={magnifyingGlass} alt="" />
        </div>
        <div id="model-explore-button--benchmark" >
          <div id="model-explore-benchmark-visual">
            <div id="model-explore-benchmark-visual--bar1"></div>
            <div id="model-explore-benchmark-visual--bar2"></div>
            <div id="model-explore-benchmark-visual--bar3"></div>
          </div>          
        </div>
      </div>
    </div>
  )

  const additionalModalStyling = {
    width: "500px",
    height: "300px",
  }
  const modalData = {
    modalSubContent: modalSubContent,
    additionalModalStyling: additionalModalStyling,
    modalId: "model-explore-modal"
  }

  useEffect(() => {
    if (showExplorePopup) {
      displayModal("model-explore-modal")
    }
  }, [showExplorePopup])

  return <Modal {...modalData}></Modal>
}

export default ModelExplorePopup