import { actionsList, moveActions, animationFrames } from './actions'
import { getStateArray } from './states'
import { gameParameters } from './game-parameters'
import { scaleAttribute, getDamage } from './game-functions'

class Game {
  constructor(fighter1, fighter2, seconds) {
    this.fighter1 = fighter1
    this.fighter2 = fighter2
    this.leftBounds = 0 + gameParameters.characterWidth / 2
    this.rightBounds = 1 - gameParameters.characterWidth / 2
    this.charFraction = gameParameters.characterWidth / gameParameters.charFractionDenom

    this.seconds = seconds
    this.animationSpeed = 0.05 // 50 milliseconds
    this.maxAnimationFrames = this.seconds / this.animationSpeed
    this.jumpTimeRatio = gameParameters.jumpMultiple / gameParameters.defaultMultiple

    this.animationStates = {0: [], 1: []}
    this.pendingAction = {0: "None", 1: "None"}
    this.sameConsecutiveActions = {0: 0, 1: 0}

    const defaultStateValues = {
      y: 0,
      health: 1,
      shieldHealth: gameParameters.shieldHealth / 100,
      action: "Nothing",
      framesBeforeUnlocking: 0
    }
    this.fighter1.state = {
      x: 0.2,
      direction: 1,
      ...defaultStateValues
    }
    this.fighter2.state = {
      x: 0.8,
      direction: -1,
      ...defaultStateValues
    }
    this.bothFighters = [this.fighter1, this.fighter2]

    this.actionTracker = { 0: {}, 1: {} }
    this.actionTransitions = { 0: {}, 1: {} }

    var action
    var nestedAction
    const expandedActionList = [...actionsList, "Get Hit"]
    for (var a = 0; a < expandedActionList.length; a++) {
      action = expandedActionList[a]
      if (action !== "Get Hit") {
        this.actionTracker[0][action] = { numExecuted: 0, numSuccessful: 0 }
        this.actionTracker[1][action] = { numExecuted: 0, numSuccessful: 0 }
      }
      this.actionTransitions[0][action] = {}
      this.actionTransitions[1][action] = {}
      for (var a2 = 0; a2 < expandedActionList.length; a2++) {
        nestedAction = expandedActionList[a2]
        this.actionTransitions[0][action][nestedAction] = 0
        this.actionTransitions[1][action][nestedAction] = 0
      }
    }    
  }

  getAction(whichFighter) {
    const currentFighter = this.bothFighters[whichFighter]
    const otherFighter = this.bothFighters[(whichFighter + 1) % 2]
    const inputs = getStateArray(
      currentFighter.state,
      otherFighter.state,
      currentFighter.battleAttributes,
      otherFighter.battleAttributes
    )
    return [actionsList[currentFighter.model.selectAction(inputs)[0]], inputs[0]]
  }

  scaleSpecificAttribute(fighter, attributeName) {
    var scaledAttribute
    if (attributeName === "speed") {
      scaledAttribute = scaleAttribute(
        fighter.battleAttributes.speed / 100,
        gameParameters.minSpeed,
        gameParameters.maxSpeed
      ) / 1.1 // temporary fix until we can match python environment
    }
    else if (attributeName === "power") {
      scaledAttribute = scaleAttribute(
        fighter.battleAttributes.power / 100,
        gameParameters.minDamage,
        gameParameters.maxDamage
      )
    }
    return scaledAttribute
  }

  shiftVertical(jumpFrame, action) {
    // This assumes jumpTimeRatio = 2
    var newY
    const verticalMultiple = gameParameters.jumpMultiple * 0.5

    if (jumpFrame === 3) newY = verticalMultiple * 0.65 / 2
    else if (jumpFrame === 4) newY = verticalMultiple * 0.65

    if (!action.includes("Punch")) {
      if (jumpFrame === 4 + 1) newY = verticalMultiple * (0.65 + 0.74) / 2
      else if (jumpFrame === 4 + 2) newY = verticalMultiple * 0.74
      else if (jumpFrame === 5 + 2) newY = verticalMultiple * (0.74 + 0.8) / 2
      else if (jumpFrame === 5 + 3) newY = verticalMultiple * 0.8
      else if (jumpFrame === 6 + 3) newY = verticalMultiple * (0.8 + 0.47) / 2
      else if (jumpFrame === 6 + 4) newY = verticalMultiple * 0.47
      else if (jumpFrame === 7 + 4) newY = verticalMultiple * 0.47 / 2
      else if (jumpFrame === 7 + 5) newY = 0
    }
    else if (action.includes("Punch")) {
      if (jumpFrame === 5 + 2) newY = verticalMultiple * (0.65 + 0.78) / 2
      else if (jumpFrame === 5 + 3) newY = verticalMultiple * 0.78
      else if (jumpFrame === 6 + 3) newY = verticalMultiple * (0.78 + 0.7) / 2
      else if (jumpFrame === 6 + 4) newY = verticalMultiple * 0.7
      else if (jumpFrame === 7 + 4) newY = verticalMultiple * (0.7 + 0.58) / 2
      else if (jumpFrame === 7 + 5) newY = verticalMultiple * 0.58
      else if (jumpFrame === 8 + 5) newY = verticalMultiple * 0.58 / 2
      else if (jumpFrame === 8 + 6) newY = 0
    }
    return newY
  }

  moveFighter(currentFighter, otherFighter, currentX, otherX, currentY, otherY, action) {
    var newX
    var spaceBetweenFighters
    const stepSize = this.scaleSpecificAttribute(currentFighter, "speed")
    const speedUp = currentY > 0 ? 1.25 : 1
    const marginalStepSize = speedUp * stepSize / animationFrames[action]
    const freeSpace = Math.abs(currentY - otherY) > gameParameters.verticalJumpClearance

    if (
      action.includes("Jump") &&
      currentFighter.state.framesBeforeUnlocking === animationFrames[action]
    ) {
      // We don't move horizontally for the first frame of the jump because it's
      // bending its knees to spring up for the jump
      newX = currentX
    }
    else {
      const otherDefending = otherFighter.state.action.includes("Defend")
      if (action.includes("Left")) {
        if (currentX < otherX || freeSpace) {
          newX = Math.max(currentX - marginalStepSize, this.leftBounds)
        }
        else {
          const currentLeftBound = currentX - this.charFraction
          const otherRightBound = otherX + this.charFraction
          spaceBetweenFighters = currentLeftBound - otherRightBound
          if (otherDefending && otherFighter.state.direction === 1 && currentX - otherX < gameParameters.shieldDistance) {
            spaceBetweenFighters = currentX - otherX - gameParameters.shieldDistance
          }
          newX = currentX - Math.min(marginalStepSize, spaceBetweenFighters)
        }
      }
      else if (action.includes("Right")) {
        if (currentX > otherX || freeSpace) {
          newX = Math.min(currentX + marginalStepSize, this.rightBounds)
        }
        else {
          const currentRightBound = currentX + this.charFraction
          const otherLeftBound = otherX - this.charFraction
          spaceBetweenFighters = otherLeftBound - currentRightBound
          if (otherDefending && otherFighter.state.direction === -1 && otherX - currentX < gameParameters.shieldDistance) {
            spaceBetweenFighters = otherX - currentX - gameParameters.shieldDistance
          }
          newX = currentX + Math.min(marginalStepSize, spaceBetweenFighters)
        }
      }
    }
    return newX
  }

  adjustLandingPosition(currentX, otherX, currentY, otherY) {
    var newX = currentX
    if (Math.abs(currentY - otherY) < gameParameters.verticalJumpClearance) {
      const horizontalSpacing = currentX - otherX
      if (Math.abs(horizontalSpacing) < this.charFraction) {
        if (
          horizontalSpacing < 0 && otherX > this.leftBounds + gameParameters.characterWidth ||
          otherX > this.rightBounds - gameParameters.characterWidth
        ) {
          newX = otherX - this.charFraction
        }
        else {
          newX = otherX + this.charFraction
        }
      }
    }
    return newX
  }

  getDefendedBool(currentFighter, otherFighter) {
    var defendedBool = false
    const otherAction = otherFighter.state.action
    if (otherAction.includes("Defend") && otherAction !== "End Defend") {
      if (currentFighter.state.x < otherFighter.state.x && otherFighter.state.direction === -1) {
        defendedBool = true
      }
      else if (currentFighter.state.x > otherFighter.state.x && otherFighter.state.direction === 1) {
        defendedBool = true
      }
    }
    return defendedBool
  }

  adjustPositionDefend(currentFighter, otherFighter) {
    const yourX = currentFighter.state.x
    const opponentX = otherFighter.state.x
    const spaceBetween = Math.abs(yourX - opponentX)

    var newYourX = yourX
    var newOpponentX = opponentX
    if (spaceBetween < gameParameters.shieldDistance) {
      if (yourX < opponentX && currentFighter.state.direction === 1) {
        newOpponentX = yourX + gameParameters.shieldDistance
        if (newOpponentX > this.rightBounds) {
          newOpponentX = this.rightBounds
          newYourX = newOpponentX - gameParameters.shieldDistance
        }
      }
      else if (yourX > opponentX && currentFighter.state.direction === -1) {
        newOpponentX = yourX - gameParameters.shieldDistance
        if (newOpponentX < this.leftBounds) {
          newOpponentX = this.leftBounds
          newYourX = newOpponentX + gameParameters.shieldDistance
        }
      }
    }
    return [newYourX, newOpponentX]
  }

  isInRange(direction, currentX, otherX, currentY, otherY, action) {
    var verticalAlignment
    var hitExtension
    if (action === "Low Kick") {
      verticalAlignment = currentY === 0 && otherY < gameParameters.verticalLowKickHitbox
      hitExtension = gameParameters.lowKickExtension
    }
    else if (action.includes("Punch")) {
      verticalAlignment = Math.abs(currentY - otherY) < gameParameters.verticalPunchHitbox
      hitExtension = gameParameters.punchExtension
    }
    var fighterRange = false
    var shieldRange = false
    if (verticalAlignment) {
      if (direction === 1 && otherX > currentX) {
        const currentRightBound = currentX + this.charFraction
        const otherLeftBound = otherX - this.charFraction
        const otherShieldBound = otherX - gameParameters.shieldDistance
        if (currentRightBound + hitExtension > otherLeftBound) {
          fighterRange = true;
        }
        if (currentRightBound + hitExtension > otherShieldBound) {
          shieldRange = true
        }
      }
      else if (direction === -1 && otherX < currentX) {
        const currentLeftBound = currentX - this.charFraction
        const otherRightBound = otherX + this.charFraction
        const otherShieldBound = otherX + gameParameters.shieldDistance
        if (currentLeftBound - hitExtension < otherRightBound) {
          fighterRange = true
        }
        if (currentLeftBound - hitExtension < otherShieldBound) {
          shieldRange = true
        }
      }
    }
    return [fighterRange, shieldRange]
  }

  isHitLandingFrame(action, framesBeforeUnlocking) {
    var hitLandingFrame
    if (action.includes("Jump")) {
      hitLandingFrame = (animationFrames[action] - framesBeforeUnlocking) === 9
    }
    else if (action.includes("Punch")) {
      hitLandingFrame = (animationFrames[action] - framesBeforeUnlocking) === 2
      if (action === "Double Punch") {
        hitLandingFrame = hitLandingFrame || framesBeforeUnlocking === 3
      }
    }
    else if (action.includes("Kick")) {
      hitLandingFrame = (animationFrames[action] - framesBeforeUnlocking) === 4
    }
    return hitLandingFrame
  }

  stateTransition(whichFighter, action) {
    const currentFighter = this.bothFighters[whichFighter]
    const otherFighter = this.bothFighters[(whichFighter + 1) % 2]
    const currentX = currentFighter.state.x
    const otherX = otherFighter.state.x
    const currentY = currentFighter.state.y
    const otherY = otherFighter.state.y

    const framesBeforeUnlocking = this.bothFighters[whichFighter].state.framesBeforeUnlocking

    var newX = currentX
    var newOtherX = otherX
    if (action.includes("Left") || action.includes("Right")) {
      newX = this.moveFighter(currentFighter, otherFighter, currentX, otherX, currentY, otherY, action)
      const newDirection = action.includes("Left") ? -1 : 1
      this.bothFighters[whichFighter].state.x = newX
      this.bothFighters[whichFighter].state.direction = newDirection
    }
    if (action.includes("Jump")) {
      var jumpFrame = animationFrames[action] - framesBeforeUnlocking + 1
      const newY = this.shiftVertical(jumpFrame, action)
      newX = this.adjustLandingPosition(newX, otherX, currentY, otherY)
      this.bothFighters[whichFighter].state.x = newX
      if (newY !== undefined) {
        this.bothFighters[whichFighter].state.y = newY
      }
    }
    if (action === "Start Defend") {
      [newX, newOtherX] = this.adjustPositionDefend(currentFighter, otherFighter)
      currentFighter.state.x = newX
      otherFighter.state.x = newOtherX
    }

    if (action.includes("Punch") || action.includes("Kick")) {
      const accurateHitBool = Math.random() < (currentFighter.battleAttributes.accuracy / 100)
      const hitLandingFrame = this.isHitLandingFrame(action, framesBeforeUnlocking)
      if (accurateHitBool && hitLandingFrame) {
        const [hitFighterBool, hitShieldBool] = this.isInRange(
          currentFighter.state.direction, currentX, otherX, currentY, otherY, action
        )

        if (hitFighterBool) {
          if (this.actionTracker[whichFighter][action] !== undefined) {
            this.actionTracker[whichFighter][action].numSuccessful += 1
          }
        }
        else if (hitShieldBool) {
          // Put logic for attack blocked
        }

        const damageParams = [
          action, 
          currentFighter.battleAttributes.power, 
          otherFighter.battleAttributes.defence,
          this.sameConsecutiveActions[whichFighter]
        ]
        if (this.getDefendedBool(currentFighter, otherFighter)) {
          if (hitShieldBool) {
            const shieldDamage = getDamage(...damageParams, true)
            otherFighter.state.shieldHealth = Math.max(
              otherFighter.state.shieldHealth - shieldDamage, 0
            )
            otherFighter.state.action = "Hit Shield"
            otherFighter.state.framesBeforeUnlocking = animationFrames["Hit Shield"] + 1
          }
        }
        else if (hitFighterBool) {
          const damage = getDamage(...damageParams, false)
          otherFighter.state.health = Math.max(
            otherFighter.state.health - damage, 0
          )
          otherFighter.state.action = "Get Hit"
          otherFighter.state.framesBeforeUnlocking = animationFrames["Get Hit"] + 1
        }
      }
    }
    this.bothFighters[whichFighter].state.action = action;
  }

  getDefendAnimationSection(currentAction, previousAction) {
    if (
      (
        currentAction === "Defend" &&
        (!previousAction.includes("Defend") && previousAction !== "Hit Shield")
      ) ||
      (currentAction === "Defend" && previousAction === "End Defend")
    ) {
      return "Start Defend"
    }
    else if (
      currentAction === "Defend" &&
      (previousAction.includes("Defend") || previousAction === "Hit Shield")
    ) {
      return "Continue Defend"
    }
    else if (!currentAction !== "Defend" && previousAction.includes("Defend")) {
      return "End Defend"
    }
  }

  runFighter(whichFighter, animationFrame) {
    const fighterState = this.bothFighters[whichFighter].state
    if (fighterState.framesBeforeUnlocking === 0) {
      var [action, modelState] = this.getAction(whichFighter)
      if (this.pendingAction[whichFighter] !== "None") {
        action = this.pendingAction[whichFighter]
        this.pendingAction[whichFighter] = "None"
      }

      const shieldDestroyed = fighterState.shieldHealth === 0
      action = shieldDestroyed ? "End Defend" : action

      const previousAction = this.bothFighters[whichFighter].state.action
      if (previousAction === action) {
        this.sameConsecutiveActions[whichFighter] += 1
      }
      else {
        this.sameConsecutiveActions[whichFighter] = 0
      }

      const prevActionKey = previousAction.includes("Defend") ? "Defend" : previousAction
      const currentTransition = this.actionTransitions[whichFighter][prevActionKey]
      if (currentTransition !== undefined && currentTransition[action] !== undefined) {
        this.actionTransitions[whichFighter][prevActionKey][action] += 1 
      }

      if (this.actionTracker[whichFighter][action] !== undefined) {
        this.actionTracker[whichFighter][action].numExecuted += 1
        if (moveActions.includes(action)) {
          this.actionTracker[whichFighter][action].numSuccessful += 1
        }
      }      

      if (
        action !== "End Defend" &&
        (action === "Defend" || previousAction === "Start Defend" || previousAction === "Continue Defend")
      ) {
        this.pendingAction[whichFighter] = action
        action = this.getDefendAnimationSection(action, previousAction)
        if (action !== "End Defend") {
          this.pendingAction[whichFighter] = "None"
        }
      }
      else {
        this.pendingAction[whichFighter] = "None"
      }

      if (action === "End Defend") {
        if (shieldDestroyed) {
          this.pendingAction[whichFighter] = "Stun"
        }
        fighterState.shieldHealth = gameParameters.shieldHealth / 100
      }
      
      this.bothFighters[whichFighter].state.framesBeforeUnlocking = animationFrames[action]
      this.stateTransition(whichFighter, action)

      this.animationStates[whichFighter].push({
        action: action,
        framesBeforeUnlocking: animationFrames[action],
        framesBeforeHit: undefined,
        x: [fighterState.x],
        y: [fighterState.y],
        health: fighterState.health,
        shieldHealth: fighterState.shieldHealth,
        animationFrame: animationFrame,
        modelState: modelState
      })
    }
    else {
      this.stateTransition(whichFighter, fighterState.action)
      this.bothFighters[whichFighter].state.framesBeforeUnlocking -= 1
    }

    const otherPlayerState = this.bothFighters[(whichFighter + 1) % 2].state
    if (
      (otherPlayerState.action === "Get Hit" && otherPlayerState.framesBeforeUnlocking === animationFrames["Get Hit"] + 1) ||
      (otherPlayerState.action === "Hit Shield" && otherPlayerState.framesBeforeUnlocking === animationFrames["Hit Shield"] + 1)
    ) {
      const opponentAnimState = this.animationStates[(whichFighter + 1) % 2]
      const opponentPrevAction = opponentAnimState[opponentAnimState.length-1].action
      const opponentPrevActionKey = opponentPrevAction.includes("Defend") ? "Defend" : opponentPrevAction
      const opponentCurrentTransition = this.actionTransitions[(whichFighter + 1) % 2][opponentPrevActionKey]
      if (opponentCurrentTransition !== undefined && opponentCurrentTransition[otherPlayerState.action] !== undefined) {
        this.actionTransitions[(whichFighter + 1) % 2][opponentPrevActionKey][otherPlayerState.action] += 1 
      }

      otherPlayerState.y = 0
      const otherPrevStateIdx = this.animationStates[(whichFighter + 1) % 2].length - 1
      const lastAnimationFrame = this.animationStates[(whichFighter + 1) % 2][otherPrevStateIdx].animationFrame
      this.animationStates[(whichFighter + 1) % 2].push({
        action: otherPlayerState.action,
        framesBeforeUnlocking: animationFrames[otherPlayerState.action],
        framesBeforeHit: animationFrame - lastAnimationFrame + 1,
        x: [otherPlayerState.x],
        y: [otherPlayerState.y],
        health: otherPlayerState.health,
        shieldHealth: otherPlayerState.shieldHealth,
        animationFrame: animationFrame + 1
      })
    }

    const framesBeforeUnlocking = this.bothFighters[whichFighter].state.framesBeforeUnlocking
    const lastStateIdx = this.animationStates[whichFighter].length - 1
    const currentAction = this.bothFighters[whichFighter].state.action
    if (currentAction.includes("Left") || currentAction.includes("Right")) {
      if (framesBeforeUnlocking < animationFrames[currentAction]) {
        this.animationStates[whichFighter][lastStateIdx].x.push(
          this.bothFighters[whichFighter].state.x
        )
      }
    }
    if (currentAction.includes("Jump")) {
      if (framesBeforeUnlocking < animationFrames[currentAction]) {
        this.animationStates[whichFighter][lastStateIdx].y.push(
          this.bothFighters[whichFighter].state.y
        )
      }
    }
  }

  runBattle() {
    var winner
    , health1
    , health2
    for (var i=0; i < this.maxAnimationFrames; i++) {
      this.runFighter(0, i)
      this.runFighter(1, i)
      health1 = this.bothFighters[0].state.health
      health2 = this.bothFighters[1].state.health
      if (health1 <= 0 || health2 <= 0 ) {
        break
      }
    }

    const battleDuration = i * this.animationSpeed

    if (health1 < health2) winner = "1"
    else if (health1 > health2) winner = "0"
    else winner = "Tie"

    return [
      this.animationStates, 
      winner, 
      this.actionTracker, 
      this.actionTransitions, 
      battleDuration
    ];
  }
}

export default Game
