import React, { useState, useEffect } from 'react'
import './ranking-table.css'

const RankingTable = ({ rankingData, setRankingData }) => {
  const [sortingData, setSortingData] = useState({
    rank: true, username: false, team: false, rating: false, winRate: false, ascending: true
  })

  const getHeaderClass = (key) => {
    const sortingClass = sortingData[key] ? "table--sorting" : ""
    const directionClass = sortingData.ascending ? "table--ascending" : "table--descending"
    return `${sortingClass} ${directionClass}`
  }

  const getSortingData = (tableKey) => {
    setSortingData((prevState) => {
      var prevKey
      Object.keys(prevState).map((key) => {
        if (key !== "ascending") {
          if (prevState[key]) {
            prevKey = key
          }          
          prevState[key] = tableKey === key
        }
      })
      return {
        ...prevState,
        ascending: prevState[prevKey] ? !prevState.ascending : false
      }
    })
  }

  useEffect(() => {    
    var sortingKey
    Object.keys(sortingData).forEach((key) => {
      if (sortingData[key] && key !== "ascending") {
        sortingKey = key
      }
    })
    setRankingData((prevState) => {
      const sortedRankingData = prevState.sort((a, b) => (
        sortingData.ascending ? a[sortingKey] > b[sortingKey] : a[sortingKey] < b[sortingKey]
      ) ? 1 : -1)
      return [...sortedRankingData]
    })
  }, [sortingData])

  return (
    <>
      <div id="ranking-leaderboard__container">
        <table id="ranking-leaderboard">
          <thead>
            <tr id="ranking-leaderboard__header">
              <th 
                className="ranking-leaderboard__header-option ranking-leaderboard--rank" 
                onClick={() => getSortingData("rank")}>
                  <div>
                    <p>Rank</p>
                    <div className={getHeaderClass("rank")}></div>
                  </div>                                
              </th>
              <th 
                className="ranking-leaderboard__header-option ranking-leaderboard--username" 
                onClick={() => getSortingData("username")}>
                  <div>
                    <p>Username</p>
                    <div className={getHeaderClass("username")}></div>
                  </div>                                
              </th>
              <th 
                className="ranking-leaderboard__header-option ranking-leaderboard--team" 
                onClick={() => getSortingData("team")}>
                  <div>
                    <p>Team</p>
                    <div className={getHeaderClass("team")}></div>
                  </div>                                
              </th>
              <th 
                className="ranking-leaderboard__header-option ranking-leaderboard--rating" 
                onClick={() => getSortingData("rating")}>
                  <div>
                    <p>Rating</p>
                    <div className={getHeaderClass("rating")}></div>
                  </div>                                
              </th>
              <th className="ranking-leaderboard__header-option ranking-leaderboard--record">
                  <div>
                    <p>Record</p>
                    <div className={getHeaderClass("record")}></div>
                  </div>                                
              </th>
            </tr>
          </thead>
          <tbody>
            {
              rankingData.map((rowData, idx) => {
                return (
                  <tr key={`row--${idx}`} className="ranking-leaderboard__row">
                    <td className="ranking-leaderboard--rank">
                      <p>{rowData.rank}</p>
                    </td>
                    <td className="ranking-leaderboard--username">
                      <p>{rowData.username}</p>
                    </td>
                    <td className="ranking-leaderboard__row--team ranking-leaderboard--team">
                      <img src={rowData.logo} alt="" />
                      <p>{rowData.team}</p>
                    </td>
                    <td className="ranking-leaderboard--rating">
                      <p>{Math.round(rowData.rating)}</p>
                    </td>
                    <td className="ranking-leaderboard--record">
                      <p>{rowData.record}</p>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default RankingTable