import React, { useState, useEffect, useContext } from "react"
import { Context } from "../../../Store"
import HistoricalElo from "./historical-elo/HistoricalElo"
import RankedSummary from './ranked-summary/RankedSummary'
import RankedMatches from './ranked-matches/RankedMatches'
import {
  resultColors,
  gradientWinLoss,
  getMatchStats
} from '../../../helpers/stats-page/matches-helpers'
import "./ranked-matches-container.css"

const RaankedMatchesContainer = ({ rankedStats, rankedPerformance }) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const [rankedSummaryStats, setRankedSummaryStats] = useState(undefined)

  // -100 is the number for tie
  const resultToIdx = {"-100": 1, "-1": 2}
  if (websiteContext.yourFighter !== undefined) {
    resultToIdx[websiteContext.yourFighter.id] = 0
  }

  useEffect(() => {
    if (rankedPerformance !== undefined && rankedPerformance.length > 0) {
      const allMatchStats = getMatchStats(rankedPerformance, resultToIdx)
      setRankedSummaryStats(allMatchStats)
    }    
  }, [rankedPerformance])

  return (
    <div id="ranked-summary__container">              
      <RankedSummary
        rankedStats={rankedStats}
        rankedSummaryStats={rankedSummaryStats}
        resultColors={resultColors}>
      </RankedSummary>
      {
        rankedPerformance !== undefined && rankedStats !== undefined &&
        <RankedMatches
          rankedPerformance={rankedPerformance}
          matchSummaryStats={rankedSummaryStats}
          resultColors={resultColors}
          gradientWinLoss={gradientWinLoss}>
        </RankedMatches>        
      }
      <HistoricalElo rankedStats={rankedStats}></HistoricalElo>
    </div>
  )
}

export default RaankedMatchesContainer
