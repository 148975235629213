import React, { useEffect, useContext } from "react"
import { Context } from "../../Store"
import { useLocation } from "react-router-dom"
import muteIcon from "../../local-assets/generic/mute.svg"
import muteIconDark from "../../local-assets/generic/mute-dark.svg"
import volumeIcon from "../../local-assets/generic/volume.svg"
import volumeIconDark from "../../local-assets/generic/volume-dark.svg"
import * as d3 from "d3"

const Music = ({ startMusicOn, darkBool }) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const location = useLocation()
  startMusicOn = startMusicOn !== undefined ? startMusicOn : false

  const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min)
  };

  const musicTimeouts = []
  const clearMusictimeouts = () => {
    var currentTimeout
    while (musicTimeouts.length > 0) {
      currentTimeout = musicTimeouts.pop()
      clearTimeout(currentTimeout)
    }
  }

  const pathToSongId = {
    "/": "homeMusic",
    "/collection": "collectionMusic",
    "/leaderboard": "leaderboardMusic",
    "/training-modes": "fighterSelectionMusic",
    "/training-opponent-selection": "fighterSelectionMusic",
    "/gameplay-menu": "fighterSelectionMusic",
    "/simulation-options": "fighterSelectionMusic",
    "/opponent-selection": "fighterSelectionMusic",
    "/simulation": "simulationMusic",
    "/model-page": "displayPageMusic"
  }

  const getSongName = () => {
    var songName
    if (location.pathname.includes("ai-inspector")) {
      songName = "inspectorMusic"
    } 
    else if (location.pathname.includes("token")) {
      songName = "displayPageMusic"
    } 
    else if (location.pathname === "/battle") {
      const throneRoom = location.state.yourFighter.renderCrown || location.state.yourOpponent.renderCrown
      const rankedBattleSong = throneRoom ? "Throne" : getRandomInt(1, 3);
      songName = `rankedBattle${rankedBattleSong}Music`
    }
    else {
      songName = pathToSongId[location.pathname]      
    }
    return songName
  }

  const getSong = (songName) => {
    if (songName === undefined) {
      songName = getSongName()
    }
    return websiteContext.musicAssets[songName]
  };

  const adjustSound = (newVolume) => {    
    if (d3.select("#music-button--mute").style("display") === "none") {
      const song = websiteContext.songPlayingHowler
      song.fade(song.volume(), newVolume, 500);
    }
  };

  const fadeOutMusic = (song) => {    
    if (song === undefined) {
      song = getSong(websiteContext.songPlaying)
    }
    song.fade(song.volume(), 0, 1000);
    musicTimeouts.push(setTimeout(() => {
      song.pause()
    }, 1050))
  };

  const fadeInMusic = (song) => {    
    const maxVolume = websiteContext.volumeSettings.music
    if (song === undefined) {
      song = getSong()
    }
    if (!song.playing()) {
      song.play()
    }
    song.fade(song.volume(), maxVolume, 1000)
    const songName = getSongName()
    setWebsiteContext((prevState) => { return { ...prevState, songPlaying: songName, songPlayingHowler: song } })
  };

  const triggerMusic = (muted) => {
    const song = getSong();
    if (!muted) {
      if (song !== undefined && song !== null) fadeInMusic(song)
      setWebsiteContext((prevState) => { return { ...prevState, musicMuted: false } })
    } else {
      if (song !== undefined && song !== null) fadeOutMusic(song)
      setWebsiteContext((prevState) => { return { ...prevState, musicMuted: true } })
    }
    if (song === undefined || song === null) {
      setWebsiteContext((prevState) => { return { ...prevState, songPlaying: undefined, songPlayingHowler: undefined } })
    }
  }

  const toggleMusic = () => {
    const muted = d3.select("#music-button--mute").style("display") === "block";
    d3.select("#music-button--mute").style("display", muted ? "none" : "block");
    d3.select("#music-button--volume").style("display", muted ? "block" : "none");
    triggerMusic(!muted);
  };

  useEffect(() => {
    return () => {
      Object.values(websiteContext.musicAssets).forEach((song) => {
        fadeOutMusic(song)
        setWebsiteContext((prevState) => {
          return {
            ...prevState,
            songPlaying: undefined
          }
        })
      })      
    }
  }, [])

  useEffect(() => {
    if (websiteContext.volumeSettings !== undefined) {
      const songName = getSongName()
      if (websiteContext.songPlaying !== songName) {
        if (websiteContext.songPlaying !== undefined) {          
          fadeOutMusic(websiteContext.songPlayingHowler)
        }
        triggerMusic(
          d3.select("#music-button--mute").style("display") === "block"
        );
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (websiteContext.volumeSettings !== undefined) {
      adjustSound(websiteContext.volumeSettings.music)  
    }
  }, [websiteContext.volumeSettings])

  return (
    <>
      {websiteContext.volumeSettings !== undefined && (
        <>
          <img
            id="music-button--mute"
            style={{ display: startMusicOn ? "none" : "block" }}
            src={darkBool ? muteIconDark : muteIcon}
            onClick={() => toggleMusic()}
            alt=""
          />
          <img
            id="music-button--volume"
            style={{ display: startMusicOn ? "block" : "none" }}
            src={darkBool ? volumeIconDark : volumeIcon}
            onClick={() => toggleMusic()}
            alt=""
          />
        </>
      )}
    </>
  );
};

export default Music;
