import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Battle from './Battle'
import * as d3 from 'd3'

const BattleContainer = () => {
  const history = useHistory()
  const location = useLocation()
  const fighterChosen = location.state !== undefined

  useEffect(() => {
    if (!fighterChosen) {
      history.push({
        pathname: '/ranking',
        state: "Battle"
      })
    }
    d3.select("#app-content--right-side").classed("app-content--full-screen", true)
    return () => {
      d3.select("#app-content--right-side").classed("app-content--full-screen", false)
    }
  }, [])

  return (
    <>
      {fighterChosen && <Battle></Battle>}
    </>
  )
}

export default BattleContainer
