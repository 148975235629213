import punching from "../../local-assets/benchmarks/punching-icon.png"
import kicking from "../../local-assets/benchmarks/kicking-icon.png"
import defending from "../../local-assets/benchmarks/defending-icon.png"
import running from "../../local-assets/benchmarks/running-icon.png"
import jumping from "../../local-assets/benchmarks/jumping-icon.png"

const actionIconAssets = {
  "Punching": punching,
  "Kicking": kicking,
  "Defending": defending,
  "Running": running,
  "Jumping": jumping,
}

export {
  actionIconAssets
}
