import React, { useEffect, useRef } from "react"
import Tooltip from "../../generic-components/tooltip/Tooltip"
import { renderChordDiagram } from "../../../helpers/charting/chord-diagram"
import { setupChart } from "../../../helpers/charting/chart-setup"
import { renderChart } from "../../../helpers/charting/render-charts"
import "./model-charts.css"
import * as d3 from "d3"

const ModelAnalytics = ({ barChartData, chordMatrix }) => {
  const chordRef = useRef()
  const stackedBarRef = useRef()

  const chartMetadata = {
    chartType: "Stacked Bar Chart",
    tooltipId: "tooltip--stackedbar",
    margin: { top: 0.15, right: 0.01, bottom: 0.5, left: 0.01 },
    labelPadding: { x: 0.05, y: 0.05 },
  }

  const tooltipContentStackedBar = (
    <div id="tooltip__content">
      <div id="tooltip__stackedbar-subcontent">
        <p id="tooltip__stackedbar--action"></p>
        <p id="tooltip__stackedbar--successful"></p>
        <p id="tooltip__stackedbar--attempts"></p>
      </div>
    </div>
  )

  const tooltipContentChordDiagram = (
    <div id="tooltip__content">
      <div id="tooltip__chord-subcontent">
        <h3>Source</h3>
        <p id="tooltip__chord--source"></p>
        <h3>Target</h3>
        <p id="tooltip__chord--target"></p>
        <h3>Instances</h3>
        <p id="tooltip__chord--value"></p>
      </div>
    </div>
  )

  useEffect(() => {
    if (chordRef.current !== undefined && chordRef.current !== null) {
      d3.select("#" + chordRef.current.id)
        .selectAll("svg")
        .remove()
    }
    if (chordMatrix !== undefined) {
      renderChordDiagram(
        "#" + chordRef.current.id,
        chordMatrix.labels,
        chordMatrix.matrix,
        "tooltip--chord-diagram"
      )
    }
  }, [chordMatrix])

  useEffect(() => {
    if (stackedBarRef.current !== undefined && stackedBarRef.current !== null) {
      d3.select("#" + stackedBarRef.current.id)
        .selectAll("svg")
        .remove()
    }   
    if (barChartData !== undefined) {
      setupChart("#" + stackedBarRef.current.id, true, chartMetadata)
      renderChart("#" + stackedBarRef.current.id, barChartData[0], {
        ...chartMetadata,
        ...barChartData[1],
      })
    }
  }, [barChartData])

  return (
    <>
      <div id="model-page__charts">
        {
          <div ref={stackedBarRef} id="model-page__charts--stacked-bar">
            <p>Action Frequency</p>
            <Tooltip
              tooltipContent={tooltipContentStackedBar}
              tooltipId="tooltip--stackedbar"
            ></Tooltip>
            {
              barChartData === undefined &&
              <p className="model-page__charts--no-data">NO DATA</p>
            }
          </div>
        }
        {
          <div ref={chordRef} id="model-page__charts--chord-diagram">
            <p>Action Transitions</p>
            {
              chordMatrix === undefined &&
              <p className="model-page__charts--no-data">NO DATA</p>
            }
          </div>
        }
      </div>
      <Tooltip
        tooltipContent={tooltipContentChordDiagram}
        tooltipId="tooltip--chord-diagram"
      ></Tooltip>
    </>
  )
}

export default ModelAnalytics
