import React from "react"
import { displayModal } from '../../../generic-components/custom-modal/CustomModal'
import ColoredTextBox from "../../../generic-components/colored-text-box/ColoredTextBox"
import RadarChart from "react-svg-radar-chart"
import "react-svg-radar-chart/build/css/index.css"
import './match-summary.css'

const MatchSummary = ({ matchSummaryStats, setExpandRounds, resultColors, ranks }) => {
  const tiedResult =
    matchSummaryStats.results[0] === matchSummaryStats.results[2]
  const yourResult = !tiedResult
    ? matchSummaryStats.results[0] > matchSummaryStats.results[2]
      ? "won"
      : "lost"
    : "tied"

  const captions = {
    Defending: "Defending",
    Jumping: "Jumping",
    Kicking: "Kicking",
    Punching: "Punching",
    Running: "Running",
  }

  return (
    <div 
      className="match-summary__aggregate-container"
      onClick={() => displayModal(`benchmark-rounds-modal--${matchSummaryStats.benchmark}`)}
    >
      <p className="match-summary__benchmark-title">
        Benchmark {matchSummaryStats.benchmark+1}
      </p>

      <div className="match-summary__aggregate-middle">
        <div className="match-summary__title-content">
          <p className="match-summary__title--big">Results</p>
          <p className="match-summary__content--big">
            {matchSummaryStats !== undefined
              ? matchSummaryStats.results.join("-")
              : ""}
          </p>
        </div>
        <div className="match-summary__title-content">
          <p className="match-summary__title">Mean Duration</p>
          <p className="match-summary__content">
            {matchSummaryStats !== undefined
              ? Math.round(matchSummaryStats.duration) + " seconds"
              : ""}
          </p>
        </div>
      </div>      

      <div className="match-summary__fighter-container">
        <ColoredTextBox
          text={yourResult.toUpperCase()}
          border={resultColors[yourResult].border}
          background={resultColors[yourResult].background}
          additionalClass="match-summary__result-box"
        ></ColoredTextBox>
        <p className="match-summary__title">
          Rank: {ranks[matchSummaryStats.benchmark]}
        </p>
        <p className="match-summary__title">
          Score: {Math.round(matchSummaryStats.score * 100) / 100}
        </p>
      </div>

      <div className="match-summary__radar-chart">
        <RadarChart
          options={{ captions: false, scales: 3 }}
          captions={captions}
          data={[
            {
              data: matchSummaryStats.actionDominance,
              meta: { color: resultColors[yourResult].border },
            },
          ]}
          size={120}
        />
      </div>
    </div>
  )
}

export default MatchSummary
