import { addToLandingPage } from '../../threejs/landing-page-ops'
import { surfaceFragmentShader } from '../../shaders/error-surface/surface-fragment-shader'
import { surfaceVertexShader } from '../../shaders/error-surface/surface-vertex-shader'
import * as THREE from 'three'

const loadErrorSurface = (size, position, resources, scene, sceneObjects, allMeshes, objectKey) => {
  const fogNear = 0.5
  const fogFar = 7.5
  const fogColor = new THREE.Color('rgb(5, 13, 25)')

  const geometry = new THREE.PlaneGeometry(100, 100, 1000, 1000)
  const material = new THREE.ShaderMaterial({
    extensions: {
      derivatives: "#extension GL_OES_standard_derivatives : enable"
    },
    uniforms: {
      uTime: { type: "f", value: 0 },
      bumpMultiplier: { type: "f", value: 0.5 },
      lineWidth: { type: "f", value: 2 },
      highlightColor: { type: "c", value: new THREE.Color("rgb(150, 255, 255)") },
      highlightPosition: { type: "V2", value: new THREE.Vector2(0.5, 0.5)},
      fogColor: { type: "c", value: fogColor },
      fogNear: { type: "f", value: fogNear },
      fogFar: { type: "f", value: fogFar },
    },
    transparent: true,
    // side: THREE.DoubleSide,
    vertexShader: surfaceVertexShader,
    fragmentShader: surfaceFragmentShader
  })
  const terrain = new THREE.Mesh(geometry, material)

  geometry.computeBoundingBox()
  geometry.computeBoundingSphere()

  terrain.position.set(position.x, position.y, position.z)
  terrain.rotation.x = -Math.PI / 2
  terrain.scale.setScalar(size)
  terrain.name = "error-surface-glow"
  addToLandingPage(terrain, scene, sceneObjects, allMeshes, objectKey)
}

export {
  loadErrorSurface
}