import { displayTooltip, closeTooltip } from '../../components/generic-components/tooltip/Tooltip'
import * as d3 from 'd3'

function createLegendElement(legend, className, text, color, posX, posY) {
  var legendClassName = "legend-" + className
  legend.append("circle")
    .attr("cx", posX)
    .attr("cy", posY)
    .attr("class", legendClassName)
    .attr("r", 10)
    .style("fill", color)

  const textColor = "white"
  const textSpacing = 25

  legend.append("text")
    .attr("x", posX + textSpacing)
    .attr("y", posY)
    .attr("class", legendClassName)
    .text(text)
    .style("font-size", "18px")
    .style("fill", textColor)
    .style("cursor", "default")
    .attr("alignment-baseline","middle")    
}

const renderChordDiagram = (id, names, chordMatrix, tooltipId) => {
  const c = d3.chordDirected()
    .padAngle(0.05)
    .sortSubgroups(d3.descending)
  const chords = c(chordMatrix)

  const colors = [
    "#64d7d4",
    "#89b1ff",
    "#7654e4",
    "#a12a87",
    "#f2a573",
    "#549ef1",
    "#9671e9",
    "#e94bb8",
    "#de3e66",
    "#ff7079",
    "#8a112d"
  ]

  var diagramContainer = d3.select(id)
  // const chartHeight = parseInt(diagramContainer.style("height")) * 1.5
  // const chartWidth = parseInt(diagramContainer.style("width")) * 1.5
  const chartHeight = 700//648
  const chartWidth = 700//648

  const positionTooltip = (e) => {
    const container = d3.select("#app-content--right-side")
    const containerWidth =  parseInt(container.style("width").replace("px", ""))
    const containerHeight =  parseInt(container.style("height").replace("px", ""))
    const leftPadding = window.innerWidth - containerWidth
    const tooltipPosition = { 
      left: ((e.x - leftPadding) / containerWidth) * 100 + "%", 
      top: (e.y / containerHeight) * 100 + "%", 
    }
    displayTooltip(tooltipId, tooltipPosition)
  }

  var svg = diagramContainer
    // .classed("svg-responsive__container", true)
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      // .attr("viewBox", `0 0 ${chartWidth} ${chartHeight}`)
      .attr("viewBox", `0 0 ${chartWidth} ${chartHeight}`)
      // .classed("svg-responsive__content", true)
        .append("g")
        .attr("class", "chord-diagram-group")

  svg
    .datum(chords)
    .append("g")
    .selectAll("g")
    .data(function(d) { return d.groups; })
    .join("g")
    .append("path")
      .style("fill", (d,i) => colors[i])
      .style("stroke", "none")
      .attr("d", d3.arc()
        .innerRadius(200)
        .outerRadius(210)
      )
      .on("mouseover", function (e, d) {
        d3.select("#tooltip__chord-subcontent").selectAll("h3").style("display", "none")
        d3.select("#tooltip__chord--source").node().innerHTML = `${names[d.index]}`

        positionTooltip(e)
        d3.selectAll(`.ribbon-${d.index}`)
          .style("opacity", 1)
      })
      .on("mousemove", function (e, d) {
        positionTooltip(e)
      })
      .on("mouseout", function (e, d) {
        d3.select("#tooltip__chord-subcontent").selectAll("h3").style("display", "block")
        d3.select("#tooltip__chord--source").node().innerHTML = ""

        closeTooltip(tooltipId)
        d3.selectAll(`.ribbon-${d.index}`)
          .style("opacity", 0.2)
      })

  const legend = svg.append("svg")
    .style("width", "100%")
    .style("height", "100%")
    .style("overflow", "visible")

  for (var i = 0; i < names.length; i++) {
    createLegendElement(legend, `legend-${names[i]}`, names[i], colors[i], 250, -200 + i * 40)
  }

  svg
    .datum(chords)
    .append("g")
    .selectAll("path")
    .data(d => d)
    .join("path")
      .attr("class", d => `ribbon-${d.source.index}`)
      .attr("d", d3.ribbon().radius(200))
      .style("fill", d => colors[d.source.index])
      .style("stroke", "none")
      .style("opacity", 0.2)
      .on("mouseover", function (e, d) {
        d3.select("#tooltip__chord--source").node().innerHTML = `${names[d.source.index]}`
        d3.select("#tooltip__chord--target").node().innerHTML = `${names[d.target.index]}`
        d3.select("#tooltip__chord--value").node().innerHTML = `${d.source.value}`          

        positionTooltip(e)
        d3.select(this)
          .style("opacity", 1)
      })
      .on("mousemove", function (e, d) {
        positionTooltip(e)
      })
      .on("mouseout", function (e, d) {
        d3.select("#tooltip__chord--source").node().innerHTML = ""
        d3.select("#tooltip__chord--target").node().innerHTML = ""
        d3.select("#tooltip__chord--value").node().innerHTML = ""
        closeTooltip(tooltipId)
        d3.select(this)
          .style("opacity", 0.2)
      })
}

export {
  renderChordDiagram
}