import { 
  benchmarkOpponentsMapping 
} from '../../components/model-page/model-page-highlevel/model-buttons/ModelButtons'
import Game from '../game-setup/battle-game'

const gameLengthSeconds = 60

const healthScoreMultiple = 2
const timeScoreMultiple = 1

const getBenchmarkScore = (
  yourHealth, 
  opponentHealth, 
  timeRemaining, 
  startingTime
) => {
  const relativeHealth = yourHealth - opponentHealth

  var resultMultiple
  if (relativeHealth > 0) resultMultiple = 1
  else if (relativeHealth < 0) resultMultiple = -1
  else resultMultiple = 0

  const timeRemainingScore = timeScoreMultiple * resultMultiple * timeRemaining / startingTime
  const healthRemainingScore = healthScoreMultiple * relativeHealth
  return timeRemainingScore + healthRemainingScore
}

const getBattleResults = (
  fighter1, 
  fighter2, 
  fighter1Side, 
  startingData, 
  benchmarkBool
) => {
  var game
  var states
  var hash
  var winner
  var winnerId
  var finishingMove
  var actionTracker
  var battleData = []
  var currentRunData

  const yourSideIdx = fighter1Side === "left" ? 0 : 1
  const interimScores = {"0": 0, "1": 0}
  states = {}

  var currentBattleStates
  var actionTransitions
  var battleDuration

  const totalGames = 5
  for (var gameIdx = 0; gameIdx < totalGames; gameIdx++) {
    if (fighter1Side === "left") {
      game = new Game(fighter1, fighter2, gameLengthSeconds)
    }
    else {
      game = new Game(fighter2, fighter1, gameLengthSeconds)
    }

    [
      currentBattleStates, 
      winner, 
      actionTracker, 
      actionTransitions, 
      battleDuration
    ] = game.runBattle()

    states[gameIdx] = { 0: [...currentBattleStates[0]], 1: [...currentBattleStates[1]]}
    if (winner !== "Tie") {
      interimScores[winner] += 3
      if (
        (fighter1Side === "left" && winner === "0") || 
        (fighter1Side === "right" && winner === "1")
      ) {
        winnerId = startingData.id1
      }
      else {
        winnerId = startingData.id2
      }
      finishingMove = currentBattleStates[winner][currentBattleStates[winner].length-1].action
    }
    else {
      interimScores["0"] += 1
      interimScores["1"] += 1
      winnerId = -100
      finishingMove = "Nothing"
    }
    currentRunData = {
      fighter1Side: fighter1Side,
      actions: actionTracker[yourSideIdx],
      opponentActions: actionTracker[(yourSideIdx + 1) % 2],
      transitions: actionTransitions[yourSideIdx],
      winner: winnerId,
      duration: battleDuration,
      healthLeft: currentBattleStates[0][currentBattleStates[0].length-1].health,
      healthRight: currentBattleStates[1][currentBattleStates[1].length-1].health,
      finishingMove: finishingMove,
    }
    if (benchmarkBool) {
      currentRunData = {...currentRunData, ...startingData}
    }      
    battleData.push({...currentRunData})

    if (!benchmarkBool && (interimScores["0"] === 6 || interimScores["1"] === 6)) break
  }
  if (interimScores["0"] !== interimScores["1"]) {
    winner = interimScores["0"] > interimScores["1"] ? "0" : "1"
  }
  else {
    winner = "Tie"
  }
  
  return [states, hash, winner, battleData]
}

const runBenchmark = (yourFighter, benchmark) => {
  const yourId = yourFighter.id
  const opponentFighter = benchmarkOpponentsMapping[benchmark]

  const startingLeftData = { id1: yourId, id2: -1}
  const startingRightData = { id1: -1, id2: yourId}
  const battleLeftData = getBattleResults(
    yourFighter, 
    opponentFighter, 
    "left", 
    startingLeftData, 
    true
  )[3]
  const battleRightData = getBattleResults(
    opponentFighter, 
    yourFighter, 
    "left", 
    startingRightData, 
    true
  )[3]
  const battleData = [...battleLeftData, ...battleRightData]

  var runData
  var currentScore
  const summaryData = { 
    benchmark: parseInt(benchmark),
    id: yourId, 
    modelVersion: parseInt(yourFighter.neuralNetVersion),
    mu: 0, 
    max: -Infinity, 
    min: Infinity 
  }
  for (var i = 0; i < battleData.length; i++) {
    runData = battleData[i]
    currentScore = getBenchmarkScore(
      runData.id1 !== -1 ? runData.healthLeft : runData.healthRight, 
      runData.id2 !== -1 ? runData.healthLeft : runData.healthRight, 
      runData.duration, 
      60
    )
    battleData[i].score = currentScore    

    summaryData.mu += currentScore
    if (currentScore > summaryData.max) summaryData.max = currentScore
    if (currentScore < summaryData.min) summaryData.min = currentScore    
  }
  summaryData.mu /= battleData.length

  const totalData = { 
    benchmark: parseInt(benchmark),
    id: yourId, 
    modelVersion: parseInt(yourFighter.neuralNetVersion),
    rounds: battleData,
    date: Date.now()
  }
  
  return { 
    data: {
      benchmarkStats: totalData, 
      benchmarkSummary: summaryData
    }
  }
}

export {
  runBenchmark
}