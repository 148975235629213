import React, { useEffect, useContext, useRef } from "react"
import { Context } from "../../../Store"
// Action Sounds
import run from '../../../audio/fx/doublemove0.41.mp3'
// import run from '../../../audio/fx/run.ogg'
import shieldBreak from '../../../audio/fx/shield-break.ogg'
import hit from '../../../audio/fx/hit-medium.ogg'
import shieldHit from '../../../audio/fx/shield-hit.ogg'
import punch from '../../../audio/fx/punch.ogg'
import shieldDeploy from '../../../audio/fx/shield-deploy.ogg'
import land from '../../../audio/fx/land.ogg'
import jump from '../../../audio/fx/jump.ogg'
import kick from '../../../audio/fx/kick.ogg'
import turn from '../../../audio/fx/turn.ogg'
// Game start sounds
import startFight from '../../../audio/fx/ready-fight.ogg'
// Gameover Sounds
import win from '../../../audio/fx/win-jingle.ogg'
import draw from '../../../audio/fx/draw-jingle.ogg'
import loss from '../../../audio/fx/lose-jingle.ogg'
import * as d3 from 'd3'

const playSoundEffect = (soundEffectId) => {
  const muteButtonSelection = d3.select("#music-button--mute")
  if (muteButtonSelection._groups[0][0] !== null && muteButtonSelection._groups[0][0] !== undefined) {
    if (muteButtonSelection.style("display") === "none") {
      const soundElement = document.getElementById(soundEffectId)
      if (soundElement !== null) {
        soundElement.currentTime = 0
        soundElement.play()
      }
    }
  }
}

const stopSoundEffect = (soundEffectId) => {
  const soundElement = document.getElementById(soundEffectId)
  if (soundElement !== null) {
    soundElement.pause()
    soundElement.currentTime = 0
  }
}

const SoundFX = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const refContainer = useRef({})

  const makeSoundEffect = (label, file) => {
    return (
      <audio
        id={`sound-fx--${label}`}
        src={file}
        ref={
          ref => refContainer.current[label] = ref
        }>
      </audio>
    )
  }

  useEffect(() => {
    Object.keys(refContainer.current).forEach((label) => {
      const currentId = refContainer.current[label].id
      const currentSoundEffect = document.getElementById(currentId)
      currentSoundEffect.volume = websiteContext.volumeSettings.fx
    })
  }, [])

  return (
    <>
      {makeSoundEffect("shield-deploy", shieldDeploy)}
      {makeSoundEffect("shield-hit", shieldHit)}
      {makeSoundEffect("shield-break", shieldBreak)}
      {makeSoundEffect("turn", turn)}
      {makeSoundEffect("punch", punch)}
      {makeSoundEffect("hit", hit)}
      {makeSoundEffect("run", run)}
      {makeSoundEffect("jump", jump)}
      {makeSoundEffect("land", land)}
      {makeSoundEffect("kick", kick)}
      {makeSoundEffect("start-fight", startFight)}
      {makeSoundEffect("win", win)}
      {makeSoundEffect("draw", draw)}
      {makeSoundEffect("loss", loss)}
    </>
  )
}

export default SoundFX
export {
  playSoundEffect,
  stopSoundEffect
}