import React, { useEffect } from 'react'
import spinningLoader from '../../../local-assets/aia-specific/aia-token-spinning.png'
import "./sub-component-loader.css"
import * as d3 from 'd3'

const loadingEllipsis = (nDots) => {
  const loadingFlagGroup = d3.select("#loading-data-flag")._groups[0][0]
  if (loadingFlagGroup !== null && loadingFlagGroup !== undefined) {
    d3.selectAll(".loading__ellipsis").text(".".repeat(nDots))
    if (d3.select("#loading-data-flag").style("display") === "block") {
      setTimeout(() => {
        loadingEllipsis((nDots + 1) % 4)
      }, 600)
    }
  }
  return
}

const SubComponentLoader = ({ positioning, loadingTextBool, customWidth, darkBackground, text, statusText }) => {
  loadingTextBool = loadingTextBool !== undefined ? loadingTextBool : true
  darkBackground = darkBackground !== undefined ? darkBackground : false
  text = text !== undefined ? text : "Loading"

  useEffect(() => {
    if (loadingTextBool) {
      loadingEllipsis(0)
    }
  }, [])

  positioning = positioning !== undefined ? positioning : "relative"

  return (
    <>
      <div id="loading-data-flag" style={{display: "block"}}></div>
      <div className="spinning-loader__container" 
           style={{
             position: positioning, 
             top: 0,
             width: customWidth !== undefined ? customWidth : "100%",
             backgroundColor: darkBackground ? "rgba(0,0,0,0.4)" : "transparent"
            }}>
        <img className="spinning-loader" src={spinningLoader} alt="" />
        {
          loadingTextBool &&
          <>
            <h1 id="battle-loading__text">
              {text} <span className="loading__ellipsis"></span>
            </h1>
            <h1 id="battle-loading__status">
              {statusText}
            </h1>
          </>
        }
      </div>
    </>
  );
};

export default SubComponentLoader;
export {
  loadingEllipsis
}
