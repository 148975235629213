const networkIdToData = {
  "1": "mainnet",
  "3": "ropsten",
  "4": "rinkeby",
  "42": "kovan",
  "421611": "arbitrum"
}

export {
  networkIdToData
}
