import React from "react"
import './wallet-display.css'

const WalletDisplay = ({ accountData, selection }) => {
  const addressName = `${accountData.address.slice(0,5)}...${accountData.address.slice(38,42)}`
  const accountName = accountData.ens !== undefined ? accountData.ens.name : addressName

  return (  
    <>
      {
        accountData &&
        <div id="wallet-display__container">
          {
            selection.name !== "Unsupported" &&
            <div id="wallet-display__address">{accountName}</div>
          }
          {
            selection.name === "Unsupported" &&
            <div id="wallet-display__switch-network">
              <p id="wallet-display__switch-network--arrow">&#8592;</p>
              <p id="wallet-display__switch-network--text">Switch</p>
            </div>
          }
        </div>
      }
    </>
  );
};

export default WalletDisplay
