const addToLandingPage = (model, scene, sceneObjects, allMeshes, objectKey) => {
  scene.add(model)
  sceneObjects[objectKey] = model
  model.traverse((obj) => {
    if (obj.type.includes("Mesh")) {
      allMeshes.push(obj)
    }
  }) 
}

const getPositionRange = (fbx) => {
  var childMesh
  var boundingSphere
  const meshPositionRange = { minY: Infinity, maxY: -Infinity }
  for (var i = 0; i < fbx.children.length; i++) {
    childMesh = fbx.children[i]
    if (childMesh.type.includes("Mesh")) {
      boundingSphere = childMesh.geometry.boundingSphere
      if (boundingSphere === null) {
        childMesh.geometry.computeBoundingSphere()
        boundingSphere = childMesh.geometry.boundingSphere
      }
      const childRange = [
        boundingSphere.center.y - boundingSphere.radius,
        boundingSphere.center.y + boundingSphere.radius
      ]
      if (childRange[0] < meshPositionRange.minY) {
        meshPositionRange.minY = childRange[0]
      }
      if (childRange[1] > meshPositionRange.maxY) {
        meshPositionRange.maxY = childRange[1]
      }
    }
  }
  return meshPositionRange
}

export {
  addToLandingPage,
  getPositionRange
}