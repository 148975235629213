import { neuralNetwork } from './neural-network'
import { flattenParameters } from './matrix-math'
import {
  getFighterValuesFromBlockchain,
  createFighterObject,
} from "../asset-handling/create-fighter"
import { getNeuralNetworkShape } from "./neural-network-architecture"
import { activationFunctionMapping } from './activation-functions'
import { networkIdToData } from "../misc/ethereum-networks"
import axios from "axios"
require('dotenv').config()

const backend = process.env.REACT_APP_BACKEND

const addFighterToBackend = (id, network) => {
  axios.post(backend + "fighters/create-fighter", {
    id: id, 
    rarity: "Bronze", 
    element: "Fire", 
    network: network, 
    flippedStarting: "1",
    encrypted: false
  })
}

const addMetadataToBackend = (id, modelVersion, metadata, network) => {  
  axios.post(backend + "research-data/save-model-metadata", {
    network: network, 
    metadata: {
      ...metadata,
      id: id, 
      modelVersion: modelVersion
    }
  })
}

const addIdToProfile = (id, address) => {
  axios.post(backend + "research-data/add-id-to-profile", {
    id: id,
    address: address
  })
}

async function getFighterObject (id, fighterContract, neuralNetworkContract) {
  const currentFighter = await getFighterValuesFromBlockchain(fighterContract, id)
  const neuralNetInfo = await neuralNetworkContract.callStatic
    .viewNeuralNetInfo(currentFighter.neuralNetId)
  const shape = getNeuralNetworkShape(
    neuralNetInfo[2],
    neuralNetInfo[3],
    neuralNetInfo[4]
  )
  currentFighter.id = id
  currentFighter.versionNumber = neuralNetInfo[5]

  const modelMetadataResult = await axios.get(
    `${backend}research-data/model-metadata?id=${id}&modelVersion=${neuralNetInfo[5]}`
  )
  var modelMetadata = modelMetadataResult.data[0]
  if (modelMetadata === undefined) {
    modelMetadata = { policyMethod: "probabilisticSampling" }
  }

  const currentFighterObject = createFighterObject(
    currentFighter,
    neuralNetInfo[0],
    neuralNetInfo[1],
    shape,
    modelMetadata
  )
  return currentFighterObject
}

const createNewFighter = (
  weights, 
  biases, 
  layerSizes, 
  activationFunction, 
  metadata,
  battleAttributes,
  fighterContract, 
  neuralNetworkContract, 
  websiteContext, 
  setWebsiteContext,
  setTransactionPending,
  setShowExplorePopup
) => {
  const modelURI = ""
  const integerWeights = flattenParameters(weights).map((weight) => { return parseInt(weight * 100000) })
  const integerBiases = flattenParameters(biases).map((bias) => { return parseInt(bias * 100000) })
  fighterContract.buyNewFighter(
    integerWeights, 
    integerBiases, 
    layerSizes, 
    [
      battleAttributes.power, 
      battleAttributes.speed, 
      battleAttributes.defence, 
      battleAttributes.accuracy
    ], 
    activationFunctionMapping[activationFunction], 
    0, 
    modelURI
  ).then((transactionResult) => {
    setTransactionPending(true)
    console.log("Model created transaction result, ", transactionResult)
    transactionResult.wait().then(async (receipt) => {
      setTransactionPending(false)
      setShowExplorePopup(true)
      console.log(`Cumulative Gas Used: ${parseInt(receipt.cumulativeGasUsed._hex, 16)}`)
      console.log(`Effective Gas Price: ${parseInt(receipt.effectiveGasPrice._hex, 16)}`)
      const eventReturnValues = receipt.events.filter((ev) => ev.event === "FighterCreated")[0]
      const fighterId = parseInt(eventReturnValues.args.id._hex, 16)

      addFighterToBackend(fighterId, networkIdToData[websiteContext.network])
      const fighter = await getFighterObject(
        fighterId,
        fighterContract,
        neuralNetworkContract
      )
      addMetadataToBackend(
        fighter.id, 
        fighter.neuralNetVersion, 
        metadata, 
        networkIdToData[websiteContext.network]
      )
      addIdToProfile(fighter.id, websiteContext.userAddress)
      fighter.model.metadata = metadata
      setWebsiteContext((prevState) => {
        return {
          ...prevState,
          yourFighter: {...fighter}
        }
      })
    })
  })
}

const writeWeightsToBlockchain = (
    fighter, 
    pretrainedModel, 
    fighterContract,
    neuralNetworkContract, 
    websiteContext, 
    setWebsiteContext,
    setTransactionPending
  ) => {
  const integerWeights = fighter.model.flattenedWeights.map((weight) => { return parseInt(weight * 100000) })
  const integerBiases = fighter.model.flattenedBiases.map((bias) => { return parseInt(bias * 100000) })
  neuralNetworkContract.updateModel(
    parseInt(fighter.id),
    integerWeights,
    integerBiases,
    fighter.model.neurons,
    fighter.model.nFeatures,
    fighter.model.nActions,
    activationFunctionMapping[fighter.model.activationFunctionName],
    3
  ).then((transactionResult) => {
    setTransactionPending(true)
    console.log("Model created transaction result, ", transactionResult)    
    addMetadataToBackend(
      fighter.id, 
      fighter.neuralNetVersion + 1, 
      fighter.model.metadata, 
      networkIdToData[websiteContext.network]
    )
    transactionResult.wait().then(async (receipt) => {
      setTransactionPending(false)
      console.log(parseInt(receipt.events[0].args.id._hex, 16))
      const newFighter = await getFighterObject(
        parseInt(receipt.events[0].args.id._hex, 16), 
        fighterContract,
        neuralNetworkContract
      )
      console.log(newFighter)
      setWebsiteContext((prevState) => {
        return {
          ...prevState,
          yourFighter: {...newFighter}
        }
      })      
    })
  })
  .catch((err) => {
    console.log(err)
    if (pretrainedModel !== undefined) {
      fighter.model = new neuralNetwork(
        pretrainedModel.nFeatures,
        pretrainedModel.neurons,
        pretrainedModel.nActions,
        pretrainedModel.flattenedWeights,
        pretrainedModel.flattenedBiases,
        pretrainedModel.metadata,
      )
    }
  })
}

export {
  addFighterToBackend,
  addIdToProfile,
  getFighterObject,
  createNewFighter,
  writeWeightsToBlockchain
}
