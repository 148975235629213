import * as THREE from 'three'

const fogPrepend = `
  uniform vec3 fogColor;
  uniform float fogNear;
  uniform float fogFar;
  ${THREE.ShaderChunk[ "common" ]}
  ${THREE.ShaderChunk[ "fog_pars_fragment" ]}
`

const fogAppend = `
  float depth = gl_FragCoord.z / gl_FragCoord.w;
  float fogFactor = smoothstep( fogNear, fogFar, depth );
  // gl_FragColor = mix( gl_FragColor, vec4( fogColor, gl_FragColor.w ), fogFactor );
  gl_FragColor = mix( gl_FragColor, vec4( fogColor, 0 ), fogFactor );
`

export {
  fogPrepend,
  fogAppend
}