import { gameParameters } from './game-parameters'

const scaleAttribute = (attributePercentage, min, max) => {
  return (attributePercentage * (max - min)) + min
}

const maxConsecutiveActions = 10

const adjustPower = (baselinePower, consecutiveActions) => {
  return baselinePower * Math.max(maxConsecutiveActions - consecutiveActions, 0) / maxConsecutiveActions
}

const getDamage = (action, yourPower, opponentDefence, consecutiveActions, shieldHit) => {
  const baselineDamage = scaleAttribute(
    adjustPower(yourPower, consecutiveActions) / 100, gameParameters.minDamage, gameParameters.maxDamage
  )
  var hitDamage
  if (action.includes("Punch")) {
    hitDamage = baselineDamage * gameParameters.punchPowerMultiple
  }
  else if (action === "Low Kick") {
    hitDamage = baselineDamage * gameParameters.lowKickPowerMultiple
  }
  if (shieldHit) {
    hitDamage *= (1 - opponentDefence / 100)
  }
  return hitDamage
}

export {
  scaleAttribute,
  getDamage
}
