import { actionsList } from '../game-setup/actions'

const actionToIdxMapping = {}
for (var i = 0; i < actionsList.length; i++) {
  actionToIdxMapping[actionsList[i]] = i
}

export class agentSidai {
  constructor() {
    this.type = "rules-based-agent"
  }

  selectAction(state) {
    const [
      relativeDistance,
      youFacingOpponent,
      opponentFacingYou,
      yourHealth,
      opponentHealth,
      relativeStrength,
      relativeSpeed,
      relativeDefence,
      relativeAccuracy
    ] = state[0]

    var action = "Single Punch"
    
    const rightSide = Math.sign(relativeDistance) > 0
    const absDistance = Math.abs(relativeDistance)
    
    if (absDistance > 0.1) {
      action = `Run ${rightSide ? "Left" : "Right"}`
    }

    return [actionToIdxMapping[action]]
  }
}