import {
  assetDir,
  sizeMultiples,
  getCharacterRenderInfo
} from '../asset-handling/render-helper'
import * as d3 from 'd3'

const animationSpeed = 50
const frameFreeze = 0
const delayBase = animationSpeed + frameFreeze

const charSize = sizeMultiples.battle
const [iconScale, xBuffer, yBuffer] = getCharacterRenderInfo(charSize).slice(4,7)

const eyesDir = assetDir + "eyes/"
const mouthDir = assetDir + "mouth/"
const shieldDir = assetDir + "extra-effects/shield/"
const punchDir = assetDir + "extra-effects/punch/"

var extraAssets = {}
const extraAssetFileNames = [
  eyesDir + "hurt-eyes.svg",
  eyesDir + "happy-eyes.svg",
  eyesDir + "dead-eyes.svg",
  eyesDir + "stun-eyes.svg",
  mouthDir + "dead-mouth.svg",
  shieldDir + "shield-small.svg",
  shieldDir + "shield-medium.svg",
  shieldDir + "shield-large.svg",
  punchDir + "punch-initial.svg",
  punchDir + "punch-middle.svg",
  punchDir + "punch-end.svg"
]
const extraAssetFiles = extraAssetFileNames.map((fileName) => { return d3.xml(fileName) })
Promise.all(extraAssetFiles).then(assets => {
  var splitName
  for (var assetIdx = 0; assetIdx < assets.length; assetIdx++) {
    splitName = extraAssetFileNames[assetIdx].split("/")
    extraAssets[splitName[splitName.length-1].replace(".svg", "")] = assets[assetIdx]
  }
})

const lineGenerator = d3.line()
  .x(d => d.x)
  .y(d => d.y)
  .curve(d3.curveBasis)

function getRotation(rotateStart, rotateEnd, x, y) {
  return d3.interpolateString(`rotate(${rotateStart},${x},${y})`,
                              `rotate(${rotateEnd},${x},${y})`)
}
function attributeTransition(attribute, delay, x, y, size) {
  if (size !== undefined) {
    attribute
      .transition()
        .duration(animationSpeed)
        .ease(d3.easeLinear)
        .delay(delay)
          .attr("width", iconScale * size)
          .attr("height", iconScale * size)
  }
  attribute
    .transition()
      .duration(animationSpeed)
      .ease(d3.easeLinear)
      .delay(delay)
        .attr("x", iconScale * x + xBuffer)
        .attr("y", iconScale * y + yBuffer)
}
function attributeRotation(attribute, delay, rotation) {
  attribute.select("g")
    .transition()
      .duration(animationSpeed)
      .ease(d3.easeLinear)
      .delay(delay)
        .attrTween("transform", function(d,i,a){ return rotation })
}
function limbTransition(limb, delay, limbData) {
  limb
    .transition()
      .duration(animationSpeed)
      .ease(d3.easeLinear)
      .delay(delay)
        .attr("d", lineGenerator(limbData))
}

function getCurrentRotation(bodyPart) {
  const bodyPartGroup = bodyPart.select("g")
  if (bodyPartGroup._groups[0][0] !== null && bodyPartGroup._groups[0][0] !== undefined) {
    const bodyPartTransform = bodyPartGroup.attr("transform")
    if (bodyPartTransform !== null) {
      const splitTransformText = bodyPartTransform.split(",")
      var rotationIdx = 0
      splitTransformText.forEach((txtSegment, k) => {
        if (txtSegment.includes("rotate")) rotationIdx = k
      })
      return +splitTransformText[rotationIdx].replace("rotate(", "")
    }
    else {
      return 0
    }
  }
}

export {
  animationSpeed,
  getRotation,
  attributeTransition,
  attributeRotation,
  limbTransition,
  getCurrentRotation,
  delayBase,
  extraAssets
}
