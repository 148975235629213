import React, { useState, useEffect, useContext, useRef } from 'react'
import { useContract, useSigner } from 'wagmi'
import { Context } from "../../../../Store"
import Modal, { displayModal } from '../../../generic-components/custom-modal/CustomModal'
import Slider from '../../../generic-components/slider/Slider'
import ModalCloseButton from '../../../generic-components/modal-close-button/ModalCloseButton'
import SubComponentLoader from '../../../generic-components/sub-component-loader/SubComponentLoader'
import ModelExplorePopup from '../model-explore-popup/ModelExplorePopup'
import { checkValidModel, upload } from '../../../../helpers/machine-learning/upload-model'
import { palette } from '../../../../helpers/design-map/color-palette'
import './model-upload-area.css'

const ModelUploadArea = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const [files, setFiles] = useState(undefined)
  const [modelSpecs, setModelSpecs] = useState(undefined)
  const [transactionPending, setTransactionPending] = useState(false)
  const [showExplorePopup, setShowExplorePopup] = useState(false)
  const [policyMethod, setPolicyMethod] = useState(undefined)
  const [remainder, setRemainder] = useState()
  const [battleAttributes, setBattleAttributes] = useState({
    power: 75,
    speed: 75,
    accuracy: 75,
    defence: 75
  })    
  const refContainer = useRef()

  const allowedAllocation = 300

  // Initialize the Smart Contracts
  const [{ data: signerData }, getSigner] = useSigner()
  const fighterContract = useContract({
      addressOrName: websiteContext.smartContract.address,
      contractInterface: websiteContext.smartContract.abi,
      signerOrProvider: signerData
  })
  const neuralNetworkContract = useContract({
    addressOrName: websiteContext.neuralNetworkContract.address,
    contractInterface: websiteContext.neuralNetworkContract.abi,
    signerOrProvider: signerData
  })

  const uploadNewModel = (manualPolicyMethod) => {
    const metadata = { 
      policyMethod: manualPolicyMethod === undefined ? policyMethod : manualPolicyMethod
    }
    upload(
      {...modelSpecs, metadata: metadata},
      websiteContext.yourFighter,
      battleAttributes,
      fighterContract, 
      neuralNetworkContract, 
      websiteContext,
      setWebsiteContext,
      setTransactionPending,
      setShowExplorePopup
    )
  }

  const handleFile = (e) => {    
    setFiles(e.target.files)
  }

  useEffect(() => {
    if (files !== undefined) {      
      checkValidModel(files, setModelSpecs)
    }
  }, [files])

  useEffect(() => {
    if (modelSpecs !== undefined) {
      displayModal("action-selection-modal")
    }
  }, [modelSpecs])

  useEffect(() => {
    if (fighterContract.signer !== undefined && websiteContext.yourFighter !== undefined) {
      const dropzone = document.getElementById(refContainer.current.id)
      dropzone.ondrop = function(e) {
        e.preventDefault()
        this.className = "dropzone"
        setFiles(e.dataTransfer.files)
      }
      dropzone.ondragover = function() {
        this.className = "dropzone" + " dragover"
        return false
      }
      dropzone.ondragleave = function() {
        this.className = "dropzone"
        return false
      }
    }
  }, [fighterContract, websiteContext.yourFighter])

  useEffect(() => {
    setRemainder(() => {
      var totalAllocated = 0
      Object.values(battleAttributes).forEach((attribute) => {
        totalAllocated += attribute
      })
      return allowedAllocation - totalAllocated
    })
  }, [battleAttributes])  

  const closeFunctionActionSelection = () => {
    document.getElementById("action-selection-modal").style.display = "none"
  }
  const closeFunctionAttributes = () => {
    document.getElementById("attributes-modal").style.display = "none"
  }

  const modalSubContent = (
    <div className="action-selection__container">
      <ModalCloseButton closeFunction={closeFunctionActionSelection}></ModalCloseButton>
      <h2>Select Policy Method</h2>
      <p>Policy Network</p>
      <div 
        className="action-selection__option"
        onClick={() => {
          setPolicyMethod("probabilisticSampling")
          closeFunctionActionSelection()
          if (websiteContext.yourFighter === undefined) {
            displayModal("attributes-modal")
          }
          else {
            uploadNewModel("probabilisticSampling")
          }
        }}>
        <p>Probabilistic Sampling</p>
      </div>
      <p>Q-Network</p>
      <div 
        className="action-selection__option"
        onClick={() => {
          setPolicyMethod("epsilonGreedy")
          closeFunctionActionSelection()
          if (websiteContext.yourFighter === undefined) {
            displayModal("attributes-modal")
          }
          else {
            uploadNewModel("epsilonGreedy")
          }
        }}>
        <p>Epsilon-Greedy</p>
      </div>
      <div 
        className="action-selection__option"
        onClick={() => {
          setPolicyMethod("argmaxPolicy")
          closeFunctionActionSelection()
          if (websiteContext.yourFighter === undefined) {
            displayModal("attributes-modal")
          }
          else {
            uploadNewModel("argmaxPolicy")
          }
        }}>
        <p>Argmax</p>
      </div>
    </div>
  )
  const additionalModalStyling = {    
    width: "300px",
    height: "350px",
  }
  const modalData = {
    modalSubContent: modalSubContent,
    additionalModalStyling: additionalModalStyling,
    modalId: "action-selection-modal"
  }  

  const modalSubContentAttributes = (
    <div className="attributes__container">
      <ModalCloseButton closeFunction={closeFunctionAttributes}></ModalCloseButton>
      <h2>Select Model Attributes</h2>
      {
        Object.keys(battleAttributes).map((attributeName) => {
          return (
            <li key={`change-attributes-${attributeName}`}>
              <Slider 
                key={`attribute-slider-${attributeName}`}
                name={attributeName}
                min={10}
                max={100}
                value={battleAttributes[attributeName]}
                step={1}
                setAttributes={setBattleAttributes}>
              </Slider>
            </li>
          )
        })
      }
      {
        remainder === 0 &&
        <div 
          id="attributes__upload-button"
          onClick={() => {
            uploadNewModel()
            closeFunctionAttributes()
          }}>
          UPLOAD
        </div>
      }
      {
        remainder !== 0 &&
        <p id="attributes__remaining-text">
          <span style={{
            color: remainder > 0 ? "green" : "red"
          }}>{remainder} </span> 
          Points Remaining
        </p>        
      }
    </div>
  )
  const additionalModalStylingAttributes = {    
    width: "300px",
    height: "350px",
  }
  const modalDataAttributes = {
    modalSubContent: modalSubContentAttributes,
    additionalModalStyling: additionalModalStylingAttributes,
    modalId: "attributes-modal"
  }  
  
  return (
    <>
      <Modal {...modalData}></Modal>
      <Modal {...modalDataAttributes}></Modal>
      <ModelExplorePopup showExplorePopup={showExplorePopup}></ModelExplorePopup>
      <div 
        className="model-upload__container"
        style={{backgroundColor: transactionPending ? "transparent" : palette.typography[4]}}>
        {
          transactionPending &&
          <SubComponentLoader 
            loadingTextBool={false}
            positioning="relative">
          </SubComponentLoader>        
        }
        <div 
          ref={refContainer} 
          id="dropzone" 
          className="dropzone"
          style={{display: transactionPending ? "none" : "flex"}}>
          <input 
            type="file" 
            onChange={(e) => handleFile(e)}
            onClick={(e) => e.target.value = null}/>
          {
            !transactionPending && 
            <h2>Upload Model</h2>
          }
        </div>
      </div>
    </>
  )
}

export default ModelUploadArea
