import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../Store'
import { useHistory } from 'react-router-dom'
import Character from '../../character/Character'
import Modal, { displayModal } from '../../generic-components/custom-modal/CustomModal'
import ModalCloseButton from '../../generic-components/modal-close-button/ModalCloseButton'
import SubComponentLoader from '../../generic-components/sub-component-loader/SubComponentLoader'
import { networkIdToData } from "../../../helpers/misc/ethereum-networks"
import warningIcon from '../../../local-assets/generic/warning.png'
import metamaskIcon from '../../../local-assets/wallet-logos/metamask.png'
import './battle-loader-popup.css'
import axios from "axios"
import Web3 from "web3"
require("dotenv").config()

window.web3 = new Web3(window.ethereum)

const BattleLoaderPopup = ({ 
  rankingData, 
  loaderData, 
  setLoaderData, 
  battleState, 
  setBattleState, 
  setBattleLoading
}) => {  
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const [acceptedWarning, setAcceptedWarning] = useState(false)
  const [awaitingTransaction, setAwaitingTransaction] = useState(false)
  const [ratings, setRatings] = useState({ you: undefined, opponent: undefined })
  const history = useHistory()

  const backend = process.env.REACT_APP_BACKEND
  const delegatedAddress = process.env.REACT_APP_DELEGATED_ADDRESS_1

  const closeFunction = () => {
    document.getElementById("battle-loader-modal").style.display = "none"
    setBattleState(undefined)
    setAcceptedWarning(false)
    setAwaitingTransaction(false)
    setLoaderData((prevState) => {
      return {
        ...prevState,
        showing: false
      }
    })
  }

  const routeToBattle = () => {
    history.push({
      pathname: '/battle',
      state: {...battleState}
    })
  }

  const characterStyling = {
    size: "0.5",
    yOffset: "35%"
  }

  const runBattle = () => {
    setBattleLoading(true)
    axios.post(backend + "research-data/run-battle", {
      yourId: websiteContext.yourFighter.id,
      opponentId: loaderData.opponentData.id, 
      fightersSignature: loaderData.opponentData.signature,
      network: networkIdToData[websiteContext.network],
    })
    .then((battleResponse) => {
      setBattleLoading(false)
      const ratingDeltaPrepend = battleResponse.data.ratingDelta > 0 ? "+" : ""
      setLoaderData((prevState) => {
        return {
          ...prevState,
          resultData: { 
            result: battleResponse.data.result,
            ratingDelta: ratingDeltaPrepend + Math.round(battleResponse.data.ratingDelta),
          }
        }
      })
      setBattleState({
        yourFighter: websiteContext.yourFighter,
        yourOpponent: loaderData.opponentData,
        yourSide: battleResponse.data.yourSide,
        states: battleResponse.data.states,
        ratingDelta: battleResponse.data.ratingDelta
      })
    })
  }

  const sendGasToBackend = () => {
    console.log("send gas to backend")
    axios.get(`${backend}research-data/estimate-battle-gas?id=${websiteContext.yourFighter.id}`)
    .then(gasEstimateResult => {
      if (gasEstimateResult.data.gasEstimate !== undefined) {
        const gasEstimateWei = window.web3.utils.toWei(
          gasEstimateResult.data.gasEstimate.toString(), "Gwei"
        )
        // console.log(websiteContext.userAddress, delegatedAddress, gasEstimateWei)
        window.web3.eth.sendTransaction({
          from: websiteContext.userAddress,
          to: delegatedAddress,
          value: gasEstimateWei * 5,
          data: delegatedAddress,
        }).on("transactionHash", (hash) => {
          console.log("transaction hash: ", hash)
          setAcceptedWarning(true)
          runBattle()
        }).on("error", (err) => {
          closeFunction()          
        })
      }
      else {
        console.log(gasEstimateResult)
      }
    })
    .catch((err) => {
      console.log(err.response.data)
    })
  }

  const modalSubContent = (
    <div className="battle-loader-modal__container">
      {
        !acceptedWarning && !awaitingTransaction &&
        <div id="battle-loader-modal__warning-container">
          <ModalCloseButton closeFunction={closeFunction}></ModalCloseButton>
          <img src={warningIcon} alt="" />
          <h3>
            Are you sure you want to run a battle?
          </h3>
          <p>
            If you proceed, then it will count towards the ranked competition.
          </p>
          <div
            id="battle-loader-modal__warning-proceed"
            onClick={() => {
              setAwaitingTransaction(true)          
              sendGasToBackend()
            }}>
            PROCEED
          </div>
        </div>
      }
      {
        !acceptedWarning && awaitingTransaction &&
        <div id="battle-loader-modal__sign-container">
          <ModalCloseButton closeFunction={closeFunction}></ModalCloseButton>
          <img src={metamaskIcon} alt="" />
          <h3>
            Sign the transaction to send some gas fees to our server.
          </h3>
          <p>
            We collect fees in order to process the transaction to the blockchain on your behalf.
          </p>
        </div>
      }
      {
        acceptedWarning &&
        <>
          {
            loaderData.resultData !== undefined &&
            <ModalCloseButton closeFunction={closeFunction}></ModalCloseButton>
          }
          
          {
            loaderData.yourData.id !== undefined &&
            <Character 
              {...loaderData.yourData} 
              {...characterStyling}
              xOffset="22%">
            </Character>
          }
          {
            loaderData.opponentData.id !== undefined &&
            <Character 
              {...loaderData.opponentData} 
              {...characterStyling}
              mirror={true}
              xOffset="77.5%">
            </Character>
          }
          
          {
            loaderData.yourData.id !== undefined &&
            rankingData.length > 0 &&
            <>
              <div id="battle-loader-modal__container--you">
                <h3>YOU</h3>
                <p>Fighter #{loaderData.yourData.id}</p>
                <p>
                  Rating: {Math.round(ratings.you)}
                </p>
              </div>
              <div id="battle-loader-modal__container--opponent">
                <h3>OPPONENT</h3>
                <p>Fighter #{loaderData.opponentData.id}</p>
                <p>
                  Rating: {Math.round(ratings.opponent)}
                </p>
              </div>
            </>
          }

          {
            battleState === undefined &&
            <SubComponentLoader 
              loadingTextBool={false}
              positioning="relative">
            </SubComponentLoader>
          }

          {
            battleState !== undefined &&
            <div 
              id="ranking-highlevel__watch-battle" 
              onClick={() => routeToBattle()}>
                <p>WATCH BATTLE</p>
            </div>
          } 
        </>
      } 
    </div>
  )
  const additionalModalStyling = {
    width: "500px",
    height: "300px",
  }
  const modalData = {
    modalSubContent: modalSubContent,
    additionalModalStyling: additionalModalStyling,
    modalId: "battle-loader-modal"
  }

  useEffect(() => {
    if (loaderData.showing) {
      displayModal("battle-loader-modal")
    }
  }, [loaderData.showing])

  useEffect(() => {
    if (
      loaderData.opponentData.owner !== undefined &&
      loaderData.yourData.owner !== undefined
    ) {
      const yourData = rankingData.filter((row) => {
        return row.address === loaderData.yourData.owner.toLowerCase()
      })[0]
      const opponentData = rankingData.filter((row) => {
        return row.address === loaderData.opponentData.owner.toLowerCase()
      })[0]
      setRatings({
        you: yourData !== undefined ? yourData.rating : undefined,
        opponent: opponentData !== undefined ? opponentData.rating : undefined
      })
    }
  }, [rankingData, loaderData.yourData])

  return <Modal {...modalData}></Modal>
}

export default BattleLoaderPopup