import React from "react";
import ModalCloseButton from '../../generic-components/modal-close-button/ModalCloseButton'
import metamaskLogo from "../../../local-assets/wallet-logos/metamask.png"
import coinbaseLogo from "../../../local-assets/wallet-logos/coinbase-wallet.png"
import walletconnectLogo from "../../../local-assets/wallet-logos/wallet-connect.png"
import "./connect-popup.css";

const ConnectPopup = ({data, connect, setOpen }) => {
  const closeFunction = () => {
    setOpen((prevState) => { return !prevState })
  }
  return (
    <div className="connect-popup__background">
      <div className="connect-popup__layover"></div>
      <div className="modalContainer">
        <ModalCloseButton closeFunction={closeFunction}></ModalCloseButton>

        <p>Connect to one of our supported wallets:</p>
        <div className="connect-popup__separator"></div>

        <div className="connect-popup__providers-container">
        {
          data.connectors.map((connector) => (
            <button
              disabled={!connector.ready}
              key={connector.id}
              onClick={() => connect(connector)}
              className="connect-popup__wallet-provider"
            >
              {connector.name}
              {!connector.ready && ' (unsupported)'}
              <img src={
                connector.name === "MetaMask" ? 
                metamaskLogo : 
                connector.name === "WalletConnect" ? 
                walletconnectLogo : 
                coinbaseLogo
              } width={40} heigh={40}  
              id={connector.name.split(" ").join("-")} 
              alt="logo" />
            </button>
          ))
        }
        </div>
        <div className="connect-popup__separator"></div>
        <p>Haven't got a crypto wallet yet?</p>
        <a 
          target="_blank" 
          href="https://metamask.io/" 
          id="connect-popup__learn-more-button">
          LEARN MORE
        </a>
      </div>
    </div>
  );
};

export default ConnectPopup;