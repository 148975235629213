import React, { useEffect, useContext } from 'react'
import { Context } from '../../../Store'
import * as d3 from 'd3'
import './benchmark-selection.css'

const BenchmarkTable = ({ benchmark, setBenchmark }) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)

  useEffect(() => {
    d3.selectAll(".benchmark-selection__option").attr("class", "benchmark-selection__option")
    d3.select(`#benchmark-selection__option--${benchmark}`)
      .classed("benchmark-selection__option--selected", true)
  }, [benchmark])

  return (
    <>
      <div id="benchmark-selection">
        {
          [...Array(websiteContext.numBenchmarks).keys()].map((benchmarkIdx) => {
            return (
              <div 
                key={`benchmark--${benchmarkIdx}`}
                id={`benchmark-selection__option--${benchmarkIdx}`}
                className="benchmark-selection__option"
                onClick={() => setBenchmark(benchmarkIdx)}>
                <h3>Benchmark {benchmarkIdx+1}</h3>
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default BenchmarkTable