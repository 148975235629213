import React, { useEffect } from 'react'
import './loading-screen.css'
import * as d3 from 'd3'

const LoadingScreen = ({ loadingStatus }) => {

  useEffect(() => {
    d3.select("#interactive-scroll-contents").style("display", "none")
    return () => {
      d3.select("#interactive-scroll-contents").style("display", "block")
    }
  })

  return (
    <div id="loading-screen__container">
      <h1>{Math.round(loadingStatus.percent * 100)}%</h1>
    </div>
  );
};

export default LoadingScreen
