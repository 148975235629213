import React, { useState, useEffect } from "react"
import arrow from '../../../../local-assets/model-page/arrow.png'
import './model-version-history.css'

const ModelVersionHistory = ({ 
  guestMode, 
  currentVersion, 
  setCurrentVersion, 
  maxVersion,
  benchmarkView
}) => {
  const [modelVersion, setModelVersion] = useState(currentVersion)

  const selectModelVersion = (number) => {
    setCurrentVersion(number)
  }

  const incrementModelVersion = (delta) => {
    setCurrentVersion((prevState) => {
      var newVersion = prevState + delta
      if (newVersion > maxVersion) {
        newVersion = 0
      }
      else if (newVersion < 0) {
        newVersion = maxVersion
      }
      return newVersion
    })
  }

  useEffect(() => {
    if (currentVersion > modelVersion || modelVersion === undefined) {
      setModelVersion(currentVersion)
    }
  }, [currentVersion])

  return (
    <div id="model-version-history__container" style={{display: guestMode ? "none" : "flex"}}>
      {
        !guestMode && benchmarkView && modelVersion !== undefined && maxVersion !== undefined &&
        <>
          <p>Version History</p>
          <div 
            id="model-version-history__button--left"
            className="model-version-history__button"
            onClick={() => incrementModelVersion(-1)}>
            <img src={arrow} alt="" />
          </div>
          <div 
            id="model-version-history__button--right"
            className="model-version-history__button"
            onClick={() => incrementModelVersion(1)}>
            <img src={arrow} alt="" />
          </div>
          <div id="model-version-history">
            {
              [...Array(modelVersion + 1).keys()].map((versionNumber) => {
                var markerClassName = "model-version-history__marker"
                if (versionNumber === currentVersion) {
                  markerClassName += " model-version-history__marker--center"
                }
                else if (
                  (versionNumber === currentVersion + 1) ||
                  (versionNumber === 0 && currentVersion === maxVersion)
                ) {
                  markerClassName += " model-version-history__marker--right"
                }
                else if (
                  (versionNumber === currentVersion - 1) ||
                  (versionNumber === maxVersion && currentVersion === 0)
                ) {
                  markerClassName += " model-version-history__marker--left"
                }
                return (
                  <div 
                    key={`version-${versionNumber}`}
                    className={markerClassName}
                    onClick={() => selectModelVersion(versionNumber)}>
                      <p>{versionNumber}</p>
                  </div>
                )
              })
            }
          </div> 
        </>
      }
      {
        benchmarkView && (guestMode || modelVersion === undefined || maxVersion === undefined) && 
        <p id="model-version-history--guest">NO MODEL HISTORY</p>
      }
      {
        !benchmarkView && 
        <p id="model-version-history--guest">NOT APPLICABLE</p>
      }
    </div>
  )
}

export default ModelVersionHistory
