import React, { useContext } from "react"
import { useHistory } from "react-router"
import { Context } from "../../../../Store"

import Modal, { displayModal } from '../../../generic-components/custom-modal/CustomModal'
import ModalCloseButton from '../../../generic-components/modal-close-button/ModalCloseButton'
import { runBenchmark } from '../../../../helpers/heuristic-agents/run-benchmarks'
import { agentSidai } from '../../../../helpers/heuristic-agents/agent-sidai'
import { agentSihing } from '../../../../helpers/heuristic-agents/agent-sihing'
import { networkIdToData } from "../../../../helpers/misc/ethereum-networks"

import gloveIcon from '../../../../local-assets/iconography/boxingglove.svg'
import magnifyingGlass from '../../../../local-assets/iconography/magnifyingglass.svg'

import "./model-buttons.css"
import axios from "axios"
require("dotenv").config()

const defaultCharInputs = {
  xOffset: "13%",
  yOffset: "35%",
  mirror: false,
  size: 0.6,
  specialClass: "fighter-simulation-option"
}

const benchmarkOpponentsMapping = {
  0: {
    ...defaultCharInputs,
    battleAttributes: {
      power: 50,
      speed: 50,
      defence: 50,
      accuracy: 50,
    },
    dna: "wei",
    model: new agentSidai(),
    id: "cpu3",
    type: "Fire"
  },
  1: {
    ...defaultCharInputs,
    battleAttributes: {
      power: 90,
      speed: 90,
      defence: 90,
      accuracy: 90,
    },
    dna: "brandon",
    model: new agentSihing(),
    id: "cpu5",
    type: "Water"
  }
}

const ModelButtons = ({ 
  modelStats, 
  setModelStats, 
  currentVersion, 
  maxVersion,
  setRunningBenchmark, 
  setModelPerformance, 
}) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const history = useHistory()

  const backend = process.env.REACT_APP_BACKEND

  const routeToInspector = () => {
    history.push(`/ai-inspector/${websiteContext.yourFighter.id}`)
  }

  const routeToSimulation = (benchmark) => {
    history.push({
      pathname: '/simulation',
      state: {
        yourFighter: websiteContext.yourFighter,
        yourOpponent: benchmarkOpponentsMapping[benchmark],
        yourSide: Math.random() < 0.5 ? "left" : "right"
      }
    })
  }

  async function runBenchmarks() {
    if (websiteContext.yourFighter !== undefined) {
      setRunningBenchmark({ status: true, statusText: `0 / ${websiteContext.numBenchmarks}`})
      var benchmarkResponse
      const benchmarkStats = []
      var benchmarkSummary
      for (var b = 0; b < websiteContext.numBenchmarks; b++) {
        if (websiteContext.guestMode) {
          benchmarkResponse = runBenchmark(websiteContext.yourFighter, b)
        }
        else {
          benchmarkResponse = await axios.post(backend + "research-data/run-benchmark", {
            benchmark: b,
            yourId: websiteContext.yourFighter.id,        
            network: networkIdToData[websiteContext.network],
          })
        }

        if (benchmarkSummary === undefined) {
          benchmarkSummary = {...benchmarkResponse.data.benchmarkSummary}
        }
        else {
          benchmarkSummary.mu += benchmarkResponse.data.benchmarkSummary.mu
        }

        benchmarkStats.push(benchmarkResponse.data.benchmarkStats)
        setRunningBenchmark({ status: true, statusText: `${b+1} / ${websiteContext.numBenchmarks}`})
        if (b === websiteContext.numBenchmarks - 1) {
          setRunningBenchmark({ status: false, statusText: undefined}) 
        }        
      }
      setModelStats(benchmarkSummary)
      setModelPerformance(benchmarkStats)
      if (websiteContext.guestMode) {
        localStorage.setItem("stats", JSON.stringify({
          "modelStats": benchmarkSummary,
          "modelPerformance": benchmarkStats
        }))
      }
    }
  }

  const closeFunction = () => {
    document.getElementById("watch-benchmark-modal").style.display = "none"
  }

  const modalSubContent = (
    <div className="modal-watch-benchmark__container">
      <ModalCloseButton closeFunction={closeFunction}></ModalCloseButton>
      <div 
        className="modal-watch-benchmark__option"
        onClick={() => routeToSimulation(0)}>
        <p>Benchmark 1</p>
      </div>
      <div 
        className="modal-watch-benchmark__option"
        onClick={() => routeToSimulation(1)}>
        <p>Benchmark 2</p>
      </div>
    </div>
  )
  const additionalModalStyling = {
    width: "300px",
    height: "300px",
  }
  const modalData = {
    modalSubContent: modalSubContent,
    additionalModalStyling: additionalModalStyling,
    modalId: "watch-benchmark-modal"
  }

  const watchModelBool = websiteContext.yourFighter !== undefined
  const inspectModelBool = (
    websiteContext.yourFighter !== undefined &&
    (currentVersion === maxVersion || websiteContext.guestMode)
  )
  const runBenchmarksBool = (
    websiteContext.yourFighter !== undefined && 
    modelStats === undefined &&
    (currentVersion === maxVersion || websiteContext.guestMode)
  )

  return (
    <>
      <Modal {...modalData}></Modal>
      <div id="model-page__buttons-container">
        {
          <div 
            id="model-button--watch"
            className={`model-button${watchModelBool ? "" : " button-unavailable"}`} 
            onClick={() => watchModelBool ? displayModal("watch-benchmark-modal") : {}}>
              <img src={gloveIcon} alt="" />
              <div id="model-button__popup--watch" className="model-button__popup">
                <p>
                  Watch your model fight against our benchmarks
                </p>
              </div>
          </div>
        }
        {
          <div 
            id="model-button--inspect"
            className={`model-button${inspectModelBool ? "" : " button-unavailable"}`}
            onClick={() => inspectModelBool ? routeToInspector() : {}}>
              <img src={magnifyingGlass} alt="" />
              <div id="model-button__popup--inspect" className="model-button__popup">
                <p>
                  Toggle the state space to inspect your model's policy.
                  If in guest mode, you are the robot.
                </p>
              </div>
          </div>
        }
        {
          <div 
            id="model-button--benchmark" 
            className={`model-button${runBenchmarksBool ? "" : " button-unavailable"}`}
            onClick={() => runBenchmarksBool ? runBenchmarks() : {}}>
              {/* <img src={magnifyingGlass} alt="" /> */}
              <div id="benchmark-visual">
                <div id="benchmark-visual--bar1"></div>
                <div id="benchmark-visual--bar2"></div>
                <div id="benchmark-visual--bar3"></div>
              </div>
              <div id="model-button__popup--benchmark" className="model-button__popup">
                <p>
                  Evaluate your model against our benchmarks
                </p>
              </div>              
          </div>
        }
      </div>
    </>
  )
}

export default ModelButtons
export {
  benchmarkOpponentsMapping
}