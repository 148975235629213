import React, { useContext } from 'react'
import { Context } from "../../../../Store"
import Character from "../../../character/Character"
import './model-profile.css'

const ModelProfile = ({ guestMode }) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)

  const fighterMetadata = {
    className: "character",
    mirror: false,
    xOffset: "45%",
    yOffset: "-70%",
    size: "1",
    specialClass: "fighter-profile"
  }

  return (
    <div id="model-profile__container" style={{width: guestMode ? "80%" : "18%"}}>
      {
        !guestMode && websiteContext.yourFighter !== undefined &&
        <>
          <div id="model-profile__picture-container">
            <Character
              {...websiteContext.yourFighter}
              {...fighterMetadata}
            ></Character>
          </div>
          <p>Model ID: {websiteContext.yourFighter.id}</p>
        </>
      }
      {
        !guestMode && websiteContext.yourFighter === undefined &&
        <>
          <p id="model-profile__no-data">NO DATA</p>
        </>
      }
      {
        guestMode &&
        <>
          <p id="model-profile__guest-title">
            GUEST MODE
          </p>
          <p id="model-profile__guest-content">
            {/* Connect Whitelisted Wallet for Full Access */}
            NO WHITELIST ACCESS YET
          </p>
          <div 
            id="model-profile__remove-model"
            onClick={() => {
              localStorage.clear()
              window.location.reload()
            }}>
            <p>RESET</p>
          </div>
        </>
      }
    </div>
  )
}

export default ModelProfile
