import React, { useState, useEffect, useContext } from "react"
import { useNetwork, useProvider } from 'wagmi'
import { Context } from "../../../Store"
import Dropdown from '../../generic-components/dropdown/Dropdown'
import arbitrumLogo from '../../../local-assets/network-logos/arbitrum.svg'
import unsupported from '../../../local-assets/generic/warning.png'
import './network-selection.css'

const labelToId = {
  "Mainnet": 1,
  "Ropsten": 3,
  "Rinkeby": 4,
  "Goerli": 5,
  "Kovan": 42,
  "Optimism": 10,
  "Optimism Kovan": 69,
  "Polygon Mainnet": 137,
  "Mumbai": 80001,
  "Arbitrum One": 42161,
  "Arbitrum Rinkeby": 421611,
  "Avalanche Mainnet": 43114,
  "Avalanche Fuji Testnet": 43113
}

const networkOptions = [
  { name: "Arbitrum Rinkeby", label: "Arbitrum", image: arbitrumLogo },
]

const unsupportedNetwork = { name: "Unsupported", label: "Unsupported", image: unsupported }

const NetworkSelection = ({ selection, setSelection }) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const [{ data, loading, error }, switchNetwork] = useNetwork()
  const provider = useProvider()  

  const currentChain = networkOptions.filter((network) => {
    return network.name === data.chain.name
  })[0]
  
  const switchNetworkDisplayed = (networkId, networkName) => {
    const newSelection = networkOptions.filter((network) => {
      return network.name === networkName
    })[0]
    setSelection(newSelection !== undefined ? newSelection : unsupportedNetwork)
    setWebsiteContext((prevState) => {
      return {
        ...prevState,
        network: networkId,
        networkName: networkName,
        networkSupported: newSelection !== undefined
      }
    })
  }

  const changeNetwork = ({ name }) => {
    switchNetwork(labelToId[name])
    .then((result) => {
      // console.log("Switched to ", result, provider)
      // console.log(provider._network)//.set()
      if (result.data !== undefined) {
        window.location.reload()
        switchNetworkDisplayed(result.data.id, result.data.name)  
      }
    })
    .catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    setSelection(currentChain !== undefined ? networkOptions[0] : unsupportedNetwork)
  }, [])
  
  useEffect(() => {
    switchNetworkDisplayed(data.chain.id, data.chain.name)
  }, [data.chain.name])

  return (
    <Dropdown
      containerId="network-selection__container"
      dropdownId="network-selection-dropdown"
      selection={selection}
      clickFunction={changeNetwork}
      options={networkOptions}
      optionsStyling={{}}
      bottomBool={false}>
    </Dropdown>
  );
};

export default NetworkSelection
export {
  labelToId
}