import React, { useEffect, useContext } from "react"
import { Context } from "../Store"
import { HashRouter as Router, Route, Switch } from "react-router-dom"

import Home from "./website-scaffolding/Home"
import Sidebar from "./website-scaffolding/Sidebar"
import ModelPageContainer from "./model-page/ModelPageContainer"
import ModelInspectorContainer from "./model-inspector/ModelInspectorContainer"
import BattleContainer from "./game-play/ranked-components/battle/BattleContainer"
import RankingContainer from "./ranking-components/ranking-container/RankingContainer"
import BenchmarkContainer from "./benchmarks/benchmark-container/BenchmarkContainer"
import SimulationContainer from "./game-play/simulation-components/SimulationContainer"
import SimulationReplay from "./game-play/simulation-components/SimulationReplay"

import LandingPageContainer from './landing-page-components/landing-page/LandingPageContainer'

import { addIdToProfile } from '../helpers/machine-learning/ethereum-requests'

import Error from "./generic-components/error/Error"
import axios from "axios"
require("dotenv").config()

const ReactRouterSetup = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const backend = process.env.REACT_APP_BACKEND

  async function getWebsiteInfo(address) {
    // Get user's profile
    const userDataPromise = await axios.get(
      `${backend}research-data/ranked-data?address=${websiteContext.userAddress}`
    )
    if (userDataPromise.data.length === 0) {
      axios.post(backend + "research-data/create-profile", {
        address: address,
        username: address,
        id: -1
      })
    }
    else {
      if (userDataPromise.data[0].id === -1 && websiteContext.yourFighter !== undefined) {
        addIdToProfile(websiteContext.yourFighter.id, websiteContext.userAddress)
      }
      // Add to Website Context
      const userData = userDataPromise.data[0]
      setWebsiteContext((prevState) => {
        return {
          ...prevState,
          username: userData.username,
        }
      })
    }
  }

  useEffect(() => {
    if (websiteContext.userAddress !== undefined) {
      // We should only do this for the whitelisted addresses
      getWebsiteInfo(websiteContext.userAddress)
    }
  }, [websiteContext.userAddress, websiteContext.yourFighter])

  return (
    <>
      <Router>
        <div id="app-content">          
        {
          websiteContext.hasAccess &&
            <>
              <Sidebar></Sidebar>
              <Switch>
                <Route exact path="/">
                  <Home></Home>
                </Route>

                <>
                  <div id="app-content--right-side">
                    <Route exact path="/model-page">
                      <ModelPageContainer></ModelPageContainer>
                    </Route>

                    <Route exact path="/battle">
                      <BattleContainer></BattleContainer>
                    </Route>
                    <Route exact path="/benchmarks">
                      <BenchmarkContainer></BenchmarkContainer>
                    </Route>
                    <Route exact path="/simulation">
                      <SimulationContainer></SimulationContainer>
                    </Route>
                    <Route exact path="/simulation-replay">
                      <SimulationReplay></SimulationReplay>
                    </Route>
                    <Route exact path="/ranking">
                      <RankingContainer></RankingContainer>
                    </Route>

                    <Route exact path="/landing-page-wip">
                      <LandingPageContainer></LandingPageContainer>
                    </Route>

                    <Route
                      exact
                      path="/ai-inspector/:id"
                      children={<ModelInspectorContainer></ModelInspectorContainer>}
                    ></Route>
                  </div>
                </>

                <Route path="*">
                  <Error></Error>
                </Route>
              </Switch>
            </>
          }

          {
            !websiteContext.hasAccess &&
            <Switch>
              <Route exact path="/">
                <Home></Home>
              </Route>

              <Route exact path="/landing-page-wip">
                <LandingPageContainer></LandingPageContainer>
              </Route>
              
              <Route path="*">
                <Error></Error>
              </Route>
            </Switch>
          }
        </div>
      </Router>
    </>
  )
}

export default ReactRouterSetup
