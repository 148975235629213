import React, { useEffect, useRef } from "react"
import { setupChart } from "../../../../helpers/charting/chart-setup"
import { renderChart } from "../../../../helpers/charting/render-charts"
import { palette } from '../../../../helpers/design-map/color-palette'
import * as d3 from 'd3'
import './historical-elo.css'

const HistoricalElo = ({ rankedStats }) => {
  const refContainer = useRef()

  const startingElo = 1500
  var domain = [startingElo, startingElo]
  if (rankedStats !== undefined && rankedStats.rankingHistory !== undefined) {
    domain = d3.extent(rankedStats.rankingHistory)
  }

  const negativeColor = palette.typography[4]
  const positiveColor = palette.typography[2]

  const horizontalSeperators = []
  if (domain[0] < startingElo) {
    horizontalSeperators.push({ 
      y: startingElo, 
      color: negativeColor, 
      bottomBorder: negativeColor, 
      topBorder: "#555" 
    })
  }
  if (domain[1] > startingElo) {
    horizontalSeperators.push({ 
      y: domain[1], 
      color: positiveColor, 
      bottomBorder: "transparent", 
      topBorder: positiveColor
    })
  }

  const chartMetadata = {
    chartType: "Line Chart",
    margin: { top: 0.15, right: 0.01, bottom: 0.33, left: 0.02 },
    labelPadding: { x: 0.1, y: 0.1 },
    labelText: { x: "Time", y: "ELO" },
    horizontalSeperators: horizontalSeperators
  }

  const getCleanEloData = () => {
    return rankedStats.rankingHistory.map((elo, idx) => { return { x: idx, y: elo } })
  }

  useEffect(() => {
    if (refContainer.current !== undefined && refContainer.current !== null) {
      d3.select("#" + refContainer.current.id)
        .selectAll("svg")
        .remove()
    }   
    if (
      rankedStats !== undefined && 
      rankedStats.rankingHistory !== undefined &&
      rankedStats.rankingHistory.length > 1
    ) {
      setupChart("#" + refContainer.current.id, true, chartMetadata)
      renderChart(
        "#" + refContainer.current.id, 
        getCleanEloData(), 
        chartMetadata
      )
    }
  }, [rankedStats])

  return (
    <div id="historical-elo__container" ref={refContainer}>
      <h3>Historical ELO Rating</h3>
      {
        (
          rankedStats === undefined || 
          rankedStats.rankingHistory === undefined ||
          rankedStats.rankingHistory.length <= 1
        ) &&
        <p className="">NOT ENOUGH DATA</p>
      }
    </div>
  )
}

export default HistoricalElo
