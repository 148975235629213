const getNeuralNetworkShape = (layerSizes, nFeatures, nActions) => {
    const fullShape = []
    var inDim, outDim
    for (var i = 0; i < layerSizes.length + 1; i++) {
        if (i === 0) inDim = nFeatures
        else inDim = layerSizes[i-1]

        if (i === layerSizes.length) outDim = nActions
        else outDim = layerSizes[i]

        fullShape.push([+inDim, +outDim])
    }
    return fullShape
}

export {
  getNeuralNetworkShape
}
