import React, { useState, useEffect, useContext } from "react"
import { Context } from "../../../Store"
import MatchSummary from './match-summary/MatchSummary'
import MatchRounds from './match-rounds/MatchRounds'
import {
  resultColors,
  gradientWinLoss,
  getMatchStats
} from '../../../helpers/stats-page/matches-helpers'
import "./model-benchmark-matches.css"

const ModelBenchmarkMatches = ({ modelPerformance, ranks }) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const [matchSummaryStats, setMatchSummaryStats] = useState(undefined)

  // -100 is the number for tie
  const resultToIdx = {"-100": 1, "-1": 2}
  if (websiteContext.yourFighter !== undefined) {
    resultToIdx[websiteContext.yourFighter.id] = 0
  }

  useEffect(() => {
    if (modelPerformance !== undefined && modelPerformance.length > 0) {
      const allBenchmarkStats = getMatchStats(modelPerformance, resultToIdx)
      setMatchSummaryStats(allBenchmarkStats)
    }    
  }, [modelPerformance])

  return (
    <div id="benchmark-summary__container">
      <p>Benchmark Statistics</p>
      {
        modelPerformance !== undefined && 
        modelPerformance[0] !== undefined &&
        matchSummaryStats !== undefined &&
        <>
          {
            matchSummaryStats.map((benchmarkStats, benchmarkIdx) => {
              return (
                <div 
                  className="model-page__matches" 
                  id={`model-page__matches--${benchmarkIdx}`}
                  key={`benchmark-summary--${benchmarkIdx}`}>                  
                  <MatchSummary
                    matchSummaryStats={benchmarkStats}
                    // setExpandRounds={setExpandRounds}
                    resultColors={resultColors}
                    ranks={ranks}>
                  </MatchSummary>
                  <MatchRounds
                    roundsPerformance={modelPerformance[benchmarkIdx].rounds}
                    matchSummaryStats={benchmarkStats}
                    resultColors={resultColors}
                    gradientWinLoss={gradientWinLoss}>
                  </MatchRounds>
              </div>
              )
            })
          }
        </>
      }
      {
        (
          modelPerformance === undefined || 
          modelPerformance[0] === undefined ||
          matchSummaryStats === undefined
        ) &&
        <p className="model-page__charts--no-data">NO BENCHMARK DATA</p>
      }
    </div>
  )
}

export default ModelBenchmarkMatches
