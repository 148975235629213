import React from 'react'
import * as d3 from 'd3'
import './model-summary.css'

const ModelSummary = ({ guestMode, rankedStats, modelStats, ranks }) => {
  return (
    <div id="model-summary__container" style={{display: guestMode ? "none" : "flex"}}>
      {
        modelStats !== undefined && !guestMode && rankedStats !== undefined && 
        <>
          <div className="model-summary__details">
            <div className="model-summary__detail-line">
              <p className="model-summary__text--big">
                Competition Rank:
              </p>
              <p className="model-summary__text--big">
                {rankedStats.rank}
              </p>
            </div>
            <div className="model-summary__detail-line">
              <p className="model-summary__text--small">
                ELO Rating:
              </p>
              <p className="model-summary__text--small">
                {parseInt(rankedStats.currentRating)}
              </p>
            </div>
          </div>

          <div className="model-summary__details">
            <div className="model-summary__detail-line">
              <p className="model-summary__text--big">
                Benchmark Rank:
              </p>
              <p className="model-summary__text--big">
                {Math.round(d3.mean(ranks) * 10) / 10}
              </p>
            </div>
            <div className="model-summary__detail-line">
              <p className="model-summary__text--small">
                Benchmark Score:
              </p>
              <p className="model-summary__text--small">
                {Math.round(modelStats.mu * 10)/10}
              </p>
            </div>
          </div>
        </>
      }
      {
        !guestMode && (modelStats === undefined || rankedStats === undefined) &&
        <p className="model-summary--no-data">COMPILING DATA</p>
      }
      {
        guestMode &&
        <p className="model-summary--no-data">NO RANKING DATA</p>
      }      
    </div>
  )
}

export default ModelSummary
