import React, { useEffect, useContext } from "react"
import { Context } from "../../../Store"
import { useContract, useProvider } from 'wagmi'
import { getFighterObject, addFighterToBackend } from '../../../helpers/machine-learning/ethereum-requests'
import { networkIdToData } from "../../../helpers/misc/ethereum-networks"

import axios from "axios"
require("dotenv").config()

const FighterData = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)

  // Initialize the Smart Contracts
  const provider = useProvider()
  const fighterContract = useContract({
    addressOrName: websiteContext.smartContract.address,
    contractInterface: websiteContext.smartContract.abi,
    signerOrProvider: provider
  })
  const neuralNetworkContract = useContract({
    addressOrName: websiteContext.neuralNetworkContract.address,
    contractInterface: websiteContext.neuralNetworkContract.abi,
    signerOrProvider: provider
  })  

  const backend = process.env.REACT_APP_BACKEND
  
  useEffect(async () => {
    if (websiteContext.fetchFighterData) {
      const fightersOwnedResult = await fighterContract
        .callStatic
        .balanceOf(websiteContext.userAddress)
      const fightersOwned = parseInt(fightersOwnedResult._hex, 16)
      if (fightersOwned > 0) {
        // Get Fighter Info from Blockchain
        const yourFighterIdResult = await fighterContract
          .callStatic
          .tokenOfOwnerByIndex(websiteContext.userAddress, 0)

        const yourFighterId = parseInt(yourFighterIdResult._hex, 16)
        const yourFighterObject = await getFighterObject(
          yourFighterId,
          fighterContract,
          neuralNetworkContract
        )

        // Add Fighter to Website Context
        setWebsiteContext((prevState) => {
          return {
            ...prevState,
            yourFighter: yourFighterObject,
            fighterContractInstance: fighterContract,
            neuralNetworkContractInstance: neuralNetworkContract,
            fetchFighterData: false
          }
        })
        
        // Add fighter to backend if it's not there yet
        const network = networkIdToData[websiteContext.network]
        const battleResultHashPromise = await axios.get(
          `${backend}fighters/battle-result-hash?id=${yourFighterId}&network=${network}`
        )
        if (battleResultHashPromise.data[0] === undefined) {
          addFighterToBackend(yourFighterId, network)
        }
      }
    }
  }, [websiteContext.userAddress, websiteContext.fetchFighterData])

  return (
    <div id="fighter-data"></div>
  );
};

export default FighterData