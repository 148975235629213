import * as d3 from 'd3'

const checkAssetLoaded = (id, key, setFightersLoaded) => {
  if (d3.select(id)._groups[0][0] !== null) {
    setFightersLoaded((prevState) => {
      prevState[key] = true
      return {...prevState}
    })
    return
  }
  else {
    setTimeout(() => {
      checkAssetLoaded(id, key, setFightersLoaded)
    }, 50)
  }
}

const runFightersLoadedChecker = (fightersArray, setFightersLoaded) => {
  checkAssetLoaded(`#leftHand-${fightersArray[0].id}`, "0", setFightersLoaded)
  checkAssetLoaded(`#leftHand-${fightersArray[1].id}`, "1", setFightersLoaded)
}

export {
  runFightersLoadedChecker
}