import React, { useState, useEffect } from 'react'
import * as d3 from 'd3'
import './dropdown.css'

const Dropdown = ({ containerId, dropdownId, selection, clickFunction, options, optionsStyling, bottomBool }) => {
  const [opened, setOpened] = useState(false)

  if (optionsStyling === undefined) {
    optionsStyling = {
      bottom: 0,
      right: 0
    }
  }

  const hideDropdownOptions = (e) => {
    const dropdownOptions = d3.select(`#${dropdownId}__container`)
    if (dropdownOptions._groups[0][0] !== null) {
      if (
        dropdownOptions.style("display") !== "none" &&
        e.target.id !== `${dropdownId}__selection` &&
        e.target.id !== `${dropdownId}__container`
      ) {
        setOpened(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener("click", hideDropdownOptions)
    return () => {
      window.removeEventListener("click", hideDropdownOptions)
    }
  }, [])

  return (
    <div id={containerId} className="dropdown__container">
      <div 
        id={`${dropdownId}__selection`}
        className="dropdown__selection" 
        onClick={() => setOpened((prevState) => { return !prevState })}>
        {
          selection.image !== undefined && 
          <img src={selection.image}/>
        }
        <p>{selection.label}</p>
      </div>
      <div 
        id={`${dropdownId}__container`}
        className="dropdown__options-container"
        style={{
          display: opened ? "flex" : "none",
          top: bottomBool ? "105%" : "auto",
          bottom: bottomBool ? "auto" : "105%"
        }}>
        {
          options.map((option, idx) => {
            return (
              <div 
                key={`${dropdownId}--${idx}`} 
                id={`${dropdownId}__option`}
                className="dropdown__option"
                onClick={() => clickFunction(option)}>
                {
                  option.image !== undefined && 
                  <img src={option.image}/>
                }
                {
                  option.image === undefined && 
                  option.label === "Unsupported" &&
                  <p>?</p>
                }
                <p>{option.label}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default Dropdown
