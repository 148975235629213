import { delayBase } from '../animations/animation-helper'
import { gameParameters } from './game-parameters'
import * as d3 from 'd3'

// Define sizes for the battle screen
const widthHeightRatio = 1.75
const borderWidth = 5

const horizontalSpace = 0.95
const menuSize = 0.1
const menuMargin = 0.02
const requiredBottomSpace = menuSize + menuMargin * 2
const verticalSpace = 0.95 - requiredBottomSpace

// Function to resize the AI Inspector Screen
const resizeInspector = (battleArena, widthAnchor, heightAnchor) => {
  const attributesPointer = d3.select("#attributes-pointer")
  if (attributesPointer.style("display") !== "none") {
    attributesPointer.style("width", `${widthAnchor * 0.25}px`)
  }
  battleArena.selectAll("h3")
    .style("font-size", `${widthAnchor * 0.022}px`)
  battleArena.selectAll("p")
    .style("font-size", `${widthAnchor * 0.015}px`)    
  battleArena.select("#return-to-config").select("img")
    .style("height", `${widthAnchor * 0.022}px`)
    .style("margin-right", `${widthAnchor * 0.01}px`)
}

// Function to resize the general battlee screen
const resizeArena = () => {
  const screenWidth = document.body.clientWidth
  const screenHeight = document.body.clientHeight
  const arenaWidth = screenWidth * horizontalSpace
  const arenaHeight = screenHeight * verticalSpace
  const clock = d3.select(".clock")

  const requiredBottomSpacePx = requiredBottomSpace * screenHeight + borderWidth * 2

  var widthAnchor
  if (arenaHeight < arenaWidth / widthHeightRatio) {
    widthAnchor = arenaHeight * widthHeightRatio
  }
  else {
    widthAnchor = arenaWidth
  }
  const heightAnchor = widthAnchor / widthHeightRatio
  const emptyVerticalSpace = screenHeight - heightAnchor
  var battleArenaShift = 0
  if (emptyVerticalSpace/2 < requiredBottomSpacePx) {
    battleArenaShift = Math.min((emptyVerticalSpace/2 - requiredBottomSpacePx), 0)
  }
  const topPadding = emptyVerticalSpace / 2 + battleArenaShift
  const battleArena = d3.select("#battle-arena")
  battleArena
    .style("width", `${widthAnchor}px`)
    .style("height", `${heightAnchor}px`)
    .style("top", `${topPadding}px`)
  battleArena.selectAll("h1:not(#which-round-visual)")
    .style("font-size", `${widthAnchor * 0.04}px`)
  battleArena.select("#which-round-visual").select("h1")
    .style("font-size", `${widthAnchor * 0.06}px`)    
  battleArena.selectAll("h2")
    .style("font-size", `${widthAnchor * 0.027}px`)
  if (clock._groups[0][0] !== null) {
    clock.select("h2")
      .style("font-size", `${widthAnchor * 0.06}px`)
  }

  d3.selectAll(".button--fit")
    .style("font-size", `${widthAnchor * 0.03}px`)

  const arenaBottom = topPadding + heightAnchor + borderWidth * 2
  const gameplayMenu = d3.select(".gameplay-menu--horizontal")
  gameplayMenu
    .style("top", `${arenaBottom + menuMargin * screenHeight}px`)
  gameplayMenu.selectAll("h2")
    .style("pointer-events", "none")
    .style("font-size", `${widthAnchor * 0.025}px`)
    .style("opacity", heightAnchor * 0.1 < 40 ? 0 : 1)
    .style("position", heightAnchor * 0.1 < 40 ? "absolute" : "relative")

  if (d3.select(".inspector-arena")._groups[0][0] !== null) {
    resizeInspector(battleArena, widthAnchor, heightAnchor)
  }
}

// Define the helper functions for dynamically sizing the viewing area during battle
const getBoundingBox = (fighterId) => {
  const fighter = document.getElementById(`character${fighterId}`)
  const fighterBB = fighter.getBoundingClientRect()
  return fighterBB
}

const getHorizontalCoordinate = (id) => {
  if (d3.select(".training-stage")._groups[0][0] !== null) {
    return +d3.select(`#character${id}`).style("left").replace("%", "") / 100
  }
}
const getVerticalCoordinate = (id) => {
  if (d3.select(".training-stage")._groups[0][0] !== null) {
    return +d3.select(`#character${id}`).style("bottom").replace("%", "") / 100
  }
}

const getBounds = (fighterId) => {
  const x = getHorizontalCoordinate(fighterId)
  const y = getVerticalCoordinate(fighterId) / 4
  const floor = 0.7
  const characterHeight = gameParameters.characterWidth * 2
  return {
    left: (x - gameParameters.characterWidth) * 100,
    right: (x + gameParameters.characterWidth) * 100,
    bottom: (floor + characterHeight) * 100,
    top: (floor - y - characterHeight) * 100,
  }
}

const fighterFocus = (fighterId1, fighterId2) => {
  const fighterBounds1 = getBounds(fighterId1)
  const fighterBounds2 = getBounds(fighterId2)
  const focusedAreaBounds = {
    left: Math.min(fighterBounds1.left, fighterBounds2.left),
    right: Math.max(fighterBounds1.right, fighterBounds2.right),
    top: Math.min(fighterBounds1.top, fighterBounds2.top),
    bottom: Math.max(fighterBounds1.bottom, fighterBounds2.bottom),
  }
  d3.select("#screen-focus-outline")
    .attr("width", focusedAreaBounds.right - focusedAreaBounds.left + "%")
    .attr("height", focusedAreaBounds.bottom - focusedAreaBounds.top + "%")
    .attr("x", focusedAreaBounds.left + "%")
    .attr("y", focusedAreaBounds.top + "%")
}

const dynamicFocus = (fighterId1, fighterId2, calledFromLoop) => {
  if (!calledFromLoop || calledFromLoop === undefined) {
    d3.select(".battle-container").append("svg")
    .attr("id", "screen-focus-container")
    .attr("width", "100%")
    .attr("height", "100%")
    .append("rect")
      .attr("id", "screen-focus-outline")
      .style("fill", "none")
      .style("stroke", "red")
      .style("stroke-width", '2px')
  }

  if (d3.select(".training-stage")._groups[0][0] !== null) {
    fighterFocus(fighterId1, fighterId2)
    setTimeout(() => {
      dynamicFocus(fighterId1, fighterId2, true)
    }, delayBase)
  }
  return
}

export {
  widthHeightRatio,
  resizeArena,
  dynamicFocus
}
