const gameParameters = {
  characterWidth: 0.08,
  characterHeight: 0.02,
  punchExtension: 0.05,
  lowKickExtension: 0.025,
  shieldDistance: 0.12,
  shieldHealth: 100,
  minDamage: 0.02,
  maxDamage: 0.1,
  punchPowerMultiple: 0.5,
  lowKickPowerMultiple: 0.8,
  highKickPowerMultiple: 1,
  minSpeed: 0.1,
  maxSpeed: 0.3,
  jumpMultiple: 4,
  defaultMultiple: 2,
  charFractionDenom: 3,
  verticalJumpClearance: 1,
  verticalPunchHitbox: 0.02,
  verticalLowKickHitbox: 0.01,
}

export {
  gameParameters
}
