import React, { useState, useEffect } from 'react'
import { useAccount } from 'wagmi'
import LandingPage from './LandingPage'
import Wallet from '../../connect-components/wallet/Wallet'
import axios from "axios"
require("dotenv").config()

const backend = process.env.REACT_APP_BACKEND

const LandingPageContainer = () => {
  const [hasAccess, setHasAccess] = useState(false)
  const [{ data: accountData }, disconnect] = useAccount({ fetchEns: true })

  async function getAccessStatus(address) {
    const accessResult = await axios.get(
      `${backend}whitelist/landing-page-access?address=${address}`
    )
    setHasAccess(accessResult.data.access)
  }

  useEffect(() => {
    if (accountData !== undefined) {
      getAccessStatus(accountData.address)
    }
  }, [accountData])

  return (
    <div id="landing-page-prescreen">
      {
        !hasAccess &&
        <Wallet></Wallet>
      }
      {
        hasAccess &&
        <LandingPage></LandingPage>
      }
    </div>
  );
};

export default LandingPageContainer
