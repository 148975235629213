import React, { useEffect } from 'react'
import * as d3 from 'd3'
import './view-selection.css'

const ViewSelection = ({ view, setView, guestMode }) => {
  const viewOptions = []  
  viewOptions.push("Benchmarks")
  if (!guestMode) viewOptions.push("Ranked")

  useEffect(() => {
    if (guestMode) {
      setView("benchmarks")
    }    
  }, [guestMode])

  useEffect(() => {
    d3.selectAll(".view-selection__option").attr("class", "view-selection__option")
    d3.select(`#view-selection__option--${view}`)
      .classed("view-selection__option--selected", true)
  }, [view])

  return (
    <>
      <div id="view-selection">
        {
          viewOptions.map((viewName) => {
            const lowercaseName = viewName.toLowerCase()
            return (
              <div 
                key={`view--${lowercaseName}`}
                id={`view-selection__option--${lowercaseName}`}
                className="view-selection__option"
                onClick={() => setView(lowercaseName)}>
                <h3>{viewName}</h3>
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default ViewSelection