import animations from '../../sources/animations'
import models from '../../sources/models'
import textures from '../../sources/textures'

export default function getSources(name) {
    let sources = []

    addToSources(models, sources, name)
    addToSources(animations, sources, name)
    addToSources(textures, sources, name)

    return sources
}


function addToSources(resources, sources, name) {
    for (const resource of resources) {
        const resourceToAdd = add(resource, name)

        if (resourceToAdd) {
            sources.push(resourceToAdd)
        }
    }
}


function add(resource, name) {
    for (const tag of resource.tags) {
        if (tag === name) {
            return resource
        }
    }

    return null
}