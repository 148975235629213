import React, { useState, useContext, useEffect } from "react"
import { Context } from "../../Store"
import ViewSelection from "./view-selection/ViewSelection"
import ModelHighlevel from './model-page-highlevel/ModelHighlevel'
import ModelCharts from "./model-charts/ModelCharts"
import ModelBenchmarkMatches from './model-page-matches/ModelBenchmarkMatches'
import RankedMatchesContainer from './model-page-ranked-matches/RankedMatchesContainer'
import SubComponentLoader from '../generic-components/sub-component-loader/SubComponentLoader'
import { getTransitionMatrix, getBarChartData } from '../../helpers/stats-page/aggregate-data'
import { getSortedLeaderboard } from '../../helpers/stats-page/leaderboard'
import { neuralNetwork } from '../../helpers/machine-learning/neural-network'
import "./model-page.css"
import axios from "axios"
require("dotenv").config()

const ModelPage = () => {  
  const [websiteContext, setWebsiteContext] = useContext(Context)

  const backend = process.env.REACT_APP_BACKEND

  const [view, setView] = useState("ranked")
  const [runningBenchmark, setRunningBenchmark] = useState({ status: false, statusText: undefined })
  const [ranks, setRanks] = useState([])
  const [modelStats, setModelStats] = useState(undefined)
  const [rankedStats, setRankedStats] = useState(undefined)
  const [modelPerformance, setModelPerformance] = useState(undefined)
  const [rankedPerformance, setRankedPerformance] = useState(undefined)
  const [barChartData, setBarChartData] = useState(undefined)
  const [chordMatrix, setChordMatrix] = useState(undefined)
  const [currentVersion, setCurrentVersion] = useState(
    websiteContext.yourFighter !== undefined ? 
    parseInt(websiteContext.yourFighter.neuralNetVersion) :
    undefined
  )

  const getActionVisuals = (stats) => {
    if (stats[0] !== undefined) {      
      const roundTransitions = []
      const roundActions = []
      for (var i = 0; i < stats.length; i++) {
        roundTransitions.push(
          ...stats[i].rounds.map((round) => { return round.transitions })
        )
        roundActions.push(
          ...stats[i].rounds.map((round) => { return round.actions })
        )
      }
      setChordMatrix(getTransitionMatrix(roundTransitions))
      setBarChartData(getBarChartData(roundActions))
    }
    else {
      setChordMatrix(undefined)
      setBarChartData(undefined)
    }
  }

  async function getBenchmarkData() {
    const benchmarkDataPromise = await axios.get(
      `${backend}research-data/benchmark-data?id=${websiteContext.yourFighter.id}&modelVersion=${currentVersion}`
    )
    const benchmarkStats = benchmarkDataPromise.data.benchmarkStats
    const benchmarkSummary = benchmarkDataPromise.data.benchmarkSummary
    if (benchmarkStats !== undefined) {
      setModelPerformance(benchmarkStats)      
    }    
    if (benchmarkSummary !== undefined && benchmarkSummary[0] !== undefined) {
      const tempBenchmarkSummary = {...benchmarkSummary[0]}
      for (var b = 1; b < benchmarkSummary.length; b++) {
        tempBenchmarkSummary.mu += benchmarkSummary[b].mu
      }
      setModelStats(tempBenchmarkSummary)
    }
    else {
      setModelStats(undefined)
    }
  }

  async function getRankedData() {
    // Each Match Data
    const rankedDataPromise = await axios.get(
      `${backend}research-data/ranked-battle-data?id=${websiteContext.yourFighter.id}`
    )

    var meanDuration = 0
    const rankingData = rankedDataPromise.data
    if (rankingData !== undefined) {
      setRankedPerformance(rankingData)
      rankingData.forEach((match) => {
        var matchDuration = 0
        match.rounds.forEach((round) => {
          matchDuration += round.duration
        })
        meanDuration += (matchDuration / match.rounds.length)
      })
      meanDuration /= rankingData.length
    }

    // Leaderboard Data
    const [,sortedLeaderboard,,] = await getSortedLeaderboard()
    const userRankedData = sortedLeaderboard.filter((user) => {
      return user.address.toLowerCase() === websiteContext.userAddress.toLowerCase()
    })[0]

    const rankingSummary = {}
    if (userRankedData !== undefined) {
      rankingSummary.rank = userRankedData.rank
      rankingSummary.currentRating = userRankedData.rating
      rankingSummary.rankingHistory = userRankedData.ratingHistory
      rankingSummary.record = userRankedData.record
      rankingSummary.results = userRankedData.results
      rankingSummary.logo = userRankedData.logo
      rankingSummary.duration = meanDuration
    }
    setRankedStats(rankingSummary)
  }

  async function getLeaderboardData() {
    var leaderboardPromise
    const leaderboardRanks = []
    for (var b = 0; b < websiteContext.numBenchmarks; b++) {
      leaderboardPromise = await axios.get(
        `${backend}research-data/benchmark-leaderboard?benchmark=${b}`
      )
      leaderboardPromise.data.sort((a, b) => (a.min < b.min) ? 1 : -1)
      for (var i = 0; i < leaderboardPromise.data.length; i++) {
        if (
          (leaderboardPromise.data[i].id === websiteContext.yourFighter.id) &&
          (leaderboardPromise.data[i].modelVersion === currentVersion)
        ) {
          leaderboardRanks.push(i + 1)
          break
        }
      }
    }
    setRanks(leaderboardRanks)
  }

  useEffect(() => {
    if (websiteContext.guestMode) {      
      const locallyStoredStats = localStorage.getItem('stats')      
      if (locallyStoredStats !== undefined && locallyStoredStats !== null) {
        try {
          const parsedLocalStats = JSON.parse(locallyStoredStats)
          setModelStats(parsedLocalStats["modelStats"])
          setModelPerformance(parsedLocalStats["modelPerformance"])
        }
        catch {
          setModelStats(undefined)
          setModelPerformance(undefined)          
        }
      }
      else {
        setModelStats(undefined)
        setModelPerformance(undefined)
      }

      const locallyStoredModel = localStorage.getItem('model')
      if (locallyStoredModel !== undefined && locallyStoredModel !== null) {
        try {
          const tempFighter = JSON.parse(locallyStoredModel)
          setWebsiteContext((prevState) => {            
            return {
              ...prevState,
              yourFighter: {
                ...tempFighter,
                model: new neuralNetwork(
                  tempFighter.model.nFeatures, 
                  tempFighter.model.neurons, 
                  tempFighter.model.nActions, 
                  tempFighter.model.flattenedWeights, 
                  tempFighter.model.flattenedBiases, 
                  tempFighter.model.metadata
                )
              }
            }
          })
        }
        catch {
          console.log("problem fetching local model")
        }
      }
    }
  }, [websiteContext.guestMode])

  useEffect(() => {
    if (view === "ranked" && rankedPerformance !== undefined) {
      getActionVisuals(rankedPerformance)
    }
    else if (view === "benchmarks" && modelPerformance !== undefined) {
      getActionVisuals(modelPerformance)
    }
    else {
      getActionVisuals([])
    }
  }, [view, rankedPerformance, modelPerformance])

  useEffect(() => {
    if (websiteContext.yourFighter !== undefined && !websiteContext.guestMode) {
      setCurrentVersion(parseInt(websiteContext.yourFighter.neuralNetVersion))
    }
    else {
      if (!websiteContext.guestMode) {
        setModelStats(undefined)
        setModelPerformance(undefined)
        setBarChartData(undefined)
        setChordMatrix(undefined)
      }
      setCurrentVersion(undefined)
      setRankedStats(undefined)
      setRankedPerformance(undefined)
    }
  }, [websiteContext.yourFighter])


  useEffect(() => {
    if (websiteContext.yourFighter !== undefined && currentVersion !== undefined) {
      if (!websiteContext.guestMode) {
        getBenchmarkData()
        getRankedData()
        getLeaderboardData()
      }
    }
  }, [currentVersion])

  return (
    <div id="model-page__container">
      <ModelHighlevel
        rankedStats={rankedStats}
        modelStats={modelStats}
        setModelStats={setModelStats}
        currentVersion={currentVersion}
        setCurrentVersion={setCurrentVersion}
        setRunningBenchmark={setRunningBenchmark}
        setModelPerformance={setModelPerformance}
        ranks={ranks}
        benchmarkView={view === "benchmarks"}>        
      </ModelHighlevel>

      <ViewSelection 
        view={view} 
        setView={setView}
        guestMode={websiteContext.guestMode}>
      </ViewSelection>

      {
        runningBenchmark.status &&
        <div id="model-page__loading-container">
          <SubComponentLoader 
            text="Evaluating"
            statusText={runningBenchmark.statusText}>
          </SubComponentLoader>
        </div>
      }

      <ModelCharts 
        barChartData={barChartData}
        chordMatrix={chordMatrix}>
      </ModelCharts>

      {
        view === "benchmarks" &&
        <ModelBenchmarkMatches
          modelPerformance={modelPerformance}
          ranks={ranks}>
        </ModelBenchmarkMatches>        
      }
      {
        view === "ranked" &&
        <RankedMatchesContainer
          rankedStats={rankedStats}
          rankedPerformance={rankedPerformance}>
        </RankedMatchesContainer>        
      }
    </div>
  )
}

export default ModelPage
