import iconDisplayOver from "../../local-assets/game-play/healthbar-icon-over.webp"
import iconDisplayUnder from "../../local-assets/game-play/healthbar-icon-under.webp"
import emptyHealthLeft from "../../local-assets/game-play/healthbar-empty-left.webp"
import emptyHealthRight from "../../local-assets/game-play/healthbar-empty-right.webp"
import fullHealth from "../../local-assets/game-play/healthbar-full.webp"

const healthAssets = {
  iconBottom: iconDisplayOver,
  iconTop: iconDisplayUnder,
  containerLeft: emptyHealthLeft,
  containerRight: emptyHealthRight,
  fullHealth: fullHealth
}

export {
  healthAssets
}
