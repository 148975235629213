import { teamLogoAssets } from '../asset-files/team-logo-assets'
import axios from 'axios'
require("dotenv").config()

const backend = process.env.REACT_APP_BACKEND

const truncateUsername = (username, n) => {
  const halfWay = Math.round(n / 2)
  const len = username.length
  return username.slice(0, halfWay) + " ... " + username.slice(len - halfWay, len)
}

async function getSortedLeaderboard() {
  const rankedDataPromise = await axios.get(
    `${backend}research-data/ranked-data`
  )
  const leaderboardData = rankedDataPromise.data

  const cleanData = []
  var totalGamesPlayed = 0
  const teamData = {}
  leaderboardData.forEach((leaderboardRow) => {
    if (leaderboardRow.team !== undefined) {
      const gamesPlayed = leaderboardRow.wins + leaderboardRow.ties + leaderboardRow.loses
      totalGamesPlayed += gamesPlayed
      cleanData.push({
        ratingHistory: leaderboardRow.ratingHistory,
        address: leaderboardRow.address,
        username: truncateUsername(leaderboardRow.username, 12).toLowerCase(),
        rating: leaderboardRow.currentRating,
        record: `${leaderboardRow.wins}-${leaderboardRow.ties}-${leaderboardRow.loses}`,
        results: [leaderboardRow.wins, leaderboardRow.ties, leaderboardRow.loses],
        team: leaderboardRow.team,
        logo: teamLogoAssets[leaderboardRow.team]
      })

      if (teamData[leaderboardRow.team] === undefined) {
        teamData[leaderboardRow.team] = {
          cumulativeRating: leaderboardRow.currentRating,
          teamMembers: 1,
          logo: teamLogoAssets[leaderboardRow.team]
        }
      }
      else {
        teamData[leaderboardRow.team].cumulativeRating += leaderboardRow.currentRating          
        teamData[leaderboardRow.team].teamMembers += 1
      }
    }
  })
  cleanData.sort((a, b) => (a.rating < b.rating) ? 1 : -1)
  cleanData.forEach((x, idx) => {
    x.rank = idx + 1
  })
  return [teamData, cleanData, totalGamesPlayed, leaderboardData.length]
}

export {
  getSortedLeaderboard
}