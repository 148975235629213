import React, { useState, useEffect, useContext } from "react"
import { Context } from "../../../Store"
import { useAccount, useConnect } from 'wagmi'

import WalletConnection from '../wallet-connection/WalletConnection'
import WalletDisplay from '../wallet-display/WalletDisplay'
import NetworkSelection from '../network-selection/NetworkSelection'
import FighterData from '../fighter-data/FighterData'

import FighterFarmNoChainlink from "../../../abis/FighterFarmNoChainlink.json"
import NeuralNetwork from "../../../abis/NeuralNetwork.json"

import axios from 'axios'
import './wallet.css'
require('dotenv').config()

const backend = process.env.REACT_APP_BACKEND

const Wallet = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const [{ data: connectionData }, connect] = useConnect()
  const [{ data: accountData }, disconnect] = useAccount({ fetchEns: true })
  const [selection, setSelection] = useState({})

  useEffect(() => {
    if (websiteContext.network !== undefined) {
      const contractNetworkData = FighterFarmNoChainlink.networks[websiteContext.network]
      const neuralNetNetworkData = NeuralNetwork.networks[websiteContext.network]
      if (contractNetworkData !== undefined && neuralNetNetworkData !== undefined) {
        setWebsiteContext((prevState) => {
          return {
            ...prevState,
            smartContract: {
              address: FighterFarmNoChainlink.networks[websiteContext.network].address,
              abi: FighterFarmNoChainlink.abi
            },
            neuralNetworkContract: {
              address: NeuralNetwork.networks[websiteContext.network].address,
              abi: NeuralNetwork.abi
            }
          }
        })
      }
      else {
        setWebsiteContext((prevState) => {
          return {
            ...prevState,
            smartContract: undefined,
            neuralNetworkContract: undefined,
            yourFighter: undefined
          }
        })
      }
    }
  }, [websiteContext.network])

  useEffect(() => {
    if (websiteContext.smartContract !== undefined) {
      setWebsiteContext((prevState) => {
        return {
          ...prevState,
          fetchFighterData: true
        }
      })
    }
  }, [websiteContext.smartContract])

  useEffect(async () => {
    if (accountData !== undefined) {
      if (accountData.address !== websiteContext.userAddress) {
        // const onWhitelist = true
        const whitelistResult = await axios.get(
          `${backend}whitelist/researcher-platform-access?address=${accountData.address}`
        )
        setWebsiteContext((prevState) => {
          return {
            ...prevState,
            userAddress: accountData.address,
            guestMode: !whitelistResult.data.access
          }
        })
      }
    }
  }, [accountData])

  useEffect(() => {
    setWebsiteContext((prevState) => {
      return {
        ...prevState,
        userAddress: undefined,
        // guestMode: !connectionData.connected,
        fetchFighterData: connectionData.connected,
        yourFighter: undefined
      }
    })
  }, [connectionData.connected])

  return (
    <div id="wallet__container">
      {
        websiteContext.smartContract !== undefined && 
        websiteContext.userAddress !== undefined &&
        websiteContext.network !== undefined &&
        <FighterData></FighterData>
      }
      {
        accountData &&
        <div id="wallet__account-data">
          <NetworkSelection
            selection={selection}
            setSelection={setSelection}>            
          </NetworkSelection>
          <WalletDisplay 
            accountData={accountData}
            selection={selection}>
          </WalletDisplay>
        </div>
      }
      <WalletConnection 
        connectionData={connectionData}
        accountData={accountData}
        connect={connect}
        disconnect={disconnect}
        showBalance={false}>
      </WalletConnection>
    </div>
  );
};

export default Wallet