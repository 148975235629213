const metaDataDefault = {
  "character1": {
    className: "character",
    mirror: false,
    xOffset: "20%",
    specialClass: "drop-shadow"
  },
  "character2": {
    className: "character",
    mirror: true,
    xOffset: "80%",
    specialClass: "drop-shadow"
  }
}
const battleMetaData = {
  "character1": {...metaDataDefault["character1"], yOffset: "12%"},
  "character2": {...metaDataDefault["character2"], yOffset: "12%"}
}
const trainMetaData = {
  "character1": {...metaDataDefault["character1"], yOffset: "20%"},
  "character2": {...metaDataDefault["character2"], yOffset: "20%"}
}
const simulationMetaData = {
  "character1": {...metaDataDefault["character1"], yOffset: "8%"},
  "character2": {...metaDataDefault["character2"], yOffset: "8%"}
}
export {
  battleMetaData,
  trainMetaData,
  simulationMetaData
}
