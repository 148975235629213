const landingTags = ["landingPage"]
const additionalSources = [
  // {
  //   name: 'marshallHead',
  //   type: 'fbxModel',
  //   tags: landingTags,
  //   path: "accessories/head/1-1/head-1-1.fbx"
  // },
  // {
  //   name: 'marshallHands',
  //   type: 'fbxModel',
  //   tags: landingTags,
  //   path: "accessories/hands/1-1/hands-1-1.fbx"
  // },
  // {
  //   name: 'marshallFeet',
  //   type: 'fbxModel',
  //   tags: landingTags,
  //   path: "accessories/feet/1-1/feet-1-1.fbx"
  // },
  // {
  //   name: 'marshallBody',
  //   type: 'fbxModel',
  //   tags: landingTags,
  //   path: "accessories/body/1-1/body-1-1.fbx"
  // },
  // {
  //   name: 'marshallHeadTexture',
  //   type: 'texture',
  //   tags: landingTags,
  //   path: "accessories/head/1-1/head-1-1--base-color.webp"
  // },
  // {
  //   name: 'marshallHandsTexture',
  //   type: 'texture',
  //   tags: landingTags,
  //   path: "accessories/hands/1-1/hands-1-1--base-color.webp"
  // },
  // {
  //   name: 'marshallFeetTexture',
  //   type: 'texture',
  //   tags: landingTags,
  //   path: "accessories/feet/1-1/feet-1-1--base-color.webp"
  // },
  // {
  //   name: 'marshallBodyTexture',
  //   type: 'texture',
  //   tags: landingTags,
  //   path: "accessories/body/1-1/body-1-1--base-color.webp"
  // },
]

export {
  additionalSources
}