import * as THREE from 'three'

const createScene = (params) => {
  const canvasContainer = document.getElementById(params.containerId)  

  const threejs = new THREE.WebGLRenderer({ antialias: true })
  threejs.outputEncoding = THREE.sRGBEncoding
  threejs.setSize(canvasContainer.clientWidth, canvasContainer.clientHeight)
  threejs.setPixelRatio(window.devicePixelRatio)
  threejs.autoClear = false
  threejs.setClearColor(0x000000, 0)

  canvasContainer.appendChild(threejs.domElement)

  const near = 0.1
  const far = params.far !== undefined ? params.far : 1000.0
  const aspect = canvasContainer.clientWidth / canvasContainer.clientHeight
  const camera = new THREE.PerspectiveCamera(params.fov, aspect, near, far) 

  const scene = new THREE.Scene()

  return [scene, camera, threejs]
}

export {
  createScene
}