import Main from "./components/index"
import Store from "./Store";
import { providers } from 'ethers'
import { Provider, chain } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { WalletLinkConnector } from 'wagmi/connectors/walletLink'
import './index.css'
require("dotenv").config()

const chainsList = Object.values(chain)

// Set up connectors
const connectors = ({ chainId }) => {
  const rpcUrl =
    chainsList.find((x) => x.id === chainId)?.rpcUrls?.[0] ??
    chain.mainnet.rpcUrls[0]

  return [
    new InjectedConnector({ 
      chains: chainsList, 
      options: { 
        shimDisconnect: true 
      },
    }),
    new WalletConnectConnector({
      chains: chainsList, 
      options: {
        infuraId: process.env.REACT_APP_INFURA_URL,
        qrcode: true,
      },
    }),
    new WalletLinkConnector({
      chains: chainsList,
      options: {
        appName: 'AI Arena',
        jsonRpcUrl: `${rpcUrl}/${process.env.REACT_APP_INFURA_URL}`,
      },
    }),
  ]
}

const provider = ({ chainId }) => new providers.InfuraProvider(chainId, process.env.REACT_APP_INFURA_URL)

function App() {
  return (
    <Provider autoConnect connectors={connectors} provider={provider}>
      <Store>
          <Main></Main>
      </Store>
    </Provider>
  );
}

export default App;
