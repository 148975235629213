// import idle from '../local-assets/landing-page/animations/idle.fbx'
// import secondPunch from '../local-assets/landing-page/animations/double-punch.fbx'
// import punchingBagHit from '../local-assets/landing-page/animations/punching-bag-hit.fbx'

const landingTags = ["landingPage"]

const animations = [
    // {
    //     name: 'idle',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: idle
    // },
    // {
    //     name: 'punch',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: secondPunch
    // },
    // {
    //     name: 'punchingBagHit',
    //     type: 'fbxModel',
    //     tags: landingTags,
    //     path: punchingBagHit
    // }
]

export default animations