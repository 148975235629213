import { assetDir } from '../asset-handling/render-helper'
import * as d3 from 'd3'

const last_position_number = 45
const animation_time_interval = 30
const readyFightDir = assetDir + "ready-fight-assets/"

const loadReadyFightAssets = () => {
  const chartHeight = parseInt(d3.select("#ready-fight-animation").style("height"))
  const chartWidth = parseInt(d3.select("#ready-fight-animation").style("width"))
  
  var svg = d3.select("#ready-fight-animation").append("svg")
    .attr("width",`${chartWidth}px`)
    .attr("height",`${chartHeight}px`)
    .style("overflow", "visible")

  for (var i = 0; i <= last_position_number; i++) {
    var assetFile = readyFightDir + `ready-fight-${i}.png`
    svg.append("svg:image")
      .attr("class", "ready-fight")
      .attr("id", `ready-fight-${i}`)
      .attr("xlink:href", decodeURI(assetFile))
      .attr('height', "100%")
      .attr('width', "100%")
      .style("opacity", 0)
  }
  return svg
}

const renderReadyFightAnimation = (svg) => {
  function startFightAnimation(position_number) {
    if (position_number >= last_position_number) {
      svg.select(`#ready-fight-${position_number-1}`).style("opacity", 0)
      svg.selectAll(".ready-fight").remove()
      svg.remove()
      return
    }
    else {
      var current_time_interval
      if (position_number < 17) {
        current_time_interval = animation_time_interval * 2
      }
      else if (position_number === 17) {
        current_time_interval = animation_time_interval * 10
      }
      else if (position_number === 38) {
        current_time_interval = animation_time_interval * 4
      }
      else {
        current_time_interval = animation_time_interval
      }
      setTimeout(function() {
        if (position_number > 0) {
          svg.select(`#ready-fight-${position_number-1}`).style("opacity", 0)
        }
        svg.select(`#ready-fight-${position_number}`).style("opacity", 1)
        startFightAnimation(position_number + 1)
      }, current_time_interval)
    }
  }
  startFightAnimation(0)
}

export {
  loadReadyFightAssets,
  renderReadyFightAnimation
}
