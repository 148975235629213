import { neuralNetwork } from '../machine-learning/neural-network'

const possibleTypes = ["Fire", "Water", "Electric"]
const maxFutureComposableBots = 10
const maxCurrentComposableBots = 1

const getNeurons = (neuralNetworkShape) => {
  var neurons = []
  for (var i = 1; i < neuralNetworkShape.length; i++) {
    neurons.push(parseInt(neuralNetworkShape[i][0]))
  }
  return neurons
}

const oldToNewKeyMapping = {
  "0": "owner",
  "1": "attributes",
  "2": "neuralNetId"
}

const replaceKeys = (keyMapping, obj) => {
  var newObj = {}
  for (const [key, value] of Object.entries(obj)) {
    newObj[keyMapping[key]] = value
  }
  return newObj
}

const getTruncatedDna = (rawDna) => {
  var truncatedDna
  const dnaLength = rawDna.length
  if (dnaLength > 16) {
    truncatedDna = rawDna.slice(dnaLength-16, dnaLength)
  }
  else {
    truncatedDna = rawDna
  }
  var dnaReplacement
  if (rawDna < maxFutureComposableBots) {
    dnaReplacement = `composable-${Math.min(rawDna, maxCurrentComposableBots)}--off`
  }
  return [truncatedDna, dnaReplacement]
}

async function getFighterValuesFromBlockchain(smartContract, tokenId) {
  const rawFighterInfo = await smartContract.callStatic.getAllFighterInfo(tokenId)
  const fighterInfo = replaceKeys(oldToNewKeyMapping, rawFighterInfo)
  
  const battleRecord = { '0': 0, '1': 0, '2': 0 }
  const attributes = fighterInfo.attributes.map((attr) => { return parseInt(attr._hex, 16) })
  const [truncatedDna, dnaReplacement] = getTruncatedDna(rawFighterInfo[1][4]._hex)
  
  const currentFighter = {
    ...fighterInfo,
    battleRecord: [battleRecord['0'], battleRecord['1'], battleRecord['2']],
    type: possibleTypes[truncatedDna % 3],
    power: attributes[0],
    speed: attributes[1],
    defence: attributes[2],
    accuracy: attributes[3],
    dna: dnaReplacement !== undefined ? dnaReplacement : parseInt("0x" + truncatedDna, 16),
    neuralNetId: parseInt(fighterInfo.neuralNetId._hex, 16)
  }
  delete currentFighter.attributes
  return currentFighter
}

const createFighterObject = (
  fighterValues, rawFlattenedWeights, rawFlattenedBiases, neuralNetworkShape, modelMetadata
) => {
  const nFeatures = parseInt(neuralNetworkShape[0][0])
  const neurons = getNeurons(neuralNetworkShape)
  const nActions = parseInt(neuralNetworkShape[neuralNetworkShape.length-1][1])
  const flattenedWeights = rawFlattenedWeights.map((weight) => { return parseInt(weight._hex, 16) / 100000 })
  const flattenedBiases = rawFlattenedBiases.map((bias) => { return parseInt(bias._hex, 16) / 100000 })
  const binary = parseInt(fighterValues.dna).toString(2)

  const newCharacter = {
    owner: fighterValues.owner,
    id: fighterValues.id,
    dna: fighterValues.dna,
    binary: binary,
    battleAttributes: {
      power: fighterValues.power,
      speed: fighterValues.speed,
      defence: fighterValues.defence,
      accuracy: fighterValues.accuracy
    },
    battleRecord: fighterValues.battleRecord,
    model: new neuralNetwork(
      nFeatures, 
      neurons, 
      nActions, 
      flattenedWeights, 
      flattenedBiases, 
      modelMetadata
    ),
    neuralNetId: fighterValues.neuralNetId,
    neuralNetVersion: fighterValues.versionNumber,
    type: fighterValues.type !== undefined ? fighterValues.type : possibleTypes[fighterValues.dna % 3],
    price: fighterValues.price,
    generation: fighterValues.generation,
    rarity: fighterValues.rarity,
    performance: fighterValues.performance,
    salePostedTime: fighterValues.salePostedTime,
    renderCrown: fighterValues.renderCrown,
    availability: fighterValues.availability,
    composableMetadata: fighterValues.composableMetadata
  }
  return newCharacter
}

export {
  possibleTypes,
  getTruncatedDna,
  getFighterValuesFromBlockchain,
  createFighterObject
}
