import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Simulation from './Simulation'
import * as d3 from 'd3'

const SimulationContainer = () => {
  const history = useHistory()
  const location = useLocation()
  const fighterChosen = location.state !== undefined

  useEffect(() => {
    if (!fighterChosen) {
      history.push('/model-page')
    }
    d3.select("#app-content--right-side").classed("app-content--full-screen", true)
    return () => {
      d3.select("#app-content--right-side").classed("app-content--full-screen", false)
    }
  }, [])

  return (
    <>
      {fighterChosen && <Simulation></Simulation>}
    </>
  )
}

export default SimulationContainer
