import {
  sizeMultiples,
  defaultLimbPositions,
  getLimbData,
  getPositionAndSize,
} from '../asset-handling/render-helper'
import {
  getRotation,
  attributeTransition,
  attributeRotation,
  limbTransition,
  getCurrentRotation,
  delayBase,
} from './animation-helper'
import * as d3 from 'd3'

// Some parameters for the size and position of body parts for the default fighter
const charSize = sizeMultiples.battle

const leftArmData = getLimbData(charSize, ...defaultLimbPositions.leftArm)
const rightArmData = getLimbData(charSize, ...defaultLimbPositions.rightArm)
const leftLegData = getLimbData(charSize, ...defaultLimbPositions.leftLeg)
const rightLegData = getLimbData(charSize, ...defaultLimbPositions.rightLeg)

function snapBackToGuard(currentId) {
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

  const startHeadRotation = getCurrentRotation(head)
  const startBodyRotation = getCurrentRotation(body)
  const startLeftHandRotation = getCurrentRotation(leftHand)
  const startRightHandRotation = getCurrentRotation(rightHand)
  const startLeftFootRotation = getCurrentRotation(leftFoot)
  const startRightFootRotation = getCurrentRotation(rightFoot)

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(startHeadRotation, 0, headX, headY)
  , headRotation2 = getRotation(0, 10, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(startBodyRotation, 0, bodyX, bodyY)

  const leftFootBBox = leftFoot.node().getBBox()
  , leftFootX = leftFootBBox.x + leftFootBBox.width/2
  , leftFootY = leftFootBBox.y + leftFootBBox.height/2
  , leftFootRotation = getRotation(startLeftFootRotation, 0, leftFootX, leftFootY)

  const rightFootBBox = rightFoot.node().getBBox()
  , rightFootX = rightFootBBox.x + rightFootBBox.width/2
  , rightFootY = rightFootBBox.y + rightFootBBox.height/2
  , rightFootRotation = getRotation(startRightFootRotation, 0, rightFootX, rightFootY)

  const leftHandBBox = leftHand.node().getBBox()
  , leftHandX = leftHandBBox.x + leftHandBBox.width/2
  , leftHandY = leftHandBBox.y + leftHandBBox.height/2
  , leftHandRotation = getRotation(startLeftHandRotation, 13, leftHandX, leftHandY)

  const rightHandBBox = rightHand.node().getBBox()
  , rightHandX = rightHandBBox.x + rightHandBBox.width/2
  , rightHandY = rightHandBBox.y + rightHandBBox.height/2
  , rightHandRotation = getRotation(startRightHandRotation, 0, rightHandX, rightHandY)

  // Attribute Transitions
  const initialHeadPosition = getPositionAndSize("head")
  attributeTransition(head, 0, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0], initialRightHandPosition[1])

  const initialLeftFootPosition = getPositionAndSize("leftFoot")
  attributeTransition(leftFoot, 0, initialLeftFootPosition[0], initialLeftFootPosition[1])

  const initialRightFootPosition = getPositionAndSize("rightFoot")
  attributeTransition(rightFoot, 0, initialRightFootPosition[0], initialRightFootPosition[1])

  // Attribute Rotations
  attributeRotation(head, 0, headRotation)
  attributeRotation(body, 0, bodyRotation)
  attributeRotation(leftFoot, 0, leftFootRotation)
  attributeRotation(rightFoot, 0, rightFootRotation)
  attributeRotation(leftHand, 0, leftHandRotation)
  attributeRotation(rightHand, 0, rightHandRotation)

  // Limb Transition
  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, 0, rightLegData)
}

function basicIdleAnimation(currentId) {
  const characterCard = d3.select(currentId)
  const characterIdNumber = currentId.replace("#character", "")

  // const idleLoopIndicator = d3.select("#idle-loop-indicator")._groups[0][0]
  // , loopBool = idleLoopIndicator !== null

  const idleSpeed = delayBase * 1.5

  const head = characterCard.select(`#head-${characterIdNumber}`)
  , body = characterCard.select(`#body-${characterIdNumber}`)
  , leftHand = characterCard.select(`#leftHand-${characterIdNumber}`)
  , rightHand = characterCard.select(`#rightHand-${characterIdNumber}`)
  , leftFoot = characterCard.select(`#leftFoot-${characterIdNumber}`)
  , rightFoot = characterCard.select(`#rightFoot-${characterIdNumber}`)

  const headBBox = head.node().getBBox()
  , headX = headBBox.x + headBBox.width/2
  , headY = headBBox.y + headBBox.height/2
  , headRotation = getRotation(0, 2, headX, headY)
  , headRotation2 = getRotation(2, 1, headX, headY)
  , headRotation3 = getRotation(1, 0, headX, headY)

  const bodyBBox = body.node().getBBox()
  , bodyX = bodyBBox.x + bodyBBox.width/2
  , bodyY = bodyBBox.y + bodyBBox.height/2
  , bodyRotation = getRotation(0, 3, bodyX, bodyY)
  , bodyRotation2 = getRotation(3, 4, bodyX, bodyY)
  , bodyRotation3 = getRotation(4, 0, bodyX, bodyY)
  , bodyRotation4 = getRotation(0, -2, bodyX, bodyY)
  , bodyRotation5 = getRotation(-2, 0, bodyX, bodyY)

  // Attribute Transitions
  const initialHeadPosition = getPositionAndSize("head")

  attributeTransition(head, 0, initialHeadPosition[0]+0.02, initialHeadPosition[1]-0.02)
  attributeTransition(head, idleSpeed, initialHeadPosition[0]+0.03, initialHeadPosition[1]+0.01)
  attributeTransition(head, idleSpeed * 2, initialHeadPosition[0]+0.035, initialHeadPosition[1]+0.04)
  attributeTransition(head, idleSpeed * 3, initialHeadPosition[0]+0.02, initialHeadPosition[1]+0.035)
  attributeTransition(head, idleSpeed * 4, initialHeadPosition[0], initialHeadPosition[1]+0.025)
  attributeTransition(head, idleSpeed * 5, initialHeadPosition[0], initialHeadPosition[1])

  const initialBodyPosition = getPositionAndSize("body")
  attributeTransition(body, 0, initialBodyPosition[0], initialBodyPosition[1]-0.02)
  attributeTransition(body, idleSpeed, initialBodyPosition[0], initialBodyPosition[1])
  attributeTransition(body, idleSpeed * 2, initialBodyPosition[0], initialBodyPosition[1]+0.03)
  attributeTransition(body, idleSpeed * 5, initialBodyPosition[0], initialBodyPosition[1])

  const initialLeftHandPosition = getPositionAndSize("leftHand")
  attributeTransition(leftHand, 0, initialLeftHandPosition[0]+0.07, initialLeftHandPosition[1]-0.02)
  attributeTransition(leftHand, idleSpeed, initialLeftHandPosition[0]+0.11, initialLeftHandPosition[1]+0.02)
  attributeTransition(leftHand, idleSpeed * 2, initialLeftHandPosition[0]+0.09, initialLeftHandPosition[1]+0.08)
  attributeTransition(leftHand, idleSpeed * 3, initialLeftHandPosition[0]+0.06, initialLeftHandPosition[1]+0.11)
  attributeTransition(leftHand, idleSpeed * 4, initialLeftHandPosition[0]+0.03, initialLeftHandPosition[1]+0.05)
  attributeTransition(leftHand, idleSpeed * 5, initialLeftHandPosition[0], initialLeftHandPosition[1])

  const initialRightHandPosition = getPositionAndSize("rightHand")
  attributeTransition(rightHand, 0, initialRightHandPosition[0]+0.01, initialRightHandPosition[1]+0.01)
  attributeTransition(rightHand, idleSpeed, initialRightHandPosition[0]+0.01, initialRightHandPosition[1]+0.06)
  attributeTransition(rightHand, idleSpeed * 2, initialRightHandPosition[0]-0.025, initialRightHandPosition[1]+0.075)
  attributeTransition(rightHand, idleSpeed * 3, initialRightHandPosition[0]-0.0375, initialRightHandPosition[1]+0.045)
  attributeTransition(rightHand, idleSpeed * 4, initialRightHandPosition[0]-0.03, initialRightHandPosition[1]+0.02)
  attributeTransition(rightHand, idleSpeed * 5, initialRightHandPosition[0], initialRightHandPosition[1])

  // Attribute Rotations
  attributeRotation(head, idleSpeed, headRotation)
  attributeRotation(head, idleSpeed * 3, headRotation2)
  attributeRotation(head, idleSpeed * 4, headRotation3)

  attributeRotation(body, 0, bodyRotation)
  attributeRotation(body, idleSpeed, bodyRotation2)
  attributeRotation(body, idleSpeed * 3, bodyRotation3)
  attributeRotation(body, idleSpeed * 4, bodyRotation4)
  attributeRotation(body, idleSpeed * 5, bodyRotation5)

  const newLeftArmData = getLimbData(charSize, 0.39, 0.46, 0.06, 0.655, 0.36, 0.57)
  const newRightArmData = getLimbData(charSize, 0.55, 0.52, 0.61, 0.685, 0.81, 0.6)
  const newLeftLegData = getLimbData(charSize, 0.35, 0.7, 0.32, 0.82, 0.11, 0.918)

  const newLeftArmData2 = getLimbData(charSize, 0.39, 0.48, 0.1, 0.65, 0.4, 0.6)
  const newRightArmData2 = getLimbData(charSize, 0.56, 0.555, 0.62, 0.695, 0.81, 0.64)
  const newLeftLegData2 = getLimbData(charSize, 0.35, 0.7, 0.32, 0.85, 0.11, 0.918)
  const newRightLegData2 = getLimbData(charSize, 0.55, 0.72, 0.68, 0.8, 0.605, 0.91)

  const newLeftArmData3 = getLimbData(charSize, 0.375, 0.5, 0.12, 0.675, 0.4, 0.63)
  const newRightArmData3 = getLimbData(charSize, 0.56, 0.55, 0.6, 0.72, 0.81, 0.63)
  const newLeftLegData3 = getLimbData(charSize, 0.36, 0.7, 0.33, 0.87, 0.11, 0.918)
  const newRightLegData3 = getLimbData(charSize, 0.55, 0.73, 0.705, 0.8, 0.605, 0.91)

  const newLeftArmData4 = getLimbData(charSize, 0.375, 0.5, 0.05, 0.67, 0.4, 0.66)
  const newRightArmData4 = getLimbData(charSize, 0.545, 0.54, 0.6, 0.71, 0.81, 0.595)
  const newLeftLegData4 = getLimbData(charSize, 0.36, 0.7, 0.36, 0.88, 0.11, 0.918)
  const newRightLegData4 = getLimbData(charSize, 0.55, 0.735, 0.725, 0.8, 0.605, 0.91)

  const newLeftArmData5 = getLimbData(charSize, 0.38, 0.5, 0.015, 0.665, 0.4, 0.61)
  const newRightArmData5 = getLimbData(charSize, 0.55, 0.56, 0.615, 0.68, 0.81, 0.59)
  const newLeftLegData5 = getLimbData(charSize, 0.36, 0.7, 0.34, 0.86, 0.11, 0.918)
  const newRightLegData5 = getLimbData(charSize, 0.55, 0.73, 0.71, 0.8, 0.605, 0.91)

  const leftArm = characterCard.select("#leftArm")
  limbTransition(leftArm, 0, newLeftArmData)
  limbTransition(leftArm, idleSpeed, newLeftArmData2)
  limbTransition(leftArm, idleSpeed * 2, newLeftArmData3)
  limbTransition(leftArm, idleSpeed * 3, newLeftArmData4)
  limbTransition(leftArm, idleSpeed * 4, newLeftArmData5)
  limbTransition(leftArm, idleSpeed * 5, leftArmData)

  const rightArm = characterCard.select("#rightArm")
  limbTransition(rightArm, 0, newRightArmData)
  limbTransition(rightArm, idleSpeed, newRightArmData2)
  limbTransition(rightArm, idleSpeed * 2, newRightArmData3)
  limbTransition(rightArm, idleSpeed * 3, newRightArmData4)
  limbTransition(rightArm, idleSpeed * 4, newRightArmData5)
  limbTransition(rightArm, idleSpeed * 5, rightArmData)

  const leftLeg = characterCard.select("#leftLeg")
  limbTransition(leftLeg, 0, newLeftLegData)
  limbTransition(leftLeg, idleSpeed, newLeftLegData2)
  limbTransition(leftLeg, idleSpeed * 2, newLeftLegData3)
  limbTransition(leftLeg, idleSpeed * 3, newLeftLegData4)
  limbTransition(leftLeg, idleSpeed * 4, newLeftLegData5)
  limbTransition(leftLeg, idleSpeed * 5, leftLegData)

  const rightLeg = characterCard.select("#rightLeg")
  limbTransition(rightLeg, idleSpeed, newRightLegData2)
  limbTransition(rightLeg, idleSpeed * 2, newRightLegData3)
  limbTransition(rightLeg, idleSpeed * 3, newRightLegData4)
  limbTransition(rightLeg, idleSpeed * 4, newRightLegData5)
  limbTransition(rightLeg, idleSpeed * 5, rightLegData)

  // setTimeout(() => {
  //   if (loopBool) {
  //     idleAnimation(currentId)
  //   }
  // }, idleSpeed * 5.5)
}

export {
  snapBackToGuard,
  basicIdleAnimation
}