import { palette } from '../design-map/color-palette'
import * as d3 from 'd3'

const xMapping = d => d.x
const yMapping = d => d.y

const getChartDimensions = (id, metadata) => {
  const chartContainer = d3.select(id)
  const chartHeight = parseInt(chartContainer.style("height"))
  const chartWidth = parseInt(chartContainer.style("width"))
  const innerWidthPadding = metadata.margin.left - metadata.margin.right - metadata.labelPadding.y * 2
  const innerHeightPadding = metadata.margin.top - metadata.margin.bottom - metadata.labelPadding.x
  const innerWidth = chartWidth * (1 + innerWidthPadding)
  const innerHeight = chartHeight * (1 + innerHeightPadding)
  return [chartWidth, chartHeight, innerWidth, innerHeight]
}

const getAxis = (data, innerWidth, innerHeight, chartPadding) => {
  if (chartPadding === undefined) {
    chartPadding = { x: 0, y: 0 }
  }
  const xRange = d3.extent(data, xMapping)
  const yRange = d3.extent(data, yMapping)
  const xScale = d3.scaleLinear()
    .domain([xRange[0] * (1 - chartPadding.x), xRange[1] * (1 + chartPadding.x)])
    .range([0, innerWidth])
    .nice()
  const yScale = d3.scaleLinear()
  .domain([yRange[0] * (1 + chartPadding.y), yRange[1] * (1 - chartPadding.y)])
    .range([innerHeight, 0])
    .nice()

  const xAxis = d3.axisBottom(xScale)
    .ticks(0)
    .tickSize(0)
  const yAxis = d3.axisLeft(yScale)
    .ticks(0)
    .tickSize(0)

  return [xScale, yScale, xAxis, yAxis]
}

const addAxisText = (yAxisG, xAxisG, innerWidth, innerHeight, metadata) => {
  yAxisG.append('text')
    .attr('class', 'axis-label')
    .attr('y', -innerWidth * metadata.labelPadding.y / 2)
    .attr('x', -innerHeight / 2)
    .attr("font-size", "18px")
    .attr("stroke", "none")
    .attr("fill", palette.typography[1])
    .attr('transform', `rotate(-90)`)
    .attr('text-anchor', 'middle')
    .text(metadata.labelText.y)

  xAxisG.append('text')
    .attr('class', 'axis-label')
    .attr('y', innerHeight * metadata.labelPadding.x)
    .attr('x', innerWidth / 2)
    .attr("font-size", "18px")
    .attr("stroke", "none")
    .attr("fill", palette.typography[1])
    .text(metadata.labelText.x)
}

const setupChart = (id, renderAxis, metadata) => {
  const [chartWidth, chartHeight, innerWidth, innerHeight] = getChartDimensions(id, metadata)
  var chart = d3.select(id)
    // .classed("svg-responsive__container", true)
      .append("svg")
      .style("overflow", "visible")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", `0 0 ${chartWidth} ${chartHeight}`)
      // .classed("svg-responsive__content", true)

  const data = [{x: 0, y: 0}]
  const horizontalTranslation = renderAxis ? metadata.margin.left + metadata.labelPadding.y : metadata.margin.left
  const g = chart.append('g').attr("class", "chart-container")
    .attr("transform", `translate(${horizontalTranslation * chartWidth}, ${metadata.margin.top * chartHeight})`)

  const [,,xAxis, yAxis] = getAxis(data, innerWidth, innerHeight, metadata.chartPadding)
  const yAxisG = g.append('g')
    .attr("class", "y-axis")
    .call(yAxis)
  const xAxisG = g.append('g')
    .attr("class", "x-axis")
    .call(xAxis)
    .attr('transform', `translate(0,${innerHeight})`)
  
  if (metadata.labelText !== undefined) {
    addAxisText(yAxisG, xAxisG, innerWidth, innerHeight, metadata)
  }
}

export {
  xMapping,
  yMapping,
  getChartDimensions,
  getAxis,
  setupChart
}