import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../../Store'
import { useHistory, useLocation } from "react-router-dom"
import BestOfThree from '../best-of-three/BestOfThree'
import Music from '../../../website-scaffolding/Music'
import SoundFX, { playSoundEffect } from '../../sound-fx/SoundFX'
import Character from '../../../character/Character'
import HealthBar from '../../hud-components/HealthBar'
import Modal, { displayModal } from '../../../generic-components/custom-modal/CustomModal'
import SubComponentLoader from '../../../generic-components/sub-component-loader/SubComponentLoader'
import { battleMetaData } from '../../../../helpers/game-setup/battle-meta-data'
import { resetFighterPositions } from '../../../../helpers/game-setup/reset-fighter-positions'
import { resizeArena } from '../../../../helpers/game-setup/arena-styling'
import { runFightersLoadedChecker } from '../../../../helpers/game-setup/pre-battle-loading'
import { actionsList, animationFrames } from '../../../../helpers/game-setup/actions'
import { attachModel } from '../../../../helpers/game-setup/model-setup'
import { battleAssets } from '../../../../helpers/asset-files/battle-assets'
import { delayBase } from '../../../../helpers/animations/animation-helper'
import { winAnimation, loseAnimation } from '../../../../helpers/animations/gameover-animation'
import {
  adjustHealthBar,
  runLeftAnimation,
  runRightAnimation,
  jumpAnimation,
  jumpLeftAnimation,
  jumpRightAnimation,
  jumpPunchAnimation,
  singlePunchAnimation,
  doublePunchAnimation,
  lowKickAnimation,
  startDefendAnimation,
  endDefendAnimation,
  hitShieldAnimation,
  stunAnimation,
  getHitAnimation
} from '../../../../helpers/animations/battle-animation'
import {
  loadReadyFightAssets,
  renderReadyFightAnimation
} from '../../../../helpers/animations/ready-fight-animation'
import '../../battle.css'
import * as d3 from 'd3'
import axios from 'axios'
import RecordGameplay from '../../../generic-components/record-gameplay/RecordGameplay'
import download from "downloadjs"
require('dotenv').config()

// On the server side, check that the user's fighter is not for sale - if it is,
// then return an error and don't execute the battle

const Battle = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const [rewardDiv, setRewardDiv] = useState(<div></div>)
  const [readyFightSvg, setReadyFightSvg] = useState(undefined)
  const [battleLoading, setBattleLoading] = useState(false)
  const [fightersLoaded, setFightersLoaded] = useState({ "0": false, "1": false })
  const [timeRemaining, setTimeRemaining] = useState({decimal: undefined, int: undefined, maxSeconds: undefined})
  const [gameoverInfo, setGameoverInfo] = useState({popup: undefined, popupType: ""})
  const [statesToRender, setStatesToRender] = useState(undefined)
  const [score, setScore] = useState({
    round: 0,
    0: { 0: undefined, 1: undefined, 2: undefined, cumulative: 0},
    1: { 0: undefined, 1: undefined, 2: undefined, cumulative: 0}
  })
  const [opponentUsername, setOpponentUsername] = useState("")
  const history = useHistory()
  const location = useLocation()

  const backend = process.env.REACT_APP_BACKEND

  const yourFighter = location.state.yourFighter
  const yourOpponent = location.state.yourOpponent
  const yourSide = location.state.yourSide
  const championBattle = yourFighter.renderCrown || yourOpponent.renderCrown
  const stageClass = `${championBattle ? "champion" : "battle"}-stage`
  if (championBattle) {
    battleMetaData["character1"].yOffset = "10%"
    battleMetaData["character2"].yOffset = "10%"
  }
  else {
    battleMetaData["character1"].yOffset = "12%"
    battleMetaData["character2"].yOffset = "12%"
  }

  // State and function for downloading video recording
  const [chunks, setChunks] = useState([])
  const [recording, setRecording] = useState(false)
  const downloadReplay = () => {
    if (chunks.length > 0) {
      let blob = new Blob(chunks, { 'type' : 'video/mp4;' })
      download(blob)
      setChunks([])
    }
    else {
      alert("You do not have anything to save. Next time click on the 'Record' button in the bottom menu")
    }
  }

  var allTimeouts = []

  const battleCharacters = new Array(2)
  battleCharacters[0] = yourSide === "left" ? attachModel(yourFighter) : attachModel(yourOpponent)
  battleCharacters[1] = yourSide === "left" ? attachModel(yourOpponent) : attachModel(yourFighter)

  const battleIdx = new Array(2)
  battleIdx[0] = yourSide === "left" ? yourFighter.id : yourOpponent.id
  battleIdx[1] = yourSide === "left" ? yourOpponent.id : yourFighter.id

  const getHealth = (id) => {
    if (d3.select("." + stageClass)._groups[0][0] === null) {
      d3.select("#battle-ongoing-flag").style("display", "none")
      setTimeRemaining((prevState) => {
        return {...prevState, decimal: 0, int: 0}
      })
    }
    else {
      return +d3.select(`#attacker-health${id}`).style("width").replace("%","")
    }
  }

  const getBattleBool = () => {
    const battleOngoingFlag = d3.select("#battle-ongoing-flag")
    if (battleOngoingFlag._groups[0][0] === null || battleOngoingFlag._groups[0][0] === undefined) return
    return battleOngoingFlag.style("display") === "block"
  }

  const runTheClock = (animationSpeed, lastFrame) => {
    const battleBool = getBattleBool()
    if (timeRemaining.decimal > 0 && battleBool) {
      setTimeRemaining((prevState) => {
        if (prevState.decimal > 0) {
          const decimalTime = Math.max(Math.round(prevState.decimal * 1000 - animationSpeed) / 1000, 0)
          if (lastFrame && decimalTime < 1) {
            return {...prevState, deminal: 0, int: 0}
          }
          return {...prevState, decimal: decimalTime, int: Math.ceil(decimalTime)}
        }
        else {
          return {...prevState, deminal: 0, int: 0}
        }
      })
    }
  }

  const bringToGround = (id) => {
    d3.select(id).select(".svg-responsive__content")
      .transition()
        .duration(delayBase * 4)
        .style("bottom", "10%")
        .style("top", "auto")
  }
  
  const afterGameAction = () => {
    allTimeouts.push(setTimeout(() => {
      if (score.round < 3 && score[0].cumulative < 6 && score[1].cumulative < 6) {
        setReadyFightSvg(loadReadyFightAssets())
        resetFighterPositions(battleCharacters[0].id, battleCharacters[1].id)
        getTimeLimit()
        d3.select("#battle-ongoing-flag").style("display", "block")
      }
      else {
        if (recording) {
          setRecording(false)
        }
        if (d3.select("#music-button--mute").style("display") === "none") {
          websiteContext.songPlayingHowler.pause()
        }
        if (score[0].cumulative !== score[1].cumulative) {
          if (
            (score[0].cumulative > score[1].cumulative && yourSide === "left") || 
            (score[0].cumulative < score[1].cumulative && yourSide === "right")
          ) {
            playSoundEffect("sound-fx--win")
            setGameoverInfo({popup: battleAssets.win, popupType: "Win"})
          }
          else {
            playSoundEffect("sound-fx--loss")
            setGameoverInfo({popup: battleAssets.lose, popupType: "Lose"})
          }
        }
        else {
          playSoundEffect("sound-fx--draw")
          setGameoverInfo({popup: battleAssets.tie, popupType: "Tie"})          
        }
        displayModal()
      }
      
    }, 2500))
  }

  useEffect(() => {
    // const battleBool = d3.select("#battle-ongoing-flag").style("display") === "block"
    // if (battleBool && score.round > 0 && timeRemaining.int === "60") {
    if (score.round > 0 && timeRemaining.int === "60") {
      allTimeouts.push(setTimeout(() => {
        renderBattle(statesToRender[score.round])
      }, 1500))
    }
  }, [timeRemaining])

  const declareWinner = (healthPlayer1, healthPlayer2) => {
    bringToGround(`#character${battleCharacters[0].id}`)
    bringToGround(`#character${battleCharacters[1].id}`)
    const battleBool = getBattleBool()
    if (battleBool) {
      if (healthPlayer1 !== healthPlayer2) {
        const winner = healthPlayer1 > healthPlayer2 ? 1 : 2
        if ((winner === 1 && yourSide === "left") || (winner === 2 && yourSide === "right")) {
          allTimeouts.push(setTimeout(() => {
            allTimeouts = winAnimation(yourFighter.id, 1, allTimeouts)
            allTimeouts = loseAnimation(yourOpponent.id, allTimeouts)            
          }, 500))
        }
        else {
          allTimeouts.push(setTimeout(() => {
            allTimeouts = loseAnimation(yourFighter.id, allTimeouts)
            allTimeouts = winAnimation(yourOpponent.id, 1, allTimeouts)
          }, 500))
        }
        setScore((prevState) => { 
          const player1Points = winner === 1 ? 3 : 0
          const player2Points = winner === 2 ? 3 : 0
          prevState[0][prevState.round] = winner === 1 ? "win" : "loss"
          prevState[1][prevState.round] = winner === 1 ? "loss" : "win"
          return { 
            0: {...prevState[0], cumulative: prevState[0].cumulative + player1Points}, 
            1: {...prevState[1], cumulative: prevState[1].cumulative + player2Points}, 
            round: prevState.round + 1 
          } 
        })
      }
      else {
        allTimeouts.push(setTimeout(() => {
          allTimeouts = loseAnimation(yourFighter.id, allTimeouts)
          allTimeouts = loseAnimation(yourOpponent.id, allTimeouts)
        }, 500))
        setScore((prevState) => { 
          prevState[0][prevState.round] = "tie"
          prevState[1][prevState.round] = "tie"
          return { 
            0: {...prevState[0], cumulative: prevState[0].cumulative + 1}, 
            1: {...prevState[1], cumulative: prevState[1].cumulative + 1}, 
            round: prevState.round + 1 
          } 
        })
      }      
      d3.select("#battle-ongoing-flag").style("display", "none")
    }
  }

  const animatePlayer = (currentCharacter, animationType, xPositions, yPositions, prevShieldHealth, currentShieldHealth) => {
    if (d3.select("." + stageClass)._groups[0][0] !== null) {
      const currentIdNumber = currentCharacter.id
      const shieldHit = prevShieldHealth !== currentShieldHealth
      if (!animationType.includes("Defend") && !animationType.includes("Shield")) {
        d3.select(`#character${currentIdNumber}`).selectAll(".shield-icon").attr("display", "none")
      }

      var animationSpeed
      if (animationType === "Run Left") {
        [animationSpeed, allTimeouts] = runLeftAnimation(currentIdNumber, xPositions, allTimeouts)
      }
      else if (animationType === "Run Right") {
        [animationSpeed, allTimeouts] = runRightAnimation(currentIdNumber, xPositions, allTimeouts)
      }
      else if (animationType === "Single Punch") {
        [animationSpeed, allTimeouts] = singlePunchAnimation(currentIdNumber, allTimeouts)
      }
      else if (animationType === "Double Punch") {
        [animationSpeed, allTimeouts] = doublePunchAnimation(currentIdNumber, allTimeouts)
      }
      else if (animationType === "Start Defend") {
        [animationSpeed, allTimeouts] = startDefendAnimation(currentIdNumber, allTimeouts)
      }
      else if (animationType === "Continue Defend") {
        animationSpeed = animationFrames[animationType] * delayBase
      }
      else if (animationType === "Hit Shield") {
        [animationSpeed, allTimeouts] = hitShieldAnimation(currentIdNumber, prevShieldHealth, currentShieldHealth, allTimeouts)
      }
      else if (animationType === "End Defend") {
        [animationSpeed, allTimeouts] = endDefendAnimation(currentIdNumber, 0, shieldHit, allTimeouts)
      }
      else if (animationType === "Stun") {
        [animationSpeed, allTimeouts] = stunAnimation(currentIdNumber, 0, allTimeouts)
      }
      else if (animationType === "Jump") {
        [animationSpeed, allTimeouts] = jumpAnimation(currentIdNumber, yPositions, allTimeouts)
      }
      else if (animationType === "Jump Left") {
        [animationSpeed, allTimeouts] = jumpLeftAnimation(currentIdNumber, xPositions, yPositions, allTimeouts)
      }
      else if (animationType === "Jump Right") {
        [animationSpeed, allTimeouts] = jumpRightAnimation(currentIdNumber, xPositions, yPositions, allTimeouts)
      }
      else if (animationType === "Jump Punch") {
        [animationSpeed, allTimeouts] = jumpPunchAnimation(currentIdNumber, yPositions, allTimeouts)
      }
      else if (animationType === "Get Hit") {
        [animationSpeed, allTimeouts] = getHitAnimation(currentIdNumber, allTimeouts)
        bringToGround(`#character${currentIdNumber}`)
      }
      else if (animationType === "Low Kick") {
        [animationSpeed, allTimeouts] = lowKickAnimation(currentIdNumber, allTimeouts)
      }
      return animationSpeed
    }
  }

  var startTime
  const animationFrameTracker = {0: 0, 1: 0}
  const expectedTimeElapsed = {0: 0, 1: 0}
  const drifts = {0: 0, 1: 0}

  const animationLoop = (whichFighter, animationSpeed, states, idx) => {
    if (idx >= states.length) return

    const { action, health, x, y, modelState, animationFrame } = states[idx]
    allTimeouts.push(setTimeout(() => {
      const battleBool = getBattleBool()
      if (battleBool) {
        // This adjusts the position for non-moving actions, but when the shield is used
        if (idx === 0 || x[0] !== states[idx-1].x[states[idx-1].x.length-1]) {
          if (!action.includes("Left") && !action.includes("Right")) {
            d3.select(`#character${battleCharacters[whichFighter].id}`)
              .transition()
                .duration(delayBase)
                .ease(d3.easeLinear)
                .style("left", x[0] * 100 + "%")
          }
        }

        animationFrameTracker[whichFighter] = animationFrame
        expectedTimeElapsed[whichFighter] = animationFrame * delayBase
        const actualTimeElapsed = Date.now() - startTime
        drifts[whichFighter] = actualTimeElapsed - expectedTimeElapsed[whichFighter]
        const relativeDrift = drifts[whichFighter] - drifts[(whichFighter + 1) % 2]

        var prevShieldHealth = states[idx].shieldHealth
        const currentShieldHealth = states[idx].shieldHealth
        if (idx > 0) {
          prevShieldHealth = states[idx-1].shieldHealth
        }

        const otherFighterIdx = (whichFighter + 1) % 2
        var proposedAnimationSpeed = animatePlayer(
          battleCharacters[whichFighter],
          action,
          x,
          y,
          prevShieldHealth,
          currentShieldHealth
        )
        proposedAnimationSpeed += delayBase

        if (idx < states.length - 1) {
          if (states[idx+1].action === "Get Hit" || states[idx+1].action === "Hit Shield") {
            proposedAnimationSpeed = (states[idx+1].framesBeforeHit) * delayBase
          }
        }

        const lastFrame = idx === states.length - 1
        if (whichFighter === 0) runTheClock(proposedAnimationSpeed, lastFrame)

        // We subtract the drift after running the clock to make sure the clock is accurate
        proposedAnimationSpeed -= Math.min(relativeDrift, 0)

        if (action === "Get Hit") {
          adjustHealthBar(battleCharacters[whichFighter].id, health * 100, delayBase * 4)
        }

        var healthPlayer1 = getHealth(battleIdx[0])
        var healthPlayer2 = getHealth(battleIdx[1])
        if (whichFighter === 0) {
          healthPlayer1 = d3.min([healthPlayer1, health])
        }
        else if (whichFighter === 1) {
          healthPlayer2 = d3.min([healthPlayer2, health])
        }

        if (healthPlayer1 === 0 || healthPlayer2 === 0) {
          declareWinner(healthPlayer1, healthPlayer2)
        }
        else {
          animationLoop(whichFighter, proposedAnimationSpeed, states, idx + 1)
        }
      }
    }, animationSpeed))
  }


  const renderBattle = (animationStates) => {
    console.log(animationStates)
    const whichRoundVisual = d3.select("#which-round-visual")
    if (whichRoundVisual._groups[0][0] !== null && whichRoundVisual._groups[0][0] !== undefined) {
      whichRoundVisual.style("display", "flex")
      allTimeouts.push(setTimeout(() => {
        whichRoundVisual.style("display", "none")
        setTimeout(() => {
          playSoundEffect("sound-fx--start-fight")
        }, 200)
        renderReadyFightAnimation(readyFightSvg)
        allTimeouts.push(setTimeout(() => {
          startTime = new Date()
          animationLoop(0, 0, animationStates[0], 0)
          animationLoop(1, 0, animationStates[1], 0)
        }, 50 * 60 + 550))
      }, 1500))
    }
  }

  // const postBattleRequest = (flattenedWeights) => {    
  //   setBattleLoading(true)
  //   axios.post(
  //     `${backend}battle/run-battle`, {
  //       yourFlattenedWeights: flattenedWeights,
  //       yourId: yourFighter.id,
  //       opponentId: yourOpponent.id,
  //       yourSide: yourSide,
  //       threeBattlesBool: true,
  //       network: websiteContext.networkName
  //     }
  //   )
  //   .then(response => {
  //     setBattleLoading(false)
  //     console.log(response.data.states)
  //     setStatesToRender(response.data.states)
  //     renderBattle(response.data.states[0])
  //   })
  //   .catch((err) => {
  //     console.log(err.response.data)
  //   })
  // }

  const runBattle = () => {
    d3.select("#battle-ongoing-flag").style("display", "block")
    setBattleLoading(false)
    setStatesToRender(location.state.states)
    renderBattle(location.state.states[0])
  }

  async function getTimeLimit() {
    const gameTimeLimit = await axios.get(`${backend}battle/game-time-limit`)
    const maxSeconds = gameTimeLimit.data.seconds.toString()
    setTimeRemaining({
      decimal: maxSeconds, 
      int: maxSeconds,
      maxSeconds: maxSeconds
    })
  }

  async function getOpponentUsername() {
    // const opponentUsernameResult = await axios.get(
    //   `${backend}research-users/username?address=${yourOpponent.owner}`
    // )
    // const tempOpponentUsername = opponentUsernameResult.data[0].username
    // setOpponentUsername(tempOpponentUsername !== "" ? tempOpponentUsername : yourOpponent.owner )
    setOpponentUsername(yourOpponent.owner)
  }



  useEffect(() => {
    getTimeLimit()
    getOpponentUsername()
    setReadyFightSvg(loadReadyFightAssets())
    runFightersLoadedChecker(battleCharacters, setFightersLoaded)
    resizeArena()    

    window.addEventListener("resize", resizeArena)
    return () => {
      window.removeEventListener("resize", resizeArena)
      d3.select("#battle-ongoing-flag").style("display", "none")
      setTimeRemaining((prevState) => {
        return {...prevState, decimal: 0, int: 0}
      })
      var currentTimeout;
      while (allTimeouts.length > 0) {
        currentTimeout = allTimeouts.pop()
        clearTimeout(currentTimeout)
      }
    }
  }, [])

  useEffect(() => {
    if (
      score.round === 0 &&
      readyFightSvg !== undefined && 
      timeRemaining.maxSeconds !== undefined
    ) {
      runBattle()
    }
  }, [readyFightSvg, timeRemaining.maxSeconds])

  useEffect(() => {
    const battleBool = getBattleBool()
    if (battleBool && timeRemaining.int === 0) {
      const healthPlayer1 = getHealth(battleIdx[0])
      const healthPlayer2 = getHealth(battleIdx[1])
      declareWinner(healthPlayer1, healthPlayer2)
    }
  }, [timeRemaining])

  useEffect(() => {
    if (score.round > 0) {
      afterGameAction()
    }    
  }, [score])

  const routeLeaderboard = () => {
    d3.select("#battle-ongoing-flag").style("display", "none")
    setTimeRemaining((prevState) => {
      return {...prevState, decimal: 0, int: 0}
    })
    history.push("/ranking")
  }

  const eloStatement = (
    <h3>
      Your ELO rating has changed by <span style={{
        color: location.state.ratingDelta >= 0 ? "green" : "red"
        }}>
        {Math.round(location.state.ratingDelta * 100) / 100}
      </span> points.
    </h3>
  )
  const winningStatement = (
    <div id="winning-statement">
      <h2>
        Congratulations on the win, it looks like your training has paid off!
      </h2>
      {eloStatement}
    </div>
  )
  const losingStatement = (
    <div id="losing-statement">
      <h2>
      Looks like your fighter can use some more training!
      </h2>
      {eloStatement}
    </div>
  )

  const getModalSubContent = () => {
    const winBool = gameoverInfo.popupType === "Win";
    const loseBool = gameoverInfo.popupType === "Lose";
    const tieBool = gameoverInfo.popupType === "Tie";
    return (
      <div className="modal-game-over">        
        {winBool && winningStatement}
        {(loseBool || tieBool) && losingStatement}
        <div 
          className="model-game-over__buttons-container"
          style={{top: "80%"}}>
          <div 
            className="model-game-over__button"            
            onClick={() => routeLeaderboard()}>
            <p>GO BACK</p>
          </div>
          {
            chunks.length > 0 &&
            <div 
              className="model-game-over__button"
              onClick={() => downloadReplay()}>
              <p>SAVE REPLAY</p>
            </div>
          }
        </div>
      </div>
    )
  }

  const modalSubContent = getModalSubContent()
  const additionalModalStyling = {
    width: "300px",
    height: "230px",
  }

  const resultsModalData = {
    modalSubContent: modalSubContent,
    additionalModalStyling: additionalModalStyling
  }

  return (
    <>
      <SoundFX></SoundFX>
      <div id="battle-ongoing-flag" style={{ display: "none" }}></div>
      <div id="battle-screen__container" className="gameplay-screen__container">
        <div id="gameplay-menu--battle" className="gameplay-menu--horizontal">
          <div className="icon-text__container">
            <img className="exit-button"
                 src={battleAssets.exit}
                 alt="Exit"
                 onClick={() => routeLeaderboard()}/>
            <h2 style={{
              color: "white",
              transform: "translateY(3px)",
              marginTop: "5px"
            }}>Exit</h2>
          </div>

          <div className="icon-text__container">
            <Music startMusicOn={!websiteContext.musicMuted}></Music>
            <h2 style={{
              color: "white",
              transform: "translateY(3px)",
              marginTop: "5px"
            }}>Music</h2>
          </div>

          <div className="icon-text__container" id="record-gameplay__container">
            <RecordGameplay 
              recording={recording} 
              setRecording={setRecording} 
              setChunks={setChunks}>  
            </RecordGameplay>
            <h2 style={{
              color: "white",
              transform: "translateY(3px)",
              marginTop: "5px"
            }}>{!recording ? "Record" : "Stop"}</h2>
          </div>
        </div>

        <div className={stageClass} id="battle-arena">
          {
            battleLoading && statesToRender === undefined &&
            <SubComponentLoader positioning="absolute"></SubComponentLoader>
          }

          <div className="battle-container">
            <div id="ready-fight-animation"></div>

            <div className="clock">
              <h2>{timeRemaining.int}</h2>
            </div>

            <div id="fighter-owner--left" className="fighter-owner__container">
              <p>Owner: </p>
              <h3>{yourSide === "left" ? websiteContext.username : opponentUsername}</h3>
            </div>
            <HealthBar character={battleCharacters[0]} attackerId="0" mirror={false}></HealthBar>
            <BestOfThree fighterIdx="0" score={score[0]} currentRound={score.round}></BestOfThree>
            <Character {...battleCharacters[0]} {...battleMetaData["character1"]} size="1"></Character>

            <div id="fighter-owner--right" className="fighter-owner__container">
              <p>Owner: </p>
              <h3>{yourSide === "right" ? websiteContext.username : opponentUsername}</h3>
            </div>
            <HealthBar character={battleCharacters[1]} attackerId="1" mirror={true}></HealthBar>
            <BestOfThree fighterIdx="1" score={score[1]} currentRound={score.round}></BestOfThree>
            <Character {...battleCharacters[1]} {...battleMetaData["character2"]} size="1"></Character>
          </div>
          <Modal {...resultsModalData}></Modal>

          <div id="which-round-visual">
            <h1>ROUND {score.round + 1}</h1>
          </div>

        </div>
      </div>
    </>
  )
}


export default Battle
