import React from "react"

const ColoredTextBox = ({ text, border, background, additionalClass, customStyling, fontSizeStyling }) => {
  customStyling = customStyling !== undefined ? customStyling : {}
  fontSizeStyling = fontSizeStyling !== undefined ? fontSizeStyling : {}
  return (
    <div 
      className={`colored-text-box ${additionalClass}`}
      style={{
        backgroundColor: background, 
        minWidth: customStyling.width,
        ...customStyling 
      }}>
        <p style={{ color: border, ...fontSizeStyling }}>{text}</p>
    </div>
  )
}

export default ColoredTextBox
