import React, { useContext } from "react"
import { Context } from "../../../../Store"
import Modal from '../../../generic-components/custom-modal/CustomModal'
import ModalCloseButton from '../../../generic-components/modal-close-button/ModalCloseButton'
import ColoredTextBox from '../../../generic-components/colored-text-box/ColoredTextBox'
import { actionIconAssets } from '../../../../helpers/asset-files/action-icons-assets'
import './match-rounds.css'

const MatchRounds = ({ roundsPerformance, matchSummaryStats, resultColors, gradientWinLoss }) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)

  // -100 is the number for tie
  const resultToWord = {"-100": "tied", "-1": "lost"}
  if (websiteContext.yourFighter !== undefined) {
    resultToWord[websiteContext.yourFighter.id] = "won"
  }

  const resultContainerColors = {
    "won": "linear-gradient(90deg, #4b76ba, #49acc3)", 
    "lost": "linear-gradient(90deg, #6f446f, #993b5f)",
    "tied": `linear-gradient(90deg, ${gradientWinLoss[1]}, ${gradientWinLoss[1]})`
  }

  const coloredBoxStyling = {
    height: "12px",
    width: "40px",
    borderRadius: "3px",
    borderWidth: "1.5px",
  }
  const coloredTextStyling = {
    fontSize: "12px"
  }

  const modalId = `benchmark-rounds-modal--${matchSummaryStats.benchmark}`

  const closeFunction = () => {
    var modal = document.getElementById(modalId)
    if (modal !== null) {
      modal.style.display = "none"
    }
  }

  const modalSubContent = (
    <div 
      className="match-rounds__container"
      id={`match-rounds__container--${matchSummaryStats.benchmark}`}>
      <p>Benchmark {matchSummaryStats.benchmark+1} Rounds</p>
      <ModalCloseButton closeFunction={closeFunction}></ModalCloseButton>
      <div id="match-rounds__subcontainer">
        {
          resultToWord[roundsPerformance[0].id1] !== undefined &&
          roundsPerformance.map((currentRound, round) => {
            const currentResult = resultToWord[currentRound.winner]
            const favouriteMove = Object.keys(currentRound.actions).reduce(
              (a, b) => currentRound.actions[a].numExecuted > currentRound.actions[b].numExecuted ? a : b
            )
            const actionDominance = matchSummaryStats.actionDominanceRounds[round]

            const fighter1Bool = websiteContext.yourFighter.id.toString() === currentRound.id1.toString()
            const fighter1Left = currentRound.fighter1Side === "left"
            const yourSide = fighter1Bool === fighter1Left ? "left" : "right"

            const yourHealth = yourSide === "left" ? currentRound.healthLeft : currentRound.healthRight
            const opponentHealth = yourSide === "left" ? currentRound.healthRight : currentRound.healthLeft

            return (
              <div 
                className="match-round__aggregate-container" 
                style={{backgroundImage: resultContainerColors[currentResult]}}
                key={`round-${round}`}>    

                <div className="match-round__fighter-container">
                  <p className="match-round__title match-round__round-indicator">{round+1}</p>
                  <ColoredTextBox 
                    text={currentResult.toUpperCase()}
                    border={resultColors[currentResult].border}
                    background={resultColors[currentResult].background}
                    additionalClass="match-summary__result-box"
                    customStyling={coloredBoxStyling}
                    fontSizeStyling={coloredTextStyling}>
                  </ColoredTextBox>
                </div>

                <div className="match-round__column" style={{alignItems: "flex-start"}}>
                  <div 
                    className="match-round__title-content" 
                    style={{alignItems: "flex-start"}}>
                      <p className="match-round__title">Finishing Move</p>
                      <p className="match-round__content">{currentRound.finishingMove}</p>
                  </div>
                  <div 
                    className="match-round__title-content" 
                    style={{alignItems: "flex-start"}}>
                      <p className="match-round__title">Duration</p>
                      <p className="match-round__content">
                        {Math.round(currentRound.duration) + " seconds"}
                      </p>
                  </div>
                </div>

                <div className="match-round__action-dominance">
                    {
                      Object.keys(actionDominance).map((group) => {
                        return (
                          <div key={`${group}-${round}`} className="match-round__action-dominance--row">
                            <img src={actionIconAssets[group]} alt="" />
                            <p className="match-round__content">
                              {Math.round(actionDominance[group] * 100)}%
                            </p>
                          </div>
                        )
                      })
                    }
                </div>
          
                <div className="match-round__column_two">
                  <div 
                    className="match-round__title-content">
                      <p className="match-round__title">Attacks Landed</p>
                      <p className="match-round__content">
                        {matchSummaryStats.attacksLanded[round] + 
                        "/" + 
                        matchSummaryStats.attacksExecuted[round]}
                      </p>
                  </div>
                  <div 
                    className="match-round__title-content">
                      <p className="match-round__title">Favorite Move</p>
                      <p className="match-round__content">{favouriteMove}</p>
                  </div>
                </div>

                <div className="match-round__style-health">
                  <div className="match-round__style-health--titles">
                    <p className="match-round__title match-round__title--health">Health</p>
                  </div>
                  <div className="match-round__style-health--row">
                    <p className="match-round__content">
                      You
                    </p>
                    <div className="match-round__health-row">
                      <div className="match-round__health-inner"
                            style={{
                              width: `${yourHealth * 100}%`, 
                            }}>
                      </div>
                    </div>
                  </div>
                  <div className="match-round__style-health--row">
                    <p className="match-round__content">
                      Opponent
                    </p>
                    <div className="match-round__health-row">
                      <div className="match-round__health-inner"
                            style={{
                              width: `${opponentHealth * 100}%`, 
                            }}>
                      </div>
                    </div>
                  </div>
                </div>
          
              </div>
            )
          })
        }
      </div>
    </div>
  )
  const additionalModalStyling = {
    width: "700px",
    height: "500px",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(74, 88, 144, 0.5)",
    borderRadius: "10px",
  }
  const modalData = {
    modalSubContent: modalSubContent,
    additionalModalStyling: additionalModalStyling,
    modalId: modalId
  }

  return <Modal {...modalData}></Modal>
}

export default MatchRounds
