import * as d3 from 'd3'
import {
  xMapping,
  yMapping,
  getChartDimensions,
  getAxis,
  addAxisText
} from './chart-setup'
import { addHorizontalSeperators } from './chart-overlays'
import { palette } from '../design-map/color-palette'
import { displayTooltip, closeTooltip } from '../../components/generic-components/tooltip/Tooltip'

const [,lightBlue, darkBlue, lightPink, darkPink] = palette["typography"]

const addText = (text, axis, yPosition, color) => {
  axis.append('text')
    .attr('class', 'axis-label')
    .attr('y', yPosition + 3)
    .attr('x', -10)
    .attr("font-size", "12px")
    .attr("stroke", "none")
    .attr("fill", color)
    .text(text)
}

const renderLineChart = (id, data, metadata) => {
  const chartPadding = { x: 0, y: -0.002 }
  const [,, innerWidth, innerHeight] = getChartDimensions(id, metadata)
  const [xScale, yScale, xAxis, yAxis] = getAxis(data, innerWidth, innerHeight, chartPadding)

  const lineGenerator = d3.line()
    .x(d => xScale(xMapping(d)))
    .y(d => yScale(yMapping(d)))
    .curve(d3.curveBasis)

  const g = d3.select(id).select(".chart-container")
  g.selectAll(".line-path").remove()
  g.selectAll(".y-axis")
    .select(".domain")
      .remove()
  g.select(".x-axis")
    .select(".domain")
      .style("stroke", "#ddd")
      .style('stroke-width', 0.5)

  const domain = d3.extent(data, (d) => { return d.y })
  addText(1500, g.selectAll(".y-axis"), yScale(1500), "#bbb")
  if (domain[0] < 1500) {
    addText(parseInt(domain[0]), g.selectAll(".y-axis"), yScale(domain[0]), "#bbb")
  }
  if (domain[1] > 1500) {
    addText(parseInt(domain[1]), g.selectAll(".y-axis"), yScale(domain[1]), "#bbb")
  }

  const fontSize = "14px"
  g.selectAll(".x-axis")
    .select("text")
    .attr("font-size", fontSize)
  g.selectAll(".y-axis")
    .select("text")
    .attr("font-size", fontSize)
    .attr("y", -innerWidth * metadata.labelPadding.y)

  if (metadata.horizontalSeperators !== undefined) {
    const areaGenerator = d3.area()
      .x(d => xScale(xMapping(d)))
      .y0(d => yScale(d.yBottom))
      .y1(d => yScale(d.yTop))
      .curve(d3.curveBasis)

    const xRange = d3.extent(data, xMapping)
    const yRange = d3.extent(data, yMapping)
    addHorizontalSeperators(
      g, 
      metadata.horizontalSeperators, 
      xRange, 
      yRange, 
      lineGenerator, 
      areaGenerator
    )
  }

  g.append('path')
    .attr('class', 'line-path')
    .style("fill", "none")
    .style('stroke-width', 1.5)
    .style('stroke', data[data.length-1].y >= 1500 ? lightBlue : darkPink)
    .attr('d', lineGenerator(data))
}

const renderScatterPlot = (id, data, metadata) => {
  const [,, innerWidth, innerHeight] = getChartDimensions(id, metadata)
  const [xScale, yScale,,] = getAxis(data, innerWidth, innerHeight, metadata.chartPadding)

  d3.select(id).selectAll("circle").remove()
  const g = d3.select(id).select(".chart-container")

  g.append('g')
    .selectAll("dot")
    .data(data)
    .enter()
    .append("circle")
      .attr("class", "scatter-plot-circle")
      .attr("cx", function (d) { return xScale(xMapping(d)) } )
      .attr("cy", function (d) { return yScale(yMapping(d)) } )
      .attr("r", 4)
      .style("fill", darkBlue)
      .on("mouseover", function (d) {
        const sourceData = d.srcElement.__data__

        const chartContainer = d3.select("#benchmark-chart")

        const leftPadding = 0.075
        const rightPadding = 0.035
        const topPadding = 0.065
        const bottomPadding = 0.115

        const chartViewBox = chartContainer.select("svg").attr("viewBox").split(" ")
        const initialWidth = parseInt(chartViewBox[2])
        const initialHeight = parseInt(chartViewBox[3])

        const tooltipPosition = {
          left: (leftPadding + (d.srcElement.cx.baseVal.value * (1 - rightPadding)) / (initialWidth)) * 100 + "%",
          top: (topPadding + (d.srcElement.cy.baseVal.value * (1 - bottomPadding)) / (initialHeight)) * 100 + "%"
        }

        // console.log(d.srcElement.cx.baseVal.value, horizontalPadding, leftPadding, containerWidth)
        
        d3.select("#tooltip__scatter--date")
          .node().innerHTML = `Date: ${sourceData.x.toISOString().split('T')[0]}`
        d3.select("#tooltip__scatter--id")
          .node().innerHTML = `Id: ${sourceData.id}`
        d3.select("#tooltip__scatter--rank")
          .node().innerHTML = `Rank: #${sourceData.rank}`
        d3.select("#tooltip__scatter--score")
          .node().innerHTML = `Score: ${sourceData.y}`


        displayTooltip(metadata.tooltipId, tooltipPosition)
        d3.select(this)
          .attr("r", 8)
          .style("fill", lightPink)
      })
      .on("mouseout", function () {
        closeTooltip(metadata.tooltipId)
        d3.select(this)
          .attr("r", 4)
          .style("fill", darkBlue)
      })
}

const renderStackedBarChart = (id, data, metadata) => {
  const innerWidthPadding = metadata.margin.left - metadata.margin.right - metadata.labelPadding.y * 2
  const innerHeightPadding = metadata.margin.top - metadata.margin.bottom - metadata.labelPadding.x
  const [,, innerWidth, innerHeight] = getChartDimensions(id, metadata)

  const g = d3.select(id).select(".chart-container")

  const groups = Object.keys(data)
  const subgroups = Object.keys(data[groups[0]])

  const iterableData = groups.map((action) => { return {action: action, ...data[action]} })
  const stackedData = d3.stack().keys(subgroups)(iterableData)

  var x = d3.scaleBand()
    .domain(groups)
    .range([0, innerWidth])
    .padding([0.2])
  var y = d3.scaleLinear()
    .domain([0, metadata.maxValue])
    .range([ innerHeight, 0 ])
    .nice()   
    
  const xAxisG = g.append("g")
    .attr("transform", "translate(0," + innerHeight + ")")
    .call(d3.axisBottom(x).tickSize(0).tickSizeOuter(0));

  xAxisG.select(".domain").style("color", "#ddd")
  d3.select(".y-axis").style("display", "none")

  xAxisG.selectAll("text")
    .attr("y", 5)
    .attr("x", -5)    
    .attr("dy", ".35em")
    .attr("transform", "rotate(-45)")
    .style("text-anchor", "end")

  g.selectAll("text").attr("font-size", `${window.innerWidth / 1000}em`)

  // xAxisG.selectAll("text")
  //   .attr("y", 10)
  //   .attr("x", -5)    
  //   .attr("dy", ".5em")
  //   .style("text-anchor", "center")

  // xAxisG.selectAll("text")._groups[0].forEach((label, idx) => {
  //   if (idx % 2 !== 0) {
  //     label.setAttribute("y", 25)
  //   }
  // })

  // g.selectAll("text").attr("font-size", `${window.innerWidth / 1200}em`)

  var colorScale = d3.scaleOrdinal()
    .domain(subgroups)
    .range([palette.typography[2], palette.typography[3]])

  g.append("g")
    .selectAll("g")
    .data(stackedData)
    .enter()
      .append("g")
      .attr("fill", function(d) { return colorScale(d.key); })
      .selectAll("rect")
      .data(function(d) { return d; })
      .enter().append("rect")
        .attr("class", function(d) { 
          const classAppend = d.data.action.split(" ").join("-")
          return `stacked-bar--${classAppend}` 
        })
        .attr("x", function(d) { return x(d.data.action); })
        .attr("y", function(d) { return y(d[1]); })
        .attr("height", function(d) { return y(d[0]) - y(d[1]); })
        .attr("width", x.bandwidth())
        .style("cursor", "pointer")
        .on("mouseover", function (d) {
          const sourceData = d.srcElement.__data__
          const totalAttempts = sourceData.data.successful + sourceData.data.unsuccessful

          const tooltip = d3.select("#" + metadata.tooltipId)
          const tooltipWidth = parseInt(tooltip.style("width").replace("px", ""))
          const tooltipHeight = parseInt(tooltip.style("height").replace("px", ""))

          const containerWidth = d3.select("#model-page__charts--stacked-bar").style("width")
          const containerHeight = d3.select("#model-page__charts--stacked-bar").style("height")

          const containerWidthInt = parseInt(containerWidth.replace("px", ""))
          const containerHeightInt = parseInt(containerHeight.replace("px", ""))
          const containerInnerWidth = containerWidthInt * (1 + innerWidthPadding) * 1.1
          const containerInnerHeight = containerHeightInt * (1 + innerHeightPadding) * 0.85

          const tooltipPosition = {
            left: (
              (
                Math.max(
                  Math.min(x(sourceData.data.action), containerInnerWidth - tooltipWidth), 
                  tooltipWidth / 2
                )
             ) / containerInnerWidth - innerWidthPadding
            ) * 100 + "%",
            top: (
              (
                Math.max(
                  Math.min(tooltipHeight / 2 + y(totalAttempts), containerInnerHeight - tooltipHeight / 2),
                  0
                )
              ) / containerInnerHeight
              ) * 100 + "%"
          }
          
          d3.select("#tooltip__stackedbar--action")
            .node().innerHTML = `${sourceData.data.action}`
          d3.select("#tooltip__stackedbar--successful")
            .node().innerHTML = `Successful: ${sourceData.data.successful}`
          d3.select("#tooltip__stackedbar--attempts")
            .node().innerHTML = `Attempts: ${totalAttempts}`

          displayTooltip(metadata.tooltipId, tooltipPosition)

          const classAppend = sourceData.data.action.split(" ").join("-")
          d3.selectAll(`.stacked-bar--${classAppend}`)
            .style("opacity", 1)
        })
        .on("mouseout", function () {
          closeTooltip(metadata.tooltipId)
          d3.selectAll("rect")
            .style("opacity", 1)
        })
}

const renderChart = (id, data, metadata) => {
  var renderFunction
  if (metadata.chartType === "Line Chart") renderFunction = renderLineChart
  else if (metadata.chartType === "Scatter Plot") renderFunction = renderScatterPlot
  else if (metadata.chartType === "Stacked Bar Chart") renderFunction = renderStackedBarChart

  renderFunction(id, data, metadata)
}

export {
  renderChart
}
