import React, { useEffect, useRef } from 'react'
import Character from '../../character/Character'
import { healthAssets } from '../../../helpers/asset-files/health-bar-assets'
import * as d3 from 'd3'
import './health-bar.css'

const HealthBar = ({ character, attackerId, mirror, draggable, setFighterState }) => {
  const refContainer = useRef()

  const containerStyling = {
    left: attackerId === "0" ? "20px" : "",
    right: attackerId === "1" ? "20px" : "",
    top: draggable ? "20px" : "40px"
  }
  const barStyling = {
    left: attackerId === "0" ? "13%" : "",
    right: attackerId === "1" ? "13%" : "",
    backgroundImage: (
      attackerId === "0" ?
      `url(${healthAssets.containerLeft})` :
      `url(${healthAssets.containerRight})`
    )
  }
  const iconStyling = {
    left: attackerId === "0" ? "0" : "",
    right: attackerId === "1" ? "0" : "",
  }
  const characterInputs = {
    id: "icon-" + character.id,
    dna: character.dna,
    limbColor: character.limbColor,
    skinColor: character.skinColor,
    mirror: mirror,
    yOffset: "-20%",
    xOffset: "50%",
    size: 0.45,
    specialClass: "fighter-icon",
    composableMetadata: character.composableMetadata
  }

  const leftTransform = "translateX(-1%) translateY(10%)"
  const rightTransform = "translateX(1%) translateY(10%) scaleX(-1)"

  var dragHandler = d3.drag()
    .on("drag", function (event) {
      const fullBarWidth = parseInt(d3.select(".health-bar").style("width"))
      const rawHealthPercentage = event.x / fullBarWidth
      const healthPercentage = character.id !== "x" ? rawHealthPercentage : 1 - rawHealthPercentage
      const boundedHealthPercentage = Math.min(Math.max(healthPercentage, 0.02), 1)
      d3.select(this)
        .style("width", `${boundedHealthPercentage * 100}%`)
      if (setFighterState !== undefined) {
        setFighterState((prevState) => {
          return {
            ...prevState,
            health: boundedHealthPercentage
          }
        })
      }
    })

  useEffect(() => {
    if (draggable) {
      const healthBarId = refContainer.current.id
      dragHandler(d3.select(`#${healthBarId}`))
    }
  }, [])

  return (
    <>
      <div className="fighter-health" style={{...containerStyling}}>
        <div className="health-bar__container" style={{...barStyling}}>
          <div className="health-bar"
                style={{
                  float: attackerId === "0" ? "right" : "left",
                  transform: attackerId === "0" ? leftTransform : rightTransform
                }}>
            <img id={`attacker-health${character.id}`}
                 ref={refContainer}
                 src={healthAssets.fullHealth}
                 style={{width: "100%", cursor: draggable ? "ew-resize" : "auto"}}/>
          </div>
        </div>

        <div className="fighter-icon__container" style={iconStyling}>
          <div className="fighter-icon">
            <img className="fighter-icon--top" src={healthAssets.iconTop} alt=""/>
            <Character {...characterInputs}></Character>
            <img className="fighter-icon--bottom" src={healthAssets.iconBottom} alt=""/>
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthBar;
