import { dnaToDecimal } from './dna-mapping'
import * as d3 from 'd3'
require('dotenv').config()

// const assetDir = process.env.REACT_APP_IPFS_BASE_URL + process.env.REACT_APP_IPFS_DIR_HASH + "/"
const assetDir = `https://${process.env.REACT_APP_IPFS_CIDV1}.${process.env.REACT_APP_FLEEK_GATEWAY}/`

const sizeMultiples = {
  battle: 1,
  leaderboard: 0.2,
  tokenShowcase: 0.8,
  card: 0.9
}

const specialCharacters = [
  "wei",
  "dylan",
  "brandon",
  "robot",
]

const defaultCharacterWidth = 200
, defaultCharacterHeight = defaultCharacterWidth * 1.6
, characterWidth = defaultCharacterWidth * 1
, characterHeight = characterWidth * 1.433
, iconScale = d3.min([characterHeight, characterWidth])
, xBuffer = 0
, yBuffer = characterWidth * 0.18
, characterShift = 0

const attributeToDnaDivisor = {
  "Hair": 2,
  "Eyes": 3,
  "Mouth": 5,
  "Body": 7,
  "Hands": 11,
  "Feet": 13,
  "Limbs": 17,
  "Skin": 19
}
const customHairPositionSize = {
  "wei": [-0.03, -0.39, 1.07],
  "dylan": [-0.06, -0.43, 1.3],
  "brandon": [-0.05, -0.25, 1.08],
  "0": [0.02, -0.5, 1.2],
  "1": [-0.12, -0.57, 1.12],
  "2": [-0.12, -0.23, 1.2],
  "3": [-0.02, -0.45, 1.06],
  "4": [-0.08, -0.2, 1.12],
  "5": [-0.26, -0.57, 1.53],
  "6": [-0.12, -0.37, 1.27],
  "7": [-0.14, -0.38, 1.13],
  "8": [-0.11, -0.28, 1.19],
  "9": [-0.26, -0.47, 1.25],
}
const customWeaponPositionSize = {
  "pencil": [-0.345, -0.25, 1.6],
}
const hitShieldPositionSize = {
  "small-1": [0.1, -0.2, 1.8],
  "large-1": [-0.015, -0.235, 2],
  "small-2": [0.08, -0.2, 1.8],
  "large-2": [-0.01, -0.24, 2],
  "small-3": [0.08, -0.2, 1.8],
  "large-3": [-0.015, -0.23, 2],
  "small-4": [0.08, -0.2, 1.8],
  "large-4": [-0.02, -0.25, 2],
  "small-5": [0.08, -0.2, 1.8],
  "large-5": [-0.02, -0.25, 2],
}

const defaultLimbPositions = {
  leftArm: [0.34, 0.49, 0.03, 0.68, 0.3, 0.58],
  rightArm: [0.54, 0.5, 0.6, 0.685, 0.785, 0.6],
  leftLeg: [0.35, 0.7, 0.32, 0.85, 0.11, 0.918],
  rightLeg: [0.55, 0.71, 0.66, 0.8, 0.605, 0.91]
}

const getCharacterRenderInfo = (size) => {
  return [
    defaultCharacterWidth * size,
    defaultCharacterHeight * size,
    characterWidth * size,
    characterHeight * size,
    iconScale * size,
    xBuffer * size,
    yBuffer * size,
    characterShift
  ]
}

const getLimbData = (size, x1, y1, x2, y2, x3, y3) => {
  const getCoordinate = (whichAxis, d) => {
    if (whichAxis === "x") {
      return size * (characterWidth * d + xBuffer)
    }
    else if (whichAxis === "y") {
      return size * (characterHeight * d + yBuffer)
    }
  }
  return [
    {x: getCoordinate("x", x1), y: getCoordinate("y", y1)},
    {x: getCoordinate("x", x2), y: getCoordinate("y", y2)},
    {x: getCoordinate("x", x3), y: getCoordinate("y", y3)}
  ]
}

function getPositionAndSize(attributeString, characterDna) {
  // Character Binary will be undefined for all animation function calls
  // That's fine because the hair and mouth is pinned to the head
  var x
  var y
  var size
  var rarityRank
  if (attributeString.includes("head")) {
    x = 0.22
    y = 0.05
    size = 0.7
  }
  if (attributeString.includes("hair")) {
    rarityRank = (characterDna / attributeToDnaDivisor["Hair"]) % 100
    var hairIdx
    if (characterDna === "default") {
      hairIdx = 0
    }
    else if (specialCharacters.includes(characterDna)) {
      hairIdx = characterDna
    }
    else {
      hairIdx = dnaToDecimal("Hair", rarityRank)
    }

    const hairPositionSize = customHairPositionSize[hairIdx]
    x = hairPositionSize[0]
    y = hairPositionSize[1]
    size = hairPositionSize[2]
  }
  else if (attributeString.includes("eyes")) {
    if (characterDna === "dylan") {
      x = -0.03
      y = -0.03
      size = 1.08
    }
    else {
      x = 0.21
      y = 0.12
      size = 0.78
    }
  }
  else if (attributeString.includes("mouth")) {
    rarityRank = (characterDna / attributeToDnaDivisor["Mouth"]) % 100
    var mouthIdx
    if (characterDna === "default") {
      mouthIdx = 0
    }
    else {
      mouthIdx = dnaToDecimal("Mouth", rarityRank)
    }

    if (characterDna === "dylan") {
      x = 0.05
      y = 0.35
      size = 0.93
    }
    else if (mouthIdx === 0) {
      x = 0.4
      y = 0.60
      size = 0.43
    }
    else if (mouthIdx === 6) {
      x = 0.45
      y = 0.62
      size = 0.38
    }
    else {
      x = 0.31
      y = 0.49
      size = 0.6
    }
  }
  else if (attributeString.includes("body")) {
    x = 0.2333
    y = 0.6867
    size = 0.41
  }
  else if (attributeString.includes("Hand")) {
    if (attributeString.includes("left")) {
      x = 0.2533
      y = 0.6267
      size = 0.31
    }
    else if (attributeString.includes("right")) {
      x = 0.7433
      y = 0.66
      size = 0.28
    }
  }
  else if (attributeString.includes("Foot")) {
    if (attributeString.includes("left")) {
      x = 0.0333
      y = 1.1667
      size = 0.33
    }
    else if (attributeString.includes("right")) {
      x = 0.5333
      y = 1.15
      size = 0.33
    }
  }
  return [x, y, size]
}

function loadAllShieldAssets(characterCard, characterIdNumber, charSize) {
  characterCard.selectAll(".shield-icon").remove()

  const defaultInputs = [characterCard.select("svg"), characterIdNumber]
  const shieldDir = assetDir + "extra-effects/new-shield/"
  const startShieldPrefix = shieldDir + "start-shield-"
  renderAttribute(...defaultInputs, `${startShieldPrefix}1.png`, "start-shield-1", charSize, 0.1, -0.2, 1.8, true)
  renderAttribute(...defaultInputs, `${startShieldPrefix}2.png`, "start-shield-2", charSize, 0.11, -0.1, 1.8, true)
  renderAttribute(...defaultInputs, `${startShieldPrefix}3.png`, "start-shield-3", charSize, 0, -0.2, 1.95, true)
  renderAttribute(...defaultInputs, `${startShieldPrefix}4.png`, "start-shield-4", charSize, 0.02, -0.2, 1.95, true)
  characterCard.select(`#start-shield-2-${characterIdNumber}`).attr("display", "none")
  characterCard.select(`#start-shield-3-${characterIdNumber}`).attr("display", "none")
  characterCard.select(`#start-shield-4-${characterIdNumber}`).attr("display", "none")

  var smallShieldAppend
  var largeShieldAppend
  var smallPositionSize
  var largePositionSize
  const hitShieldPrefix = shieldDir + "hit-shield-"
  for (var shieldHitFrame = 1; shieldHitFrame < 6; shieldHitFrame++) {
    smallShieldAppend = `small-${shieldHitFrame}`
    largeShieldAppend = `large-${shieldHitFrame}`
    smallPositionSize = hitShieldPositionSize[smallShieldAppend]
    largePositionSize = hitShieldPositionSize[largeShieldAppend]
    renderAttribute(...defaultInputs, `${hitShieldPrefix}${smallShieldAppend}.png`, `hit-shield-${smallShieldAppend}`, charSize, ...smallPositionSize, true)
    renderAttribute(...defaultInputs, `${hitShieldPrefix}${largeShieldAppend}.png`, `hit-shield-${largeShieldAppend}`, charSize, ...largePositionSize, true)
    characterCard.select(`#hit-shield-small-${shieldHitFrame}-${characterIdNumber}`).attr("display", "none")
    characterCard.select(`#hit-shield-large-${shieldHitFrame}-${characterIdNumber}`).attr("display", "none")
  }

  renderAttribute(...defaultInputs, `${hitShieldPrefix}break-1.png`, `hit-shield-break-1`, charSize, 0.08, -0.2, 1.8, true)
  renderAttribute(...defaultInputs, `${hitShieldPrefix}break-2.png`, `hit-shield-break-2`, charSize, -0.02, -0.28, 2, true)
  renderAttribute(...defaultInputs, `${hitShieldPrefix}break-3.png`, `hit-shield-break-3`, charSize, -0.02, -0.25, 2, true)
  renderAttribute(...defaultInputs, `${hitShieldPrefix}break-4.png`, `hit-shield-break-4`, charSize, -0.02, -0.3, 2, true)
  characterCard.select(`#hit-shield-break-1-${characterIdNumber}`).attr("display", "none")
  characterCard.select(`#hit-shield-break-2-${characterIdNumber}`).attr("display", "none")
  characterCard.select(`#hit-shield-break-3-${characterIdNumber}`).attr("display", "none")
  characterCard.select(`#hit-shield-break-4-${characterIdNumber}`).attr("display", "none")

  renderAttribute(...defaultInputs, shieldDir + "end-shield.png", `end-shield`, charSize, 0.05, -0.13, 1.8, true)
  characterCard.select(`#end-shield-${characterIdNumber}`).attr("display", "none")
}

const pinToFaceList = ["hair", "eyes", "mouth", "hurt-eyes", "happy-eyes", "dead-eyes", "stun-eyes", "dead-mouth", "crown", "rotate"]
function renderAttribute(characterCard, characterIdNumber, icon, iconName, sizeMultiple, x, y, size, extraBool, characterDna) {
  if (characterDna !== "default" || iconName !== "hair") {
    const iconId = iconName + "-" + characterIdNumber
    const pinBool = pinToFaceList.includes(iconName) || Object.keys(customWeaponPositionSize).includes(iconName)
    const iconSize = pinBool ? 100*size+"%" : sizeMultiple * (iconScale * size)
    const iconX = pinBool ? 100*x+"%" : sizeMultiple * (iconScale * x + xBuffer)
    const iconY = pinBool ? 100*y+"%" : sizeMultiple * (iconScale * y + yBuffer)

    var anchorPoint
    if (pinToFaceList.includes(iconName)) {
      anchorPoint = characterCard.select(`#head-${characterIdNumber}`).select("g")
    }
    else if (Object.keys(customWeaponPositionSize).includes(iconName)) {
      anchorPoint = characterCard.select(`#leftHand-${characterIdNumber}`).select("g")
    }
    else {
      anchorPoint = characterCard
    }

    var iconObject
    if (iconName.includes("shield")) {
      iconObject = anchorPoint.append("svg:image")
        .attr("xlink:href", decodeURI(icon))
        .attr("class", "shield-icon")
    }
    else {
      iconObject = anchorPoint.append(function() {
        return icon.documentElement.cloneNode(true)
      })
    }
    iconObject
      .attr("id", iconId)
      .attr("y", iconY)
      .attr("x", iconX)
      .attr("width", iconSize)
      .attr("height", iconSize)
      .style("overflow", "visible")
      .classed("extra-asset-effect", extraBool)
      .attr("display", function() {
        if (extraBool) {
          return "none"
        }
        else {
          return "block"
        }
      })

    if (Object.keys(customWeaponPositionSize).includes(iconName)) {
      anchorPoint.selectAll("path").raise()
    }
  }
}

export {
  assetDir,
  sizeMultiples,
  iconScale,
  xBuffer,
  yBuffer,
  defaultCharacterWidth,
  defaultCharacterHeight,
  defaultLimbPositions,
  getCharacterRenderInfo,
  attributeToDnaDivisor,
  getLimbData,
  getPositionAndSize,
  customWeaponPositionSize,
  loadAllShieldAssets,
  renderAttribute,
  specialCharacters
}
