import React, { useState, useEffect, useContext } from 'react'
import { Context } from "../../Store"
import { useHistory } from 'react-router-dom'
import Wallet from '../connect-components/wallet/Wallet'

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';

import "../../css/website-scaffolding/landing-page.css"
import '../../css/website-scaffolding/home.css'

import aiarenaLogoLetters from '../../local-assets/threejs-assets/AI-letters.png'

import * as THREE from 'three'
import * as d3 from 'd3'
import axios from "axios"
require("dotenv").config()


const Home = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const [inputUnlocked, setInputUnlocked] = useState(websiteContext.hasAccess)
  const [hasAccess, setHasAccess] = useState(websiteContext.hasAccess)
  const [password, setPassword] = useState(undefined)

  const history = useHistory()

  const backend = process.env.REACT_APP_BACKEND

  const getPassword = () => {
    setPassword(document.getElementById("enter-platform-password").value)
  }

  class LandingPageVisual {
    constructor() {
      this.initialize();
    }

    initialize() {
      this.threejs = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      this.threejs.outputEncoding = THREE.sRGBEncoding
      this.threejs.setPixelRatio(window.devicePixelRatio)
      this.threejs.autoClear = false
      this.threejs.setClearColor(0x000000, 0.0)

      this.threejs.setSize(window.innerWidth, window.innerHeight)

      this.containerId = "landing-page-visual"
      this.canvasContainer = document.getElementById(this.containerId)
      this.canvasContainer.appendChild(this.threejs.domElement)

      window.addEventListener('resize', () => {
        this.onWindowResize();
      }, false);

      const near = 0.1
      const far = 1000.0
      const fov = 75
      this.aspect = window.innerWidth / window.innerHeight
      this.camera = new THREE.PerspectiveCamera(fov, this.aspect, near, far)
      this.camera.position.set(0,0,1)
      this.camera.lookAt(0,0,0)

      this.scene = new THREE.Scene()

      let light = new THREE.DirectionalLight(0xFFFFFF, 1)
      light.position.set(0, 2, 1.75)
      this.scene.add(light)

      light = new THREE.AmbientLight(0xFFFFFF, 0.25);
      this.scene.add(light);

      const sphereGeometry = new THREE.SphereGeometry( 0.3, 64, 32 );
      const redMaterial = new THREE.MeshLambertMaterial({ color: 0x611818 });
      const logoMaterial = new THREE.MeshBasicMaterial({
        map: new THREE.TextureLoader().load(aiarenaLogoLetters),
        transparent: true, 
        opacity: 1 
      })

      this.redSphere = new THREE.Mesh(sphereGeometry, redMaterial);
      this.redSphere.position.set(0,0,0)
      this.scene.add(this.redSphere);

      this.logoSphere = new THREE.Mesh(sphereGeometry, logoMaterial);
      this.logoSphere.position.set(0,0,0)
      this.scene.add(this.logoSphere);

      this.backLogoSphere = new THREE.Mesh(sphereGeometry, logoMaterial);
      this.backLogoSphere.position.set(0,0,0)
      this.backLogoSphere.rotation.y = Math.PI
      this.scene.add(this.backLogoSphere);

      const fontLoader = new FontLoader()
      fontLoader.load('fonts/Bahnschrift_Regular.json', (font) => {
        const textGeometry = new TextGeometry('PROBABLY NOTHING.', {
          font: font,
          size: 0.07,
          height: 0.001,
          curveSegments: 12,
          bevelEnabled: true,
          bevelThickness: 0.001,
          bevelSize: 0.002,
          bevelOffset: 0,
          bevelSegments: 5
        } )
        const textMaterial = new THREE.MeshBasicMaterial({ color: 0xFFFFFF })
        const textMesh = new THREE.Mesh(textGeometry, textMaterial)
        textMesh.position.set(-0.45,-0.5,0)
        this.scene.add(textMesh)
      })

      this.effectComposer = new EffectComposer(this.threejs)
      this.effectComposer.setSize(window.innerWidth, window.innerHeight)
      this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      this.effectComposer.addPass(new RenderPass(this.scene, this.camera))

      const glitchPass = new GlitchPass(0.001)
      this.effectComposer.addPass(glitchPass)
      
      this.render()
    }

    render() {
      requestAnimationFrame((t) => {
        this.redSphere.rotation.y = Math.PI * t / 3000
        this.logoSphere.rotation.y = Math.PI * t / 3000
        this.backLogoSphere.rotation.y = Math.PI * t / 3000 + Math.PI
        
        this.threejs.render(this.scene, this.camera)
        this.effectComposer.render()
        this.render()
      })      
    }  

    onWindowResize() {
      this.threejs.setSize(window.innerWidth, window.innerHeight)
      this.effectComposer.setSize(window.innerWidth, window.innerHeight)
      this.camera.aspect = window.innerWidth / window.innerHeight
      this.camera.updateProjectionMatrix()
    }
  }

  useEffect(() => {
    const visual = new LandingPageVisual()
    d3.select("#sidebar").style("opacity", 0)
    return () => {
      visual.threejs.domElement.remove()
      d3.select("#sidebar").style("opacity", 1)
    }
  }, [])

  useEffect(async () => {
    if (websiteContext.userAddress !== undefined) {
      // const hasAccessResult = await axios.get(
      //   `${backend}whitelist/researcher-platform-access?address=${websiteContext.userAddress}`
      // )
      setHasAccess(true)
      // setHasAccess(hasAccessResult.data.access)
      setWebsiteContext((prevState) => {
        return {
          ...prevState,
          hasAccess: true
          // hasAccess: hasAccessResult.data.access
        }
      })
    }
    else {
      setHasAccess(true)
      // setHasAccess(false)
    }
  }, [websiteContext.userAddress])

  return (
    <>
      <div id="landing-page__container"> 
        {
          // inputUnlocked &&
          // <Wallet></Wallet>
        }
        <div id="landing-page-visual"></div>
        <div id="hello" onClick={() => setInputUnlocked(true)}></div>
          {
            hasAccess &&
            <div 
              id="home__enter-button"
              className="button--generic button--fit button--small" 
              onClick={() => history.push('/model-page')}>
                ENTER
            </div>
          }
      </div>
    </>
  )
}

export default Home
