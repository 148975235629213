import React, { useEffect, useState } from 'react'
import * as d3 from 'd3'
import './record-gameplay.css'

const RecordGameplay = ({ recording, setRecording, setChunks }) => {  
	const [mediaRecorder, setMediaRecorder] = useState(undefined)
  const [mediaStream, setMediaStream] = useState(undefined)

  let displayMediaOptions = {
    video: {
      cursor: "always"
    },
    audio: true
  };

  async function startCapture() {
    try {
      const tempMediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
      setMediaStream(tempMediaStream)

      d3.select("#record-gameplay__red-dot--pulsing").style("display", "block")
      setRecording(true)
      
      let tempMediaRecorder = new MediaRecorder(tempMediaStream)
      setMediaRecorder(tempMediaRecorder)
      tempMediaRecorder.onstop = (ev)=>{
        setRecording(false)
      }
  
      tempMediaStream.oninactive = () => {
        setRecording(false)
      }

      tempMediaRecorder.ondataavailable = function(ev) {
        setChunks(prevState => [...prevState, ev.data])
      }
      tempMediaRecorder.start()
    }
    catch {
      console.log("Cancelled screen recording")
    }
  }

  function stopCapture(evt) {
    setRecording(false)
    d3.select("#record-gameplay__container").style("display", "none")
  }

  useEffect(() => {
    if (!recording && mediaRecorder !== undefined && mediaStream !== undefined) {
      if (mediaRecorder.state !== "inactive") {
        mediaRecorder.stop()
      }      
      mediaStream.getTracks().forEach(function(track) {
        track.stop();
      });
      d3.select("#record-gameplay__container").style("display", "none")
    }
  }, [recording])

  return (
    <>
      <div id="record-gameplay" 
           onClick={() => !recording ? startCapture() : stopCapture()}>
        <div id="record-gameplay__red-dot--pulsing"></div>
        <div id="record-gameplay__red-dot"></div>
      </div>
    </>
  )
}

export default RecordGameplay