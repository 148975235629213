import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import "./slider.css"

const Slider = ({ name, min, max, step, value, setAttributes, nameColor, valueColor, label }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const nameStyling = nameColor !== undefined ? { color: nameColor } : {}
  const valueStyling = valueColor !== undefined ? { color: valueColor } : {}

  const makeSliderInteractive = (sliderName) => {
    var $slider = $("#slider-".concat(sliderName))
    var $fill = $("#bar-".concat(sliderName, " #fill-", sliderName))
    function setBar() {
      var max = $slider[0]["max"]
      var min = $slider[0]["min"]
      var percentage = ($slider.val() - min) / (max - min) * 100
      $fill.css("width", percentage + "%")
      setCurrentValue($slider.val())
      setAttributes((prevState) => {
        prevState[name] = +$slider.val()
        return {...prevState}
      })
    }
    $slider.on("input", setBar);
    return setBar();
  }

  if (label === undefined) {
    label = (name.charAt(0).toUpperCase() + name.slice(1)).match(/[A-Z][a-z]+/g).join(" ")
  }

  useEffect(() => {
    makeSliderInteractive(name)
  }, [])

  return (
    <>
      <div className="slider__parent">
        <label htmlFor={`bar-${name}`} style={nameStyling}>
          {label}: <span style={valueStyling}>{currentValue}</span>
        </label>
        <div className="middle">
          <div className="slider__container">
            <span id={`bar-${name}`} className="bar">
              <span id={`fill-${name}`} className="fill"></span>
            </span>
            <input type="range"
                   id={`slider-${name}`}
                   className="slider"
                   min={min}
                   max={max}
                   defaultValue={value}
                   step={step}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
