import { createNewFighter, writeWeightsToBlockchain } from './ethereum-requests'
import { neuralNetwork } from './neural-network'
import { attachModel } from '../game-setup/model-setup'
import { flattenParameters } from './matrix-math'

const checkModelSpecs = (modelSpecs) => {
  const useBias = modelSpecs.biases.length > 0

  var inDim
  var outDim
  var correctFormat = true
  for (var l = 0; l < modelSpecs.weights.length; l++) {
    if (l === 0) {
      inDim = modelSpecs.n_features
      outDim = modelSpecs.neurons[l]
    }
    else if (l === modelSpecs.weights.length - 1) {
      inDim = modelSpecs.neurons[l-1]
      outDim = modelSpecs.n_actions
    }
    else {
      inDim = modelSpecs.neurons[l-1]
      outDim = modelSpecs.neurons[l]
    }

    if (modelSpecs.weights[l].length !== inDim) correctFormat = false
    else if (modelSpecs.weights[l][0].length !== outDim) correctFormat = false

    if (useBias) {
      if (modelSpecs.biases[l].length !== 1) correctFormat = false
      else if (modelSpecs.biases[l][0].length !== outDim) correctFormat = false  
    }
  }

  return [correctFormat, useBias]
}

const checkValidModel = (files, setModelSpecs) => {
  if (files.length === 1) {
    if (files[0].type === "application/json") {
      const fileReader = new FileReader()
      fileReader.readAsText(files[0])

      fileReader.onload = function () {
        const proposedModelSpecs = JSON.parse(fileReader.result)
        const [correctFormat, useBias] = checkModelSpecs(proposedModelSpecs)
        if (correctFormat) {
          setModelSpecs(proposedModelSpecs)
        }
        else {
          alert("Your model specification is not in the correct format!")
        }
      }
    }
    else {
      alert("You are only allowed to upload application/json files!")
    }
  }
  else {
    alert("You are only allowed to upload 1 file at a time!")
  }
}

const upload = (
  modelSpecs, 
  yourFighter, 
  battleAttributes,
  fighterContract, 
  neuralNetworkContract, 
  websiteContext, 
  setWebsiteContext,
  setTransactionPending,
  setShowExplorePopup
) => {
  modelSpecs.metadata = {
    ...modelSpecs.metadata,
    activationFunction: modelSpecs.activation_function,
    outputActivation: modelSpecs.output_activation,
  }
  if (yourFighter !== undefined) {
    const preTrainedModel = {...yourFighter.model}
    const functionalFighter = attachModel(yourFighter)
    functionalFighter.model.weights = modelSpecs.weights
    functionalFighter.model.bias = modelSpecs.biases
    functionalFighter.model.flattenedWeights = flattenParameters(modelSpecs.weights)
    functionalFighter.model.flattenedBiases = flattenParameters(modelSpecs.biases)
    functionalFighter.model.neurons = modelSpecs.neurons
    functionalFighter.model.activationFunctionName = modelSpecs.activation_function
    functionalFighter.model.metadata = modelSpecs.metadata
    writeWeightsToBlockchain(
      functionalFighter, 
      preTrainedModel, 
      fighterContract,
      neuralNetworkContract, 
      websiteContext, 
      setWebsiteContext,
      setTransactionPending
    )
  }
  else {
    createNewFighter(
      modelSpecs.weights,
      modelSpecs.biases, 
      modelSpecs.neurons,
      modelSpecs.activation_function,
      modelSpecs.metadata,
      battleAttributes,
      fighterContract, 
      neuralNetworkContract, 
      websiteContext, 
      setWebsiteContext,
      setTransactionPending,
      setShowExplorePopup
    )
  }
}

const uploadGuest = (modelSpecs, battleAttributes, setWebsiteContext) => {
  const newFighter = {
    id: "guest",
    dna: "robot",
    battleAttributes: battleAttributes,
    model: new neuralNetwork(
      modelSpecs.n_features, 
      modelSpecs.neurons, 
      modelSpecs.n_actions, 
      flattenParameters(modelSpecs.weights), 
      flattenParameters(modelSpecs.biases), 
      modelSpecs.metadata
    ),
    neuralNetVersion: 0
  }
  // We need to only do this in guest mode (feed it in?)
  localStorage.setItem('model', JSON.stringify(newFighter))
  setWebsiteContext((prevState) => {
    return {
      ...prevState,
      yourFighter: newFighter
    }
  })
}

export {
  checkValidModel,
  upload,
  uploadGuest
}
