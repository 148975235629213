import { actionsList } from "../game-setup/actions"

const getTransitionMatrix = (transitionMaps) => {
  const matrix = []
  const matrixLabels = []
  const transitionKeys = Object.keys(transitionMaps[0])
  for (var i = 0; i < transitionKeys.length; i++) {
    matrix.push([])
    matrixLabels.push(transitionKeys[i])
    for (var j = 0; j < transitionKeys.length; j++) {
      for (var k = 0; k < transitionMaps.length; k++) {
        if (k === 0) {
          matrix[i].push(transitionMaps[k][transitionKeys[i]][transitionKeys[j]])
        }
        else {
          matrix[i][j] += transitionMaps[k][transitionKeys[i]][transitionKeys[j]]
        }
      }        
    }
  }
  return { matrix: matrix, labels: matrixLabels }
}

const getBarChartData = (data) => {
  const aggregatedData = {}
  var action
  var dataSubset
  var maxValue = 0
  for (var i = 0; i < actionsList.length; i++) {
    action = actionsList[i]
    for (var k = 0; k < data.length; k++) {
      dataSubset = data[k][action]
      if (aggregatedData[action] === undefined) {
        aggregatedData[action] = { 
          successful: dataSubset.numSuccessful,
          unsuccessful: dataSubset.numExecuted - dataSubset.numSuccessful,
        }
      }
      else {
        aggregatedData[action] = { 
          successful: aggregatedData[action].successful + dataSubset.numSuccessful,
          unsuccessful: aggregatedData[action].unsuccessful + (dataSubset.numExecuted - dataSubset.numSuccessful),
        }
      }
    }
    if (aggregatedData[action].successful + aggregatedData[action].unsuccessful > maxValue) {
      maxValue = aggregatedData[action].successful + aggregatedData[action].unsuccessful
    }
  }
  return [aggregatedData, { maxValue: maxValue }]
}

export {
  getTransitionMatrix, 
  getBarChartData
}