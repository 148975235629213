import { noiseFunctions } from '../utils/vertexNoisePrepend'

const surfaceVertexShader = `
  uniform float uTime;
  uniform float bumpMultiplier;
  varying vec3 vertex;
  varying vec2 vUv;
  ${noiseFunctions}
  void main() {
    vertex = position;
    vUv = uv;

    // float xSin = sin(vertex.x * 0.5 + uTime * 0.00075) * 0.75;
    // float yCos = sin(vertex.y * 0.5 + uTime * 0.00075) * 0.75;
    // vertex.z = (xSin + yCos) * bumpMultiplier;

    float sinTime = sin(uTime * 0.0004) * 0.2;
    float cosTime = cos(uTime * 0.0004) * 0.2;
    vertex.z = cnoise(
      vec3(
        (position.x) * 0.05 + sinTime, 
        (position.y) * 0.1 + cosTime, 
        (position.z) * 0.15 + (sinTime + cosTime) * 0.5
      )
    ) * 10. * bumpMultiplier;

    // vertex.z = cnoise(
    //   vec3(
    //     (position.x) * 0.1, 
    //     (position.y) * 0.1, 
    //     0.
    //   )
    // ) * 10. * bumpMultiplier;

    vec4 mvPosition = modelViewMatrix * vec4(vertex, 1.0);
    gl_Position = projectionMatrix * mvPosition;
  }
`

export {
  surfaceVertexShader
}