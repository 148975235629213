const statesList = [
  "Relative Distance",
  "You Facing Opponent",
  "Opponent Facing You",
  "Your Health",
  "Opponent Health",
  "Relative Power",
  "Relative Speed",
  "Relative Defence",
  "Relative Accuracy"
]

const idxToFunctionGenerator = {
  0: "Full Range",
  1: "Positive Negative Bool",
  2: "Positive Negative Bool",
  3: "Positive Range",
  4: "Positive Range",
  5: "Full Range",
  6: "Full Range",
  7: "Full Range",
  8: "Full Range",
}

const randomStateGenerator = (idx) => {
  var randomState
  const functionName = idxToFunctionGenerator[idx]
  if (functionName === "Full Range") {
    randomState = Math.random() * 2 - 1
  }
  else if (functionName === "Positive Negative Bool") {
    randomState = Math.random() < 0.5 ? 1 : -1
  }
  else if (functionName === "Positive Range") {
    randomState = Math.random()
  }
  return randomState
}

const randomizeStates = (states, nonRandomIndices) => {
  return states.map((x, idx) => {
    var value
    if (nonRandomIndices.includes(idx)) {
      value = x
    }
    else {
      value = randomStateGenerator(idx)
    }
    return value
  })
}

const getStateArray = (referenceState, otherState, referenceAttributes, otherAttributes) => {
  const relativeDistance = referenceState.x - otherState.x
  return [[
    relativeDistance,
    referenceState.direction * Math.sign(relativeDistance) * -1,
    otherState.direction * Math.sign(relativeDistance),
    referenceState.health,
    otherState.health,
    (referenceAttributes.power - otherAttributes.power) / 100,
    (referenceAttributes.speed - otherAttributes.speed) / 100,
    (referenceAttributes.defence - otherAttributes.defence) / 100,
    (referenceAttributes.accuracy - otherAttributes.accuracy) / 100,
  ]]
}

export {
  statesList,
  randomizeStates,
  getStateArray
}
