import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../Store'
import { useHistory, useLocation } from 'react-router-dom'
import Music from '../website-scaffolding/Music'
import Character from '../character/Character'
import HealthBar from '../game-play/hud-components/HealthBar'
import Modal from '../generic-components/custom-modal/CustomModal'
import Slider from '../generic-components/slider/Slider'
import { trainMetaData } from '../../helpers/game-setup/battle-meta-data'
import { actionsList } from '../../helpers/game-setup/actions'
import { getStateArray } from '../../helpers/game-setup/states'
import { resizeArena } from '../../helpers/game-setup/arena-styling'
import { neuralNetwork } from '../../helpers/machine-learning/neural-network'
import { writeWeightsToBlockchain } from '../../helpers/machine-learning/ethereum-requests'
import inspectorStage from '../../local-assets/stages/ai-inspector-stage.webp'
import exitButton from '../../local-assets/generic/exit-white.svg'
import leftArrow from '../../local-assets/generic/left-arrow.svg'
import pointerIcon from '../../local-assets/generic/pointer.svg'
import backIcon from '../../local-assets/generic/back.svg'
import popup from '../../local-assets/game-play/empty-popup.webp'
import './model-inspector.css'
import '../../css/game-play/battle.css'
import * as d3 from 'd3'

const ModelInspector = ({ locallyStoredModel }) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const history = useHistory()
  const location = useLocation()
  const actionsToShow = 5

  var pretrainedModel
  const yourFighter = websiteContext.yourFighter
  if (location.state !== undefined) {
    if (location.state.pretrainedModel !== undefined) {
      const rawPretrainedModel = location.state.pretrainedModel
       pretrainedModel = new neuralNetwork(
        rawPretrainedModel.nFeatures,
        rawPretrainedModel.neurons,
        rawPretrainedModel.nActions,
        rawPretrainedModel.flattenedWeights,
        rawPretrainedModel.flattenedBiases,
        rawPretrainedModel.metadata
      )
    }
  }
  yourFighter.renderCrown = false
  const [defaultAttributes, setDefaultAttributes] = useState({
    power: yourFighter.battleAttributes.power,
    speed: yourFighter.battleAttributes.speed,
    defence: yourFighter.battleAttributes.defence,
    accuracy: yourFighter.battleAttributes.accuracy
  })

  const linearBool = yourFighter.model.metadata.outputActivation === "linear"

  const undoTraining = () => {
    yourFighter.model = new neuralNetwork(
     pretrainedModel.nFeatures,
     pretrainedModel.neurons,
     pretrainedModel.nActions,
     pretrainedModel.flattenedWeights,
     pretrainedModel.flattenedBiases,
     pretrainedModel.metadata
   )
  }

  // Functions to Route to Different Parts of the App
  const routeTrainingOptions = (undoTrainingBool) => {
    if (undoTrainingBool) {
      undoTraining()
    }    
    history.push('/training-modes')
  }
  const routeToModelPage = () => {
    history.push('/model-page')
  }
  const routeToGame = () => {
    const opponentFighter = location.state.opponentFighter
    const sameFighterChosen = opponentFighter.id.replace("-dark", "") === yourFighter.id
    history.push({
      pathname: '/training',
      state: {
        trainingType: location.state.method,
        whichSide: location.state.whichSide,
        yourFighter: yourFighter,
        opponentFighter: {
          ...opponentFighter, 
          model: sameFighterChosen ? {...yourFighter.model} : {...opponentFighter.model}
        },
        useRandomModel: location.state.useRandomModel
      }
    })
  }
  const routeToConfig = () => {
    undoTraining()
    history.push({
      pathname: "/training-config",
      state: {
        trainingData: location.state.trainingData,
        whichSide: location.state.whichSide,
        yourFighter: yourFighter,
        opponentFighter: {...location.state.opponentFighter},
        pretrainedModel: location.state.pretrainedModel,
        useRandomModel: location.state.useRandomModel,
        renderStartingText: false
      }
    })
  }

  const [yourState, setYourState] = useState({x: 0.2, health: 1, direction: 1})
  const [opponentState, setOpponentState] = useState({x: 0.8, health: 1, direction: -1})
  const [opponentAttributes, setOpponentAttributes] = useState({...defaultAttributes})
  const [actionProbabilities, setActionProbabilities] = useState(
    Object.fromEntries(actionsList.slice(0, yourFighter.model.nActions).map((a) => { return [a, 0] }))
  )
  const [sortedActions, setSortedActions] = useState(actionsList.slice(0, yourFighter.model.nActions))
  const [actionProbabilityDeltas, setActionProbabilityDeltas] = useState({...actionProbabilities})

  const attributeMin = {
    power: 10, speed: 10, defence: 5, accuracy: 20
  }
  const attributeMax = {
    power: 100, speed: 100, defence: 95, accuracy: 100
  }

  const characterInputs = {
    ...trainMetaData["character1"],
    size: "0.8",
    inspectorBool: true,
    setFighterState: setYourState
  }
  const opponentFighter = {...yourFighter}
  opponentFighter.dna = "default"
  opponentFighter.id = "x"
  const opponentCharacterInputs = {
    ...characterInputs,
    ...trainMetaData["character2"],
    setFighterState: setOpponentState
  }

  const hidePointers = () => {
    if (d3.select(".inspector-pointer").style("display") !== "none") {
      d3.selectAll(".inspector-pointer").style("display", "none")
      d3.select("#initial-message").style("display", "none")
    }
  }

  const hideOptions = (e) => {
    const attributeDropdown = d3.select("#change-attributes__options")
    const otherActionsDropdown = d3.select("#other-actions__list")
    if (attributeDropdown._groups[0][0] !== null && otherActionsDropdown._groups[0][0] !== null) {
      const outsideAttributes = e.target.id !== "change-attributes"
      const outsideOtherActions = e.target.id !== "other-actions"
      if (
        (
          attributeDropdown.style("display") !== "none" ||
          otherActionsDropdown.style("display") !== "none"
        ) &&
        outsideAttributes &&
        outsideOtherActions
      ) {
        if (e.path.filter((domEl) => { return domEl.tagName === "LI" }).length === 0) {
          attributeDropdown.style("display", "none")
          otherActionsDropdown.style("display", "none")
          d3.select("#change-attributes__dropdown-arrow").style("transform", "rotate(-90deg)")
          d3.select("#other-actions__dropdown-arrow").style("transform", "rotate(-90deg)")
        }
      }
    }
  }

  const getDropdown = (dropdownId, arrowId) => {
    const dropdown = d3.select(dropdownId)
    if (dropdown.style("display") === "none") {
      dropdown.style("display", "block")
      d3.select(arrowId).style("transform", "rotate(90deg)")
    }
    else {
      dropdown.style("display", "none")
      d3.select(arrowId).style("transform", "rotate(-90deg)")
    }
  }

  const getState = () => {
    return getStateArray(
      yourState,
      opponentState,
      yourFighter.battleAttributes,
      opponentAttributes
    )
  }

  const adjustAttributeState = (attributeName, attributeValue) => {
    setOpponentAttributes((prevState) => {
      prevState[attributeName] = attributeValue
      return {...prevState}
    })
  }

  const getSortedActions = () => {
    const sortableActions = Object.keys(actionProbabilities).map((action) => {
      return [action, actionProbabilities[action]]
    })
    sortableActions.sort(function(a, b) {
      return b[1] - a[1]
    })
    setSortedActions(sortableActions.map((actionAndProb) => { return actionAndProb[0] }))
  }

  const additionalModalStyling = {
    backgroundImage: `url(${popup})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    width: "30vw",
    height: "31vw"
  }

  const modalSubContent = (
    <div id="post-training-options__container">
      <h1 id="post-training-options__text--title">Great job training!</h1>
      <h2 id="post-training-options__text--content">
        Do you want to train again with the same fighters or go back to the training menu?
      </h2>
      <div id="post-training-options__button-container">
        <div className="button--generic"
             onClick={() => routeToGame()}>
          SAME FIGHTERS
        </div>
        <div className="button--generic"
             onClick={() => routeTrainingOptions(false)}>
          TRAINING MENU
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    d3.select("#slider-power").on("input", function(d){
      adjustAttributeState("power", +this.value)
    })
    d3.select("#slider-speed").on("input", function(d){
      adjustAttributeState("speed", +this.value)
    })
    d3.select("#slider-defence").on("input", function(d){
      adjustAttributeState("defence", +this.value)
    })
    d3.select("#slider-accuracy").on("input", function(d){
      adjustAttributeState("accuracy", +this.value)
    })

    d3.select("header").style("display", "none")
    d3.select(".footer").style("display", "none")
    resizeArena()
    window.addEventListener("resize", resizeArena)
    window.addEventListener("click", hideOptions)
    return () => {
      d3.select("header").style("display", "block")
      d3.select(".footer").style("display", "flex")
      window.removeEventListener("resize", resizeArena)
      window.removeEventListener("click", hideOptions)
    }
  }, [])

  useEffect(() => {
    if (d3.select("#remove-pointers-flag").style("display") === "none") {
      hidePointers()
    }
    const state = getState()
    const policy = yourFighter.model.forwardProp(state)[0][0]
    var probabilities = {}
    const multiple = linearBool ? 10 : 1000
    for (var i = 0; i < policy.length; i++) {
      probabilities[actionsList[i]] = Math.round(policy[i] * multiple) / 10
    }
    setActionProbabilities(probabilities)
    if (pretrainedModel !== undefined) {
      const pretrainedProbabilities = pretrainedModel.forwardProp(state)[0][0]
      const probDeltas = pretrainedProbabilities.map((p, idx) => {
        return policy[idx] - p
      })
      var probDeltasObject = {}
      for (var i = 0; i < policy.length; i++) {
        probDeltasObject[actionsList[i]] = Math.round(probDeltas[i] * multiple) / 10
      }
      setActionProbabilityDeltas(probDeltasObject)
    }
    d3.select("#remove-pointers-flag").style("display", "none")
  }, [yourState, opponentState, opponentAttributes])

  useEffect(() => {
    getSortedActions()
  }, [actionProbabilities])

  return (
    <>
      <Modal
        modalId="post-training-options-modal"
        modalSubContent={modalSubContent} 
        additionalModalStyling={additionalModalStyling}>
      </Modal> 
      <div id="inspector-screen__container" className="gameplay-screen__container">
        <div className="inspector-arena" id="battle-arena">
          {
            pretrainedModel !== undefined &&
            <div id="return-to-config"
                 className="button--generic button--fit button--small"
                 onClick={() => routeToConfig()}>
              <img src={backIcon} alt="" /> Config
            </div>
          }

          <img id="inspector-stage" src={inspectorStage} alt=""/>

          <p id="initial-message">
            The top-left corner shows the probability that your AI will take various
            actions. Move things around in the environment to see how it changes
            the probabilities.
          </p>

          <div id="probability-display">
            <h3 className="probability-label">
              {!linearBool ? "Probability" : "Output"}
            </h3>
            {
              sortedActions.map((action, idx) => {
                const style = {display: idx < actionsToShow ? "block" : "none"}
                return (
                  <div key={`prob-${action}`} className="individual-probability">
                    <h3 style={style}>{action}:</h3>
                    <h3 style={style}>{actionProbabilities[action]}
                      {linearBool ? "" : "%"}
                    </h3>
                  </div>
                )
              })
            }
          </div>
          <div id="other-actions__container">
            <div id="other-actions"
                 onClick={() => getDropdown(
                   "#other-actions__list",
                   "#other-actions__dropdown-arrow"
                 )}>
              <p>Other Actions</p>
              <img id="other-actions__dropdown-arrow" src={leftArrow} alt=""/>
            </div>
            <ul id="other-actions__list">
              {
                sortedActions.map((action, idx) => {
                  const style = {
                    display: idx >= actionsToShow ? "block" : "none",
                    height: idx >= actionsToShow ? "10%" : "0"
                  }
                  return (
                    <li key={`prob-${action}`} className="individual-probability">
                      <h3 style={style}>{action}:</h3>
                      <h3 style={style}>{actionProbabilities[action]}
                        {linearBool ? "" : "%"}
                      </h3>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          {
            pretrainedModel !== undefined &&
            <div id="probability-delta-display">
              <h3 className="probability-label">Change</h3>
              {
                sortedActions.map((action, idx) => {
                  const probDelta = actionProbabilityDeltas[action]
                  var probColor
                  if (probDelta > 0) probColor = "green"
                  else if (probDelta < 0) probColor = "red"
                  else probColor = "lightgrey"
                  return (
                    <div key={`prob-delta-${action}`}
                         className="individual-probability"
                         style={{justifyContent: "flex-end"}}>
                      <h3 style={{color: probColor, display: idx < actionsToShow ? "block" : "none"}}>
                        {probDelta > 0 ? "+" : ""}{probDelta}{linearBool ? "" : "%"}
                      </h3>
                    </div>
                  )
                })
              }
            </div>
          }

          <div id="remove-pointers-flag"></div>
          <div id="attributes-pointer" className="inspector-pointer">
            <img src={pointerIcon} alt=""/>
          </div>
          <div id="your-health-pointer" className="inspector-pointer">
            <img src={pointerIcon} alt=""/>
            <h2>Drag</h2>
          </div>
          <div id="opponent-health-pointer" className="inspector-pointer">
            <h2>Drag</h2>
            <img src={pointerIcon} alt=""/>
          </div>
          <div id="your-fighter-pointer" className="inspector-pointer">
            <img src={pointerIcon} alt=""/>
            <h2>Drag</h2>
          </div>
          <div id="opponent-fighter-pointer" className="inspector-pointer">
            <img src={pointerIcon} alt=""/>
            <h2>Drag</h2>
          </div>
          <div id="your-fighter-rotate" className="inspector-pointer">
            <h2>Rotate</h2>
          </div>
          <div id="opponent-fighter-rotate" className="inspector-pointer">
            <h2>Rotate</h2>
          </div>

          <Character {...yourFighter} {...characterInputs}></Character>
          <HealthBar character={yourFighter}
                     attackerId="0"
                     mirror={false}
                     draggable={true}
                     setFighterState={setYourState}>
          </HealthBar>

          <Character {...opponentFighter} {...opponentCharacterInputs}></Character>
          <HealthBar character={opponentFighter}
                     attackerId="1"
                     mirror={true}
                     draggable={true}
                     setFighterState={setOpponentState}>
          </HealthBar>

          <div id="change-attributes__container">
            <div id="change-attributes"
                 onClick={() => getDropdown(
                   "#change-attributes__options",
                   "#change-attributes__dropdown-arrow"
                 )}>
              <p>Opponent's Attributes</p>
              <img id="change-attributes__dropdown-arrow" src={leftArrow} alt=""/>
            </div>
            <ul id="change-attributes__options">
              {
                Object.keys(defaultAttributes).map((attributeName) => {
                  return (
                    <li key={`change-attributes-${attributeName}`}>
                        <Slider key={`attribute-slider-${attributeName}`}
                                name={attributeName}
                                min={attributeMin[attributeName]}
                                max={attributeMax[attributeName]}
                                value={defaultAttributes[attributeName]}
                                step={1}
                                setAttributes={setDefaultAttributes}>
                        </Slider>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>

        <div className="gameplay-menu--horizontal">
          {
            pretrainedModel === undefined &&
            <div className="icon-text__container">
              <img className="exit-button"
                   src={exitButton}
                   alt="Exit"
                   onClick={() => routeToModelPage()}/>
              <h2 style={{color: "white"}}>Exit</h2>
            </div>
          }
          {
            pretrainedModel !== undefined &&
            <div className="save-options">
              <div id="save-training"
                   className="button--generic button--fit button--medium"
                   onClick={() => writeWeightsToBlockchain(
                     yourFighter, pretrainedModel, websiteContext, history
                   )}>
                SAVE
              </div>
              <div id="no-save-training"
                   className="button--generic button--fit button--medium"
                   onClick={() => routeTrainingOptions(true)}>
                DON'T SAVE
              </div>
            </div>
          }
          <div className="icon-text__container">
            <Music startMusicOn={!websiteContext.musicMuted}></Music>
            <h2 style={{
              color: "white",
              transform: "translateY(3px)",
              marginTop: "5px"
            }}>Music</h2>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModelInspector
