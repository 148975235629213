import homeMusic from "../../audio/music/home.ogg";
import collectionMusic from "../../audio/music/collection.ogg";
import leaderboardMusic from "../../audio/music/leaderboard.ogg";
import rankedBattle1Music from "../../audio/music/ranked-battle-1.ogg";
import rankedBattle2Music from "../../audio/music/ranked-battle-2.ogg";
import rankedBattleThroneMusic from "../../audio/music/ranked-battle-throne.ogg";
import simulationMusic from "../../audio/music/simulation.ogg";
import inspectorMusic from "../../audio/music/inspector.ogg";
import displayPageMusic from "../../audio/music/display-page.ogg";
import { Howl } from "howler";

const createMusicAssets = () => {
  const defaultMusicInputs = { loop: true, volume: 0 }

  const homeSong = new Howl({ src: [homeMusic], ...defaultMusicInputs });
  const collectionSong = new Howl({ src: [collectionMusic], ...defaultMusicInputs });
  const leaderboardSong = new Howl({ src: [leaderboardMusic], ...defaultMusicInputs });
  const rankedBattle1Song = new Howl({ src: [rankedBattle1Music], ...defaultMusicInputs });
  const rankedBattle2Song = new Howl({ src: [rankedBattle2Music], ...defaultMusicInputs });
  const rankedBattleThroneSong = new Howl({ src: [rankedBattleThroneMusic], ...defaultMusicInputs });
  const simulationSong = new Howl({ src: [simulationMusic], ...defaultMusicInputs });
  const inspectorSong = new Howl({ src: [inspectorMusic], ...defaultMusicInputs });
  const displayPageSong = new Howl({ src: [displayPageMusic], ...defaultMusicInputs });
  
  const musicAssets = {
    homeMusic: homeSong,
    collectionMusic: collectionSong,
    leaderboardMusic: leaderboardSong,
    rankedBattle1Music: rankedBattle1Song,
    rankedBattle2Music: rankedBattle2Song,
    rankedBattleThroneMusic: rankedBattleThroneSong,
    simulationMusic: simulationSong,
    inspectorMusic: inspectorSong,
    displayPageMusic: displayPageSong
  }

  return musicAssets
}

export { createMusicAssets };
