import React, { useState, useEffect } from 'react'
import LoadingScreen from '../loading-screen/LoadingScreen'

import { createScene } from '../../../helpers/threejs/scene-setup'
import { setUpBloom, renderBloom } from '../../../helpers/threejs/selective-bloom-rendering'
import Resources from '../../../helpers/threejs/resources'

import { additionalSources } from '../../../helpers/asset-management/landing-page/additional-sources'
import { loadErrorSurface } from '../../../helpers/asset-management/landing-page/error-surface'

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import * as THREE from 'three'
import "./landing-page.css"

const roundFrameSpeed = (frameSpeed, decimalPrecision, roundFunction) => {
  roundFunction = roundFunction !== undefined ? roundFunction : Math.round
  return roundFunction(frameSpeed * 10 ** decimalPrecision) / 10 ** decimalPrecision
}

const LandingPage = () => {
  const [scrollVisual, setScrollVisual] = useState()
  const [scrollPercent, setScrollPercent] = useState(0)
  const [loadingStatus, setLoadingStatus] = useState({ percent: 0, done: false })

  class InteractiveScrollVisual {
    constructor() {
      this.initialize();
    }

    initialize() {
      this.previousTime = 0
      this.scrollPercent = 0

      const initializationParams = {
        containerId: "interactive-scroll-visual",
        fov: 75
      }
      const sceneArtifacts = createScene(initializationParams)
      this.scene = sceneArtifacts[0]
      this.camera = sceneArtifacts[1]
      this.threejs = sceneArtifacts[2]

      this.targetFPS = 30
      this.fpsDecimalPrecision = 5
      this.fpsInterval = roundFrameSpeed(1 / this.targetFPS, this.fpsDecimalPrecision, Math.floor)
      
      this.camera.position.set(0,0,5)
      this.camera.lookAt(0,0,0)

      window.addEventListener('resize', () => {
        this.onWindowResize();
      }, false);

      let light = new THREE.DirectionalLight(0xFFFFFF, 1)
      light.position.set(0, 1, 1)
      light.castShadow = true
      light.shadow.bias = -0.001
      this.scene.add(light)

      light = new THREE.AmbientLight(0xFFFFFF, 0.25);
      this.scene.add(light);

      this.resources = new Resources("landingPage", additionalSources)
      this.resources.on("itemLoaded", () => {
        const statusPercent = Math.round(this.resources.loaded / this.resources.toLoad * 100) / 100
        setLoadingStatus({
          percent: statusPercent,
          done: statusPercent === 1
        })
      })

      this.animations = {}

      this.allMeshes = []
      this.sceneObjects = {}
      this.materials = {}
      this.darkMaterial = new THREE.MeshBasicMaterial( { color: 'black' } )
      this.resources.on("ready", () => {
        const defaultInputs = [this.resources, this.scene, this.sceneObjects, this.allMeshes]
        this.surfaceScale = 0.15 
        loadErrorSurface(this.surfaceScale, { x: 0, y: -1.5, z: 0 }, ...defaultInputs, "error-surface")
        
        const [bloomComposer, finalComposer] = setUpBloom(this.threejs, this.scene, this.camera)
        this.bloomComposer = bloomComposer
        this.finalComposer = finalComposer
      })

      const controls = new OrbitControls(this.camera, this.threejs.domElement)
      this.previousRAF = null
      this.animate()
    }

    animate() {
      requestAnimationFrame((t) => {
        if (this.previousRAF === null) {
          this.previousRAF = t;
        }
        const elapsed = roundFrameSpeed((t - this.previousRAF) / 1000, this.fpsDecimalPrecision, Math.ceil)
        if (elapsed >= this.fpsInterval) {
          this.previousRAF = t - (elapsed % this.fpsInterval)
          if (this.bloomComposer !== undefined && this.finalComposer !== undefined) {
            renderBloom(
              this.bloomComposer, 
              this.finalComposer, 
              this.allMeshes, 
              this.materials, 
              this.darkMaterial
            )
          }
        }
        this.animate()
      })
    }  

    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight
      this.camera.updateProjectionMatrix()
      this.threejs.setSize(window.innerWidth, window.innerHeight)
      this.render()
    }
  }

  document.body.onscroll = () => {
    if (scrollVisual !== undefined) {
      const currentYOffset = window.pageYOffset
      const maxYOffset = document.getElementById("interactive-scroll-contents").scrollHeight - window.innerHeight

      const currentTime = Date.now()
      const timeElapsedS = currentTime - scrollVisual.previousTime
      const t = 1.0 - Math.pow(0.001, timeElapsedS)
      const proposedScrollPercent = (currentYOffset / maxYOffset) * 100
      const lerp = (proposedScrollPercent - scrollVisual.scrollPercent) * t + scrollVisual.scrollPercent
      scrollVisual.scrollPercent = lerp
      scrollVisual.previousTime = currentTime
      
      setScrollPercent(lerp)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const visual = new InteractiveScrollVisual()
    setScrollVisual(visual)
    return () => {
      visual.threejs.domElement.remove()
    }
  }, [])

  return (
    <>
      <div id="played-agenes-animation-flag" style={{diplay: "none"}}></div>
      <div id="pause-tpc-flag" style={{diplay: "none"}}></div>
      {
        !loadingStatus.done &&
        <LoadingScreen loadingStatus={loadingStatus}></LoadingScreen>
      } 
      {/* <HamburgerMenu></HamburgerMenu>
      <ScrollTracker scrollPercent={scrollPercent}></ScrollTracker> */}
      <div id="interactive-scroll-visual"></div>
    </>
  )
}

export default LandingPage