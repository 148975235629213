import waterloo from "../../local-assets/team-logos/waterloo-logo.png"
import uoft from "../../local-assets/team-logos/UofT-logo.png"
import stanford from "../../local-assets/team-logos/stanford-logo.png"
import harvard from "../../local-assets/team-logos/harvard-logo.png"
import hku from "../../local-assets/team-logos/hku-logo.png"
import princeton from "../../local-assets/team-logos/princeton-logo.svg"
import coreTeam from "../../local-assets/team-logos/core-team-token.png"
import aiarenaCommunity from "../../local-assets/team-logos/M_default.png"
import contributionDao from "../../local-assets/team-logos/cdao-logo.png"

const teamLogoAssets = {
  "Blue Warriors": uoft,
  "JARS": harvard,
  "Quantmetry": hku,
  "Jason": princeton,
  "Soroush": uoft,
  "TH[AI] Arena": contributionDao,
  "Inglourious Botsards": aiarenaCommunity,
  "Crazy Universe": hku,
  "404_NameNotFound": hku,
  "Core Team": coreTeam
}

export {
  teamLogoAssets
}
