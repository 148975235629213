import React, { useState, useEffect, useRef } from 'react'
import Tooltip from '../../generic-components/tooltip/Tooltip'
import { setupChart } from '../../../helpers/charting/chart-setup'
import { renderChart } from '../../../helpers/charting/render-charts'
// import cornerBorder from '../../../local-assets/model-page/corner-border.png'
import circleTooltip from '../../../local-assets/benchmarks/scatter-plot-hover-circle.png'
import './benchmark-chart.css'

const BenchmarkChart = ({ benchmarkData }) => {
  const chartRef = useRef()
  const [chartData, setChartData] = useState([])

  const chartMetadata = {
    chartType: "Scatter Plot",
    margin: { top: 0.01, right: 0.01, bottom: 0.01, left: 0.01 },
    labelPadding: { x: 0.075, y: 0.05 },
    labelText: { x: "Date", y: "Score" },
    tooltipId: "tooltip--scatter-plot",
    chartPadding: { x: 0, y: 0.05 }
  }

  const tooltipContent = (
    <div id="tooltip__content">
      <img id="tooltip__circle" src={circleTooltip} alt="" />
      <div id="tooltip__scatter-subcontent">        
        <p id="tooltip__scatter--id"></p>
        <p id="tooltip__scatter--rank"></p>
        <p id="tooltip__scatter--score"></p>
        <p id="tooltip__scatter--date"></p>
      </div>
    </div>
  )

  useEffect(() => {
    const id = "#" + chartRef.current.id
    setupChart(id, true, chartMetadata)
  }, [])

  useEffect(() => {
    setChartData(benchmarkData.map((row) => {
      return { id: row.id, rank: row.rank, x: row.date, y: row.score }
    }))
  }, [benchmarkData])

  useEffect(() => {
    const id = "#" + chartRef.current.id
    if (chartData.length > 0) {
      renderChart(id, chartData, chartMetadata)
    }
  }, [chartData])

  return (
    <>
      <div ref={chartRef} id="benchmark-chart" className="svg-responsive__container">
        {/* <img id="benchmark-chart__corner--top-left" src={cornerBorder} alt="" />
        <img id="benchmark-chart__corner--top-right" src={cornerBorder} alt="" />
        <img id="benchmark-chart__corner--bottom-left" src={cornerBorder} alt="" />
        <img id="benchmark-chart__corner--bottom-right" src={cornerBorder} alt="" /> */}
        <Tooltip tooltipContent={tooltipContent} tooltipId="tooltip--scatter-plot"></Tooltip>
      </div>      
    </>
  )
}

export default BenchmarkChart