import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Wallet from '../connect-components/wallet/Wallet'
import logo from "../../local-assets/model-page/aiarena-logo--white.png"
import '../../css/website-scaffolding/sidebar.css'
import * as d3 from 'd3'

const Sidebar = () => {
  const [windowWidth, setWindowWidth] = useState(undefined)
  const [open, setOpen] = useState(window.innerWidth > 1000)
  const location = useLocation()

  const noSidebarLocations = ["/", "/simulation", "/battle"]
  const renderBool = (
    !noSidebarLocations.includes(location.pathname) &&
    !location.pathname.includes("ai-inspector")
  )

  function moveIcon(componentName) {
    if (window.innerWidth <= 1000) {
      setOpen(false)
    }
    d3.selectAll(".sidebar__icon").attr("class", "sidebar__icon sidebar__icon--empty")
    d3.select(`#sidebar__icon--${componentName}`).classed(`sidebar__icon--chosen`, true)
    d3.selectAll(".sidebar__label").attr("class", "sidebar__label")
    d3.select(`#sidebar__label--${componentName}`).classed(`sidebar__label--chosen`, true)
  }

  const handleClick = (e) => {
    var clickedSidebar = false
    for (var i = 0; i < e.path.length; i++) {
      var elementId = e.path[i].id
      if (elementId !== undefined && elementId.includes("sidebar")) {
        clickedSidebar = true
      }
    }
    if (!clickedSidebar && window.innerWidth <= 1000) {
      setOpen(false)
    }
  }

  const handleResize = () => {
    if (window.innerWidth > 1000 && windowWidth <= 1000) {
      setOpen(true)
    }
    else if (window.innerWidth <= 1000 && windowWidth > 1000) (
      setOpen(false)
    )
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("click", handleClick)
    return () => {
      window.removeEventListener("click", handleClick)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [windowWidth])

  useEffect(() => {    
    if (location.pathname === "/ranking") moveIcon("ranking")
    if (location.pathname === "/benchmarks") moveIcon("benchmark")
    else if (location.pathname === "/model-page") moveIcon("model")
  }, [location.pathname])

  return (
    renderBool &&
    <>
      <div 
        id="sidebar__expand"
        style={{
          display: open ? "none" : "block"
        }} 
        onClick={() => setOpen((prevState) => { return !prevState })}>
      </div>

      <div id="sidebar__background" style={{ display: open ? "flex" : "none" }}></div>
      <div id="sidebar" style={{ display: open ? "flex" : "none" }}>
        <div id="sidebar__icons">
          <div id="sidebar__logo-frost"></div>
          <Link style={{zIndex: 1}} to="/" onClick={() => moveIcon()}>
            <img src={logo} alt="logo" id="sidebar__logo"/>
          </Link>          

          <div className="sidebar__icon" id="sidebar__icon--ranking"></div>
          <div className="sidebar__icon" id="sidebar__icon--benchmark"></div>
          <div className="sidebar__icon" id="sidebar__icon--model"></div>
        </div>      

        <div id="sidebar__labels">
          <p className="sidebar__label" id="sidebar__label--ranking">
            <Link to="/ranking" onClick={() => {            
              moveIcon("ranking")
            }} replace>Ranking</Link>
          </p>
          <p className="sidebar__label" id="sidebar__label--benchmark">
            <Link to="/benchmarks" onClick={() => {            
              moveIcon("benchmark")
            }} replace>Benchmarks</Link>
          </p>
          <p className="sidebar__label" id="sidebar__label--model">
            <Link to="/model-page" onClick={() => {           
              moveIcon("model")}
              }>Model</Link>
          </p>
        </div>

        <Wallet></Wallet>
      </div>
    </>
  );
};


export default Sidebar;
