import React, { useState, useEffect, useContext } from 'react'
import BenchmarkSelection from '../benchmark-selection/BenchmarkSelection'
import BenchmarkChart from '../benchmark-chart/BenchmarkChart'
import BenchmarkTable from '../benchmark-table/BenchmarkTable'
import './benchmark-container.css'
import axios from "axios"
require("dotenv").config()

const BenchmarkContainer = () => {
  const [benchmarkData, setBenchmarkData] = useState([])
  const [benchmark, setBenchmark] = useState(0)

  const backend = process.env.REACT_APP_BACKEND

  const getCleanData = (leaderboardData) => {
    // I hate how many loops I'm using    
    const cleanData = []
    leaderboardData.forEach((leaderboardRow) => {
      cleanData.push({
        id: leaderboardRow.id,
        version: leaderboardRow.modelVersion,
        date: new Date(parseInt(leaderboardRow.date)),
        mu: Math.round(leaderboardRow.mu * 100) / 100,
        range: Math.round((leaderboardRow.max - leaderboardRow.min) / 2 * 100) / 100,
        score: Math.round(leaderboardRow.min * 100) / 100
      })
    })
    cleanData.sort((a, b) => (a.score < b.score) ? 1 : -1)
    cleanData.forEach((x, idx) => {
      x.rank = idx + 1
    })
    setBenchmarkData(cleanData)
  }

  async function getBenchmarkData() {
    const benchmarkLeaderboardPromise = await axios.get(
      `${backend}research-data/benchmark-leaderboard?benchmark=${benchmark}`
    )
    getCleanData(benchmarkLeaderboardPromise.data)
  }

  useEffect(() => {
    getBenchmarkData()
  }, [benchmark])

  return (
    <>
      <div id="benchmark-container">
        <BenchmarkSelection benchmark={benchmark} setBenchmark={setBenchmark}></BenchmarkSelection>
        <BenchmarkChart benchmarkData={benchmarkData}></BenchmarkChart>
        <BenchmarkTable benchmarkData={benchmarkData} setBenchmarkData={setBenchmarkData}></BenchmarkTable>
      </div>
    </>
  )
}

export default BenchmarkContainer