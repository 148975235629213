import { assetDir } from '../asset-handling/render-helper'
import * as d3 from 'd3'
require('dotenv').config()

export function renderSimpleLoader(repeatBool) {
  const last_position_number = 25
  const animation_time_interval = 65
  const shineDir = assetDir + "shine-assets/"

  const svg = d3.select("#simple-loader").append("svg")
    .attr("width", "100%")
    .attr("height", "100%")
    .style("overflow", "visible")

  svg.append("svg:image")
    .attr("class", `game-logo`)
    .attr('x', 0)
    .attr('y', 0)
    .attr('height', "100%")
    .attr('width', "100%")
    .attr("xlink:href", decodeURI(assetDir + "game-logo.png"))

  for (var i = 0; i <= last_position_number; i++) {
    var assetFile = `${shineDir}shine-${i}.png`
    svg.append("svg:image")
      .attr("class", "logo-shine")
      .attr("id", `logo-shine-${i}`)
      .attr("xlink:href", decodeURI(assetFile))
      .attr("x", "-0.5%")
      .attr("y", "-0.5%")
      .attr('height', "100.5%")
      .attr('width', "104.5%")
      .style("opacity", 0)
  }

  function logoShine(position_number, repeat) {
    if (position_number >= last_position_number) {
      svg.select(`#logo-shine-${position_number-1}`).style("opacity", 0)
      if (repeat) {
        setTimeout(function() {
          logoShine(0, repeatBool)
        }, animation_time_interval * 10)
      }
      else {
        svg.selectAll(".logo-shine").remove()
        return
      }
    }
    else {
      setTimeout(function() {
        if (position_number > 0) {
          svg.select(`#logo-shine-${position_number-1}`).style("opacity", 0)
        }
        svg.select(`#logo-shine-${position_number}`).style("opacity", 1)
        logoShine(position_number + 1, repeatBool)
      }, animation_time_interval)
    }
  }
  logoShine(0, repeatBool)
}
