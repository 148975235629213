import { snapBackToGuard } from '../animations/idle-animations'
import { battleMetaData } from './battle-meta-data'
import * as d3 from 'd3'

const resetLosingAttributes = (id) => {
  d3.select(`#dead-eyes-${id}`).remove()
  d3.select(`#dead-mouth-${id}`).remove()
  d3.select(`#eyes-${id}`).style("display", "block")
  d3.select(`#mouth-${id}`).style("display", "block")
}

const resetFighterPositions = (id1, id2) => {
  snapBackToGuard(`#character${id1}`)
  snapBackToGuard(`#character${id2}`)

  resetLosingAttributes(id1)
  resetLosingAttributes(id2)

  d3.select(`#character${id1}`)
    .style("left", battleMetaData["character1"].xOffset)
    .select("svg")
      .attr("transform", "scale(1,1)")
      .style("-webkit-transform", "scale(1,1)")
  d3.select(`#character${id2}`)
    .style("left", battleMetaData["character2"].xOffset)
    .select("svg")
      .attr("transform", "scale(-1,1)")
      .style("-webkit-transform", "scale(-1,1)")

  d3.select(`#attacker-health${id1}`).style("width", "100%")
  d3.select(`#attacker-health${id2}`).style("width", "100%")

  d3.select(`#character${id1}`).selectAll(".shield-icon").attr("display", "none")
  d3.select(`#character${id2}`).selectAll(".shield-icon").attr("display", "none")
}

export {
  resetFighterPositions
}