import React, { useState, useEffect } from 'react'
import RankingTable from '../ranking-table/RankingTable'
import RankingHighlevel from '../ranking-highlevel/RankingHighlevel'
import BattleLoaderPopup from '../battle-loader-popup/BattleLoaderPopup'
import { getSortedLeaderboard } from '../../../helpers/stats-page/leaderboard'
import './ranking-container.css'

const RankingContainer = () => {
  const [rankingData, setRankingData] = useState([])
  const [highlevelData, setHighlevelData] = useState({})
  const [battleLoading, setBattleLoading] = useState(false)
  const [battleState, setBattleState] = useState(undefined)
  const [loaderData, setLoaderData] = useState({ 
    showing: false, 
    yourData: {}, 
    opponentData: {} 
  })

  async function getRankingData() {
    const [teamData, cleanData, totalGamesPlayed, modelUploads] = await getSortedLeaderboard()
    var topTeam = { team: undefined, cumulativeRating: 0, logo: undefined }
    Object.keys(teamData).forEach((team) => {
      const currentRating = teamData[team].cumulativeRating / teamData[team].teamMembers
      if (currentRating > topTeam.cumulativeRating) {
        topTeam.team = team
        topTeam.cumulativeRating = currentRating
        topTeam.logo = teamData[team].logo
      }
    })
    setHighlevelData({ 
      gamesPlayed: totalGamesPlayed,
      modelUploads: modelUploads,
      teamData: teamData,
      topTeam: topTeam
    })
    setRankingData(cleanData)
  }

  useEffect(() => {
    getRankingData()
  }, [])

  return (
    <>
      <BattleLoaderPopup 
        rankingData={rankingData}
        loaderData={loaderData}
        setLoaderData={setLoaderData}
        battleState={battleState}
        setBattleState={setBattleState}
        setBattleLoading={setBattleLoading}>
      </BattleLoaderPopup>
      <div id="ranking-container">
        <RankingHighlevel 
          highlevelData={highlevelData}
          setLoaderData={setLoaderData}
          battleState={battleState}
          setBattleState={setBattleState}
          battleLoading={battleLoading}>
        </RankingHighlevel>
        <RankingTable 
          rankingData={rankingData} 
          setRankingData={setRankingData}>
        </RankingTable>
      </div>
    </>
  )
}

export default RankingContainer