import React, { useContext } from "react"
import { Context } from "../../../Store"

import ModelProfile from "./model-profile/ModelProfile"
import ModelSummary from "./model-summary/ModelSummary"
import ModelVersionHistory from "./model-version-history/ModelVersionHistory"
import ModelUploadArea from "./model-upload-area/ModelUploadArea"
import GuestModelUploadArea from "./model-upload-area/GuestModelUploadArea"
import IncorrectNetwork from "./model-upload-area/IncorrectNetwork"
import ModelButtons from './model-buttons/ModelButtons'

import "./model-high-level.css"

const ModelHighlevel = ({ 
  rankedStats,
  modelStats, 
  setModelStats, 
  currentVersion, 
  setCurrentVersion, 
  setRunningBenchmark, 
  setModelPerformance, 
  ranks,
  benchmarkView
}) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)

  const maxVersion = (
    websiteContext.yourFighter !== undefined ? 
    parseInt(websiteContext.yourFighter.neuralNetVersion) :
    undefined
  )

  return (
    <>
      <div id="model-page__high-level">
        <ModelProfile guestMode={websiteContext.guestMode}></ModelProfile>

        <ModelSummary 
          guestMode={websiteContext.guestMode}
          rankedStats={rankedStats}
          modelStats={modelStats}
          ranks={ranks}>
        </ModelSummary>

        <ModelVersionHistory
          guestMode={websiteContext.guestMode}
          currentVersion={currentVersion}
          setCurrentVersion={setCurrentVersion}
          maxVersion={maxVersion}
          benchmarkView={benchmarkView}>        
        </ModelVersionHistory>

        {
          websiteContext.guestMode &&
          <GuestModelUploadArea></GuestModelUploadArea>
        }
        {
          !websiteContext.guestMode &&
          websiteContext.smartContract !== undefined &&
          websiteContext.neuralNetworkContract !== undefined &&
          <ModelUploadArea 
            existingModel={currentVersion !== undefined}>
          </ModelUploadArea>
        }   
        {
          !websiteContext.guestMode &&
          (
            websiteContext.smartContract === undefined ||
            websiteContext.neuralNetworkContract === undefined
          ) &&
          <IncorrectNetwork></IncorrectNetwork>
        }
        <ModelButtons
          modelStats={modelStats}
          setModelStats={setModelStats }
          currentVersion={currentVersion}
          maxVersion={maxVersion}
          setRunningBenchmark={setRunningBenchmark}
          setModelPerformance={setModelPerformance}
        >
        </ModelButtons>     
      </div>
    </>
  )
}

export default ModelHighlevel