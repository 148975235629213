import React, { useContext, useEffect } from 'react'
import { Context } from '../../Store'
import ModelPage from './ModelPage'
import ConnectWalletPage from '../website-scaffolding/ConnectWalletPage'
import Loader from '../generic-components/loader/Loader'

const ModelPageContainer = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  // const showLoader = websiteContext.smartContract === undefined || !websiteContext.networkSupported
  // const connectWallet = !showLoader && websiteContext.userAddress === undefined
  const showLoader = false
  const connectWallet = false
  
  
  return (
    <>
      {showLoader && <Loader repeatBool={true} smallLoaderBool={true}></Loader>}
      {connectWallet && <ConnectWalletPage></ConnectWalletPage>}
      {
        !showLoader && !connectWallet && 
        <ModelPage characters={websiteContext.userFighters}></ModelPage>
      }
    </>
  )
}

export default ModelPageContainer
