import { neuralNetwork } from '../machine-learning/neural-network'

const attachModel = (character) => {
  const modelAttributes = character.model
  if (modelAttributes.type !== "rules-based-agent") {
    const functionalModel = new neuralNetwork(
      modelAttributes.nFeatures,
      modelAttributes.neurons,
      modelAttributes.nActions,
      modelAttributes.flattenedWeights,
      modelAttributes.flattenedBiases,  
      modelAttributes.metadata
    )
    character.model = functionalModel
  }
  return character
}

export {
  attachModel
}
