import { palette } from "../design-map/color-palette"
import * as d3 from 'd3'

const resultColors = { 
  "won": {border: palette.typography[1], background: "rgb(42, 65, 131)"}, 
  "lost": {border: palette.typography[3], background: "rgb(84, 26, 40)"},
  "tied": {border: "#cc96ff", background: "rgba(67, 17, 138, 0.3)"}
}

const gradientWinLoss = ["#1e95bf", "#715ca8", "#8a112d"]

const groups = {
  "Single Punch": "Punching",
  "Double Punch": "Punching",
  "Jump Punch": "Punching",
  "Low Kick": "Kicking",
  "Run Left": "Running",
  "Run Right": "Running",
  "Jump": "Jumping",
  "Jump Left": "Jumping",
  "Jump Right": "Jumping",
  "Defend": "Defending",
}

const getGroupedPercentages = (groupCount, totalExecuted) => {
  const groupKeys = Object.keys(groupCount)
  for (var i = 0; i < groupKeys.length; i++) {
    groupCount[groupKeys[i]] /= totalExecuted
  }
  return groupCount
}

const getMatchStats = (performance, resultToIdx) => {
  var currentPerformance
  var tempSummaryStats
  var actions

  const allMatchStats = []
  for (var b = 0; b < performance.length; b++) {
    currentPerformance = performance[b]
    tempSummaryStats = { 
      benchmark: currentPerformance.benchmark,
      results: [0,0,0], 
      duration: 0,
      actionDominance: {},
      actionDominanceRounds: [],
      opponentActionDominance: {},
      totalExecuted: 0,
      totalExecutedRounds: [],
      attacksLanded: [],
      attacksExecuted: [],
      score: 0
    }
    var currentRound
    var currentExecuted
    var currentOpponentExecuted
    var currentGroup
    actions = Object.keys(currentPerformance.rounds[0].actions)
    for (var i = 0; i < currentPerformance.rounds.length; i++) {
      currentRound = currentPerformance.rounds[i]
      tempSummaryStats.results[resultToIdx[currentRound.winner]] += 1
      tempSummaryStats.duration += currentRound.duration
      tempSummaryStats.score += currentRound.score
      tempSummaryStats.actionDominanceRounds.push({})
      tempSummaryStats.totalExecutedRounds.push(0)
      tempSummaryStats.attacksLanded.push(0)
      tempSummaryStats.attacksExecuted.push(0)
      for (var a = 0; a < actions.length; a++) {
        currentGroup = groups[actions[a]]

        currentExecuted = currentRound.actions[actions[a]].numExecuted
        if (tempSummaryStats.actionDominance[currentGroup] === undefined) {
          tempSummaryStats.actionDominance[currentGroup] = currentExecuted
        }
        else {
          tempSummaryStats.actionDominance[currentGroup] += currentExecuted
        }

        currentOpponentExecuted = currentRound.opponentActions[actions[a]].numExecuted
        if (tempSummaryStats.opponentActionDominance[currentGroup] === undefined) {
          tempSummaryStats.opponentActionDominance[currentGroup] = currentOpponentExecuted
        }
        else {
          tempSummaryStats.opponentActionDominance[currentGroup] += currentOpponentExecuted
        }

        if (tempSummaryStats.actionDominanceRounds[i][currentGroup] === undefined) {
          tempSummaryStats.actionDominanceRounds[i][currentGroup] = currentExecuted
        }
        else {
          tempSummaryStats.actionDominanceRounds[i][currentGroup] += currentExecuted
        }
        if (currentGroup === "Punching" || currentGroup === "Kicking") {
          tempSummaryStats.attacksLanded[i] += currentRound.actions[actions[a]].numSuccessful
          tempSummaryStats.attacksExecuted[i] += currentExecuted
        }
        tempSummaryStats.totalExecutedRounds[i] += currentExecuted
        tempSummaryStats.totalExecuted += currentExecuted
      } 
      tempSummaryStats.actionDominanceRounds[i] = getGroupedPercentages(
        tempSummaryStats.actionDominanceRounds[i], tempSummaryStats.totalExecutedRounds[i]
      )
    }
    tempSummaryStats.duration /= currentPerformance.rounds.length
    tempSummaryStats.score /= currentPerformance.rounds.length
    tempSummaryStats.actionDominance = getGroupedPercentages(
      tempSummaryStats.actionDominance,
      d3.max(Object.values(tempSummaryStats.actionDominance))
    )
    tempSummaryStats.opponentActionDominance = getGroupedPercentages(
      tempSummaryStats.opponentActionDominance,
      d3.max(Object.values(tempSummaryStats.opponentActionDominance))
    )
    allMatchStats.push(tempSummaryStats)
  }
  return allMatchStats
}

export {
  resultColors,
  gradientWinLoss,
  groups,
  getGroupedPercentages,
  getMatchStats
}