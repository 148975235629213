import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const SimulationReplay = () => {
  const history = useHistory()
  const location = useLocation()
  const fighterChosen = location.state !== undefined

  useEffect(() => {
    if (!fighterChosen) {
      history.push('/model-page')
    }
    else {
      history.push({
        pathname: '/simulation',
        state: {
          yourFighter: location.state.yourFighter,
          yourOpponent: location.state.yourOpponent,
          yourSide: location.state.yourSide
        }
      })
    }
  }, [])

  return (
    <div></div>
  )
}

export default SimulationReplay
