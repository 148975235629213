import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../../../Store'
import { useHistory, useLocation } from "react-router-dom"
import Music from '../../website-scaffolding/Music'
import SoundFX, { playSoundEffect } from '../sound-fx/SoundFX'
import Character from '../../character/Character'
import HealthBar from '../hud-components/HealthBar'
import Modal, { displayModal } from '../../generic-components/custom-modal/CustomModal'
import { simulationMetaData } from '../../../helpers/game-setup/battle-meta-data'
import Game from '../../../helpers/game-setup/battle-game'
import { resizeArena } from '../../../helpers/game-setup/arena-styling'
import { runFightersLoadedChecker } from '../../../helpers/game-setup/pre-battle-loading'
import { animationFrames } from '../../../helpers/game-setup/actions'
import { attachModel } from '../../../helpers/game-setup/model-setup'
import { battleAssets } from '../../../helpers/asset-files/battle-assets'
import { delayBase } from '../../../helpers/animations/animation-helper'
import { winAnimation, loseAnimation } from '../../../helpers/animations/gameover-animation'
import {
  adjustHealthBar,
  runLeftAnimation,
  runRightAnimation,
  jumpAnimation,
  jumpLeftAnimation,
  jumpRightAnimation,
  jumpPunchAnimation,
  singlePunchAnimation,
  doublePunchAnimation,
  lowKickAnimation,
  startDefendAnimation,
  endDefendAnimation,
  hitShieldAnimation,
  stunAnimation,
  getHitAnimation
} from '../../../helpers/animations/battle-animation'
import {
  loadReadyFightAssets,
  renderReadyFightAnimation
} from '../../../helpers/animations/ready-fight-animation'
import '../battle.css'
import * as d3 from 'd3'
import axios from 'axios'
import RecordGameplay from '../../generic-components/record-gameplay/RecordGameplay'
import download from "downloadjs"
require('dotenv').config()

// On the server side, check that the user's fighter is not for sale - if it is,
// then return an error and don't execute the battle

const Simulation = () => {
  const [websiteContext, setWebsiteContext] = useContext(Context)
  const [readyFightSvg, setReadyFightSvg] = useState(undefined)
  const [battleBool, setBattleBool] = useState(true)
  const [timeRemaining, setTimeRemaining] = useState({decimal: undefined, int: undefined})
  const [gameoverInfo, setGameoverInfo] = useState({popup: undefined, popupType: ""})
  const [fightersLoaded, setFightersLoaded] = useState({ "0": false, "1": false })

  // State and function for downloading video recording
  const [chunks, setChunks] = useState([])
  const [recording, setRecording] = useState(false)
  const downloadReplay = () => {
    if (chunks.length > 0) {
      let blob = new Blob(chunks, { 'type' : 'video/mp4;' })
      download(blob)
      setChunks([])
    }
    else {
      alert("You do not have anything to save. Next time click on the 'Record' button in the bottom menu")
    }
  }
 
  const history = useHistory()
  const location = useLocation()
  const yourFighter = location.state.yourFighter
  const yourOpponent = location.state.yourOpponent
  const yourSide = location.state.yourSide

  // var startTime

  const backend = process.env.REACT_APP_BACKEND
  const delegatedAddress = process.env.REACT_APP_DELEGATED_ADDRESS_1

  var allTimeouts = []

  const battleCharacters = new Array(2)
  battleCharacters[0] = yourSide === "left" ? attachModel(yourFighter) : attachModel(yourOpponent)
  battleCharacters[1] = yourSide === "left" ? attachModel(yourOpponent) : attachModel(yourFighter)

  const battleIdx = new Array(2)
  battleIdx[0] = yourSide === "left" ? yourFighter.id : yourOpponent.id
  battleIdx[1] = yourSide === "left" ? yourOpponent.id : yourFighter.id

  const getHealth = (id) => {
    if (d3.select(".simulation-stage")._groups[0][0] === null) {
      setBattleBool(false)
      setTimeRemaining({decimal: 0, int: 0})
    }
    else {
      return +d3.select(`#attacker-health${id}`).style("width").replace("%","")
    }
  }

  const runTheClock = (animationSpeed, lastFrame) => {
    if (timeRemaining.decimal > 0 && battleBool) {
      setTimeRemaining((prevState) => {
        if (prevState.decimal > 0) {
          const decimalTime = Math.max(Math.round(prevState.decimal * 1000 - animationSpeed) / 1000, 0)
          if (lastFrame && decimalTime < 1) {
            return {deminal: 0, int: 0}
          }
          return {decimal: decimalTime, int: Math.ceil(decimalTime)}
        }
        else {
          return {deminal: 0, int: 0}
        }
      })
    }
  }

  const bringToGround = (id) => {
    d3.select(id).select(".svg-responsive__content")
      .transition()
        .duration(delayBase * 4)
        .style("bottom", "10%")
        .style("top", "auto")
  }

  const afterGameAction = (decision) => {
    allTimeouts.push(setTimeout(() => {
      if (recording) {
        setRecording(false)
      }
      if (d3.select("#music-button--mute").style("display") === "none") {
        websiteContext.songPlayingHowler.pause()
      }
      if (decision === "win") playSoundEffect("sound-fx--win")
      else if (decision === "draw") playSoundEffect("sound-fx--draw")
      else if (decision === "loss") playSoundEffect("sound-fx--loss")
      displayModal()
    }, 1500))
  }

  const declareWinner = (healthPlayer1, healthPlayer2) => {
    bringToGround(`#character${battleCharacters[0].id}`)
    bringToGround(`#character${battleCharacters[1].id}`)
    if (battleBool) {
      if (healthPlayer1 !== healthPlayer2) {
        const winner = healthPlayer1 > healthPlayer2 ? 1 : 2
        if ((winner === 1 && yourSide === "left") || (winner === 2 && yourSide === "right")) {
          setGameoverInfo({popup: battleAssets.win, popupType: "Win"})
          allTimeouts.push(setTimeout(() => {
            allTimeouts = winAnimation(yourFighter.id, 1, allTimeouts)
            allTimeouts = loseAnimation(yourOpponent.id, allTimeouts)
            afterGameAction("win")
          }, 500))
        }
        else {
          setGameoverInfo({popup: battleAssets.lose, popupType: "Lose"})
          allTimeouts.push(setTimeout(() => {
            allTimeouts = loseAnimation(yourFighter.id, allTimeouts)
            allTimeouts = winAnimation(yourOpponent.id, 1, allTimeouts)
            afterGameAction("loss")
          }, 500))
        }
      }
      else {
        setGameoverInfo({popup: battleAssets.tie, popupType: "Tie"})
        allTimeouts.push(setTimeout(() => {
          allTimeouts = loseAnimation(yourFighter.id, allTimeouts)
          allTimeouts = loseAnimation(yourOpponent.id, allTimeouts)
          afterGameAction("draw")
        }, 500))
      }
      setBattleBool(false)
    }
  }

  const animatePlayer = (currentCharacter, animationType, xPositions, yPositions, prevShieldHealth, currentShieldHealth) => {
    if (d3.select(".simulation-stage")._groups[0][0] !== null) {
      const currentIdNumber = currentCharacter.id
      const shieldHit = prevShieldHealth !== currentShieldHealth
      if (!animationType.includes("Defend") && !animationType.includes("Shield")) {
        d3.select(`#character${currentIdNumber}`).selectAll(".shield-icon").attr("display", "none")
      }

      var animationSpeed
      if (animationType === "Run Left") {
        [animationSpeed, allTimeouts] = runLeftAnimation(currentIdNumber, xPositions, allTimeouts)
      }
      else if (animationType === "Run Right") {
        [animationSpeed, allTimeouts] = runRightAnimation(currentIdNumber, xPositions, allTimeouts)
      }
      else if (animationType === "Single Punch") {
        [animationSpeed, allTimeouts] = singlePunchAnimation(currentIdNumber, allTimeouts)
      }
      else if (animationType === "Double Punch") {
        [animationSpeed, allTimeouts] = doublePunchAnimation(currentIdNumber, allTimeouts)
      }
      else if (animationType === "Start Defend") {
        [animationSpeed, allTimeouts] = startDefendAnimation(currentIdNumber, allTimeouts)
      }
      else if (animationType === "Continue Defend") {
        animationSpeed = animationFrames[animationType] * delayBase
      }
      else if (animationType === "Hit Shield") {
        [animationSpeed, allTimeouts] = hitShieldAnimation(currentIdNumber, prevShieldHealth, currentShieldHealth, allTimeouts)
      }
      else if (animationType === "End Defend") {
        [animationSpeed, allTimeouts] = endDefendAnimation(currentIdNumber, 0, shieldHit, allTimeouts)
      }
      else if (animationType === "Stun") {
        [animationSpeed, allTimeouts] = stunAnimation(currentIdNumber, 0, allTimeouts)
      }
      else if (animationType === "Jump") {
        [animationSpeed, allTimeouts] = jumpAnimation(currentIdNumber, yPositions, allTimeouts)
      }
      else if (animationType === "Jump Left") {
        [animationSpeed, allTimeouts] = jumpLeftAnimation(currentIdNumber, xPositions, yPositions, allTimeouts)
      }
      else if (animationType === "Jump Right") {
        [animationSpeed, allTimeouts] = jumpRightAnimation(currentIdNumber, xPositions, yPositions, allTimeouts)
      }
      else if (animationType === "Jump Punch") {
        [animationSpeed, allTimeouts] = jumpPunchAnimation(currentIdNumber, yPositions, allTimeouts)
      }
      else if (animationType === "Get Hit") {
        [animationSpeed, allTimeouts] = getHitAnimation(currentIdNumber, allTimeouts)
        bringToGround(`#character${currentIdNumber}`)
      }
      else if (animationType === "Low Kick") {
        [animationSpeed, allTimeouts] = lowKickAnimation(currentIdNumber, allTimeouts)
      }
      return animationSpeed
    }
  }

  var startTime
  const animationFrameTracker = {0: 0, 1: 0}
  const expectedTimeElapsed = {0: 0, 1: 0}
  const drifts = {0: 0, 1: 0}

  const animationLoop = (whichFighter, animationSpeed, states, idx) => {
    if (idx >= states.length) return

    const gameplayScreen = d3.select(".gameplay-screen__container")._groups[0][0]
    if (gameplayScreen === null || gameplayScreen === undefined) return

    const { action, health, x, y, modelState, animationFrame } = states[idx]

    allTimeouts.push(setTimeout(() => {
      if (battleBool) {
        // This adjusts the position for non-moving actions, but when the shield is used
        if (idx === 0 || x[0] !== states[idx-1].x[states[idx-1].x.length-1]) {
          if (!action.includes("Left") && !action.includes("Right")) {
            d3.select(`#character${battleCharacters[whichFighter].id}`)
              .transition()
                .duration(delayBase)
                .ease(d3.easeLinear)
                .style("left", x[0] * 100 + "%")
          }
        }

        animationFrameTracker[whichFighter] = animationFrame
        expectedTimeElapsed[whichFighter] = animationFrame * delayBase
        const actualTimeElapsed = Date.now() - startTime
        drifts[whichFighter] = actualTimeElapsed - expectedTimeElapsed[whichFighter]
        const relativeDrift = drifts[whichFighter] - drifts[(whichFighter + 1) % 2]

        var prevShieldHealth = states[idx].shieldHealth
        const currentShieldHealth = states[idx].shieldHealth
        if (idx > 0) {
          prevShieldHealth = states[idx-1].shieldHealth
        }

        const otherFighterIdx = (whichFighter + 1) % 2
        var proposedAnimationSpeed = animatePlayer(
          battleCharacters[whichFighter],
          action,
          x,
          y,
          prevShieldHealth,
          currentShieldHealth
        )
        proposedAnimationSpeed += delayBase

        if (idx < states.length - 1) {
          if (states[idx+1].action === "Get Hit" || states[idx+1].action === "Hit Shield") {
            proposedAnimationSpeed = (states[idx+1].framesBeforeHit) * delayBase
          }
        }

        const lastFrame = idx === states.length - 1
        if (whichFighter === 0) runTheClock(proposedAnimationSpeed, lastFrame)

        // We subtract the drift after running the clock to make sure the clock is accurate
        proposedAnimationSpeed -= Math.min(relativeDrift, 0)

        if (action === "Get Hit") {
          adjustHealthBar(battleCharacters[whichFighter].id, health * 100, delayBase * 4)
        }

        var healthPlayer1 = getHealth(battleIdx[0])
        var healthPlayer2 = getHealth(battleIdx[1])
        if (whichFighter === 0) {
          healthPlayer1 = d3.min([healthPlayer1, health])
        }
        else if (whichFighter === 1) {
          healthPlayer2 = d3.min([healthPlayer2, health])
        }

        if (healthPlayer1 === 0 || healthPlayer2 === 0) {
          declareWinner(healthPlayer1, healthPlayer2)
        }
        else {
          animationLoop(whichFighter, proposedAnimationSpeed, states, idx + 1)
        }
      }
    }, animationSpeed))
  }

  const renderBattle = (animationStates) => {
    setTimeout(() => {
      playSoundEffect("sound-fx--start-fight")
    }, 200)
    renderReadyFightAnimation(readyFightSvg)
    allTimeouts.push(setTimeout(() => {
      startTime = Date.now()
      animationLoop(0, 0, animationStates[0], 0)
      animationLoop(1, 0, animationStates[1], 0)
    }, 50 * 60 + 550))
  }

  const runBattle = () => {
    var game
    if (yourSide === "left") {
      game = new Game(yourFighter, yourOpponent, timeRemaining.int)
    }
    else {
      game = new Game(yourOpponent, yourFighter, timeRemaining.int)
    }
    const [animationStates, winner] = game.runBattle()
    d3.select("#battle-button").style("display", "none")
    renderBattle(animationStates)
  }

  async function getTimeLimit() {
    const gameTimeLimit = await axios.get(`${backend}battle/game-time-limit`)
    const maxSeconds = gameTimeLimit.data.seconds
    setTimeRemaining({decimal: maxSeconds.toString(), int: maxSeconds.toString()})
  }

  useEffect(() => {
    getTimeLimit()
    setReadyFightSvg(loadReadyFightAssets())
    runFightersLoadedChecker(battleCharacters, setFightersLoaded)
    resizeArena()

    window.addEventListener("resize", resizeArena)
    return () => {
      window.removeEventListener("resize", resizeArena)
      setBattleBool(false)
      setTimeRemaining({decimal: 0, int: 0})
      var currentTimeout;
      while (allTimeouts.length > 0) {
        currentTimeout = allTimeouts.pop()
        clearTimeout(currentTimeout)
      }
    }
  }, [])

  useEffect(() => {
    if (battleBool && timeRemaining.int === 0) {
      const healthPlayer1 = getHealth(battleIdx[0])
      const healthPlayer2 = getHealth(battleIdx[1])
      declareWinner(healthPlayer1, healthPlayer2)
    }
  }, [timeRemaining])

  const routeHome = () => {
    setBattleBool(false)
    setTimeRemaining({decimal: 0, int: 0})
    history.push('/model-page')
  }

  const routeReplay = () => {
    history.push({
      pathname: '/simulation-replay',
      state: {
        yourFighter: yourFighter,
        yourOpponent: yourOpponent,
        yourSide: yourSide
      }
    })
  }

  const concludingStatement = (
    <div>
      <h3 id="concluding-statement" style={{fontSize: "1em"}}>
        Did your AI perform as you expected?
        <br/>
        <br/>
        What do you want to do next?
      </h3>
    </div>
  )

  const getModalSubContent = () => {
    return (
      <div className="modal-game-over">
        {concludingStatement}
        <div className="model-game-over__buttons-container">
          <div 
            className="model-game-over__button"
            onClick={() => routeReplay()}>
            <p>PLAY AGAIN</p>
          </div>
          <div 
            className="model-game-over__button"
            onClick={() => routeHome()}>
            <p>GO BACK</p>
          </div>
          {
            chunks.length > 0 &&
            <div 
              className="model-game-over__button"
              onClick={() => downloadReplay()}>
              <p>SAVE REPLAY</p>
            </div>
          }
        </div>
      </div>
    )
  }

  const modalSubContent = getModalSubContent()
  const additionalModalStyling = {
    width: "300px",
    height: "250px",
  }

  const resultsModalData = {
    modalSubContent: modalSubContent,
    additionalModalStyling: additionalModalStyling
  }

  const redBoss = yourOpponent.id === "cpu5"
  const stageClass = `simulation-stage--${redBoss ? "red" : "normal"}`
  const stageContainerId = `simulation-screen__container--${redBoss ? "red" : "normal"}`
  return (
    <>
      <SoundFX></SoundFX>
      <div id={stageContainerId} className="gameplay-screen__container">
        <div className="gameplay-menu--horizontal"
             style={{background: redBoss ? "rgb(177, 81, 80)" : "rgb(162, 155, 130)"}}>
          <div className="icon-text__container">
            <img className="exit-button"
                 src={battleAssets.exit}
                 alt="Exit"
                 onClick={() => routeHome()}/>
            <h2 style={{
              color: "white",
              transform: "translateY(3px)",
              marginTop: "5px"
            }}>Exit</h2>
          </div>

          <div className="icon-text__container">
            <Music startMusicOn={!websiteContext.musicMuted}></Music>
            <h2 style={{
              color: "white",
              transform: "translateY(3px)",
              marginTop: "5px"
            }}>Music</h2>
          </div>

          <div className="icon-text__container" id="record-gameplay__container">
            <RecordGameplay 
              recording={recording} 
              setRecording={setRecording} 
              setChunks={setChunks}>  
            </RecordGameplay>
            <h2 style={{
              color: "white",
              transform: "translateY(3px)",
              marginTop: "5px"
            }}>{!recording ? "Record" : "Stop"}</h2>
          </div>
        </div>

        <div className={"simulation-stage " + stageClass} id="battle-arena">
          {
            fightersLoaded["0"] && fightersLoaded["1"] &&
            <div id="battle-button"
                 className="button"
                 onClick={() => runBattle()}>
              Battle
            </div>
          }

          <div className="battle-container">
            <div id="ready-fight-animation"></div>

            <div className="clock">
              <h2>{timeRemaining.int}</h2>
            </div>

            <HealthBar character={battleCharacters[0]} attackerId="0" mirror={false}></HealthBar>
            <Character {...battleCharacters[0]} {...simulationMetaData["character1"]} size="1"></Character>

            <HealthBar character={battleCharacters[1]} attackerId="1" mirror={true}></HealthBar>
            <Character {...battleCharacters[1]} {...simulationMetaData["character2"]} size="1"></Character>
          </div>
          <Modal {...resultsModalData}></Modal>
        </div>
      </div>
    </>
  )
}

export default Simulation
