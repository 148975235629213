import React, { useContext } from 'react'
import { Context } from '../../../Store'
import { getFighterObject } from '../../../helpers/machine-learning/ethereum-requests'
import './ranking-highlevel.css'
import * as d3 from 'd3'
import axios from "axios"
require("dotenv").config()

const RankingContainer = ({ highlevelData, setLoaderData, battleState, battleLoading }) => {
  const [websiteContext, setWebsiteContext] = useContext(Context)     

  const backend = process.env.REACT_APP_BACKEND

  const walletConnected = websiteContext.userAddress !== undefined
  const hasFighter = websiteContext.yourFighter !== undefined
  d3.select("#wallet-connect__button--connect")
    .classed("pulsing-connect-button", !walletConnected)

  const runBattle = () => {
    axios.get(
      `${backend}research-data/ranked-battle-opponent?id=${websiteContext.yourFighter.id}`
    )
    .then(async (opponentData) => {
      console.log("gettign blockchain data")
      const opponent = await getFighterObject(
        opponentData.data.opponent.id,
        websiteContext.fighterContractInstance,
        websiteContext.neuralNetworkContractInstance
      )
      console.log("setting data")
      opponent.signature = opponentData.data.signature
      setLoaderData({ 
        showing: true, 
        yourData: websiteContext.yourFighter,
        opponentData: opponent
      })
    })
  }

  const runBattleAvailable = battleState === undefined && !battleLoading

  return (
    <>
      <div id="ranking-highlevel__container">
        <div id="ranking-highlevel__buttons">
        <h3>COMPETITION IS OVER!</h3>
          {/* {
            walletConnected && 
            hasFighter &&
            <div 
              id="ranking-highlevel__run-battle" 
              className={
                runBattleAvailable ? 
                "ranking-highlevel__battle--available" : 
                "ranking-highlevel__battle--not-available"
              }
              onClick={() => runBattle()}>
                <p>RUN BATTLE</p>
            </div>
          }
          {
            battleState === undefined && 
            !battleLoading && !
            walletConnected &&
            <h3>WALLET NOT CONNECTED</h3>
          }
          {
            battleState === undefined && 
            !battleLoading && 
            walletConnected && 
            !hasFighter &&
            <h3>MODEL NOT UPLOADED</h3>
          } */}
        </div>

        <div id="ranking-highlevel__top-team">
          <h3 className="ranking-highlevel__name">Top Team:</h3>
          {
            highlevelData.topTeam !== undefined &&
            <div className="ranking-highlevel__img-text">              
              <img className="ranking-highlevel__img" src={highlevelData.topTeam.logo} alt="" />
              <h1 className="ranking-highlevel__stat">{highlevelData.topTeam.team}</h1>  
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default RankingContainer