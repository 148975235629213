import React from 'react'
import './model-upload-area.css'

const IncorrectNetwork = () => {
  return (
    <>
      <div id="switch-network__container">
        <p>INCORRECT NETWORK</p>
      </div>
    </>
  )
}

export default IncorrectNetwork
