import React from 'react'
import './custom-modal.css'

const displayModal = (modalId) => {
  modalId = modalId !== undefined ? modalId : "my-modal"
  var modal = document.getElementById(modalId)
  if (modal !== null) {
    modal.style.display = "flex"
  }
}

const defaultModalStyling = {
  position: "relative",
  borderRadius: "5px",
  // backgroundColor: "rgba(27, 32, 51, 0.5)",
  backgroundColor: "rgba(74, 88, 144, 0.5)",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  backdropFilter: "blur(5px)"
}

const Modal = ({ modalSubContent, additionalModalStyling, modalId }) => {
  modalId = modalId !== undefined ? modalId : "my-modal"
  return (
    <>
      <div id={modalId} className="modal">
        <div 
          className="custom-modal-content" 
          style={{...defaultModalStyling, ...additionalModalStyling}}>
        {modalSubContent}
        </div>
      </div>
    </>
  )
}

export default Modal
export {
  displayModal
}
