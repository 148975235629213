import { delayBase } from './animation-helper'
import { iconScale, xBuffer, yBuffer } from '../asset-handling/render-helper'
import * as d3 from 'd3'
require('dotenv').config()

const assetDir = `https://${process.env.REACT_APP_IPFS_CIDV1}.${process.env.REACT_APP_FLEEK_GATEWAY}/`
const fxDir = assetDir + "fx/"

const uniqueImagesPerFX = {
  "Small Get Hit": 5,
  "Medium Get Hit": 5,
  "Large Get Hit": 5,
  "Single Punch": 4,
  "Double Punch": 4,
  "Jump Punch": 4,
  "High Kick": 8,
  "Low Kick": 6,
  "Jump": 6,
  "Run": 9
}

const repeatDict = (dict, n) => {
  const newDict = {}
  for (var i=0; i<n; i++) {
    newDict[i] = dict
  }
  return newDict
}


// FX Position and Size Specs
const positionSizeSpecs = {
  "Small Get Hit": repeatDict({ x: 0.3, y: 0.05, width: 0.5, height: 0.5 }, uniqueImagesPerFX["Small Get Hit"]),
  "Medium Get Hit": repeatDict({ x: -0.15, y: -0.4, width: 0.9, height: 0.9 }, uniqueImagesPerFX["Medium Get Hit"]),
  "Large Get Hit": repeatDict({ x: -0.45, y: -1.1, width: 1.30, height: 1.30 }, uniqueImagesPerFX["Large Get Hit"]),
  "Single Punch": repeatDict({ x: 0.62, y: -0.17, width: 0.65, height: 0.65 }, uniqueImagesPerFX["Single Punch"]),
  "Double Punch": repeatDict({ x: 0.62, y: -0.15, width: 0.65, height: 0.65 }, uniqueImagesPerFX["Single Punch"]),
  "Jump Punch": repeatDict({ x: 0.65, y: -0.36, width: 0.65, height: 0.65 }, uniqueImagesPerFX["Jump Punch"]),
  "High Kick": { // Still need to incorporate and fix high kick
    0: { x: 0.3, y: 0.26, width: 0.72, height: 0.72 },
    1: { x: 0.3, y: 0.26, width: 0.72, height: 0.72 },
    2: { x: 0, y: 0.26, width: 0.72, height: 0.72 },
    3: { x: 0, y: 0.26, width: 0.72, height: 0.72 },
    4: { x: 0.1, y: 0.21, width: 0.77, height: 0.77 },
    5: { x: 0.1, y: 0.21, width: 0.77, height: 0.77 },
    6: { x: 0.1, y: 0.19, width: 0.77, height: 0.77 },
    7: { x: 0.1, y: 0.19, width: 0.77, height: 0.77 },
  },
  "Low Kick": repeatDict({ x: 0.13, y: 0.45, width: 0.55, height: 0.55 }, uniqueImagesPerFX["Low Kick"]),
  "Jump": repeatDict({ x: -0.75, y: -0.45, width: 1.20, height: 1.20 }, uniqueImagesPerFX["Jump"]),
  "Run": repeatDict({ x: -0.7, y: 0.22, width: 0.65, height: 0.65 }, uniqueImagesPerFX["Run"])
}

// Functions to Load the Images for the FX
const renderAssetsFX = (
  characterCard, characterIdNumber, action, baseURL, fileName, charSize
) => {
  for (var i=0; i < uniqueImagesPerFX[action]; i++) {
    characterCard.append("svg:image")
      .attr("xlink:href", decodeURI(`${baseURL}${i+1}.png`))
      .attr("id", `${fileName}${i}-${characterIdNumber}`)
      .attr("class", `${fileName}fx`)
      .attr("x", charSize * (iconScale * positionSizeSpecs[action][i].x + xBuffer))
      .attr("y", charSize * (iconScale * positionSizeSpecs[action][i].y + yBuffer))
      .attr('height', charSize * (iconScale * positionSizeSpecs[action][i].height) + "%")
      .attr('width', charSize * (iconScale * positionSizeSpecs[action][i].width) + "%")
      .style("opacity", 0)
      .lower()
  }
}

const capitalizeWords = (unformattedWord) => {
  const capitalizedList = []
  unformattedWord.split("-").forEach(word => {
      capitalizedList.push(word[0].toUpperCase() + word.slice(1, word.length));
  })
  return capitalizedList.join(" ")
}

const loadAllFXAssets = (characterCard, characterIdNumber, charSize) => {
  const fileNames = [
    "get-hit",
    "single-punch",
    "double-punch",
    "low-kick",
    "jump",
    "run"
  ]
  const fxSizes = ["small", "medium", "large"]
  fileNames.forEach((name) => {
    const action = capitalizeWords(name)
    if (name === "get-hit") {
      fxSizes.forEach((sizeName) => {
        renderAssetsFX(
          characterCard, 
          characterIdNumber, 
          `${capitalizeWords(sizeName)} ${action}`, 
          `${fxDir+name}/${sizeName}/${name}-`, 
          `${sizeName}-${name}-`, 
          charSize
        )
      })
    }
    else {
      renderAssetsFX(
        characterCard, characterIdNumber, action, `${fxDir+name}/${name}-`, name+"-", charSize
      )
    }
  })
}

const transitionOpacity = (fileName, characterIdNumber, idx, startTime, charSize, positionSizes) => {
  const selection = d3.select(`#${fileName}${idx}-${characterIdNumber}`)
  if (positionSizes !== undefined) {
    selection
      .attr("x", charSize * (iconScale * positionSizes.x + xBuffer))
      .attr("y", charSize * (iconScale * positionSizes.y + yBuffer))
  }
  selection
    .transition()
      .delay(startTime)
      .duration(delayBase * 0.5)
      .style("opacity", 1)
      .transition()
        .duration(delayBase * 0.5)
        .style("opacity", 0)
}


// Animation Loop for the FX
const renderGetHitFX = (characterIdNumber, action) => {
  const defaultInputs = [action.split(" ").join("-").toLowerCase() + "-", characterIdNumber]
  transitionOpacity(...defaultInputs, 0, 0)
  transitionOpacity(...defaultInputs, 1, delayBase * 0.5)
  transitionOpacity(...defaultInputs, 2, delayBase)
  transitionOpacity(...defaultInputs, 3, delayBase * 1.5)
  transitionOpacity(...defaultInputs, 4, delayBase * 2)
}

const renderSinglePunchFX = (characterIdNumber, charSize, specialAction) => {
  const positionSizes = (
    specialAction !== undefined ? 
    positionSizeSpecs[specialAction] : 
    positionSizeSpecs["Single Punch"]
  )
  const defaultInputs = ["single-punch-", characterIdNumber]
  transitionOpacity(...defaultInputs, 0, delayBase * 1.5, charSize, positionSizes[0])
  transitionOpacity(...defaultInputs, 1, delayBase * 2, charSize, positionSizes[1])
  transitionOpacity(...defaultInputs, 2, delayBase * 2.5, charSize, positionSizes[2])
  transitionOpacity(...defaultInputs, 3, delayBase * 3, charSize, positionSizes[3])
  // transitionOpacity(...defaultInputs, 4, delayBase * 3.5, positionSizes[4])
}

const renderDoublePunchFX = (characterIdNumber) => {
  const defaultInputs = ["double-punch-", characterIdNumber]
  transitionOpacity(...defaultInputs, 0, delayBase * 1.5)
  transitionOpacity(...defaultInputs, 1, delayBase * 2)
  transitionOpacity(...defaultInputs, 2, delayBase * 2.5)
  transitionOpacity(...defaultInputs, 3, delayBase * 3)
  // transitionOpacity(...defaultInputs, 4, delayBase * 3.5)
}

const renderHighKickFX = (characterIdNumber) => {
  const defaultInputs = ["high-kick-", characterIdNumber]
  transitionOpacity(...defaultInputs, 0, delayBase * 0.5)
  transitionOpacity(...defaultInputs, 1, delayBase * 1)
  transitionOpacity(...defaultInputs, 2, delayBase * 1.5)
  transitionOpacity(...defaultInputs, 3, delayBase * 1.8)
  transitionOpacity(...defaultInputs, 4, delayBase * 2.2)
  transitionOpacity(...defaultInputs, 5, delayBase * 2.5)
  transitionOpacity(...defaultInputs, 6, delayBase * 3)
  transitionOpacity(...defaultInputs, 7, delayBase * 3.5)
  transitionOpacity(...defaultInputs, 8, delayBase * 4)
}

const renderLowKickFX = (characterIdNumber) => {
  const defaultInputs = ["low-kick-", characterIdNumber]
  transitionOpacity(...defaultInputs, 0, delayBase * 0.5)
  transitionOpacity(...defaultInputs, 1, delayBase * 1)
  transitionOpacity(...defaultInputs, 2, delayBase * 1.5)
  transitionOpacity(...defaultInputs, 3, delayBase * 2)
  transitionOpacity(...defaultInputs, 4, delayBase * 2.5)
  transitionOpacity(...defaultInputs, 5, delayBase * 3)
  transitionOpacity(...defaultInputs, 6, delayBase * 3.5)
}

const renderJumpFX = (characterIdNumber) => {
  const defaultInputs = ["jump-", characterIdNumber]
  transitionOpacity(...defaultInputs, 0, delayBase * 0.75)
  transitionOpacity(...defaultInputs, 1, delayBase * 1.5)
  transitionOpacity(...defaultInputs, 2, delayBase * 2.25)
  transitionOpacity(...defaultInputs, 3, delayBase * 3)
  transitionOpacity(...defaultInputs, 4, delayBase * 3.75)
  transitionOpacity(...defaultInputs, 5, delayBase * 4.5)
  transitionOpacity(...defaultInputs, 6, delayBase * 5.25)
}

const renderRunFX = (characterIdNumber) => {
  const defaultInputs = ["run-", characterIdNumber]
  transitionOpacity(...defaultInputs, 0, 0)
  transitionOpacity(...defaultInputs, 1, delayBase)
  transitionOpacity(...defaultInputs, 2, delayBase * 2)
  transitionOpacity(...defaultInputs, 3, delayBase * 3)
  transitionOpacity(...defaultInputs, 4, delayBase * 4)
  transitionOpacity(...defaultInputs, 5, delayBase * 5)
  transitionOpacity(...defaultInputs, 6, delayBase * 6)
  transitionOpacity(...defaultInputs, 7, delayBase * 7)
  transitionOpacity(...defaultInputs, 8, delayBase * 8)
}


// Generic Function to Render FX
const renderFX = (characterCard, characterIdNumber, charSize, action) => {
  if (action.includes("Get Hit")) {
    renderGetHitFX(characterIdNumber, action)
  }
  else if (action === "Single Punch") {
    renderSinglePunchFX(characterIdNumber, charSize)
  }
  else if (action === "Jump Punch") {
    renderSinglePunchFX(characterIdNumber, charSize, action)
  }
  else if (action === "Double Punch") {
    renderDoublePunchFX(characterIdNumber)
  }
  else if (action === "High Kick") {
    renderHighKickFX(characterIdNumber)
  }
  else if (action === "Low Kick") {
    renderLowKickFX(characterIdNumber)
  }
  else if (action === "Jump") {
    renderJumpFX(characterIdNumber)
  }
  else if (action.includes("Run")) {
    renderRunFX(characterIdNumber)
  }
}

export {
  loadAllFXAssets,
  renderFX
}
