const activationFunctionMapping = {
  "relu": 0,
  "elu": 1,
  "sigmoid": 2,
  "tanh": 3
}

const relu = () => {

}

const elu = () => {

}

const sigmoid = () => {

}

const tanh = () => {

}

export {
  activationFunctionMapping,
  relu,
  elu,
  sigmoid,
  tanh
}