import { attributeToDnaDivisor } from './render-helper';

const hairIdxToDetails = {
  0: { name: "Ball Cap", rarity: "Silver", probability: 0.1 },
  1: { name: "Spiky", rarity: "Bronze", probability: 0.15 },
  2: { name: "Bob", rarity: "Bronze", probability: 0.15 },
  3: { name: "Bowl Cut", rarity: "Diamond", probability: 0.04 },
  4: { name: "Beanie", rarity: "Silver", probability: 0.1 },
  5: { name: "Pigtails", rarity: "Gold", probability: 0.07 },
  6: { name: "Afro", rarity: "Silver", probability: 0.1 },
  7: { name: "Bun", rarity: "Bronze", probability: 0.15 },
  8: { name: "Balding", rarity: "Gold", probability: 0.07 },
  9: { name: "Cool Cap", rarity: "Gold", probability: 0.07 },
}

const eyesIdxToDetails = {
  0: { name: "Serious", rarity: "Bronze", probability: 0.15 },
  1: { name: "Lazy", rarity: "Bronze", probability: 0.15 },
  2: { name: "Worried", rarity: "Silver", probability: 0.1 },
  3: { name: "Crazy", rarity: "Diamond", probability: 0.04 },
  4: { name: "Angry", rarity: "Silver", probability: 0.1 },
  5: { name: "Sleepy", rarity: "Silver", probability: 0.1 },
  6: { name: "Old School", rarity: "Gold", probability: 0.07 },
  7: { name: "Squinting", rarity: "Gold", probability: 0.07 },
  8: { name: "Focused", rarity: "Gold", probability: 0.07 },
  9: { name: "Happy", rarity: "Bronze", probability: 0.15 },
}

const mouthIdxToDetails = {
  0: { name: "Smile", rarity: "Bronze", probability: 0.15 },
  1: { name: "Frown", rarity: "Bronze", probability: 0.15 },
  2: { name: "Buck Teeth", rarity: "Silver", probability: 0.1 },
  3: { name: "Spikey Teeth", rarity: "Gold", probability: 0.07 },
  4: { name: "Caveman", rarity: "Gold", probability: 0.07 },
  5: { name: "Afraid", rarity: "Silver", probability: 0.1 },
  6: { name: "Plump Lips", rarity: "Diamond", probability: 0.04 },
  7: { name: "Kissable", rarity: "Gold", probability: 0.07 },
  8: { name: "Nauseous", rarity: "Silver", probability: 0.1 },
  9: { name: "Upset", rarity: "Bronze", probability: 0.15 },
}

const bodyIdxToDetails = {
  0: { name: "Karate Gi", rarity: "Bronze", probability: 0.15 },
  1: { name: "Overalls", rarity: "Silver", probability: 0.1 },
  2: { name: "Sailor Scout", rarity: "Gold", probability: 0.07 },
  3: { name: "Star Dress", rarity: "Bronze", probability: 0.15 },
  4: { name: "Cheerleader", rarity: "Silver", probability: 0.1 },
  5: { name: "Puffer Jacket", rarity: "Gold", probability: 0.07 },
  6: { name: "Office", rarity: "Diamond", probability: 0.04 },
  7: { name: "Workout", rarity: "Bronze", probability: 0.15 },
  8: { name: "Archaeologist", rarity: "Silver", probability: 0.1 },
  9: { name: "Punk", rarity: "Gold", probability: 0.07 },
}

const handsIdxToDetails = {
  0: { name: "Half Gloves", rarity: "Bronze", probability: 0.15 },
  1: { name: "Flameboy", rarity: "Gold", probability: 0.07 },
  2: { name: "Boxing Gloves", rarity: "Silver", probability: 0.1 },
  3: { name: "Mouse Hands", rarity: "Silver", probability: 0.1 },
  4: { name: "Taped Hands", rarity: "Bronze", probability: 0.15 },
  5: { name: "Shredder", rarity: "Gold", probability: 0.07 },
  6: { name: "Happy Hands", rarity: "Bronze", probability: 0.15 },
  7: { name: "Spike Knuckles", rarity: "Silver", probability: 0.1 },
  8: { name: "Star Hands", rarity: "Gold", probability: 0.07 },
  9: { name: "Death Slappers", rarity: "Diamond", probability: 0.04 },
}

const feetIdxToDetails = {
  0: { name: "Spike Cleats", rarity: "Bronze", probability: 0.15 },
  1: { name: "Designer Slides", rarity: "Silver", probability: 0.1 },
  2: { name: "Athletic Sneakers", rarity: "Bronze", probability: 0.15 },
  3: { name: "Skater Dude", rarity: "Silver", probability: 0.1 },
  4: { name: "Cloud Boost", rarity: "Diamond", probability: 0.04 },
  5: { name: "Space Boot", rarity: "Gold", probability: 0.07 },
  6: { name: "Waves", rarity: "Gold", probability: 0.07 },
  7: { name: "Bare Feet", rarity: "Bronze", probability: 0.15 },
  8: { name: "Gum Shoes", rarity: "Silver", probability: 0.1 },
  9: { name: "X-Trainer", rarity: "Gold", probability: 0.07 },
}

const limbsIdxToDetails = {
  0: { name: "No Color", rarity: "Bronze", probability: 0.5, color: "Black" },
  1: { name: "Common Color", rarity: "Silver", probability: 0.15, color: "Red" },
  2: { name: "Common Color", rarity: "Silver", probability: 0.15, color: "Purple" },
  3: { name: "Rare Color", rarity: "Gold", probability: 0.08, color: "Blue" },
  4: { name: "Rare Color", rarity: "Gold", probability: 0.08, color: "Green" },
  5: { name: "Shiny", rarity: "Diamond", probability: 0.04, color: "url(#super-rare-skin)" },
}

const skinIdxToDetails = {
  0: { name: "Human", rarity: "Bronze", probability: 0.22, color: "#ffccaa" },
  1: { name: "Human", rarity: "Bronze", probability: 0.22, color: "#b39462" },
  2: { name: "Human", rarity: "Bronze", probability: 0.22, color: "#755e45" },
  3: { name: "Common Alien", rarity: "Silver", probability: 0.12, color: "#d2a7f2" },
  4: { name: "Common Alien", rarity: "Silver", probability: 0.12, color: "#92e09b" },
  5: { name: "Rare Alien", rarity: "Gold", probability: 0.07, color: "#84b6d9" },
  6: { name: "Shiny", rarity: "Diamond", probability: 0.03, color: "url(#super-rare-skin)" },
}

const attributeToMapping = {
  Hair: hairIdxToDetails,
  Eyes: eyesIdxToDetails,
  Mouth: mouthIdxToDetails,
  Body: bodyIdxToDetails,
  Hands: handsIdxToDetails,
  Feet: feetIdxToDetails,
  Limbs: limbsIdxToDetails,
  Skin: skinIdxToDetails
}

const dnaToDecimal = (attribute, rarityRank) => {
  if (rarityRank === "x") {
    if (attribute === "Hair" || attribute === "Mouth") {
      return 0
    }
    return "x"
  }
  else if (attribute === "Head") {
    return 0
  }

  var cumProb = 0
  for (var i = 0; i < Object.keys(attributeToMapping[attribute]).length; i++) {
    cumProb += attributeToMapping[attribute][i].probability * 100
    if (cumProb >= rarityRank) {
      break
    }
  }
  return i
}

const getPhysicalDetails = (dna) => {
  var rarityRank
  const physicalDetails = {}
  for (var attributeString in attributeToMapping) {
    rarityRank = (dna / attributeToDnaDivisor[attributeString]) % 100
    physicalDetails[attributeString] = attributeToMapping[attributeString][dnaToDecimal(attributeString, rarityRank)]
  }
  return physicalDetails
}

export {
  attributeToMapping,
  dnaToDecimal,
  getPhysicalDetails
}
