// import techyBackground from '../local-assets/landing-page/textures/background/tech-hexagon-tonedown.webp'
import robotBody from '../local-assets/landing-page/textures/robot-body/robot.png'
import toonGradient from '../local-assets/landing-page/textures/toon-gradient-map/three-tone.jpeg'
// import logoLetters from '../local-assets/landing-page/textures/misc/AI-letters.png'
// import worldMap from '../local-assets/landing-page/textures/misc/world-map.png'
// import punchingBagTexture from '../local-assets/landing-page/textures/misc/punching-bag.png'
import circleTexture from '../local-assets/landing-page/textures/misc/circle.png'
// import honeycombTexture from '../local-assets/landing-page/textures/misc/honeycomb.png'

const landingTags = ["landingPage"]

const textures = [
    // {
    //     name: 'techyBackground',
    //     type: 'texture',
    //     tags: landingTags,
    //     path: techyBackground
    // },
    {
        name: 'robotBodyTexture',
        type: 'texture',
        tags: landingTags,
        path: robotBody
    },
    {
        name: 'toonGradient',
        type: 'texture',
        tags: landingTags,
        path: toonGradient
    },
    // {
    //     name: 'logoLetters',
    //     type: 'texture',
    //     tags: landingTags,
    //     path: logoLetters
    // },
    // {
    //     name: 'worldMap',
    //     type: 'texture',
    //     tags: landingTags,
    //     path: worldMap
    // },
    // {
    //     name: 'punchingBagTexture',
    //     type: 'texture',
    //     tags: landingTags,
    //     path: punchingBagTexture
    // },
    {
        name: 'circleTexture',
        type: 'texture',
        tags: landingTags,
        path: circleTexture
    },
    // {
    //     name: 'wormholeTexture',
    //     type: 'texture',
    //     tags: landingTags,
    //     path: honeycombTexture
    // }
]

export default textures