import React, { useState, useEffect } from 'react'
import './benchmark-table.css'

const BenchmarkTable = ({ benchmarkData, setBenchmarkData }) => {
  const [sortingData, setSortingData] = useState({
    rank: true, id: false, score: false, mu: false, range: false, ascending: true
  })

  const getHeaderClass = (key) => {
    const sortingClass = sortingData[key] ? "table--sorting" : ""
    const directionClass = sortingData.ascending ? "table--ascending" : "table--descending"
    return `${sortingClass} ${directionClass}`
  }

  const getSortingData = (tableKey) => {
    setSortingData((prevState) => {
      var prevKey
      Object.keys(prevState).map((key) => {
        if (key !== "ascending") {
          if (prevState[key]) {
            prevKey = key
          }          
          prevState[key] = tableKey === key
        }
      })
      return {
        ...prevState,
        ascending: prevState[prevKey] ? !prevState.ascending : false
      }
    })
  }

  useEffect(() => {    
    var sortingKey
    Object.keys(sortingData).forEach((key) => {
      if (sortingData[key] && key !== "ascending") {
        sortingKey = key
      }
    })
    setBenchmarkData((prevState) => {
      const sortedBenchmarkData = prevState.sort((a, b) => (
        sortingData.ascending ? a[sortingKey] > b[sortingKey] : a[sortingKey] < b[sortingKey]
      ) ? 1 : -1)
      return [...sortedBenchmarkData]
    })
  }, [sortingData])

  return (
    <>
      <div id="leaderboard__container">
        <table id="leaderboard">
          <thead>
            <tr id="leaderboard__header">
              <th 
                className="leaderboard__header-option" 
                onClick={() => getSortingData("rank")}>
                  <div>
                    <p>Rank</p>
                    <div className={getHeaderClass("rank")}></div>
                  </div>                                
              </th>
              <th 
                className="leaderboard__header-option" 
                onClick={() => getSortingData("id")}>
                  <div>
                    <p>Model ID (Version)</p>
                    <div className={getHeaderClass("id")}></div>
                  </div>                                
              </th>
              <th 
                className="leaderboard__header-option" 
                onClick={() => getSortingData("score")}>
                  <div>
                    <p>Score</p>
                    <div className={getHeaderClass("score")}></div>
                  </div>                                
              </th>
              <th 
                className="leaderboard__header-option" 
                onClick={() => getSortingData("mu")}>
                  <div>
                    <p>Score Mean</p>
                    <div className={getHeaderClass("mu")}></div>
                  </div>                                
              </th>
              <th 
                className="leaderboard__header-option" 
                onClick={() => getSortingData("range")}>
                  <div>
                    <p>Score Range</p>
                    <div className={getHeaderClass("range")}></div>
                  </div>                                
              </th>
            </tr>
          </thead>
          <tbody>
            {
              benchmarkData.map((rowData, idx) => {
                return (
                  <tr key={`row--${idx}`} className="leaderboard__row">
                    <td>
                      <p>{rowData.rank}</p>
                    </td>
                    <td>
                      <p>{rowData.id} <span>v{rowData.version}</span></p>
                    </td>
                    <td>
                      <p>{rowData.score}</p>
                    </td>
                    <td>
                      <p>{rowData.mu}</p>
                    </td>
                    <td>
                      <p>+/- {rowData.range}</p>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default BenchmarkTable