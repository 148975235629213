import { fogPrepend, fogAppend } from "../utils/fog"

const surfaceFragmentShader = `
  varying vec3 vertex;
  varying vec2 vUv;

  uniform float lineWidth;
  uniform vec3 highlightColor;
  uniform vec2 highlightPosition;
  ${fogPrepend}

  void main() {
    // Pick a coordinate to visualize in a grid
    vec2 coord = vertex.xy;

    // Compute anti-aliased world-space grid lines
    vec2 grid = abs(fract(coord - 0.5) - 0.5) / (lineWidth * fwidth(coord));
    float line = min(grid.x, grid.y);

    // Just visualize the grid lines directly
    vec3 color = highlightColor;
    float opacity = 1.0 - min(line, 1.0);

    float strength = 0.015 / distance(vUv, highlightPosition);

    // Apply gamma correction
    // gl_FragColor = vec4(color * strength, opacity);
    gl_FragColor = vec4(color * 0.7, opacity);
    ${fogAppend}
  }
`

export {
  surfaceFragmentShader
}