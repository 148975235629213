const reverseMapping = (mapping) => {
  const newMapping = {}
  Object.keys(mapping).forEach((key) => {
    newMapping[mapping[key]] = key
  })
  return newMapping
}

export {
  reverseMapping
}