import React, { useState } from 'react';
import { createMusicAssets } from "./helpers/asset-files/music-mapping"

const initialState = {
  web3: undefined,
  network: undefined,
  networkName: undefined,
  smartContract: undefined,
  neuralNetworkContract: undefined,
  userAddress: undefined,
  username: undefined,
  yourFighter: undefined,
  musicMuted: true,
  songPlaying: undefined,
  volumeSettings: {music: 0.5, fx: 0.5},
  musicAssets: createMusicAssets(),
  guestMode: true,
  hasAccess: true,
  numBenchmarks: 2
}

export const Context = React.createContext();

const Store = ({ children }) => {
  const [websiteContext, setWebsiteContext] = useState(initialState)

  return (
    <Context.Provider value={[websiteContext, setWebsiteContext]}>
      {children}
    </Context.Provider>
  )
}

export default Store;
