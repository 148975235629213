import { actionsList } from '../game-setup/actions'

const actionToIdxMapping = {}
for (var i = 0; i < actionsList.length; i++) {
  actionToIdxMapping[actionsList[i]] = i
}

export class agentSihing {
  constructor() {
    this.type = "rules-based-agent"
  }

  selectAction(state) {
    const [
      relativeDistance,
      youFacingOpponent,
      opponentFacingYou,
      yourHealth,
      opponentHealth,
      relativeStrength,
      relativeSpeed,
      relativeDefence,
      relativeAccuracy
    ] = state[0]

    var action = "Single Punch"

    const rightSide = Math.sign(relativeDistance) > 0
    const absDistance = Math.abs(relativeDistance)
    
    if (absDistance > 0.15) {
      action = `Run ${rightSide ? "Left" : "Right"}`
    }
    else {
      if (youFacingOpponent === -1 && opponentFacingYou === 1) {
        action = `Jump ${rightSide ? "Left" : "Right"}`
      }
      else if (youFacingOpponent === 1 && opponentFacingYou === -1) {
        if (absDistance <= 0.1) {
          action = Math.random() < 0.5 ? "Single Punch" : "Low Kick"
        }
        else {
          action = `Run ${rightSide ? "Left" : "Right"}`
        }
      }
      else if (youFacingOpponent === -1 && opponentFacingYou === -1) {
        action = `Run ${rightSide ? "Left" : "Right"}`
      }
      else {
        const relativeHealth = yourHealth - opponentHealth
        if (yourHealth < 0.2 && relativeHealth < 0) {
          action = `${opponentFacingYou === 1 ? "Jump" : "Run"} ${rightSide ? "Left" : "Right"}`
        }
        else {
          action = Math.random() < 0.5 ? "Single Punch" : "Low Kick"
        }
      }
    }

    return [actionToIdxMapping[action]]
  }
}